import React from "react"
// import * as Const from "./../../../constants/Const"
// import * as Fc from "./../../../utils/Functions"
import { useSelector, useDispatch } from 'react-redux'
import { Link, Redirect, Route, useHistory } from "react-router-dom"
import ButtonGoBack from '../../../components/ButtonGoBack'
import Loading from "../../../components/Loading"
import PopupChat from './../../../components/PopupChat'
//screens
import ChangePassword from "../../Login/_ChangePassword"
import * as Const from './../../../constants/Const'
import * as type from "../../../constants/actionType"
import * as Fc from "./../../../utils/Functions"
import Edit from "./../Edit"
import MyWork from "./../MyWork"

import HeaderDesktop from './../../../components/HeaderDesktop/HeaderDesktop'
import HeaderEdit from './../../../components/HeaderDesktop/HeaderEdit'
import {
  isDesktop,
  isTablet, isIPad13,
  isMobile
} from "react-device-detect"


function LayoutComponent(props) {
  let token = useSelector(state => state.ws.token)
  const dispatch = useDispatch();
  const {
    hideMenu,
    showMenu,
    matchUrl,
    logOut,
    buttonEl,
    operatorInfo,
    openPopup,
    setOpenPopup,
    socket,
    message,
    setMessage,
    messageList,
    setMessageList,
    role,
    listClass,
    ClassId,
    setClassId, ListStudent,
    ListTeacher,
    setSelectedId,
    selectedId,
    room,
    tabChat, setTabChat,
    userType, setUserType,
    userName, setUserName,
    getListNotif, scrollToBottom, messageEndRef, getListMessageInGroup,
    GetChatMessages,
    getDetailClass,
    setPageIndex,
    onScrollTop,
    pageIndex
  } = props

  let history = useHistory();
  let user = useSelector(state => state.ws.userLogin)
  let countNotif = useSelector(state => state.ws.countNotif)

  const sendMessage = async () => {
    if (message === "") {
      return
    }

    if (message === "") {
      return
    }
    let toName = ""
    let to = ""
    let toRoom = room
    let toRole = ""

    if (selectedId === "" && tabChat === 'contactAdmin') {
      toName = ""
      to = `${Fc.getOperator()}|${Fc.getUserId()}`
      toRoom = Fc.getOperator()
      toRole = userType

    } else if (selectedId !== "" && selectedId !== "Teacher/Student") {
      toName = userName
      to = selectedId
      toRole = userType

    } else {
      toName = ClassId
      to = room
      toRole = "CLASS"
    }


    let messageContent = {
      room: toRoom,
      Token: Fc.getToken(),
      content: {
        From: Fc.getUserId(),
        To: to,
        Type: (selectedId !== "" && selectedId !== "Teacher/Student") || tabChat === 'contactAdmin' ? "normal" : "groupchat",
        Body: message,
        FromRole: Fc.getRole(),
        // ToRole: selectedId !== "" ? userType : "CLASS",
        ToRole: toRole,
        FromName: user.FirstName + " " + user.LastName,
        // ToName: selectedId !== "" ? userName : ClassId,
        ToName: toName,
        ClassId: ClassId,
        CreatedDate: new Date()
      },
    };

    await socket.emit("send_message", messageContent);

    setMessageList([...messageList, messageContent.content]);
    setMessage("");
  };

  // if (Fc.getToken() === null) {
  if (token === null || token === '' || Fc.getToken() === null) {
    return (
      <Redirect to='/' />
    )
  } else
    return (
      <>
        <Loading />
        <div className="bg-xam77"></div>
        <b className="screen-overlay"></b>
        <aside className="offcanvas" id="my_offcanvas1">
          <header className="mt-5a">
            <button className="btn btn-close">
              <i className="fas fa-times fa-2x text-white"></i>{" "}
            </button>
          </header>
          <nav className="list-group list-group-flush">
            <Link
              onClick={hideMenu}
              to={`${matchUrl.url}`}
              className="list-group-item bg-transparent font-Paytone font18 border-0"
            >
              My Work
          </Link>
            <Link
              onClick={hideMenu}
              to={`${matchUrl.url}/ChangePassword`}
              className="list-group-item bg-transparent font-Paytone font18 border-0"
            >
              Change Password
          </Link>
            <Link
              to="#"
              onClick={logOut}
              className="list-group-item bg-transparent font-Paytone font18 border-0"
            >
              <span className="text-danger">Log Out</span>
            </Link>
          </nav>
        </aside>

        {(isDesktop && !isMobile)
          && ((history.location.pathname.includes('/Edit/Assignment') || history.location.pathname.includes('/Edit/Notes') || history.location.pathname.includes('/Edit/Document'))
            ? <HeaderEdit operatorInfo={operatorInfo} />
            : <HeaderDesktop
              role={role}
              hideMenu={hideMenu}
              matchUrl={matchUrl}
              operatorInfo={operatorInfo}
              logOut={logOut}
            />)
        }
        {(!isDesktop || isIPad13) && <nav className="header fixed-top p-3 z-5 drop-shadow">
          <div className="row align-items-center">
            <div className="col-auto">
              <Link to="#" ref={buttonEl} onClick={showMenu} data-trigger="#my_offcanvas1">
                <i className="fas fa-bars fa-2x"></i>
              </Link>
            </div>
            <div className="mx-auto">
              <h3 className="font-Paytone">{!(operatorInfo.BusinessName || operatorInfo.FullName) ? 'Epen' :
                (operatorInfo.BusinessName || operatorInfo.FullName)}</h3>
            </div>
            <div className="col-auto">
              <Route path={`${matchUrl.path}/ChangePassword`}>
                <ButtonGoBack
                  handleClick={() => {
                    hideMenu()
                    history.push(`${matchUrl.url}`)
                  }}
                />
              </Route>
              <Route path={`${matchUrl.path}/Edit/:type/:assignId/:pageIndex`}>
                <ButtonGoBack
                  handleClick={() => {
                    // hideMenu()
                    dispatch({ type: type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Exercise' })
                    dispatch({ type: type.SAVE_PAGEINDEX_WS, payload: null })
                    dispatch({ type: type.SAVE_PAGEINDEX_NOTE, payload: 0 })
                    history.push(`${matchUrl.url}`);
                  }}
                />
              </Route>
            </div>
          </div>
        </nav>}

        <main>
          <Route path={`${matchUrl.path}`} exact component={MyWork} />
          <Route path={`${matchUrl.path}/ChangePassword`}>
            <div className="bg-xanh"></div>
            {/* <div className="row">
              <div className="col-6 offset-3" style={{top: "150px"}}>
                <div className="row align-items-center">
                  <div className="mx-auto">
                    <h3 className="font-Paytone">Change Password</h3>
                  </div>
                </div>
              </div>
            </div> */}
            <ChangePassword {...props} />
          </Route>

          <Route path={`${matchUrl.path}/Edit/:type/:assignId/:pageIndex`} component={Edit} />
          {/* <Route path={`${matchUrl.path}/Edit/:type/:assignId/:classId/:assignDate`} component={Edit} /> */}
          {Fc.getRole() === Const.Role.STUDENT && <div className="chat" style={{ zIndex: 1000 }}>
            {countNotif > 0 && <span className="badge radius-10 badge-danger position-absolute badge-form">{countNotif}</span>}
            <button
              className="button-show-popupchat"
              type="button"
              style={{ backgroundColor: 'transparent' }}
              onClick={() => { setOpenPopup(true) }}
            ><i className="fas fa-comments fa-2x" /></button>
          </div>}

          {openPopup &&
            <PopupChat
              role={role}
              message={message}
              setMessage={setMessage}
              messageList={messageList}
              setOpenPopup={setOpenPopup}
              sendMessage={sendMessage}
              listClass={listClass}
              ClassId={ClassId}
              setClassId={setClassId}
              ListStudent={ListStudent}
              ListTeacher={ListTeacher}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              tabChat={tabChat} setTabChat={setTabChat}
              userType={userType} setUserType={setUserType}
              userName={userName} setUserName={setUserName}
              getListNotif={getListNotif}
              scrollToBottom={scrollToBottom}
              messageEndRef={messageEndRef}
              getListMessageInGroup={getListMessageInGroup}
              GetChatMessages={GetChatMessages}
              getDetailClass={getDetailClass}
              setPageIndex={setPageIndex}
              onScrollTop={onScrollTop}
              pageIndex={pageIndex}
            />}
        </main>
      </>
    )
}

export default LayoutComponent
