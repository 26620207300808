import React from 'react'

function LayoutSearch(props) {
    const {
        listCategories, listLevel, ListSubject,
        resetForm, handleSubmit,
        ChangeLevel,
        keyword, setKeyword, buttonEl,
        ChangeCategories, ChangeSubject, ChangeBookName,
        bookNameRef, categoryRef, subjectRef, levelRef
    } = props
    return (
        <div className="modal-dialog modal-epen">
            <div className="modal-content radius-0 border-0 bg-epen pte-auto">
                <div className="modal-header border-bottom-0">
                    <button type="button" ref={buttonEl} className="close1" data-dismiss="modal"><i className="fas fa-times fa-2x"></i></button>
                </div>
                <div className="modal-body">
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <form className="form-row" onSubmit={handleSubmit}>
                                <div className="col-md-4 mb-2">
                                    <select name="Category" className="custom-select" ref={categoryRef} onChange={ChangeCategories}>
                                        <option value="">All Categories</option>
                                        {listCategories.map((item, index) => {
                                            return (
                                                <option value={item.value} key={index.toString()}>{item.label}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <select name="Subject" className="custom-select" ref={subjectRef} onChange={ChangeSubject}>
                                        <option value="">All Subject</option>
                                        {ListSubject.map((item, index) => {
                                            return (
                                                <option value={item.value} key={index.toString()}>{item.label}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <select name="Subject" className="custom-select" ref={levelRef} onChange={ChangeLevel}>
                                        <option value="">All Level</option>
                                        {listLevel.map((item, index) => {
                                            return (
                                                <option value={item.value} key={index.toString()}>{item.label}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <input type="text" name="bookName" id="bookName" className="form-control" placeholder="Enter book's name..."
                                        ref={bookNameRef}
                                        onChange={ChangeBookName}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <input type="text" name="keyword" id="keyword" className="form-control" placeholder="Enter keywords..."
                                        value={keyword}
                                        onChange={(e) => { setKeyword(e.target.value);}}
                                    />
                                </div>
                                <div className="col-auto mr-auto">
                                    <button
                                        type="button"
                                        className="btn font-Paytone pl-4 pr-4 text-white"
                                        onClick={resetForm}
                                    >
                                        Reset
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button type="submit" className="btn btn-light font-Paytone pl-4 pr-4" >Search</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal-footer border-top-0">
                </div>

            </div>
        </div>
    );
}
export default LayoutSearch;