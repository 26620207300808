/* eslint-disable no-useless-escape */
import { toast } from "react-toastify"
import _ from 'lodash'

const tokenKey = "token"
const roleKey = "role"
const operatorKey = "operator"
const userIdKey = "userid"
const AccountMethod = "AccountMethod"

export function showLoading() {
  let elm = document.getElementById('circle');
  if(!elm) return
  elm.classList.add("d-block");
}

export function hideLoading() {
  let elm = document.getElementById('circle');
  if(!elm) return
  elm.classList.remove("d-block");
}

export function setToken(token) {
  sessionStorage.setItem(tokenKey, token)
}

export function getToken() {
  return sessionStorage.getItem(tokenKey)
}

export function setRole(role) {
  sessionStorage.setItem(roleKey, role)
}

export function getRole() {
  return sessionStorage.getItem(roleKey)
}

export function setOperator(operator) {
  sessionStorage.setItem(operatorKey, operator)
}

export function getOperator() {
  return sessionStorage.getItem(operatorKey)
}

export function setUserId(userId) {
  sessionStorage.setItem(userIdKey, userId)
}

export function getUserId() {
  return sessionStorage.getItem(userIdKey)
}

export function setAccountMethod(method) {
  sessionStorage.setItem(AccountMethod, method)
}

export function getAccountMethod() {
  return sessionStorage.getItem(AccountMethod)
}

export function validateEmail(email) {
  const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

export function validatePhone(phone) {
  const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  return regex.test(phone)
}

export function showError(text) {
  return toast.error(text, {
    position: "bottom-right",
    hideProgressBar: true,
    autoClose: 3000,
  })
}

export function showSuccess(text) {
  return toast.info(text, {
    position: "bottom-right",
    hideProgressBar: true,
    autoClose: 3000,
  })
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

/* Get into full screen */
export function GoInFullscreen(element) {
  if(element.requestFullscreen)
    element.requestFullscreen();
  else if(element.mozRequestFullScreen)
    element.mozRequestFullScreen();
  else if(element.webkitRequestFullscreen)
    element.webkitRequestFullscreen();
  else if(element.msRequestFullscreen)
    element.msRequestFullscreen();
}

/* Get out of full screen */
export function GoOutFullscreen() {
	if(document.exitFullscreen)
		document.exitFullscreen();
	else if(document.mozCancelFullScreen)
		document.mozCancelFullScreen();
	else if(document.webkitExitFullscreen)
		document.webkitExitFullscreen();
	else if(document.msExitFullscreen)
		document.msExitFullscreen();
}



/* Is currently in full screen or not */
export function IsFullScreenCurrently(element) {
	var full_screen_element = element.fullscreenElement || element.webkitFullscreenElement || element.mozFullScreenElement || element.msFullscreenElement || null;
  
  console.log('HUYNH: ', full_screen_element)
	// If no element is in full-screen
	if(full_screen_element === null)
		return false;
	else
		return true;
}

export function sortAndGroupDatetime(data, groupDate = 'CreatedDate', sortFilter = 'DateSort') {
  const sortData = _.groupBy(
    _.map(_.sortBy(data, groupDate), item => {

      let dateMonth = new Date(item.CreatedDate)

      return {
        ...item, 
        DateSort: dateMonth.getDate() + '/' + (dateMonth.getMonth() + 1) + '/' +dateMonth.getFullYear(),
        Time: (dateMonth.getHours() < 10 ? '0' + dateMonth.getHours() : dateMonth.getHours()) 
          + ':' + (dateMonth.getMinutes() < 10 ? '0' + dateMonth.getMinutes() : dateMonth.getMinutes())
      }
    })
  , sortFilter)

  let listDataSort =[]
  _.forIn(sortData, (value, key) => {
    listDataSort.push({ date: key, data: value})
  });
  return listDataSort
}