import React, { useState, useEffect, useRef } from 'react'
import API from '../../../utils/Api'
import * as Fc from '../../../utils/Functions'
import * as Const from '../../../constants/Const'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import UploadDocumentsView from './UploadDocumentsView'
import useIsMounted from '../../../hooks/useIsMounted'
import * as type from "../../../constants/actionType"

export default function UploadDocuments(props) {
    const role = Fc.getRole()
    const accountMethod = Fc.getAccountMethod()
    const matchUrl = useRouteMatch()
    const operatorId = role === Const.Role.VENDOR ? matchUrl.params.id : Fc.getOperator()

    const [showUploadModal, setShowUploadModal] = useState(false)
    const [file, setFile] = useState({})
    const [fileTitle, setFileTitle] = useState('')
    const [onSendingAPI, setOnSendingAPI] = useState(false)
    const [listFiles, setListFile] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedDocumentid, setSelectedDocumentid] = useState('')
    const [isShowAddAlertModal, setShowAddAlertModal] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [isShowAddErrorModal, setShowAddErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [firstDocumentId, setFirstDocumentId] = useState('')
    const [isShowDocumentUploadPage, setShowDocumentUploadPage] = useState(false)
    const [messageForDocumentUploadPage, setMessageForDocumentUploadPage] = useState('')
    const [pageIndex, setPageIndex] = useState(1)
    const pageSize = 20
    const [total, setTotal] = useState(0)
    const [keyword, setKeyword] = useState('')
    const dispatch = useDispatch();

    let classIdRedux = useSelector(state => state.ws.classId)
    let studentId = useSelector(state => state.ws.studentId)
    let isMounted = useIsMounted();
    let keywordRef = useRef('');
    
    function setClassId(id) {
        dispatch({ type: type.SAVE_CLASS_ID, classId: id })
    }

    useEffect(() => {
        classIdRedux !== "" && getDocuments()
        if (role !== Const.Role.VENDOR && accountMethod !== Const.AccountMethod.PAID)
            getDayLeft()
        return () => { Fc.hideLoading() }
    }, [])

    useEffect(() => {
        keywordRef.current = keyword;
        let timerStr = setTimeout(() => {
            studentId && getDocuments()
        }, 500)
        return () => clearTimeout(timerStr) 
    }, [studentId, classIdRedux, keyword])

    useEffect(() => {
        const el = document.getElementById("list")
        el.addEventListener("scroll", loadMore)
        return function cleanupListener() {
            el.removeEventListener("scroll", loadMore)
        }
    }, [pageIndex])


    function loadMore() {
        const el = document.getElementById("list")
        if (el.scrollTop + el.clientHeight > el.scrollHeight - 100) {
            if (pageSize * pageIndex >= total) {
                return null
            } else {
                setPageIndex(pageIndex + 1)
            }
        }
    }

    function openUploadModal() {
        setShowUploadModal(true)
    }

    function hideUploadModal() {
        setShowUploadModal(false)
        setFileTitle('')
        setFile({})
    }

    function upload() {
        setShowUploadModal(false)
        Fc.showLoading()
        setFile({})
        setFileTitle('')
        var formData = new FormData();
        formData.append("file", file);
        !onSendingAPI && API.uploadDocumentForStudent(fileTitle, props.studentId, classIdRedux, formData).then(res => {
            if(!isMounted.current) return
            Fc.hideLoading()
            setOnSendingAPI(false)
            if (res.status === 200 && res.data.Success && isMounted.current) {

                getFirstDocument()
                // setShowUploadModal(false)
                if (pageIndex !== 1)
                    setPageIndex(1)
                else {
                    getDocuments()
                }
                ShowAddAlert()
                Fc.showSuccess("Upload success")
            } else {
                if (res.data.Message === "This feature is locked. Upgrade now to use all Epen App features" && isMounted.current) {
                    setErrorMessage("This feature is locked. Upgrade now to use all Epen App features")
                    setShowUploadModal(false)
                    ShowAddError()
                } else
                    Fc.showError(res.data.Message || res.statusText)
            }
        }).catch(err => {
            Fc.hideLoading()
            setOnSendingAPI(false)
            console.log(err);
            Fc.showError("Can not connect to server")
        })
        if(!isMounted.current) return
        setOnSendingAPI(true)
    }

    function getDocuments() {
        if(keywordRef.current.includes("#") || keywordRef.current.includes("/")) {
             return
        }
        
        API.searchDocumentForStudent(keywordRef.current, props.studentId, classIdRedux, pageIndex, pageSize)
            .then(res => {
                if(!isMounted.current) return
                if (res.status && res.data.Success && isMounted.current) {
                    setListFile(pageIndex === 1 ? res.data.Data : listFiles.concat(res.data.Data))
                    setTotal(res.data.Total)
                } else {
                    console.log(res);
                    Fc.showError(res.data.Message || res.statusText)
                }
            }).catch((err) => {
                console.log(err);
                Fc.showError("Can not connect to server")
            })
    }

    function getFirstDocument() {
        API.GetFirstDocumentOfStudentAndClass(props.studentId, classIdRedux)
            .then((res) => {
                if(!isMounted.current) return
                if (res.status === 200 && res.data.Success) {
                    if (res.data.Data) {
                        setFirstDocumentId(res.data.Data._id);
                    } else {
                        setFirstDocumentId("")
                    }
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            })
            .catch((err) => {
                console.log(err);
                Fc.showError("Can not connect to server")
            })
    }

    function openDeleteModal(id) {
        setSelectedDocumentid(id)
        setShowDeleteModal(true)
    }

    function hideDeleteModal() {
        setSelectedDocumentid('')
        setShowDeleteModal(false)
    }

    function deleteDocument() {
        API.deleteDocument(selectedDocumentid).then(res => {
            if(!isMounted.current) return
            if (res.status === 200 && res.data.Success && isMounted.current) {
                Fc.showSuccess("Delete success")
                setShowDeleteModal(false)
                if (pageIndex !== 1)
                    setPageIndex(1)
                else
                    getDocuments()
            } else {
                Fc.showError(res.data.Message || res.statusText)
            }
        }).catch(err => {
            console.log(err);
            Fc.showError("Can not connect to server")
        })
    }

    function onHideAddAlert() {
        setShowAddAlertModal(false)
    }

    function onHideAddError() {
        setShowAddErrorModal(false)
    }

    function ShowAddAlert() {
        if (total >= 1)
            setShowAddAlertModal(true)
    }

    function ShowAddError() {
        setShowAddErrorModal(true)
    }

    function getDayLeft() {
        API.GetDayLeftToRestriction(operatorId)
            .then((res) => {
                if(!isMounted.current) return
                if (res.status === 200 && res.data.Success && isMounted.current) {
                    if (res.data.Data) {
                        setAlertMessage(`This document will be locked after ${res.data.Data} days.`)
                    }
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            })
            .catch((err) => {
                console.log(err);
                Fc.showError("Can not connect to server")
            })
    }

    function onSearch(e) {
        e && e.preventDefault()
        if (pageIndex === 1)
            getDocuments()
        else
            setPageIndex(1)
    }

    var prevScrollpos = window.pageYOffset;
    if(window.innerWidth < 576){
        window.onscroll = function() {
            var currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
                document.getElementById("scrollTop").style.marginTop = "58px";
            } else {
                document.getElementById("scrollTop").style.marginTop = "-120px";
            }
            prevScrollpos = currentScrollPos;
        }
    }
    return (
        <UploadDocumentsView
            showUploadModal={showUploadModal} hideUploadModal={hideUploadModal} openUploadModal={openUploadModal}
            file={file} setFile={setFile}
            fileTitle={fileTitle} setFileTitle={setFileTitle}
            upload={upload}
            classId={classIdRedux} setClassId={setClassId}
            getDocuments={getDocuments}
            listFiles={listFiles}
            showDeleteModal={showDeleteModal} openDeleteModal={openDeleteModal}
            hideDeleteModal={hideDeleteModal}
            deleteDocument={deleteDocument}
            matchUrl={matchUrl}
            setShowUploadModal={setShowUploadModal}
            isShowAddAlertModal={isShowAddAlertModal}
            onHideAddAlert={onHideAddAlert}
            alertMessage={alertMessage}
            isShowAddErrorModal={isShowAddErrorModal}
            onHideAddError={onHideAddError}
            errorMessage={errorMessage}
            firstDocumentId={firstDocumentId}

            isShowDocumentUploadPage={isShowDocumentUploadPage}
            setShowDocumentUploadPage={setShowDocumentUploadPage}
            messageForDocumentUploadPage={messageForDocumentUploadPage}
            setMessageForDocumentUploadPage={setMessageForDocumentUploadPage}

            keywor={keyword} setKeyword={setKeyword}
            onSearch={onSearch}
            {...props} />
    )
}
