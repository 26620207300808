import React from "react"

export default function StopwatchView(props) {

    let { milisecs,
        isPause, setIsPause,
        reset
    } = props

    return (
        <div className="tran-70 box-time1 w200 mobile-d-none" style={{ zIndex: 10 }}>
            <button type="button" className="btn btn-time"><i className={`fas ${isPause ? "fa-play" : "fa-pause"}`}
                onClick={() => { setIsPause(!isPause) }}></i></button>
            <span className="font-Paytone box-time" style={{ minWidth: 110, textAlign: 'left', marginLeft: 10 }}>
                {parseInt(milisecs / 60000)}:{(parseInt((milisecs % 60000) / 1000)).toString().padStart(2, '0')}.{((milisecs % 1000) / 10).toString().padStart(2, '0')}
            </span>
            <span className="bg-black pt-1 pb-1 pl-2 pr-2 float-right" onClick={reset}>
                <i className="fas fa-stopwatch"></i>
            </span>
        </div>
    )
}
