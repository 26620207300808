import React from 'react';
import { Role } from '../../constants/Const'
import moment from 'moment';
import './style.css'
import classNames from 'classnames'

function NotifChat(props) {
    const {
        notification,
        countNotif,
        closePopup,
        scrollToBottom,
        seenNotification,
        listClass
    } = props

    return (
        <div className="position-absolute bt-chat">
            <div className="dropdown float-left">
                <button type="button" className="btn dropdown-toggle text-white-50" data-toggle="dropdown">
                    <i className="fas fa-bell" />
                    {countNotif > 0 && <span className="badge badge-danger position-absolute badge1">{countNotif}</span>}
                </button>
                <div className="dropdown-menu dropdown-menu-right w-300 py-0 bg-chat-than border-dark">
                    <div className="hidden-scrollbar-noti">
                        <div className="inner-noti">
                            <h6 className="font-Paytone pl-3 mb-0 text-white-50">Notification</h6>
                            {
                                notification && notification.map((item, index) => {
                                    const clsName = listClass && listClass.length > 0 && listClass.find(i => item.ClassId === i._id) || null
                                    return (
                                        <div
                                            key={index.toString()}
                                            className="box-noti px-3 py-2 pointer"
                                            onClick={() => {
                                                seenNotification(item.From, item.To, item.Type, item.FromRole, item.ClassId, item.ToRole, item)
                                            }}
                                        >
                                            <div className="media" onClick={scrollToBottom}>
                                                <div className="media-body">
                                                    <h6 className="mb-0 text-white">
                                                        {item.FromRole === Role.VENDOR ? item.FromRole : item.FromName}
                                                        {
                                                            item.Type === "groupchat" &&
                                                            <span style={{fontSize: 12}}>{clsName ? " - " + clsName.Name  : ""}</span>
                                                        }
                                                    </h6>
                                                    <p className="gioihan-1dong mb-0  d-flex justify-content-between align-items-center">
                                                        <small className="text-white-50">{item.Body}</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <button
                type="button"
                className="btn text-muted"
                onClick={closePopup}
            ><i className="fas fa-times" /></button>
        </div>
    );
}

export default NotifChat;