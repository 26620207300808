import * as type from './../constants/actionType'

const InitialState = {
    selectWs: [],
    token: "",
    role: [],
    classId: "",
    userInfo: {},
    activeTab: "Workbook",
    socket: {},
    permissionSolution: true,
    permissionWriteNote: true,
    listRoom: [],
    userLogin: {},
    notifyArr: [],
    notify: [],
    notifyContent: '',
    countNotif: 0,
    studentId: "",
    assignmentDate: "",
    params: null,
    headerEditActive: "Exercise",
    preHeaderEditActive: "Exercise",
    currentChapter: null,
    currentSession: null,
    isLandscape: false,
    isNotReload: false,
    studentClassList: [],
    currentChat: null,
    isLoading: false,
    pageIndexWs: null,
    pageIndexNote: 0
}
const reducer = (state = InitialState, action) => {
    switch (action.type) {
        case type.SELECTWS:
            return { ...state, selectWs: action.payload };

        case type.SAVE_TOKEN:
            return { ...state, token: action.payload };

        case type.SAVE_ROLE:
            return { ...state, role: action.payload };

        case type.SAVE_CLASS_ID:
            return { ...state, classId: action.classId };

        case type.SAVE_USER_INFO:
            return { ...state, userInfo: action.payload };

        case type.SET_ACTIVE_TAB_MYWORK:
            return { ...state, activeTab: action.activeTab };

        case type.SET_SELECTED_FOLDER:
            return { ...state, selectedFolder: action.selectedFolder };

        case type.SET_STUDENT_ID:
            return { ...state, studentId: action.studentId };

        case type.CLEAR_DATA:
            return state;

        case type.SOCKET_STORE:
            return { ...state, socket: action.payload };

        case type.PERMISSION_SOLUTION:
            return { ...state, permissionSolution: action.payload };

        case type.PERMISSION_WRITENOTE:
            return { ...state, permissionWriteNote: action.payload };

        case type.SAVE_LIST_ROOM:
            return { ...state, listRoom: action.payload };

        case type.SAVE_USER_LOGIN:
            return { ...state, userLogin: action.payload };

        case type.SAVE_NOTIFY_ARRAY:
            return { ...state, notifyArr: action.payload };

        case type.SAVE_NOTIFY:
            return { ...state, notify: action.payload };

        case type.SAVE_NOTIFY_CONTENT:
            return { ...state, notifyContent: action.payload };

        case type.COUNT_NOTIFY:
            return { ...state, countNotif: action.payload };

        case type.SAVE_PARAMS_SEARCH:
            return { ...state, params: action.payload }

        case type.SAVE_ASSIGNMENT_DATE:
                return { ...state, assignmentDate: action.payload }

        case type.SAVE_HEADER_EDIT_ACTIVE:
            return { ...state, headerEditActive: action.payload }

        case type.SAVE_PRE_HEADER_EDIT_ACTIVE:
            return { ...state, preHeaderEditActive: action.payload }

        case type.SAVE_CURRENT_CHAPTER:
            return { ...state, currentChapter: action.payload }

        case type.SAVE_CURRENT_SESSION:
            return { ...state, currentSession: action.payload }

        case type.CHANGE_ORIENTATION:
            return { ...state, isLandscape: action.payload }

        case type.CHANGE_NOT_RELOAD:
            return { ...state, isNotReload: action.payload }

        case type.SAVE_STUDENT_CLASS_LIST:
            return { ...state, studentClassList: action.payload }
             
        case type.SAVE_CURRENT_CHAT:
            return { ...state, currentChat: action.payload }

        case type.SAVE_IS_LOADING:
            return { ...state, isLoading: action.payload }

        case type.SAVE_PAGEINDEX_WS:
            return { ...state, pageIndexWs: action.payload }

        case type.SAVE_PAGEINDEX_NOTE:
            return { ...state, pageIndexNote: action.payload }
                
        default:
            return state;
    }
}

export default reducer;