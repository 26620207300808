/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Browser from './Browse'
import * as Fc from '../../../utils/Functions'
import API from "../../../utils/Api"
import * as Const from "../../../constants/Const";
import { useRouteMatch } from "react-router-dom";

function BrowseIndex(props) {
    // const operatorId = Fc.getRole().includes(Const.Role.VENDOR) ? props.match.params.id : Fc.getOperator()

    const [username, setUsername] = useState('')
    const matchUrl = useRouteMatch()
    const [listWorkBook, setListWorkBook] = useState([])
    const [pageIndex, setPageIndex] = useState(1)
    const [total, setTotal] = useState(0)
    const pageSize = 0

    useEffect(() => {
        API.getAccountInfo().then(res => {
            if (res.status === 200 && res.data.Success) {
                if (Fc.getRole() === Const.Role.VENDOR) {
                    //vendor show email 
                    setUsername(res.data.Data.Email)
                } else if (Fc.getRole() === Const.Role.OPERATOR) {
                    //operator show account id
                    setUsername(res.data.Data.Email)
                } else {
                    setUsername(res.data.Data.SSOAccountId)
                }
            } else {
                if (res.data.Message)
                    Fc.showError(res.data.Message)
                else
                    Fc.showError(res.statusText)
            }
        }).catch(err => {
        })

    }, [])

    // Pagination

    useEffect(() => {
        const el = document.getElementById("iScroll")
        el.addEventListener("scroll", loadMore)

        return function cleanupListener() {
            el.removeEventListener("scroll", loadMore)
        }
    }, [pageIndex, total])

    function loadMore() {
        const el = document.getElementById("iScroll")
        if (el.scrollTop + el.clientHeight > el.scrollHeight - 100) {
            if (pageSize * pageIndex >= total) {
                return null
            } else {
                setPageIndex(1)
            }
        }
    }
    // End

    useEffect(() => {
        // console.log(props.location.search);
        let params = getQueryParams()
        getListWorkBook(params.category, params.subject, params.level, params.bookname, pageIndex, pageSize)
    }, [props.location.search, pageIndex])

    const getQueryParams = () => props.location.search.replace('?', '').split('&').reduce((r, e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});

    function getListWorkBook(category, subject, level, bookname, pIndex, pageSize) {
        API.WorkBookSearch(category, subject, level, bookname, pIndex, pageSize)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        if (res.data.Data) {
                            let data = [...res.data.Data]
                            setListWorkBook(pageIndex === 1 ? data : listWorkBook.concat(data))
                            setTotal(res.data.Total);
                        }
                    } else {
                        Fc.showError(res.data.Message)
                    }
                } else {
                    Fc.showError(res.statusText)
                }
            })
            .catch((err) => {
                Fc.showError(err)
            })
    }

    return (
        <Browser listWorkBook={listWorkBook} matchUrl={matchUrl} username={username} {...props} />
    );
}

export default BrowseIndex;