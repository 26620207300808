import React, { useState, useEffect, useRef } from 'react'
import * as Fc from '../../../../utils/Functions'
import API from "../../../../utils/Api"
import LayoutSearch from './LayoutSearch'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch } from "react-redux"
import * as Type from "../../../../constants/actionType"

function Layout(props) {
    const [ListCategories, setListCategories] = useState([])
    const [listLevel, setListLevel] = useState([])
    const [ListSubject, setListSubject] = useState([])
    const [bookName, setBookName] = useState("")
    const [keyword, setKeyword] = useState("")
    const buttonEl = useRef(null)
    
    const bookNameRef = useRef("")
    const categoryRef = useRef("")
    const subjectRef = useRef("")
    const levelRef = useRef("")
    const matchUrl = useRouteMatch()
    let dispatch = useDispatch()

    useEffect(() => {
        getListLevel()
        getListSubject()
        getListCategory()
    }, [])

    function ChangeCategories(e) {
        onSubmit()
    }

    function ChangeSubject(e) {
        onSubmit()
    }

    function ChangeBookName(e) {
        onSubmit()
    }

    function ChangeLevel(e) {
        onSubmit()
    }

    function getListCategory() {
        API.getAllCategories()
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        if (res.data.Data) {
                            let ListCategories = [];
                            res.data.Data.forEach((item, index) => {
                                ListCategories.push({
                                    value: item.Value,
                                    label: item.Text
                                })
                            })
                            setListCategories(ListCategories)
                        }
                    } else {
                        Fc.showError(res.data.Message)
                    }
                } else {
                    Fc.showError(res.statusText)
                }
            })
            .catch((err) => {
                Fc.showError(err)
            })
    }

    function getListLevel() {
        API.getGetAllLevels()
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        if (res.data.Data) {
                            let ListLevel = []
                            res.data.Data.forEach((item, index) => {
                                ListLevel.push({
                                    value: item.Value,
                                    label: item.Text
                                })
                            })
                            setListLevel(ListLevel)
                        }
                    } else {
                        Fc.showError(res.data.Message)
                    }
                } else {
                    Fc.showError(res.statusText)
                }
            })
            .catch((err) => {
                Fc.showError(err)
            })
    }

    function getListSubject() {
        API.getGetAllSubjects()
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        if (res.data.Data) {
                            let ListSubject = []
                            res.data.Data.forEach((item, index) => {
                                ListSubject.push({
                                    value: item.Value,
                                    label: item.Text
                                })
                            })
                            setListSubject(ListSubject)
                        }
                    } else {
                        Fc.showError(res.data.Message)
                    }
                } else {
                    Fc.showError(res.statusText)
                }
            })
            .catch((err) => {
                Fc.showError(err)
            })
    }

    function resetForm() {
        setBookName("")
        setKeyword("")
        bookNameRef.current.value = ""
        categoryRef.current.value = ""
        subjectRef.current.value = ""
        levelRef.current.value = ""
        // onSubmit()
        props.history.push(`${matchUrl.url}?category=&subject=&level=&bookname=`)
    }

    function handleSubmit(e) {
        e && e.preventDefault()
        onSubmit()
        buttonEl.current.click()
    }

    function onSubmit() {
        const bookNameValue = bookNameRef.current.value
        const categoryValue = categoryRef.current.value
        const subjectValue = subjectRef.current.value
        const levelValue = levelRef.current.value

        if (keyword) {
            // resetForm()
            setBookName("")
            setKeyword("")
            bookNameRef.current.value = ""
            categoryRef.current.value = ""
            subjectRef.current.value = ""
            levelRef.current.value = ""

            if(keyword.includes("/") || keyword.includes("%")) {
                // Fc.showError('Keyword contains invalid symbol!')
                return
            }

            dispatch({ type: Type.SAVE_PARAMS_SEARCH, payload: `search?keyword=${keyword}&category=${categoryValue}&subject=${subjectValue}&level=${levelValue}&bookname=${bookNameValue}` })
            props.history.push(`${matchUrl.url}/search?keyword=${keyword}&category=${categoryValue}&subject=${subjectValue}&level=${levelValue}&bookname=${bookNameValue}`)
        } else {

            if(bookNameValue.includes("/") || bookNameValue.includes("%")) {
                // Fc.showError('Keyword contains invalid symbol!')
                return
            }
            
            props.history.push(`${matchUrl.url}?category=${categoryValue}&subject=${subjectValue}&level=${levelValue}&bookname=${bookNameValue}`)
        }
    }

    return (
        <LayoutSearch
            listCategories={ListCategories}
            listLevel={listLevel}
            ListSubject={ListSubject}
            resetForm={resetForm} buttonEl={buttonEl}
            bookName={bookName} setBookName={setBookName}
            keyword={keyword} setKeyword={setKeyword}
            handleSubmit={handleSubmit} ChangeCategories={ChangeCategories}
            ChangeSubject={ChangeSubject} ChangeBookName={ChangeBookName}
            ChangeLevel={ChangeLevel} bookNameRef={bookNameRef} categoryRef={categoryRef} subjectRef={subjectRef} levelRef={levelRef}
        />
    );
}

export default Layout;