import React, { useState, useEffect } from 'react'
import * as Const from '../../constants/Const'
import './style.css'
import useIsMounted from '../../hooks/useIsMounted'

function ImageWithBanner(props) {
    const { mainImage, dynamicBanner, onClick, styleMainImage, styleWrapMainImage } = { ...props }
    const [imageRatio, setImageRatio] = useState(1.5)
    const staticBanner = window.bannerImage
    let isMounted = useIsMounted()

    useEffect(() => {
        var main = new Image()
        main.onload = function () {
            if(!isMounted.current) return
            setImageRatio(this.width / this.height)
        }
        main.src = mainImage
    }, [mainImage])

    return (
        <div className={styleWrapMainImage ? styleWrapMainImage : ''} style={{ position: 'relative' }}>
            <img className={styleMainImage ? styleMainImage : "img-fluid"} src={mainImage} alt="" onClick={onClick} />

            <img style={{
                position: 'absolute',
                width: (Const.StaticBanner.WIDTH) + '%',
                height: (Const.StaticBanner.HEIGHT) * imageRatio + '%',
                top: (Const.StaticBanner.TOP) * imageRatio + '%',
                left: (Const.StaticBanner.LEFT) + '%'
            }} src={staticBanner.src} alt="" onClick={onClick} />
            {dynamicBanner && <img style={{
                position: 'absolute',
                width: (Const.DynamicBanner.WIDTH) + '%',
                height: (Const.DynamicBanner.HEIGHT) * imageRatio + '%',
                top: (Const.DynamicBanner.TOP) * imageRatio + '%',
                left: (Const.DynamicBanner.LEFT) + '%'
            }} src={dynamicBanner} alt="" onClick={onClick} />}
        </div>
    )
}

export default React.memo(ImageWithBanner);