import React, { useState } from 'react'
import { Formik, ErrorMessage } from "formik"
import { Link, Redirect } from "react-router-dom"
import * as Yup from "yup"
import API from '../../utils/Api'
import * as Fc from '../../utils/Functions'
import * as Const from "../../constants/Const"
import { passwordRegExp } from './../../constants/Const'

function ChangePasswordByEmail(props) {
    const [newPass, setNewPass] = useState('')
    const [confirmPass, setconfirmPass] = useState('')

    const initValue = {
        newPass: newPass,
        confirmPass: confirmPass
    }

    const validateForm = Yup.object().shape({
        newPass: Yup
            .string()
            .required("Required")
            .matches(passwordRegExp, 'Password must have at least 8 characters, at least one uppercase letter, one lowercase letter and one number'),
        confirmPass: Yup
            .string()
            .required("Required")
            .equals([newPass], 'Confirm password is not match'),
    })

    function ChangePassword() {
        API.changePassByEmail(confirmPass, props.match.params.tokenId)
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    if (res.data.responseStatusCode === 200) {
                        Fc.showSuccess("Change password success")
                        //props.history.push("")
                    }
                    else Fc.showError(res.data.responseStatusMessage)
                } else {
                    Fc.showError(res.statusText)
                }
            }).catch((err) => {
                Fc.showError("Can not connect to server")
            })
    }

    if (Fc.getToken() && Fc.getRole().includes(Const.Role.VENDOR))
        return <Redirect to="/vendor" />
    else if (Fc.getToken() && Fc.getRole().includes(Const.Role.OPERATOR))
        return <Redirect to="/operator" />
    return (
        <div>
            <div className="bg-xanh"></div>
            <nav className="header fixed-top p-3 z-5">
                <div className="row align-items-center">
                    <div className="mx-auto"><h3 className="font-Paytone">Password Recovery</h3></div>
                </div>
            </nav>
            <main >
                <div id="login">
                    <div id="login-column" className="modal-dialog-centered modal-dialog-login">
                        <div id="login-box" className="col-md-12">
                            <Formik
                                initialValues={initValue}
                                onSubmit={ChangePassword}
                                validationSchema={validateForm}
                            >
                                {function (properties) {
                                    const {
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                    } = properties
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input type="password" name="newPass" value={newPass} className="form-control" placeholder="New password"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setNewPass(e.target.value);
                                                    }}
                                                />
                                                <ErrorMessage name="newPass">{(msg) => <div className="validate-input mt-1">* {msg}</div>}</ErrorMessage>
                                            </div>
                                            <div className="form-group mb-4">
                                                <input type="password" name="confirmPass" value={confirmPass} className="form-control" placeholder="Confirm New Password"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setconfirmPass(e.target.value);
                                                    }}
                                                />
                                                <ErrorMessage name="confirmPass">{(msg) => <div className="validate-input mt-1">* {msg}</div>}</ErrorMessage>
                                            </div>
                                            <button type="submit" className="btn btn-light font-Paytone btn-block mb-3">Confirm</button>
                                            {/* <div className="col-12 text-center"><Link to="" >Back to Login</Link></div> */}
                                        </form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </main>
        </div >
    );
}

export default ChangePasswordByEmail;