import React, { useState } from 'react';
import { Modal } from "react-bootstrap"
import * as Const from '../../constants/Const'

ModalAlertStudentTeacher.defaultProps = {
    showRadioButton: true
}

function ModalAlertStudentTeacher(props) {
    const {
        showModal,
        functionClose,
        role,
        AccountMethod,
        keyStorage,
        functionContactAdmin,
        message, showRadioButton
    } = props
    const [radioHidePopup, setRadioHidePopup] = useState(false)
    const storageKey = localStorage.getItem(keyStorage)

    if ((role === Const.Role.STUDENT || role === Const.Role.TEACHER) && (!storageKey && AccountMethod !== Const.AccountMethod.PAID))
        return (
            <Modal
                show={showModal}
                onHide={functionClose}
                className="modal fade"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-epen pte-auto">
                        <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                            <h4 className="modal-title font-Paytone">
                                {message ? message : 'Please contact your administrator for more information'}
                            </h4>
                        </div>
                        {(showRadioButton && AccountMethod !== Const.AccountMethod.RESTRICTED) && (<div style={{ width: '100%' }} className="custom-control custom-radio custom-control-inline">
                            <input type="checkbox" className="custom-control-input m-auto" id="radioHidePopup" checked={radioHidePopup} onChange={(e) => setRadioHidePopup(e.target.checked)} />
                            <label className="custom-control-label m-auto" htmlFor="radioHidePopup">Do not show this again</label>
                        </div>)}
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 m-auto">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            if (radioHidePopup) {
                                                localStorage.setItem(keyStorage, true)
                                            }
                                            functionClose()
                                        }}
                                        className="btn btn-light font-Paytone btn-block"
                                    >
                                        <span className="text-danger">Close</span>
                                    </button>
                                </div>
                                <div className="col-5 m-auto">
                                    <button
                                        type="button"
                                        className="btn btn-light font-Paytone btn-block"
                                        onClick={functionContactAdmin}
                                    >
                                        <span className="text-danger">Contact Admin</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    return null
}

export default ModalAlertStudentTeacher;