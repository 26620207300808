/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import API from '../../utils/Api'
import * as Fc from '../../utils/Functions'
import * as Const from '../../constants/Const'
import { useRouteMatch } from 'react-router-dom'
import useDidMount from '../../hooks/useDidMount'

import UploadDocumentsView from './UploadDocumentsView' 

function UploadDocuments(props) {
    const role = Fc.getRole()
    const isDidMount = useDidMount()
    const accountMethod = Fc.getAccountMethod()
    // const { userId } = props;
    const operatorId = Fc.getRole() === Const.Role.VENDOR ? props.userId : Fc.getOperator()
    const matchUrl = useRouteMatch()
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [file, setFile] = useState({})
    const [fileTitle, setFileTitle] = useState('')
    const [listFiles, setListFile] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedDocumentid, setSelectedDocumentid] = useState('')
    const [firstDocumentId, setFirstDocumentId] = useState('')
    const [banner, setBanner] = useState('')

    const [pageIndex, setPageIndex] = useState(1)
    const [total, setTotal] = useState(0)
    const pageSize = 30

    const [onSendingAPI, setOnSendingAPI] = useState(false)
    const [dayLeft, setDayLeft] = useState(0)

    // Check create Alert
    const [alertMessage, setAlertMessage] = useState("")
    const [isShowAddAlertModal, setShowAddAlertModal] = useState(false)

    // error message
    const [errorMessage, setErrorMessage] = useState("")
    const [isShowAddErrorModal, setShowAddErrorModal] = useState(false)

    function ShowAddError() {
        setShowAddErrorModal(true)
    }

    function onHideAddError() {
        setShowAddErrorModal(false)
    }

    function ShowAddAlert() {
        if (total >= 1)
            setShowAddAlertModal(true)
    }

    function onHideAddAlert() {
        setShowAddAlertModal(false)
    }

    function getFirstDocumentId() {
        let accountType = '';
        let userId = ''

        if (role === Const.Role.VENDOR) {
            accountType = "Vendor"
            userId = props.userId
        } else if (role === Const.Role.OPERATOR) {
            accountType = "Operator"
            userId = sessionStorage.getItem('userid')
        }
        else if (role === Const.Role.TEACHER) {
            accountType = "Teacher"
            userId = sessionStorage.getItem('userid')
        }

        API.GetFirstDocumentOfAccount(accountType, userId)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        if (res.data.Data) {
                            setFirstDocumentId(res.data.Data._id);
                        }
                    }
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            })
            .catch((err) => {
                Fc.showError(err)
            })
    }

    useEffect(() => {
        getFirstDocumentId()
        getBanner()
        return () => { Fc.hideLoading() }
    }, [])

    function getBanner() {
        let id = ""
        if (role === "VENDOR") {
            id = props.userId
        } else if (role === "OPERATOR" || role === "TEACHER") {
            id = sessionStorage.getItem('operator')
        }
        API.getBannerByOperator(id)
            .then((res) => {
                if (res.status === 200 && res.data.Success) {
                    setBanner(res.data.Data);
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            })
            .catch(err => {
                Fc.showError(err)
            })
    }

    useEffect(() => {
        API.GetDayLeftToRestriction(operatorId)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        if (res.data.Data) {
                            setDayLeft(res.data.Data)
                        }
                    }
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            })
            .catch((err) => {
                Fc.showError(err)
            })
    }, [])

    useEffect(() => {
        if (dayLeft <= 0) {
            setAlertMessage(`This features is locked. Upgrade now to use all Epen App features !`)
            return
        }
        setAlertMessage(`This document will be locked after ${dayLeft} days.`)
    }, [dayLeft])

    useEffect(() => {
        setListFile('')
        // if pageindex == 1 it wont trigger change on pageindex below
        if(isDidMount) getDocuments()
           
        setPageIndex(1)
        setTotal(0)
    }, [props.keyword, props.userId])

    useEffect(() => {
        //ngan goi api getDocument khi lan dau render voi useDidMount()
        // if(isDidMount) 
        getDocuments()

        const el = document.getElementById("list")
        el.addEventListener("scroll", loadMore)
        return function cleanupListener() {
            el.removeEventListener("scroll", loadMore)
        }
    }, [pageIndex])

    function loadMore() {
        const el = document.getElementById("list")
        if (el.scrollTop + el.clientHeight > el.scrollHeight - 100) {
            if (pageSize * pageIndex >= total) {
                return null
            } else {
                setPageIndex(pageIndex + 1)
            }
        }
    }

    function getDocuments() {
        //chan 1 so ky tu dac biet % / ^ 
        if(props.keyword.match("^[/%^\\s]+$")) {
            return
        }
        
        API.searchDocument(props.keyword, props.role, props.userId, pageIndex, pageSize)
            .then(res => {
                if (res.status && res.data.Success) {
                    setListFile(pageIndex === 1 ? res.data.Data : listFiles.concat(res.data.Data))
                    setTotal(res.data.Total)
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            }).catch(err => {
                Fc.showError("Can not connect to server")
            })
    }

    //#region upload
    function openUploadModal() {
        setShowUploadModal(true)
    }

    function upload() {
        Fc.showLoading()
        setShowUploadModal(false)
        setShowUploadModal(false)
        setFileTitle('')
        setFile({})
        var formData = new FormData();
        formData.append("file", file);
        !onSendingAPI && API.uploadDocument(fileTitle, props.role, props.userId, formData).then(res => {
            Fc.hideLoading()
            setOnSendingAPI(false)
            if (res.status === 200 && res.data.Success) {
                Fc.showSuccess("Upload success")
                getFirstDocumentId()
                setFile({})
                setFileTitle('')
                // setShowUploadModal(false)
                if (pageIndex !== 1)
                    setPageIndex(1)
                else
                    getDocuments()
                if (accountMethod === "Trials")
                    ShowAddAlert()
            } else {
                if (res.data.Message === "This feature is locked. Upgrade now to use all Epen App features") {
                    if (role === Const.Role.OPERATOR) {
                        setErrorMessage("You need to upgrade to use this feature")
                        setShowUploadModal(false)
                        ShowAddError()
                    }
                    if (role === Const.Role.TEACHER) {
                        setErrorMessage("Please contact your administrator for more infomation.")
                        setShowUploadModal(false)
                        ShowAddError()
                    }
                    if (role === Const.Role.VENDOR) {
                        Fc.showError(res.data.Message || res.statusText)
                    }
                } else
                    Fc.showError(res.data.Message || res.statusText)
            }
        }).catch(err => {
            Fc.hideLoading()
            setOnSendingAPI(false)
            Fc.showError("Can not connect to server")
        })
        setOnSendingAPI(true)
    }

    function hideUploadModal() {
        setShowUploadModal(false)
        setFileTitle('')
        setFile({})
    }
    //#endregion

    //#region delete
    function openDeleteModal(id) {
        setSelectedDocumentid(id)
        setShowDeleteModal(true)
    }

    function deleteDocument() {
        API.deleteDocument(selectedDocumentid).then(res => {
            if (res.status === 200 && res.data.Success) {
                Fc.showSuccess("Delete success")
                getDocuments()
                setShowDeleteModal(false)
                if (pageIndex !== 1)
                    setPageIndex(1)
                else
                    getDocuments()
            } else {
                Fc.showError(res.data.Message || res.statusText)
            }
        }).catch(err => {
            Fc.showError("Can not connect to server")
        })
    }

    function hideDeleteModal() {
        setSelectedDocumentid('')
        setShowDeleteModal(false)
    }

    //#endregion

    return (
        <UploadDocumentsView
            showUploadModal={showUploadModal} hideUploadModal={hideUploadModal} openUploadModal={openUploadModal}
            file={file} setFile={setFile}
            fileTitle={fileTitle} setFileTitle={setFileTitle}
            listFiles={listFiles}
            showDeleteModal={showDeleteModal} openDeleteModal={openDeleteModal}
            hideDeleteModal={hideDeleteModal}
            deleteDocument={deleteDocument}
            matchUrl={matchUrl}
            isShowAddAlertModal={isShowAddAlertModal}
            onHideAddAlert={onHideAddAlert}
            alertMessage={alertMessage}
            alertMessageForTeacherUpload={dayLeft <= 0 ? `This document is locked today. Contact admin for more infomation!` : `This document will be locked after ${dayLeft} days.`}
            upload={upload}
            isShowAddErrorModal={isShowAddErrorModal}
            onHideAddError={onHideAddError}
            errorMessage={errorMessage}
            role={role}
            firstDocumentId={firstDocumentId}
            accountMethod={accountMethod}
            ShowAddError={ShowAddError}
            setShowAddAlertModal={setShowAddAlertModal}
            setAlertMessage={setAlertMessage}
            setErrorMessage={setErrorMessage}
            banner={banner}
            dayLeft={dayLeft}
            {...props} />
    )
}

export default React.memo(UploadDocuments);