/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import API from '../../../utils/Api'
import * as Fc from '../../../utils/Functions'
import * as Const from '../../../constants/Const'
import * as UrlConst from '../../../constants/UrlConst'
import * as Type from "../../../constants/actionType"

import uuid from 'react-uuid'
import * as $ from 'jquery'
import html2canvas from 'html2canvas'

import EditView from './EditView'
import _, { set } from 'lodash';
import { useSelector, useDispatch } from 'react-redux'
import { isDesktop, isMobile } from 'react-device-detect';
import useCheckdevice from '../../../hooks/useCheckdevice'
import classNames from 'classnames'
import useDidMount from '../../../hooks/useDidMount'

const whiteBackground = require(`../../../static/images/bg-white-1112x1439.png`);
const lineBackground = require(`../../../static/images/page-kengang-01.png`);
const caroBackground = require(`../../../static/images/page_ovuong-01.png`);

//=============anh de tinh toan kich thuoc note=================
const url = 'http://dsho445x5pqu1.cloudfront.net/Vendor/SampleForCaculateImg.png'
///=============================================================

export default function Edit(props) {
    const dispatch = useDispatch();
    const isDidMount = useDidMount()
    const checkDevice = useCheckdevice()

    let classIdFromRedux = useSelector(state => state.ws.classId)
    let permissionWriteNote = useSelector(state => state.ws.permissionWriteNote)
    let permissionSolution = useSelector(state => state.ws.permissionSolution)
    const headerEditActiveRx = useSelector(state => state.ws.headerEditActive)
    const preHeaderEditActiveRx = useSelector(state => state.ws.preHeaderEditActive)
    const isLandscape = useSelector(state => state.ws.isLandscape)
    const pageIndexWsRx = useSelector(state => state.ws.pageIndexWs)
    const pageIndexNoteRx = useSelector(state => state.ws.pageIndexNote)

    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))

    const role = Fc.getRole()
    const operatorId = role === Const.Role.VENDOR ? props.match.params.id : Fc.getOperator()
    const sketchpadUserId = uuid()
    const assignId = props.match.params.assignId //if type = Notes => noteId === assignId
    const worksheetType = props.match.params.type ? props.match.params.type : "Assignment" //if type = Notes => worksheetType === Notes
    const [pageIndex, setPageIndex] = useState(pageIndexWsRx)
    const [isSave, setIsSave] = useState(false)
    //use for input chosse pageIndex
    const [pageIndexTemp, setPageIndexTemp] = useState(parseInt(props.match.params.pageIndex) || 0)

    const [worksheet, setWorksheet] = useState({})
    const [worksheetJsonData, setWorksheetJsonData] = useState(null)
    const [imageSrc, setImageSrc] = useState("")
    const [banner, setBanner] = useState(null)
    const [totalPage, setTotalPage] = useState(0)
    const [zoomValue, setZoomValue] = useState(1)
    const [exerciseWidth, setExerciseWidth] = useState(0)

    const [menuPosition, setMenuPosition] = useState("left")
    const [penColor, setPenColor] = useState(Fc.getRole() === Const.Role.STUDENT ? [0, 0, 180] : [255, 0, 0])
    const [penLineWidth, setPenLineWidth] = useState(1)
    const [showQuestionClearAll, setShowQuestionClearAll] = useState(false)
    const [isShowRefreshBrowser, setIsShowRefreshBrowser] = useState(false)

    const [highlighterColor, setHighlighterColor] = useState([255, 255, 15])
    const [highlighterLineWidth, setHighlighterLineWidth] = useState(1)

    const [sketchpad, setSketchpad] = useState({})

    const [isShowPenMenu, setIsShowPenMenu] = useState(false)
    const [isShowTrash, setIsShowTrash] = useState(false)
    const [isShowHightLighterMenu, setIsShowHightLighterMenu] = useState(false)
    const [isShowTypeMenu, setIsShowTypeMenu] = useState(false)
    const [isShowShapesMenu, setIsShowShapesMenu] = useState(false)
    const [toolId, setToolId] = useState('null')

    const [typeBold, setTypeBold] = useState(false)
    const [typeItalic, setTypeItalic] = useState(false)
    const [typeUnderline, setTypeUnderline] = useState(false)
    const [typeFontSize, setTypeFontSize] = useState(14)
    const [typeFontFamily, setTypeFontFamily] = useState("Arial, Helvetica, sans-serif")

    const [showFormulaModal, setShowFormulaModal] = useState(false)
    const [showEquationModal, setShowEquationModal] = useState(false)
    const [mathEditor, setMathEditor] = useState(null)

    const [solutionPageIndex, setSolutionPageIndex] = useState(0)
    const [isShowShap, setIsShowShap] = useState(false)
    const [isFirstShapNote, setFirstShapNote] = useState(true)
    const [isFirstShapWorksheet, setFirstShapWorksheet] = useState(true)
    //account restriction
    const accountMethod = Fc.getAccountMethod()
    const [showDayLeftModal, setShowDayLeftModal] = useState(false)
    const [trialDayLeft, setTrialDayLeft] = useState(0)
    const [showContactAdminModal, setShowContactAdminModal] = useState(false)
    const [showFeatureLockedModal, setShowFeatureLockedModal] = useState(false)

    const [indexBg, setIndexBg] = useState(0)
    const [isReloadSkp, setIsReloadSkp] = useState(false)

    //notes
    const [noteOfWorksheet, setNoteOfWorksheet] = useState(null)
    const [pageOfNote, setPageOfNote] = useState(0)
    // const [pageIndexNoteTmp, setPageIndexNoteTmp] = useState(worksheetType === "Notes" ? (pageIndexNoteRx === 0 ? pageIndexNoteRx + 1 : pageIndexNoteRx) : pageIndexNoteRx + 1)
    const [pageIndexNoteTmp, setPageIndexNoteTmp] = useState((pageIndexNoteRx === 0 ? pageIndexNoteRx + 1 : pageIndexNoteRx) || 1)
    const [showNote, setShowNote] = useState((worksheetType === "Notes" || (headerEditActiveRx === 'CreateNote')) ? true : false)
    const [showSolution, setShowSolution] = useState((headerEditActiveRx === 'Solution') ? true : false)
    const [noteData, setNoteData] = useState(null)
    // const [textPageindexNote, setTextPageindexNote] = useState(worksheetType === "Notes" ? (pageIndexNoteRx === 0 ? pageIndexNoteRx + 1 : pageIndexNoteRx) : pageIndexNoteRx + 1)
    const [textPageindexNote, setTextPageindexNote] = useState((pageIndexNoteRx === 0 ? pageIndexNoteRx + 1 : pageIndexNoteRx) || 1)
    
    const [demoCalculator, setDemoCalculator] = useState(null)

    //loading while swipe trigger
    const [isSwiping, setIsSwiping] = useState(false)
    let timer = null
    let debounce_timer = null
    const userId = Fc.getUserId()
    let editScrollYRef = useRef(0)
    let toolIdTmp = useRef('null')


    useEffect(() => {
        toolIdTmp.current = toolId
    }, [toolId])

    useEffect(() => {
        window.onorientationchange = function (event) {
            // window.location.reload();
            switch(window.orientation){
              case 0:
              dispatch({ type: Type.CHANGE_ORIENTATION, payload: false });
              break;
      
              case -90:
              dispatch({ type: Type.CHANGE_ORIENTATION, payload: true });
              break;
      
              case 90:
              dispatch({ type: Type.CHANGE_ORIENTATION, payload: true });
              break;
      
              case 180:
              dispatch({ type: Type.CHANGE_ORIENTATION, payload: false });
      
              case 270:
              dispatch({ type: Type.CHANGE_ORIENTATION, payload: true });
      
              case 360:
              dispatch({ type: Type.CHANGE_ORIENTATION, payload: false });
              break;
            }
        };

        if(isBrowserDesktop) {
            $('[data-toggle="tooltip"]').tooltip();
            $('[data-toggle="tooltip"]').click(function() {
                $(this).tooltip('hide');
            });
        }
    })

    useEffect(() => {
        if(!isBrowserDesktop || checkDevice == 'iPad') return
        if(headerEditActiveRx === 'CreateNote') {
            selectTool('null')
            showNoteDesktop()
        }

        if(headerEditActiveRx === 'Solution') {
            selectTool('null')
            showSolutionDesktop()
        }

        if(headerEditActiveRx === 'Exercise') {
            selectTool('null')
            showExerciseDesktop()
        }
        setPenLineWidth(1)
        setHighlighterLineWidth(1)
        if (Object.keys(sketchpad).length === 0) return
        sketchpad.setScale(sketchpad.scale);
    }, [headerEditActiveRx])

    useEffect(() => {
        if(!isDidMount) return
        let timerZoomValue = setTimeout(() => {
            setIsReloadSkp(!isReloadSkp)
            if(isSave) return
            if (showNote) {

                //set drawing note state before save drawing note
                const dateTime = new Date().getTime();
                let noteJson = [{dateTime}]
                if(sketchpad && Object.keys(sketchpad).length > 0) 
                noteJson = sketchpad.saveSketchpad()
                setNoteData(JSON.stringify(noteJson))

                return
            }

            //set drawing worksheet state before save drawing worksheet
            const dateTime = new Date().getTime();
            let dataJson = [{dateTime}]
            if(sketchpad && Object.keys(sketchpad).length > 0) 
            
            dataJson = sketchpad.saveSketchpad()
            setWorksheetJsonData(JSON.stringify(dataJson))

        }, 300)

        return () => {
            clearTimeout(timerZoomValue)
        }
    }, [zoomValue])
    
    useEffect(() => {
        /**
        |--------------------------------------------------
        | MATH
        |--------------------------------------------------
        */
        // var ResizableNode1 = new window.ResizableNode({
        //     content: '#mathEditor',
        //     parent: '#sketchpad',
        //     save: onSaveMath,
        //     cancel: onCancelEditor,
        //     keyboard: onPressKeyboard,
        //     offset: [16, 16]
        // }); 

        // let config = {
        //     containerEl: document.getElementById('scaleWrapper'),
        //     offset: [50, 50]
        // }
        // window.ResizeNode = new window.MathContainer(config)
        /**
        |--------------------------------------------------
        | END MATH
        |--------------------------------------------------
        */

        window.addEventListener("scroll",editOnScroll)

        if(worksheetType === "Notes" ) 
            dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: "CreateNote" });
        else {
            // dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: "Exercise" });
        }

        if (role !== Const.Role.VENDOR && accountMethod !== Const.AccountMethod.PAID) {
            getTrialDayLeft()
        }
        getBanner()
        let el = document.getElementById("graphingCalculator")
        let elm = document.getElementById('Scientific');
        var ScientificCalculator = window.Desmos.ScientificCalculator(elm);
        var calculator = window.Desmos.GraphingCalculator(el);
        calculator.setExpression({});

        setDemoCalculator(calculator)
        // setTimeout(() => {
        //     console.log("calculator: ", calculator.screenshot({
        //     height: 300,
        //     width: 300
        //   }))
        // }, 1000)
        
        if (typeof window.Desmos === 'undefined') window.Desmos = {};
        window.Desmos.config = { "sciKeypad": true, "degreeMode": true };
        window.Desmos.commit = '0784ba61f21868ccb2f303628ded85c3f23ee7b9';

        document.querySelector('meta[name="viewport"]').setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
        return () => {
            document.querySelector('meta[name="viewport"]').setAttribute("content", "width=device-width, initial-scale=1");
            clearTimeout(timer);
            Fc.hideLoading();
        }
    }, [])



    function editOnScroll() {
        if(debounce_timer) {
            window.clearTimeout(debounce_timer)
        }
        debounce_timer = window.setTimeout(function() {
            editScrollYRef.current = window.scrollY;
        }, 100)
    }

    function onClickShape() {
        if((isFirstShapNote && worksheetType === 'Notes') || (isFirstShapWorksheet && worksheetType === 'Assignment') || (isFirstShapWorksheet && worksheetType === 'Document')){
            setIsShowShap(true)
            if (worksheetType === 'Notes') 
                setFirstShapNote(false)
            else 
                setFirstShapWorksheet(false)
            timer = setTimeout(() => setIsShowShap(false), 3000)
        }
    }

    //#region load data
    useEffect(() => {
        if (pageIndex != null) {
            
            setSketchpad({})
            if (worksheetType != "Notes") {
                getWorksheet()
            } else {
                const noteId = props.match.params.assignId
                getNoteWithNodeId(noteId)
            }

            if (worksheetType != "Notes") {
                setPageIndexTemp(pageIndex + 1)
                setPageIndexNoteTmp(parseInt(1))
                dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: 1});
            } else {
                // setPageIndexNoteTmp(parseInt(pageIndex + 1))
                // dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: pageIndex + 1});
                // setTextPageindexNote(parseInt(pageIndex + 1))
            }
        }
    }, [pageIndex]);

    useEffect(() => {
        if(!showNote && !showSolution) {
            if(Object.keys(worksheet).length === 0) return
        } 
    }, [showNote, showSolution]);

    function getWorksheet() {
        Fc.showLoading()
        if (worksheetType === "Assignment") {
            const { classId, studentId } = props.match.params
            let classIdWorksheet = classId ? classId : classIdFromRedux
            API.getWorksheetByAssignmentAndIndex(assignId, pageIndex, classIdWorksheet).then(res => {
                if (res.status === 200 && res.data.Success) {
                    Fc.hideLoading()
                    let jsonData = res.data.Data.WorksheetData.Exercise.StudentJsonVersion
                    const dateTime = new Date().getTime();
                    if(!jsonData || typeof jsonData === "string"  && jsonData.length < 3) {
                        jsonData = JSON.stringify([{dateTime}])
                    }
                    
                    // if (role !== Const.Role.STUDENT)
                    if (role === Const.Role.VENDOR)
                        res.data.Data.WorksheetData.ShowSolution = true

                    if(role !== Const.Role.STUDENT) {
                        if(permissionSolution) res.data.Data.WorksheetData.ShowSolution = true
                    }

                    let body = []
                    if (window.bannerImage && window.bannerImage.src)
                        body.push({
                            Url: window.bannerImage.src,
                            Top: parseFloat(Const.StaticBanner.TOP / 100),
                            Left: parseFloat(Const.StaticBanner.LEFT / 100),
                            Width: parseFloat(Const.StaticBanner.WIDTH / 100),
                            Height: parseFloat(Const.StaticBanner.HEIGHT / 100),
                        })

                    if (banner)
                        body.push({
                            Url: banner,
                            Top: parseFloat(Const.DynamicBanner.TOP / 100),
                            Left: parseFloat(Const.DynamicBanner.LEFT / 100),
                            Width: parseFloat(Const.DynamicBanner.WIDTH / 100),
                            Height: parseFloat(Const.DynamicBanner.HEIGHT / 100),
                        })
                    if (res.data.Data.WorksheetData.Exercise.Workbook && res.data.Data.WorksheetData.Exercise.Workbook.Category === "Foundation" && body.length > 0) {
                        API.mergeImagesByWidth(res.data.Data.WorksheetData.Exercise.Url, body).then(res1 => {
                            if (res1.status === 200 && res1.data.Success) {
                                setImageSrc("data:image/png;base64," + res1.data.Data)
                                setWorksheet(res.data.Data.WorksheetData)
                                setWorksheetJsonData(jsonData)
                                setTotalPage(res.data.Data.TotalWorksheet)
                            } else {
                                Fc.showError(res1.data.Message || res1.statusText)
                                props.history.goBack()
                                Fc.hideLoading()
                            }
                        }).catch(err => {
                            Fc.showError("Can not connect to server")
                            props.history.goBack()
                            Fc.hideLoading()
                        })
                    } else {
                        setImageSrc(res.data.Data.WorksheetData.Exercise.Url + "?t=" + Date.now())
                        setWorksheet(res.data.Data.WorksheetData)
                        setWorksheetJsonData(jsonData)
                        setTotalPage(res.data.Data.TotalWorksheet)
                    }

                } else {
                    if (res.data.Message)
                        Fc.showError(res.data.Message)
                    else
                        Fc.showError(res.statusText)
                    props.history.goBack()
                    Fc.hideLoading()
                }
            }).catch(err => {
                Fc.showError("Can not connect to server")
                props.history.goBack()
                Fc.hideLoading()
            })
        } else {
            API.getWorksheetByDocumentAndIndex(assignId, pageIndex).then(res => {
                if (res.status === 200 && res.data.Success) {

                    let jsonData = res.data.Data.Worksheet.StudentJsonVersion
                    const dateTime = new Date().getTime();
                    if(!jsonData || typeof jsonData === "string"  && jsonData.length < 3) {
                        jsonData = JSON.stringify([{dateTime}])
                    }

                    setImageSrc(res.data.Data.Worksheet.Url + "?t=" + Date.now())
                    setWorksheet({
                        ShowSolution: false,
                        Exercise: res.data.Data.Worksheet
                    })
                    setWorksheetJsonData(jsonData)
                    setTotalPage(res.data.Data.TotalWorksheet)

                    
                } else {
                    if (res.data.Message)
                        Fc.showError(res.data.Message)
                    else
                        Fc.showError(res.statusText)
                    props.history.goBack()
                    Fc.hideLoading()
                }
            }).catch(err => {
                Fc.showError("Can not connect to server")
                props.history.goBack()
                Fc.hideLoading()
            })
        }
    }

    useEffect(() => {
        getNoteOfWorksheet()
    }, [worksheet && worksheet.Exercise && worksheet.Exercise._id])

    useEffect(() => {
        setSketchpad({});
        if(noteOfWorksheet && !isNaN(parseInt(pageIndexNoteTmp)) && parseInt(pageIndexNoteTmp) <= parseInt(pageOfNote)) {
            const dateTime = new Date().getTime();
            let noteJson = [{dateTime}]
            setNoteData(JSON.stringify(noteJson))
            getNoteDetail(noteOfWorksheet[0]._id)
        }
    }, [pageIndexNoteTmp])

    // ===============Note=======================
    async function getNoteWithNodeId(noteId) {
        if (!noteId) return
        Fc.showLoading()
        try {
            const noteDetail = await API.getNoteDetail(noteId)
            if (noteDetail.status === 200 && noteDetail.data.Success) {
                setNoteOfWorksheet([noteDetail.data.Data])
                setNoteData(noteDetail.data.Data.StudentWork[pageIndexNoteTmp -1])
                setPageIndexNoteTmp(pageIndexNoteTmp)
                // dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: 1});

                setTextPageindexNote(pageIndexNoteTmp)
                setPageOfNote(noteDetail.data.Data.StudentWork.length)
            } else {
                if (noteDetail.data.Message)
                    Fc.showError(noteDetail.data.Message)
                else
                    Fc.showError(noteDetail.statusText)
                props.history.goBack()
                Fc.hideLoading()
            }
            Fc.hideLoading()
        } catch (err) {
            Fc.hideLoading()
            Fc.showError("Can not connect to server")
        }
    }

    async function getNoteDetail(noteId) {
        if (!noteId) return
        Fc.showLoading()
        try {
            const noteDetail = await API.getNoteDetail(noteId)
            if (noteDetail.status === 200 && noteDetail.data.Success) {
                if (noteDetail.data.Data.StudentWork && noteDetail.data.Data.StudentWork.length > 0 &&  parseInt(pageIndexNoteTmp) <= noteDetail.data.Data.StudentWork.length ){
                    setNoteData(noteDetail.data.Data.StudentWork[parseInt(pageIndexNoteTmp) - 1])
                }

                //hidden keyboard when change page
                if (document.activeElement) {
                    document.activeElement.blur();
                }
            } else {
                if (noteDetail.data.Message)
                    Fc.showError(noteDetail.data.Message)
                else
                    Fc.showError(noteDetail.statusText)
                props.history.goBack()
                Fc.hideLoading()
            }
            Fc.hideLoading()
        } catch (err) {
            Fc.hideLoading()
            Fc.showError("Can not connect to server")
        }
    }

    async function getNoteOfWorksheet() {
        if (!worksheet || !worksheet.Exercise) return
        const worksheetID = worksheet.Exercise._id
        const { classId, studentId } = props.match.params
        let classIdSavenote = classId ? classId : classIdFromRedux
        const studentIdSavenote = studentId ? studentId : userId
        try {
            const noteResult = await API.getNoteOfWorksheet(worksheetID, classIdSavenote, studentIdSavenote);
            if (noteResult.status === 200 && noteResult.data.Success) {
                setNoteOfWorksheet(noteResult.data.Data)
                if (noteResult.data.Data && noteResult.data.Data.length > 0) {
                    setPageOfNote(noteResult.data.Data[0].StudentWork.length)
                    // setNoteData(noteResult.data.Data[0].StudentWork[0])
                    setNoteData(noteResult.data.Data[0].StudentWork[pageIndexNoteTmp - 1])
                    setPageIndexNoteTmp(pageIndexNoteTmp)
                    setTextPageindexNote(pageIndexNoteTmp)
                    return 
                }
                createNoteOfWorksheet()
            } else {
                if (noteResult.data.Message) {
                    Fc.showError(noteResult.data.Message)
                }
                else
                    Fc.showError(noteResult.statusText)
            }
        } catch (err) {
            Fc.showError("Can not connect to server")
        }
    }

    async function createNoteOfWorksheet() {
        if (!worksheet || !worksheet.Exercise) return
        const worksheetID = worksheet.Exercise._id;
        const { classId, studentId } = props.match.params;
        const noteName = `Note worksheet - ${worksheetID}`;
        const type = "WorksheetNote";
        const noteId = "";
        const folderId = "";
        let classIdSavenote = classId ? classId : classIdFromRedux
        const studentISavenote = studentId ? studentId : userId
        Fc.showLoading()
        try {
            const noteResult = await API.saveNote(studentISavenote, classIdSavenote, noteName, folderId, worksheetID, type, noteId);
            if (noteResult.status === 200 && noteResult.data.Success) {
                setNoteOfWorksheet([noteResult.data.Data])
                setPageOfNote(1)
                setPageIndexNoteTmp(1)
                dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: 1});

                setTextPageindexNote(1)
                // setShowNote(true)
            } else {
                if (noteResult.data.Message)
                    Fc.showError(noteResult.data.Message)
                else
                    Fc.showError(noteResult.statusText)
                setShowNote(false);
            }
            Fc.hideLoading()
        } catch (err) {
            Fc.hideLoading();
            setShowNote(false);
            Fc.showError("Can not connect to server");
        }
    }

    function onIncreasePageNote() {
        window.ResizeNode && window.ResizeNode.cancel()
        selectTool('null')
        setPenLineWidth(1)
        setHighlighterLineWidth(1)
        if (pageIndexNoteTmp >= 1 && pageIndexNoteTmp < pageOfNote && textPageindexNote >= 1 && textPageindexNote < pageOfNote) {
            setPageIndexNoteTmp(pageIndexNoteTmp + 1)
            dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: pageIndexNoteTmp + 1});

            setTextPageindexNote(textPageindexNote + 1)
        }
    }

    function onDecreasePageNote() {
        window.ResizeNode && window.ResizeNode.cancel()
        selectTool('null')
        setPenLineWidth(1)
        setHighlighterLineWidth(1)
        if (pageIndexNoteTmp > 1 && pageIndexNoteTmp <= pageOfNote && textPageindexNote > 1 && textPageindexNote <= pageOfNote) {
            setPageIndexNoteTmp(pageIndexNoteTmp - 1)
            dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: pageIndexNoteTmp - 1});

            setTextPageindexNote(textPageindexNote - 1)
        }
    }

    function changePageNote(e) {
        selectTool('null')
        setPenLineWidth(1)
        setHighlighterLineWidth(1)
        if (e.key === "Enter" && !isNaN(parseInt(pageIndexNoteTmp))) {
            window.ResizeNode && window.ResizeNode.cancel()
            let page = parseInt(pageIndexNoteTmp)
            if(page > pageOfNote) {
                setPageIndexNoteTmp(pageOfNote)
                dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: pageOfNote});
                setTextPageindexNote(pageOfNote)

                //hidden keyboard when change page
                if (document.activeElement) {
                    document.activeElement.blur();
                }
                return
            }
            if (page >= 1 && page <= pageOfNote) {
                setPageIndexNoteTmp(page)
                dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: page});
                setTextPageindexNote(page)
                //hidden keyboard when change page
                if (document.activeElement) {
                    document.activeElement.blur();
                }
                return
            }
        }
    }

    function onChangePageNote(e) {
        setTextPageindexNote(e.target.value)
    }
    //trigger after textPageindexNote change 500ms
    useEffect(() => {
        let timerStr = setTimeout(() => {
            setPageIndexNoteTmp(parseInt(textPageindexNote))
            dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: parseInt(textPageindexNote)});
        }, 500)
        return () => clearTimeout(timerStr) 
    }, [textPageindexNote])

    async function addNotePage() {
        if (!showRestrictModal()) return
        if (!noteOfWorksheet || noteOfWorksheet.length <= 0) return

        const dateTime = new Date().getTime();
        const body = {
            noteId: noteOfWorksheet[0]._id,
            index: -1,
            work: JSON.stringify([{dateTime}])
        }

        Fc.showLoading()
        try {
            await drawNote()
            const result = await API.drawNote(body);
            if (result.status === 200 && result.data.Success) {
                let dataJson = [{dateTime}]
                if(sketchpad && Object.keys(sketchpad).length > 0) dataJson = sketchpad.saveSketchpad()

                setNoteData(JSON.stringify(dataJson))
                setPageOfNote(pageOfNote + 1)
                setPageIndexNoteTmp(pageOfNote + 1)
                dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: pageOfNote + 1 });
                setTextPageindexNote(pageOfNote + 1)
            }
            Fc.hideLoading()
        } catch (err) {
            Fc.hideLoading()
            Fc.showError("Can not connect to server");
        }
    }

    async function drawNote(reload = false, callback) {
        if (!noteOfWorksheet || noteOfWorksheet.length <= 0 || !sketchpad || Object.keys(sketchpad).length == 0) return
        const dataJson = sketchpad.saveSketchpad()
        const body = {
            noteId: noteOfWorksheet[0]._id,
            index: parseInt(pageIndexNoteTmp) - 1,
            work: JSON.stringify(dataJson)
        }
        setIsSave(true)
        try {
            const result = await API.drawNote(body);
            if (result.status === 200 && result.data.Success) {
                if (reload) {
                    setNoteData(result.data.Data.StudentWork[0])
                    setPageIndexNoteTmp(1)
                    dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: 1 });
                    setTextPageindexNote(1)
                }
                if(callback && typeof callback === 'function') {
                    setNoteData(result.data.Data.StudentWork[pageIndexNoteTmp - 1])
                    callback();
                }
            }
            if(result) setIsSave(false)
            Fc.hideLoading()
        } catch (err) {
            // Fc.hideLoading()
            Fc.showError("Can not connect to server");
            setIsSave(false)
        }
    }
    // ======================End note=====================

    async function getBanner() {
        await API.getBannerByOperator(operatorId)
            .then((res) => {
                if (res.status === 200 && res.data.Success) {
                    setBanner(res.data.Data);
                } else {
                    // Fc.showError(res.data.Message || res.statusText)
                }
            })
            .catch(err => {
                // Fc.showError(err)
            })
        if(pageIndexWsRx && pageIndexWsRx > 0) return  
        setPageIndex(0)
        dispatch({ type: Type.SAVE_PAGEINDEX_WS, payload: 0 });
    }

    /**
    |------------------------------------------------------------------------------------
    | EDIT EXERCISE
    |------------------------------------------------------------------------------------
    */
    useEffect(() => {
        if (worksheet.Exercise && !showNote && !showSolution && pageIndexNoteTmp && headerEditActiveRx === "Exercise") {
            //sketchpad worksheet
            let sketchpadEl = document.getElementById("sketchpad");
            let ws = new WebSocket(UrlConst.WebSocketServer);

            let tm, tmInterval 
            function ping() {
                ws.send('__ping__');
                tm = setTimeout(function () {
                    /// ---connection closed ///
                    setIsShowRefreshBrowser(true)
                }, 1000);
            }
            function pong() {
                clearTimeout(tm);
            }
            ws.onmessage = function(e) {
                if(e.data === 'pong') {
                    pong();
                    return;
                }
            };

            function onError(e) {
                ws.removeEventListener("error", onError);
            }
            function onOpen() {
                if (ws.readyState === 1) {
                    ws.removeEventListener("error", onError);
                    tmInterval = setInterval(ping, 5000);
                    let skp = new window.Sketchpad({
                        containerEl: sketchpadEl,
                        token: `${worksheetType}|${worksheet.Exercise._id}|${assignId}`,
                        ws: ws,
                        createPageConfig: {
                            sid: worksheet.Exercise._id,
                            no: 1,
                            backgroundColor: "rgba(250,250,250,1)"
                        },

                        user_id: sketchpadUserId,
                        backgroundImage: imageSrc,
                        // data: worksheet.Exercise.StudentJsonVersion,
                        data: worksheetJsonData
                    })
                    setSketchpad(skp)
                    window.sketchpad = skp

                    const img = new Image();
                    img.onload = function () {
                        let width = skp.width
                        let height = width / this.width * this.height
                        setExerciseWidth(this.width)
                        let scaleWrapperEl = document.getElementById("scaleWrapper")
                        if(scaleWrapperEl) {
                            scaleWrapperEl.style.width = width + 'px'
                            scaleWrapperEl.style.height = height + 'px'
                        }
                        sketchpadEl.style.height = height + 'px'
                    }
                    img.src = worksheet.Exercise.Url + "?t=" + Date.now();
                }
                Fc.hideLoading()
            }
            ws.addEventListener("open", onOpen);
            ws.addEventListener("error", onError);

            return () => {
                let newSketchpadEl = sketchpadEl.cloneNode(true);
                sketchpadEl.parentNode.replaceChild(newSketchpadEl, sketchpadEl);
                ws.close()
                clearTimeout(tm)
                clearInterval(tmInterval)
            }
        }
    }, [worksheetJsonData, showNote, showSolution, headerEditActiveRx, isLandscape, isReloadSkp, pageIndexNoteTmp])
    //===================================================================================

    useEffect(() => {
        setToolId(toolIdTmp.current)
        let timerReload = setTimeout(() => {
            setIsReloadSkp(!isReloadSkp)
        },100)
        
        if(showNote) {
            //set drawing note state before save drawing note
            const dateTime = new Date().getTime();
            let noteJson = [{dateTime}]
            if(sketchpad && Object.keys(sketchpad).length > 0) 
            
            noteJson = sketchpad.saveSketchpad()
            setNoteData(JSON.stringify(noteJson))

        }

        if(!showNote && !showSolution) {
            const dateTime = new Date().getTime();
            let dataJson = [{dateTime}]
            if(sketchpad && Object.keys(sketchpad).length > 0) 
            dataJson = sketchpad.saveSketchpad()
            setWorksheetJsonData(JSON.stringify(dataJson))

        }

        return () => { clearTimeout(timerReload)}
    }, [isLandscape])

    /**
    |------------------------------------------------------------------------------------
    | EDIT NOTE 
    |------------------------------------------------------------------------------------
    */
    useEffect(() => {
        if ((worksheet.Exercise || noteOfWorksheet) && showNote && !showSolution) {
            if(!noteOfWorksheet || noteOfWorksheet.length <= 0) return
            let sketchpadEl = document.getElementById("sketchpadNote");
            let ws = new WebSocket(UrlConst.WebSocketServer);
            let tm, tmInterval 
            function ping() {
                ws.send('__ping__');
                tm = setTimeout(function () {
                    /// ---connection closed ///
                    setIsShowRefreshBrowser(true)
                }, 1000);
            }
            function pong() {
                clearTimeout(tm);
            }
            ws.onmessage = function(e) {
                if(e.data === 'pong') {
                    pong();
                    return;
                }
            };
            function onError(e) {
                ws.removeEventListener("error", onError);
                // Fc.showError('Can not connect to websocket server')
            }
            function onOpen() {
                if (ws.readyState === 1) {
                    tmInterval = setInterval(ping, 5000);
                    //     //change image background when indexBg change
                    var imageBg = null;
                    if (parseInt(indexBg) % 3 === 0) imageBg = whiteBackground;
                    if (parseInt(indexBg) % 3 === 1) imageBg = lineBackground
                    if (parseInt(indexBg) % 3 === 2) imageBg = caroBackground

                    var imgCanvas = document.getElementById("canvasImg");
                    imgCanvas.style.display = 'none'
                    imgCanvas.style.width = '100%'
                    imgCanvas.style.height = '100%'
                    var ctx = imgCanvas.getContext('2d');
                    const imgBg = new Image();

                    //Set sketchpad note when load image background 
                    imgBg.onload = function () {
                        //scale canvas with innerHeight and innerWidth
                        const ratioImgAssign = 0.77275886031966
                        const H = isBrowserDesktop ? window.innerHeight - 51 : window.innerHeight - 80
                        const W = window.innerWidth - 50
                        const realW = isBrowserDesktop ? H*ratioImgAssign*zoomValue : W*zoomValue
                        const realH = realW/ratioImgAssign
                        imgCanvas.height = realH
                        imgCanvas.width = realW
                        ctx.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
                        ctx.drawImage(this, 0, 0, realW, realH);

                        var imageStr = imgCanvas.toDataURL();
                        ws.removeEventListener("error", onError);
                        let skp = new window.Sketchpad({
                            containerEl: sketchpadEl,
                            token: `Notes|${noteOfWorksheet[0]._id}|${pageIndexNoteTmp.toString()}`,
                            ws: ws,
                            createPageConfig: {
                                sid: noteOfWorksheet[0]._id,
                                no: 1,
                                backgroundColor: "rgba(250,250,250,1)"
                            },
                            user_id: sketchpadUserId,
                            backgroundImage: imageStr,
                            data: noteData
                        })
                        setSketchpad(skp)
                        window.sketchpad = skp

                        let scaleWrapperEl = document.getElementById("scaleWrapperNote")
                        if (scaleWrapperEl) {
                            scaleWrapperEl.style.width = realW + 'px'
                            scaleWrapperEl.style.height = realH + 'px'
                            setExerciseWidth(realW)
                        }
                        sketchpadEl.style.width = realW + 'px'
                        sketchpadEl.style.height = realH + 'px'
                    }
                    imgBg.src = imageBg;
                }
                Fc.hideLoading()
            }
            ws.addEventListener("open", onOpen);
            ws.addEventListener("error", onError);

            return () => {
                let newSketchpadEl = sketchpadEl.cloneNode(true);
                sketchpadEl.parentNode.replaceChild(newSketchpadEl, sketchpadEl);
                ws.close()
                clearTimeout(tm)
                clearInterval(tmInterval)
            }
        }
    }, [noteData, indexBg, headerEditActiveRx, isLandscape, pageIndexNoteTmp, showNote, showSolution, isReloadSkp])
    //===================================================================================

    useEffect(() => {
        if (sketchpad && sketchpad.canvas) {
            //reset when client listen reset event
            sketchpad.on("reset", () => onReset())

            sketchpad.setTool('pen');
            let setInit = setTimeout(() => {
                let config = sketchpad.getToolsConfigs()
                let font = config.type.font
                setTypeFontFamily(font)
                if (toolId === "null") {
                    selectTool('null')
                } else {
                    selectTool(toolIdTmp.current)
                    setIsShowPenMenu(false)
                }
                sketchpad.setScale(1);
            }, 100)

            return () => {
                clearTimeout(setInit);
            }
        }
    }, [sketchpad])
    //#endregion

    //#region pentool/highlighter/type...
    function setColor(color, alpha) {
        if (["pen", "highlighter", "type", 'rectangle', 'line', 'circle'].includes(sketchpad.tool))
            sketchpad.getCurrentTool().setColor(color[0], color[1], color[2], alpha);
    }

    function onClickPenColor(e) {
        var color = e.target.style.color.match(/\d+/g);
        setColor(color, 1)
        setPenColor(color)
    }


    function onClickHightLighterColor(e) {
        var color = e.target.style.color.match(/\d+/g);
        setColor(color, 0.5)
        setHighlighterColor(color)
    }

    function selectTool(toolId) {
        if (sketchpad && sketchpad.canvas) {
            removeScaleFrame()
            if (!["pen", "highlighter", "eraser", "null"].includes(toolId)) {
                if (!showRestrictModal())
                    return
            }
            if (toolId !== "shape") {
                setToolId(toolId)
                sketchpad.setTool(toolId);
                // sketchpad.setScale(sketchpad.scale); //set scale lam sketchpad refresh 
                if (['pen', 'type', 'rectangle', 'line', 'circle'].includes(toolId)) {
                    setColor(penColor, 1)
                }
                if (toolId === "highlighter") {
                    setColor(highlighterColor, 0.5)
                    // sketchpad.setScale(sketchpad.scale);
                }
            }

            setIsShowPenMenu(false)
            setIsShowTrash(false)
            setIsShowHightLighterMenu(false)
            setIsShowTypeMenu(false)
            setIsShowShapesMenu(false)

            switch (toolId) {
                case "pen":
                    setIsShowPenMenu(!isShowPenMenu)
                    break;
                case "eraser":
                    setIsShowTrash(!isShowTrash) //show eraser
                    break;
                case "highlighter":
                    setIsShowHightLighterMenu(!isShowHightLighterMenu)
                    break;
                case "type":
                    setIsShowTypeMenu(!isShowTypeMenu)
                    break;
                case "line":
                    setIsShowShapesMenu(!isShowShapesMenu)
                    break;
                case "shape":
                    setIsShowShapesMenu(!isShowShapesMenu)
                    break;
                default:
                    break;
            }
        }
    }
    //hide menu after 3 seconds
    useEffect(() => {
        if (isShowPenMenu) {
            let timeout = setTimeout(() => {
                setIsShowPenMenu(false)
            }, 3000)
            return () => {
                clearTimeout(timeout)
            }
        }
    }, [isShowPenMenu])

    //hide menu after 3 seconds
    useEffect(() => {
        if (isShowTrash) {
            let timeout = setTimeout(() => {
                setIsShowTrash(false)
            }, 3000)
            return () => {
                clearTimeout(timeout)
            }
        }
    }, [isShowTrash])

    //hide menu after 3 seconds
    useEffect(() => {
        if (isShowHightLighterMenu) {
            let timeout = setTimeout(() => {
                setIsShowHightLighterMenu(false)
            }, 3000)
            return () => {
                clearTimeout(timeout)
            }
        }
    }, [isShowHightLighterMenu])

    useEffect(() => {
        if (sketchpad && sketchpad.canvas) {
            sketchpad.initPenLineWidth = 1 * penLineWidth
            sketchpad.setScale(sketchpad.scale);
        }
    }, [penLineWidth])

    useEffect(() => {
        if (sketchpad && sketchpad.canvas) {
            sketchpad.initPaintLineWidth = 16 * highlighterLineWidth
            sketchpad.setScale(sketchpad.scale);
        }
    }, [highlighterLineWidth])
    //#endregion

    //#region undo/redo
    function undo() {
        if (sketchpad && sketchpad.canvas) {
            removeScaleFrame()
            if (showRestrictModal())
                sketchpad.undo();
        }
    }

    function redo() {
        if (sketchpad && sketchpad.canvas) {
            removeScaleFrame()
            if (showRestrictModal())
                sketchpad.redo();
        }
    }
    //#endregion

    //#region style type
    useEffect(() => {
        if (sketchpad.tool === "type") {
            if (typeBold) {
                sketchpad.getCurrentTool().setFontWeight("bold")
            } else {
                sketchpad.getCurrentTool().setFontWeight("")
            }
        }
    }, [typeBold])

    useEffect(() => {
        if (sketchpad.tool === "type") {
            if (typeItalic) {
                sketchpad.getCurrentTool().setFontStyle("italic")
            } else {
                sketchpad.getCurrentTool().setFontStyle("")
            }
        }
    }, [typeItalic])

    useEffect(() => {
        if (sketchpad.tool === "type") {
            if (typeUnderline) {
                sketchpad.getCurrentTool().setTextDecoration("underline")
            } else {
                sketchpad.getCurrentTool().setTextDecoration("")
            }
        }
    }, [typeUnderline])

    useEffect(() => {
        if (sketchpad.tool === "type") {
            sketchpad.initTypeFontSize = typeFontSize
            sketchpad.setScale(sketchpad.scale);
        }
    }, [typeFontSize])

    useEffect(() => {
        if (sketchpad.tool === "type") {
            sketchpad.getCurrentTool().setFont(typeFontFamily)
        }
    }, [typeFontFamily])

    //#endregion

    //#region graphing calculator

    function onShowGraphingCalculator() {
        if (showRestrictModal()) {
            setIsShowPenMenu(false)
            setIsShowHightLighterMenu(false)
            setIsShowTypeMenu(false)
            setIsShowShapesMenu(false)
            $('#graphingCalculatorModal').modal('show')
        }
        scrollToTop()
    }

    function cropGraph() {
        // let heightEl = $('.dcg-graph-outer')[0].offsetHeight || 300
        // let widthEl = $('.dcg-graph-outer')[0].offsetWidth || 300
        let dataUrl = demoCalculator.screenshot({ height: 300, width: 300, targetPixelRatio: 2 })
        API.assignmentUploadImage(assignId, dataUrl).then(res => {
            if (res.status === 200 && res.data.Success) {
                var config = {
                    containerEl: showNote ? document.getElementById('scaleWrapperNote') : document.getElementById('scaleWrapper'),
                    width: 300,
                    height: 300
                }
                selectTool('null')
                window.scaleFrame = new window.ScaleFrame(res.data.Data, config, drawPicture)
                $('#graphingCalculatorModal').modal('hide')
            } else {
                Fc.showError(res.data.Message || res.statusText)
            }
        }).catch(err => {
            Fc.showError("Can not connect to server")
        })

        //Ko dung code nay do Demos Calculator thay doi
        // html2canvas($('.dcg-graph-outer')[0]).then(canvas => {
        //     var dataUrl = canvas.toDataURL("image/jpeg")
        //     console.log("DataURL: ", dataUrl)
        //     API.assignmentUploadImage(assignId, dataUrl).then(res => {
        //         if (res.status === 200 && res.data.Success) {
        //             var config = {
        //                 containerEl: showNote ? document.getElementById('scaleWrapperNote') : document.getElementById('scaleWrapper'),
        //                 width: parseInt(canvas.style.width.substring(0, canvas.style.width.length - 2)),
        //                 height: parseInt(canvas.style.height.substring(0, canvas.style.height.length - 2))
        //             }
        //             selectTool('null')
        //             window.scaleFrame = new window.ScaleFrame(res.data.Data, config, drawPicture)
        //             $('#graphingCalculatorModal').modal('hide')
        //         } else {
        //             Fc.showError(res.data.Message || res.statusText)
        //         }
        //     }).catch(err => {
        //         Fc.showError("Can not connect to server")
        //     })
        // });
    }


    function onShowCalculator() {
        if (showRestrictModal()) {
            setIsShowPenMenu(false)
            setIsShowHightLighterMenu(false)
            setIsShowTypeMenu(false)
            setIsShowShapesMenu(false)
            $('#calculatorModal').modal('show')
        }
    }
    

    function drawPicture() {
        let sketchpadEl = showNote ? $('#sketchpadNote') : $('#sketchpad')
        var scaleFrame = window.scaleFrame
        let drawPicX = scaleFrame.marginLeft + sketchpadEl.offset().left
        let drawPixY = (scaleFrame.marginTop + sketchpadEl.offset().top) - editScrollYRef.current
        sketchpad.drawPicture(scaleFrame.picture.src, drawPicX, drawPixY, scaleFrame.width, scaleFrame.height)
        removeScaleFrame()
    }

    function removeScaleFrame() {
        if (window.scaleFrame) {
            var scaleFrame = window.scaleFrame
            scaleFrame.remove()
            scaleFrame = null
        }
    }

    //#endregion

    function onShowFormulaModal() {
        if (showRestrictModal())
            setShowFormulaModal(true)
    }

    function hideFormulaModal() {
        setShowFormulaModal(false)
    }

    function changePage(e) {
        selectTool('null')
        setPenLineWidth(1)
        setHighlighterLineWidth(1)
        if (e.key === "Enter" && !isNaN(parseInt(pageIndexTemp))) {
            window.ResizeNode && window.ResizeNode.cancel()
            let page = parseInt(pageIndexTemp)
            if (page >= 1 && page <= totalPage) {
                setPageIndex(page - 1)
                dispatch({ type: Type.SAVE_PAGEINDEX_WS, payload: page - 1});
                //hidden keyboard when change page
                if (document.activeElement) {
                    document.activeElement.blur();
                }
            }
        }
    }

    function onIncreasePage() {
        window.ResizeNode && window.ResizeNode.cancel()
        selectTool('null')
        setPenLineWidth(1)
        setHighlighterLineWidth(1)
        if(pageIndexTemp >= 1 && pageIndexTemp < totalPage && (pageIndex + 1) >= 1 && (pageIndex + 1) < totalPage) {
            setPageIndexTemp(1)
            setPageIndex(pageIndex + 1)
            dispatch({ type: Type.SAVE_PAGEINDEX_WS, payload: pageIndex + 1});
        }
    }

    function onDecreasePage() {
        window.ResizeNode && window.ResizeNode.cancel()
        selectTool('null')
        setPenLineWidth(1)
        setHighlighterLineWidth(1)
        if(pageIndexTemp > 1 && pageIndexTemp <= totalPage && (pageIndex + 1) > 1 && (pageIndex + 1) <= totalPage) {
            setPageIndexTemp(1)
            setPageIndex(pageIndex - 1)
            dispatch({ type: Type.SAVE_PAGEINDEX_WS, payload: pageIndex - 1});
        }
    }

    function onZoomInPage() {
        window.ResizeNode && window.ResizeNode.cancel()
        const ratioImgAssign = 0.77275886031966
        const H = isBrowserDesktop ? window.innerHeight - 51 : window.innerHeight - 80
        const W = window.innerWidth - 65
        const realW = isBrowserDesktop ? H*ratioImgAssign : W
        const width = realW*parseFloat(zoomValue)

        selectTool('null')
        setPenLineWidth(1)
        setHighlighterLineWidth(1)
        let zoom = parseFloat(zoomValue) + parseFloat(0.1)

        if(worksheetType != "Notes" && parseFloat(zoomValue) < 2 && headerEditActiveRx === 'CreateNote') {
            setZoomValue(zoom.toPrecision(2))
        }
        
        if((width >= exerciseWidth && worksheetType != "Notes")  || parseFloat(zoomValue) >= 2) {
            // Fc.showError('Image width not enought large to zoom')
            return
        } 
        
        setZoomValue(zoom.toPrecision(2))
    }

    function onZoomOutPage() {
        window.ResizeNode && window.ResizeNode.cancel()
        selectTool('null')
        setPenLineWidth(1)
        setHighlighterLineWidth(1)
        if(parseFloat(zoomValue) <= parseFloat(1)) return
        let zoom = parseFloat(zoomValue) - parseFloat(0.1)
        setZoomValue(zoom.toPrecision(2))
    }

    function onFitPage() {
        window.ResizeNode && window.ResizeNode.cancel()
        selectTool('null')
        setPenLineWidth(1)
        setHighlighterLineWidth(1)
        if(parseFloat(zoomValue) <= parseFloat(1)) return
        setZoomValue(1)
    }
    //#region swipe
    function onSwipeLeft(e) {
        if(!isSwiping){
        if(worksheetType !==  "Assignment") return
        setIsSwiping(true)

        if (e.absX > 250) {
            window.ResizeNode && window.ResizeNode.cancel()
            //save drawing worksheet
            if (!showSolution && !showNote){
                const dateTime = new Date().getTime();
                let dataJson = [{dateTime}]
                if(sketchpad && Object.keys(sketchpad).length > 0) dataJson = sketchpad.saveSketchpad()
                setWorksheetJsonData(JSON.stringify(dataJson))
                saveDrawingWorksheet()

            }

            if (showNote) {
                dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Exercise' })

                if (worksheetType != "Notes")
                    setShowNote(false)
                
                //set drawing note state before save drawing note
                const dateTime = new Date().getTime();
                let noteJson = [{dateTime}]
                if(sketchpad && Object.keys(sketchpad).length > 0) noteJson = sketchpad.saveSketchpad()
                setNoteData(JSON.stringify(noteJson))   

                drawNote()

                return
            }

            if (showSolution && solutionPageIndex < worksheet.Exercise - 1) {
                setSolutionPageIndex(solutionPageIndex + 1)
            } else if (worksheet.ShowSolution && worksheet.Exercise.Solutions && worksheet.Exercise.Solutions.length > 0) {
                setShowSolution(true)
                dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Solution' })
                
            }
        }
        }
        setTimeout(() => {
            setIsSwiping(false)
        },1000)
    }

    function onSwipeRight(e) {
        if(!isSwiping) {
        if(worksheetType !==  "Assignment") return
        if (e.absX > 250) {
            window.ResizeNode && window.ResizeNode.cancel()
            //save drawing worksheet
            if (!showSolution && !showNote){
                dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: 'CreateNote' })

                const dateTime = new Date().getTime();
                let dataJson = [{dateTime}]
                if(sketchpad && Object.keys(sketchpad).length > 0) dataJson = sketchpad.saveSketchpad()
                setWorksheetJsonData(JSON.stringify(dataJson))

                saveDrawingWorksheet()

            }
            if (showSolution) {
                dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Exercise' })

                //show solution
                if (solutionPageIndex > 0) {
                    setSolutionPageIndex(solutionPageIndex - 1)
                } else {
                    setShowSolution(false)
                }


            } else {
                //check permission to write or show notes
                if(!permissionWriteNote) {
                    Fc.showError('Please upgrade your account to use this features.')
                    return
                }
                //if noteWorksheet equal null or [] then create noteWorksheet
                if(noteOfWorksheet && noteOfWorksheet.length > 0) {
                    //show note worksheet
                    setShowNote(true)
                    return 
                }
                createNoteOfWorksheet()
                setTimeout(() => {
                    setIsSwiping(false)
                },1000)

            }
        }
        }
        setTimeout(() => {
            setIsSwiping(false)
        },1000)
    }



    function onSwipeUp(e) {
        if (e.absY > 350 && (window.innerWidth<window.innerHeight)) {
            setIsSwiping(true)
            window.ResizeNode && window.ResizeNode.cancel()
            //Swipe up when worksheet is active
            if (!showSolution && !showNote && pageIndex < totalPage - 1) {
                setPageIndex(pageIndex + 1)
                dispatch({ type: Type.SAVE_PAGEINDEX_WS, payload: pageIndex + 1});
            }
            
            //swipe up when note is active
            if (!showSolution && showNote && parseInt(pageIndexNoteTmp) < parseInt(pageOfNote)) {
                setPageIndexNoteTmp(parseInt(pageIndexNoteTmp) + 1)
                dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: parseInt(pageIndexNoteTmp) + 1 });
                setTextPageindexNote(parseInt(pageIndexNoteTmp) + 1)
                drawNote()
            }
        }
        setTimeout(() => {
            setIsSwiping(false)
        },1000)
    }

    function onSwipeDown(e) {
        if (e.absY > 350 && (window.innerWidth<window.innerHeight)) {
            setIsSwiping(true)
            window.ResizeNode && window.ResizeNode.cancel()
            //swipe down when worksheet is active
            if (!showSolution && !showNote && pageIndex > 0) {
                setPageIndex(pageIndex - 1)
                dispatch({ type: Type.SAVE_PAGEINDEX_WS, payload: pageIndex - 1});
            }

            //swipe down when note is active
            if (!showSolution && showNote && parseInt(pageIndexNoteTmp) > 1) {
                setPageIndexNoteTmp(parseInt(pageIndexNoteTmp) - 1)
                dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: parseInt(pageIndexNoteTmp) - 1 });
                setTextPageindexNote(parseInt(pageIndexNoteTmp) - 1)
                drawNote()
            }
        }
        setTimeout(() => {
            setIsSwiping(false)
        },1000)
    }
    
    //#endregion

    function showNoteDesktop() {
        if (!showSolution && !showNote){
            const dateTime = new Date().getTime();
            let dataJson = [{dateTime}]
            if(sketchpad && Object.keys(sketchpad).length > 0) 
            
            dataJson = sketchpad.saveSketchpad()
            setWorksheetJsonData(JSON.stringify(dataJson))
            saveDrawingWorksheet()

        }

        if (showSolution) {
            //show solution
                setShowSolution(false)
        } 

        //check permission to write or show notes
        if(!permissionWriteNote) {
            Fc.showError('Please upgrade your account to use this features.')
            return
        }

        //if noteWorksheet equal null or [] then create noteWorksheet
        if(noteOfWorksheet && noteOfWorksheet.length > 0) {
            setShowNote(true)
            return 
        }

        setShowNote(true)
        // createNoteOfWorksheet()
    }

    function showSolutionDesktop() {
        //save drawing worksheet
        if (!showSolution && !showNote){

            const dateTime = new Date().getTime();
            let dataJson = [{dateTime}]
            if(sketchpad && Object.keys(sketchpad).length > 0) 
            dataJson = sketchpad.saveSketchpad()
            setWorksheetJsonData(JSON.stringify(dataJson))
            saveDrawingWorksheet()
        }

        // console.log("worksheet: ", worksheet)
        if(Object.keys(worksheet).length > 0 && !worksheet.ShowSolution) {
            Fc.showError('Please upgrade your account to use this features.');
            dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: preHeaderEditActiveRx });
            return 
        }

        if (showNote) {
            if (worksheetType != "Notes") setShowNote(false)

            //set drawing note state before save drawing note
            const dateTime = new Date().getTime();
            let noteJson = [{dateTime}]
            if(sketchpad && Object.keys(sketchpad).length > 0) 
            noteJson = sketchpad.saveSketchpad()
            setNoteData(JSON.stringify(noteJson))

            drawNote()
        }
        
        if (worksheet.ShowSolution && worksheet.Exercise.Solutions && worksheet.Exercise.Solutions.length > 0) {
            setShowSolution(true)
            return
        }
        setShowSolution(true)
    }

    function showExerciseDesktop() {
        setShowNote(false)
        setShowSolution(false)
        if (showNote) {
            if (worksheetType != "Notes")
                setShowNote(false)

            //set drawing note state before save drawing note
            const dateTime = new Date().getTime();
            let noteJson = [{dateTime}]
            if(sketchpad && Object.keys(sketchpad).length > 0) 
            
            noteJson = sketchpad.saveSketchpad()
            setNoteData(JSON.stringify(noteJson))


            drawNote()
        }
    }

    function saveDrawingWorksheet(reload = false) {
        if(worksheetType === "Notes") return

        if(worksheetType === "Assignment") {
            saveDrawingAssignment(reload)
            return
        }

        saveDrawingDocument(reload)
    }

    async function saveDrawingAssignment(reload) {
        if(!sketchpad && !sketchpad.canvas) return
        if((worksheet && !worksheet.Exercise) || !worksheet) return
        setIsSave(true)
        const dateTime = new Date().getTime();
        let dataJson = [{dateTime}]
        if(sketchpad && Object.keys(sketchpad).length > 0) dataJson = sketchpad.saveSketchpad()
        try {
            const body = {
                AssignmentId: assignId,
                WorksheetId: worksheet.Exercise._id,
                StudentWork: JSON.stringify(dataJson)
            }
            const saveResult = await API.updateAssignment(body)
            if(saveResult.data.Success && reload){
                // getWorksheet() //huynh cmt 1
                // updateWorksheetJsonData() 
            } 
            if(saveResult) setIsSave(false)
        } catch(err) {
            setIsSave(false)
        }
    }

    async function saveDrawingDocument(reload) {
        if(!sketchpad && !sketchpad.canvas) return
        setIsSave(true)

        const dateTime = new Date().getTime();
        let dataJson = [{dateTime}]
        if(sketchpad && Object.keys(sketchpad).length > 0) dataJson = sketchpad.saveSketchpad()
        try {
            const body = {
                WorksheetId: worksheet.Exercise._id,
                StudentWork: JSON.stringify(dataJson)
            }
            const saveResult = await API.updateDrawOnWorksheet(body)
            if(saveResult.data.Success && reload) {
                // getWorksheet() //huynh cmt 1
                // updateWorksheetJsonData()
            }
            if(saveResult) setIsSave(false)
        } catch(err) {
            setIsSave(false)
        }
    }

    function onClickMenuPosition() {
        if (showRestrictModal()) {
            if (menuPosition === "left")
                setMenuPosition('right')
            else
                setMenuPosition('left')
        }
    }
    //#region restrict
    function getTrialDayLeft() {
        API.GetDayLeftToRestriction(operatorId).then(res => {
            if (res.status === 200 && res.data.Success) {
                setTrialDayLeft(res.data.Data)
            } else {
                Fc.showError(res.data.Message || res.Message)
            }
        }).catch(err => {
            Fc.showError("Can not connect to server")
        })
    }

    function showRestrictModal() {
        if (accountMethod === Const.AccountMethod.RESTRICTED) {
            if (role === Const.Role.OPERATOR)
                setShowFeatureLockedModal(true)
            else
                setShowContactAdminModal(true)
            return false
        }
        if (role !== Const.Role.VENDOR) {
            setShowDayLeftModal(true)
        }
        return true
    }

    function onHideDayLeftModal() {
        setShowDayLeftModal(false)
    }

    function onHideContactAdminModal() {
        setShowContactAdminModal(false)
    }

    function onHideFeatureLockedModal() {
        setShowFeatureLockedModal(false)
    }
    //#endregion

    async function onChangeBackgroundSketchpad() {
        if (!showRestrictModal()) return

        //set drawing note state before save drawing note
        const dateTime = new Date().getTime();
        let noteJson = [{dateTime}]
        if(sketchpad && Object.keys(sketchpad).length > 0) noteJson = sketchpad.saveSketchpad()
        setNoteData(JSON.stringify(noteJson))

        //set background index
        function setIndexBackground() {
            let index = parseInt(indexBg) + 1
            setIndexBg(index)
        }
        setIndexBackground()
    }

    async function clearAll() {
        setShowQuestionClearAll(true)
    }

    async function acceptClearAll() {
        sketchpad.reset()
        setPenLineWidth(1)
        setHighlighterLineWidth(1)
        setShowQuestionClearAll(false)
    }

    function denyClearAll() {
        setShowQuestionClearAll(false)
    }

    function acceptRefreshBr() {
        setIsShowRefreshBrowser(false)
        window.location.reload();
    }

    function denyRefreshBr() {
        setIsShowRefreshBrowser(false)
    }

    function onReset() {
        const dateTime = new Date().getTime();
        if(showNote) setNoteData(JSON.stringify([{time: dateTime}]))
        if(!showNote) setWorksheetJsonData(JSON.stringify([{time: dateTime}]))
    }

    function hideShap() {
    }

    // When the user clicks on the button, scroll to the top of the document
    function scrollToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    function showMathEditor(mml = null) {
        if (!showRestrictModal()) return

        setToolId(null)
        selectTool('null')
        setShowEquationModal(true)
        setTimeout(() => {
            let editor = window.wiris.jsEditor.JsEditor.newInstance({
                'language': 'en', 
                'autoformat': true, 
                'detectHand': false,
                // 'toolbar':` <toolbar ref='general' removeLinks='true'>
                //     <tab ref='matrices'>
                        
                //     </tab>
                // </toolbar>`
            });
            editor.insertInto(document.getElementById('editorContainer'));
            mml && editor.setMathML(mml)
            setMathEditor(editor)
            window.editor = editor
        }, 0)
    }

    function hideEquationModal() {
        setShowEquationModal(false)
        setMathEditor(null)
    }

    function insertEquation() {
        let mathMlString = mathEditor ? mathEditor.getMathML() : '';

        let config = {
            containerEl: showNote ? document.getElementById('sketchpadNote') : document.getElementById('sketchpad'),
            save: insertEquationImg,
            showKeyboar: showMathEditor,
            textColor: penColor,
            content: mathMlString,
            MathJax: window.MathJax,
            offset: [sketchpad ? sketchpad.width/2 : 50, sketchpad ? sketchpad.height/2 : 50]
        }
        window.ResizeNode = new window.MathContainer(config)
        hideEquationModal()
    }

    function insertEquationImg (el) {
        //delete mjx-assistive-mml tag, because image is duplicated in firefox, safari
        $('mjx-assistive-mml')[0] && $('mjx-assistive-mml')[0].remove(); 

        html2canvas($('.mathContent')[0], 
            {
                // useCORS: true, 
                allowTaint: true,
                backgroundColor: null,
                inlineImages: true
            }
        ).then(canvas => {
            var dataUrl = canvas.toDataURL("image/png")
            API.assignmentUploadImage(assignId, dataUrl).then(res => {
                if (res.status === 200 && res.data.Success) {
                    selectTool('null')
                    drawMathEquation(res.data.Data, el)
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            }).catch(err => {
                Fc.showError("Can not connect to server")
            })
        });
    }

    function drawMathEquation (url, el) {
        let sketchpadEl = showNote ? $('#sketchpadNote') : $('#sketchpad')
        let drawPicX = el.left + sketchpadEl.offset().left
        let drawPixY = (el.top + sketchpadEl.offset().top) - editScrollYRef.current
        sketchpad.drawPicture(url, drawPicX, drawPixY, el.width -16, el.height)
    }

    return (
        <EditView
            sketchpad={sketchpad}
            pageIndex={pageIndex}
            onIncreasePage={onIncreasePage}
            onDecreasePage={onDecreasePage}
            onZoomInPage={onZoomInPage}
            onZoomOutPage={onZoomOutPage}
            onFitPage={onFitPage}
            zoomValue={zoomValue}

            pageIndexTemp={pageIndexTemp} 
            setPageIndexTemp={setPageIndexTemp}
            totalPage={totalPage}
            worksheet={worksheet}
            banner={banner}

            menuPosition={menuPosition} 
            onClickMenuPosition={onClickMenuPosition}

            penColor={penColor}
            highlighterColor={highlighterColor}
            onClickPenColor={onClickPenColor}
            onClickHightLighterColor={onClickHightLighterColor}
            selectTool={selectTool}
            undo={undo}
            redo={redo}
            isShowPenMenu={isShowPenMenu}
            isShowTrash={isShowTrash}
            isShowHightLighterMenu={isShowHightLighterMenu}
            isShowTypeMenu={isShowTypeMenu}
            isShowShapesMenu={isShowShapesMenu}
            toolId={toolId}

            penLineWidth={penLineWidth} 
            setPenLineWidth={setPenLineWidth}
            highlighterLineWidth={highlighterLineWidth} 
            setHighlighterLineWidth={setHighlighterLineWidth}

            typeBold={typeBold} 
            setTypeBold={setTypeBold}
            typeItalic={typeItalic} 
            setTypeItalic={setTypeItalic}
            typeUnderline={typeUnderline} 
            setTypeUnderline={setTypeUnderline}
            typeFontSize={typeFontSize} 
            setTypeFontSize={setTypeFontSize}
            typeFontFamily={typeFontFamily} 
            setTypeFontFamily={setTypeFontFamily}

            showFormulaModal={showFormulaModal} 
            onShowFormulaModal={onShowFormulaModal}
            hideFormulaModal={hideFormulaModal}

            onShowGraphingCalculator={onShowGraphingCalculator}
            cropGraph={cropGraph}
            onShowCalculator={onShowCalculator}

            showQuestionClearAll={showQuestionClearAll}
            isShowRefreshBrowser={isShowRefreshBrowser}
            denyRefreshBr={denyRefreshBr}
            acceptRefreshBr={acceptRefreshBr}
            acceptClearAll={acceptClearAll}
            denyClearAll={denyClearAll}
            clearAll={clearAll}
            changePage={changePage}
            onChangeBackgroundSketchpad={onChangeBackgroundSketchpad}

            showMathEditor={showMathEditor}
            showNote={showNote}
            pageOfNote={pageOfNote}
            pageIndexNoteTmp={pageIndexNoteTmp}
            changePageNote={changePageNote}
            onChangePageNote={onChangePageNote}
            textPageindexNote={textPageindexNote}
            setPageIndexNoteTmp={setPageIndexNoteTmp}
            addNotePage={addNotePage}
            onIncreasePageNote={onIncreasePageNote}
            onDecreasePageNote={onDecreasePageNote}

            showSolution={showSolution}
            solutionPageIndex={solutionPageIndex}
            onSwipeLeft={onSwipeLeft}
            onSwipeRight={onSwipeRight}
            onSwipeUp={onSwipeUp}
            onSwipeDown={onSwipeDown}
            isShowShap={isShowShap}
            hideShap={hideShap}

            // Account restrict
            role={role}
            accountMethod={accountMethod}
            trialDayLeft={trialDayLeft}
            showDayLeftModal={showDayLeftModal} 
            onHideDayLeftModal={onHideDayLeftModal}
            showContactAdminModal={showContactAdminModal} 
            onHideContactAdminModal={onHideContactAdminModal}
            showFeatureLockedModal={showFeatureLockedModal} 
            onHideFeatureLockedModal={onHideFeatureLockedModal}
            onClickShape={onClickShape}

            showEquationModal={showEquationModal}
            hideEquationModal={hideEquationModal}
            insertEquation={insertEquation}
            {...props} />
    )
}