/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from "react-router-dom";
import io from "socket.io-client";
import * as $ from 'jquery'

import * as Const from "../../../constants/Const";
import * as UrlConst from "../../../constants/UrlConst";
import useDidMount from '../../../hooks/useDidMount'

import API from "../../../utils/Api";
import * as Fc from "../../../utils/Functions";
import * as Type from './../../../constants/actionType';
import LayoutComponent from "./LayoutComponent";
import { cloneDeep, filter, sortBy } from "lodash"

let socket = {};

function _Layout(props) {
  const dispatch = useDispatch()
  let didMount = useDidMount()

  // GET LIST CHAT ROOM
  let allRoom = useSelector(state => state.ws.listRoom)
  let listNotifRedux = useSelector(state => state.ws.notify)
  let currentChatRx = useSelector(state => state.ws.currentChat)
  const userLogin = useSelector(state => state.ws.userLogin)
  const isLoading = useSelector(state => state.ws.isLoading)

  // const socket = useRef()
  const role = Fc.getRole()
  const operatorId = role && role.includes(Const.Role.VENDOR) ? props.match.params.id : Fc.getOperator()
  const [operatorInfo, setOperatorInfo] = useState({})
  const [openPopup, setOpenPopup] = useState(false)
  const [listClass, setListClass] = useState([])
  const [ClassId, setClassId] = useState("")

  const [ListStudent, setListStudent] = useState([])
  const [ListTeacher, setListTeacher] = useState([])
  const [selectedId, setSelectedId] = useState("")
  const [userType, setUserType] = useState("")
  const [userName, setUserName] = useState("")
  const [room, setRoom] = useState('')
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  let matchUrl = useRouteMatch();
  let messageTemp = useRef([])
  let isLoadTmp = useRef(false)
  let userLoginIdRef = useRef("")

  //user Send message to admin
  const [userSendMessage, setUserSendMessagge] = useState("")

  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState([])
  const [tabChat, setTabChat] = useState('ChatClass')

  let messageEndRef = useRef(null)
  let currentChatTmp = useRef(null)
  let isActive = useRef(null)
  let pageIndexRef = useRef(1)
  let lstNotifTmp = useRef([])
  let lsRoomTmp = useRef([])

  let timerLstMsgGr = null
  let timerLstMsgPsn = null

  /**
  |----------------------------------------------------------------------------
  | Detect device is Landscape or Portrait when orientation Ipad
  |----------------------------------------------------------------------------
  */
  useEffect(() => {
    window.onorientationchange = function (event) {
      switch (window.orientation) {
        case 0:
          dispatch({ type: Type.CHANGE_ORIENTATION, payload: false });
          break;

        case -90:
          dispatch({ type: Type.CHANGE_ORIENTATION, payload: true });
          break;

        case 90:
          dispatch({ type: Type.CHANGE_ORIENTATION, payload: true });
          break;

        case 180:
          dispatch({ type: Type.CHANGE_ORIENTATION, payload: false });

        case 270:
          dispatch({ type: Type.CHANGE_ORIENTATION, payload: true });

        case 360:
          dispatch({ type: Type.CHANGE_ORIENTATION, payload: false });
          break;
      }
    };
  })
  //===========================================================================


  useEffect(() => {
    lsRoomTmp.current = allRoom
  }, [allRoom])

  useEffect(() => {
    if(userLogin)
    userLoginIdRef.current = userLogin._id
    return () => { userLoginIdRef.current = "" }
  }, [userLogin])

  const scrollToBottom = () => {
    messageEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    if(!currentChatRx) {
      setMessageList([])
      setListStudent([])
      setListTeacher([])
      setClassId("")
      setSelectedId("")
      setTabChat("ChatClass")
      currentChatTmp.current = {tabChat: null, classId: null, fromId: null}
      return
    }
    currentChatTmp.current = currentChatRx
  }, [currentChatRx])

  function onScrollTop() {
    if(isLoadTmp.current) return
    pageIndexRef.current = pageIndexRef.current + 1
    if(currentChatTmp.current && currentChatTmp.current.tabChat === "class" && pageIndexRef.current > 1) {
      getListMessageInGroup(currentChatTmp.current.classId, pageIndexRef.current)
      dispatch({ type: Type.SAVE_IS_LOADING, payload: true})
    }

    if(currentChatTmp.current && currentChatTmp.current.tabChat === "person" && pageIndexRef.current > 1) {
      GetChatMessages(Fc.getUserId(), currentChatRx.fromId, pageIndexRef.current)
      dispatch({ type: Type.SAVE_IS_LOADING, payload: true})
    }
  }

  useEffect(() => {
    isLoadTmp.current = isLoading
  }, [isLoading])

  useEffect(() => {
    if(!allRoom || allRoom.length <= 0) return

    messageTemp.current = []
    setMessageList([])
    setListStudent([])
    setListTeacher([])
    setClassId("")
    setSelectedId("")
    setTabChat("ChatClass")
    /**
    |-------------------------------------------------------------------------------------------
    | kiem tra socket 5s 1 lan 
    |-------------------------------------------------------------------------------------------
    */
    let timeInterval = setInterval(() => {
      if(!socket || Object.keys(socket).length == 0 || !socket.connected) {
        socket = io(UrlConst.CONNECTION_PORT, {transports: [ "websocket" ]}).connect()
        
        //JOIN ALL ROOM
        if (Fc.getRole() === Const.Role.OPERATOR) {
          socket.emit('join_all_room', allRoom)
          socket.emit('join_room', Fc.getUserId())
        }

        if (Fc.getRole() === Const.Role.VENDOR) {
          socket.emit('join_all_room', allRoom)
        }
      }
    }, 4999)
    //==========================================================================================

    if(!socket || Object.keys(socket).length == 0 || !socket.connected)
    socket = io(UrlConst.CONNECTION_PORT, {transports: [ "websocket" ]}).connect()
    
    //JOIN ALL ROOM
    if (Fc.getRole() === Const.Role.OPERATOR) {
      socket.emit('join_all_room', allRoom)
      socket.emit('join_room', Fc.getUserId())
    }

    if (Fc.getRole() === Const.Role.VENDOR) {
      socket.emit('join_all_room', allRoom)
    }

    socket.on('show_notification', (data) => {
      if(data.ToRole === "CLASS") return 

      if (data.From !== Fc.getUserId() && data.ToRole === Const.Role.OPERATOR) {
        //khong hien thi notification cua lop chat khi dang o lop do
        if(currentChatTmp.current && currentChatTmp.current.tabChat === "class" && data.Type === "groupchat" && data.ClassId === currentChatTmp.current.classId ) {
          return
        } 

        //===Khong hien thi notification dang chat voi user
        if(currentChatTmp.current && currentChatTmp.current.tabChat === "person" && data.ClassId === currentChatTmp.current.classId && data.From === currentChatTmp.current.fromId ) {
          return
        } 

        let a = lstNotifTmp.current.some(x => (x.From === data.From && x.To === data.To))
        if (a === true) {
          let index = lstNotifTmp.current.findIndex(item => item.From === data.From && item.To === data.To)
          lstNotifTmp.current.splice(index, 1)
        }
        lstNotifTmp.current.push(data)

        dispatch({ type: Type.SAVE_NOTIFY, payload: cloneDeep(lstNotifTmp.current) })
        return
      }

      if (data.From !== Fc.getUserId() && data.ToRole === "CLASS") {
        if(currentChatTmp.current && currentChatTmp.current.tabChat === "class" && data.Type === "groupchat" && data.ClassId === currentChatTmp.current.classId ) {
          return
        } 
      }

      if(data.To !== Fc.getUserId() ){
        return
      }

      let a = lstNotifTmp.current.some(x => (x.From === data.From && x.To === data.To))
      if (a === true) {
        let index = lstNotifTmp.current.findIndex(item => item.From === data.From && item.To === data.To)
        lstNotifTmp.current.splice(index, 1)
      }
      lstNotifTmp.current.push(data)

      dispatch({ type: Type.SAVE_NOTIFY, payload: cloneDeep(lstNotifTmp.current) })
    });

    // Get Notifi in DB
    if((role === Const.Role.OPERATOR)) getListNotif()

    window.toogleMenuEvent()

    if (role === Const.Role.VENDOR) {
      API.getDetailClient(operatorId).then(res => {
        if (res.status === 200 && res.data.Success) {
          setOperatorInfo(res.data.Data)
        } else {
          if (res.data.Message)
            Fc.showError(res.data.Message)
          else
            Fc.showError(res.statusText)
        }
      }).catch(err => {
        Fc.showError("Cant not connect to server")
      })

      // GET LIST CLASS-ROLE VENDOR
      API.listClass(operatorId).then(res => {
        if (res.status === 200 && res.data.Success) {
          setListClass(res.data.Data);
        } else {
          if (res.data.Message)
            Fc.showError(res.data.Message)
          else
            Fc.showError(res.statusText)
        }
      }).catch(err => {
        Fc.showError("Cant not connect to server")
      })
      
    } else {
      API.getAccountInfo().then(res => {
        if (res.status === 200 && res.data.Success) {
          setOperatorInfo(res.data.Data)
        } else {
          if (res.data.Message)
            Fc.showError(res.data.Message)
          else
            Fc.showError(res.statusText)
        }
      }).catch(err => {
        Fc.showError("Cant not connect to server")
      })
    }

    return () => {
      clearInterval(timeInterval)
    }
  }, [allRoom])


  useEffect(() => {
    //JOIN ALL ROOM
    if (socket && Object.keys(socket).length > 0 && socket.connected) {
      socket.emit('join_all_room', allRoom)
    }
  })
  /**
  |----------------------------------------------------------------------------
  | Delete notification
  |----------------------------------------------------------------------------
  */
  function delNotification(from, to, userId, callback = null) {
    API.DeleteNotification(from, to, userId)
    .then((res) => {
        if (res.data.Success) {
          if(callback) callback()
        } 
    }).catch((err) => {
        // Fc.showError("Can not connect to server")
    })
  }
  //===========================================================================

  useEffect(() => {

    // if (Fc.getRole() === Const.Role.OPERATOR)
    //   GetChatMessages(Fc.getUserId(), userSendMessage)

  }, [userSendMessage])

  /**
  |----------------------------------------------------------------------------
  | Dispatch notify count number when notify list in redux change
  |----------------------------------------------------------------------------
  */
  useEffect(() => {
    lstNotifTmp.current = listNotifRedux
    dispatch({ type: Type.COUNT_NOTIFY, payload: (listNotifRedux && listNotifRedux.length > 0) ? listNotifRedux.length : 0 })
  }, [listNotifRedux])
  //===========================================================================

  /**
  |----------------------------------------------------------------------------
  | - Set Operato ID with ROLE OPERATOR & VENDOR
  | - Get class list with operator ID
  |----------------------------------------------------------------------------
  */
  useEffect(() => {
    //GET LIST CLASS - ROLE OPERATOR & VENDOR
    let idOperator
    if (role === Const.Role.OPERATOR) {
      idOperator = operatorInfo._id
    } else
      idOperator = operatorId

    getListClass(idOperator)
  }, [operatorInfo])
  //===========================================================================

  /**
  |----------------------------------------------------------------------------
  | Get class list with operator ID
  |----------------------------------------------------------------------------
  */
  function getListClass(idOperator) {
    API.listClass(idOperator).then(res => {
      if (res.status === 200 && res.data.Success) {
        setListClass(res.data.Data);
      } else {
        if (res.data.Message)
          Fc.showError(res.data.Message)
        else
          Fc.showError(res.statusText)
      }
    }).catch(err => {
      Fc.showError("Cant not connect to server")
    })
  }
  //===========================================================================

  useEffect(() => {
    setPageIndex(1)
    dispatch({ type: Type.SAVE_IS_LOADING, payload: true})
    pageIndexRef.current = 1
    messageTemp.current = []
    let timerDispatchLoading = null

    //lan dau render ma curenChatRx ton tai thi gan ve gia tri mac dinh
    if(!didMount && currentChatRx) {
      dispatch({ type: Type.SAVE_CURRENT_CHAT, payload: null });
      return
    }

    if(!currentChatRx) return

    if (currentChatRx.tabChat === 'class') {
      if(!currentChatRx.classId) return

      getDetailClass(currentChatRx.classId)
      setMessageList([])

      if(!socket || Object.keys(socket).length == 0 || !socket.connected)
      socket = io(UrlConst.CONNECTION_PORT, {transports: [ "websocket" ]}).connect()
      
      socket.emit("join_room", `${currentChatRx.classId}`);
      setRoom(`${currentChatRx.classId}`)
      getListMessageInGroup(currentChatRx.classId, pageIndexRef.current, () => {scrollToBottom(); timerDispatchLoading = setTimeout(() => { dispatch({ type: Type.SAVE_IS_LOADING, payload: false}); }, 2000) })
      return
    }

    if (currentChatRx.tabChat === 'person') {
      if (role === Const.Role.OPERATOR) {
        if(!currentChatRx.classId || !currentChatRx.fromId) return
        pageIndexRef.current = 1
        setMessageList([])
        socket.emit("join_room", `${operatorId}|${currentChatRx.fromId}`);
        socket.emit("join_room", `${currentChatRx.fromId}|${operatorId}`);
        setRoom(`${operatorId}|${currentChatRx.fromId}`)
        GetChatMessages(Fc.getUserId(), currentChatRx.fromId, pageIndexRef.current, 
          () => {
            getDetailClass(currentChatRx.classId, () => { setSelectedId(currentChatRx.fromId) });
            
            timerDispatchLoading = setTimeout(() => {scrollToBottom(); dispatch({ type: Type.SAVE_IS_LOADING, payload: false}); }, 2000)
          }
        )
        return
      }
    }
    return () => { if(timerDispatchLoading) clearTimeout(timerDispatchLoading) }
  }, [currentChatRx])


  /**
  |----------------------------------------------------------------------------
  | Get class detail with class ID
  |----------------------------------------------------------------------------
  */
  function getDetailClass(ClassId, callback = null) {
    API.detailClass(ClassId).then(res => {
      if (res.status === 200 && res.data.Success) {
        let primaryTeacher = res.data.Data.PrimaryTeacher
        let AssistantTeachers = res.data.Data.AssistantTeachers
        AssistantTeachers.push(primaryTeacher)
        let techers = AssistantTeachers
        setListTeacher(techers);
        setListStudent(res.data.Data.Students)
        if(callback) callback()
      } else {
        if (res.data.Message)
          Fc.showError(res.data.Message)
        else
          Fc.showError(res.statusText)
      }
    }).catch(err => {
      Fc.showError("Cant not connect to server")
    })
  }
  //===========================================================================

  /**
  |----------------------------------------------------------------------------
  | - Receive message from socket node js
  |----------------------------------------------------------------------------
  */
  useEffect(() => {
    (messageList && messageEndRef.current && pageIndexRef.current === 1) && scrollToBottom()

    messageTemp.current = messageList
  }, [messageList])
  //===========================================================================

  /**
  |----------------------------------------------------------------------------
  | - Receive message from socket node js
  | - Reconnect socket when focus tab on browser
  |----------------------------------------------------------------------------
  */
  useEffect(() => {
    isActive.current = true
    let timerDelNotif = null
    if(!socket || Object.keys(socket).length == 0) return

    socket.on("receive_message_1", (data) => {
      if(!currentChatTmp.current || !currentChatTmp.current.tabChat ) return 

      if (data.Type === "groupchat") {
        if (currentChatTmp.current && currentChatTmp.current.classId && data.To === currentChatTmp.current.classId && !currentChatTmp.current.fromId) {
          pageIndexRef.current = 1
          messageTemp.current.push(data)
          setMessageList([...messageTemp.current]);

          //xoa notify khi dang trong class group chat
          timerDelNotif = setTimeout(() => {
            delNotification(data.From, data.To, userLoginIdRef.current)
          }, 1000)
        }
      } else {
        if(!currentChatTmp.current.fromId) return

        //chan hien thi tin nhan ko dung tab, ko dung ng dung
        if(!currentChatTmp.current || (currentChatTmp.current.tabChat === 'person' && currentChatTmp.current.fromId !== data.From)) {
          return
        }
        if(data.To !== Fc.getUserId()) return

        if (data.From === currentChatTmp.current.fromId) {
          pageIndexRef.current = 1
          setMessageList([...messageTemp.current, data]);

          //xoa notify khi dang chat voi user
          timerDelNotif = setTimeout(() => {
            delNotification(data.From, data.To, userLoginIdRef.current)
          }, 1000)
        }
      }
    })

    window.addEventListener('focus', () => {
      // ================reconnect ==============
      if (!socket.connected) {
        socket = io(UrlConst.CONNECTION_PORT, {transports: [ "websocket" ]}).connect();
        //=============JOIN ALL ROOM==============
        if (Fc.getRole() === Const.Role.OPERATOR) {
          socket.emit('join_all_room', lsRoomTmp.current)
          socket.emit('join_room', Fc.getUserId())
        }

        if (Fc.getRole() === Const.Role.VENDOR) {
          socket.emit('join_all_room', lsRoomTmp.current)
        }
      }

      //============ Get Notifi in DB=============
      if(Fc.getRole() === Const.Role.OPERATOR && isActive.current) getListNotif()
    })
    return () => {
      window.removeEventListener('focus', () => console.log('remove listener focus'))
      isActive.current = false
      if(timerLstMsgGr) clearTimeout(timerLstMsgGr)
      if(timerLstMsgPsn) clearTimeout(timerLstMsgPsn)
      if(timerDelNotif) clearTimeout(timerDelNotif)
    }
  }, [socket])
  //==========================================================================


  /**
  |---------------------------------------------------------------------------
  | Get List Notification 
  |---------------------------------------------------------------------------
  */
  function getListNotif(from = null, fromType = Const.Role.OPERATOR) {
    const userId = (Fc.getRole() === Const.Role.VENDOR) ? from : Fc.getUserId()
    const type = (Fc.getRole() === Const.Role.VENDOR) ? fromType : Fc.getRole()

    Fc.getUserId() && API.GetListNotification(userId, type, 1, 0, "").then((res) => {
      if (res.status === 200 && res.data.Success) {
        const listClassChat = filter(res.data.Data, e => (e.Type === "groupchat" && e.ToRole === "CLASS"))
        const notInClassChat  = res.data.Data.filter((_) => !listClassChat.map((_) => _._id).includes(_._id));

        dispatch({ type: Type.SAVE_NOTIFY, payload: notInClassChat })
        dispatch({ type: Type.COUNT_NOTIFY, payload: (listNotifRedux && listNotifRedux.length > 0) && listNotifRedux.length })
      }
    }).catch((err) => {
      // Fc.showError("Can not connect to server")
    })
  }
  //===========================================================================

  /**
  |----------------------------------------------------------------------------
  | Get list message in group
  |----------------------------------------------------------------------------
  */
  function getListMessageInGroup(classID, pageIndex1 = 1, callback = null) {
    dispatch({ type: Type.SAVE_IS_LOADING, payload: true})
    
    API.GetChatMessagesInGroup(classID, '', pageSize, pageIndex1)
      .then((res) => {
        if (res.status === 200 && res.data.Success) {

          const sortData = sortBy(res.data.Data, "CreatedDate")
          if(sortData.length  === 0) dispatch({ type: Type.SAVE_IS_LOADING, payload: true})

          if(sortData.length > 0) {
            sortData.forEach(item => messageTemp.current.push(item))
            timerLstMsgGr = setTimeout(() => {
              dispatch({ type: Type.SAVE_IS_LOADING, payload: false})
            }, 1000)
          } 

          setMessageList([...messageTemp.current]);

          if (callback !== null) callback()
        } else {
          timerLstMsgGr = setTimeout(() => {
            dispatch({ type: Type.SAVE_IS_LOADING, payload: false})
          }, 1000)

          if (res.data.Message)
            Fc.showError(res.data.Message)
          else
            Fc.showError(res.statusText)
        }
      }).catch(err => {
        timerLstMsgGr = setTimeout(() => {
          dispatch({ type: Type.SAVE_IS_LOADING, payload: false})
        }, 1000)
        Fc.showError("Cant not connect to server")
      })
  }
  //============================================================================

  /**
  |----------------------------------------------------------------------------
  | Get list message of person
  |----------------------------------------------------------------------------
  */
  function GetChatMessages(from, to, pageIndex1 = 1, callback = null) {
    dispatch({ type: Type.SAVE_IS_LOADING, payload: true})
  
    API.GetChatMessages(from, to, '', pageSize, pageIndex1)
      .then((res) => {
        if (res.status === 200 && res.data.Success) {
          const sortData = sortBy(res.data.Data, "CreatedDate")
          if(sortData.length  === 0) dispatch({ type: Type.SAVE_IS_LOADING, payload: true})
  
          if(sortData.length > 0) {
            sortData.forEach(item => messageTemp.current.push(item))
            timerLstMsgPsn = setTimeout(() => {
              dispatch({ type: Type.SAVE_IS_LOADING, payload: false})
            }, 1000)
          } 
  
          setMessageList([...messageTemp.current]);
          if(callback) callback()
        } else {
          timerLstMsgPsn = setTimeout(() => {
            dispatch({ type: Type.SAVE_IS_LOADING, payload: false})
          }, 1000)
  
          if (res.data.Message)
            Fc.showError(res.data.Message)
          else
            Fc.showError(res.statusText)
        }
      }).catch(err => {
        timerLstMsgPsn = setTimeout(() => {
          dispatch({ type: Type.SAVE_IS_LOADING, payload: false})
        }, 1000)
        Fc.showError("Cant not connect to server")
      })
    }
  //============================================================================

  function hideMenu() {
    dispatch({ type: Type.SAVE_USER_INFO, payload: {} })
    dispatch({ type: Type.SET_ACTIVE_TAB_MYWORK, activeTab: "Workbook" })
    dispatch({ type: Type.SAVE_CURRENT_CHAPTER, payload: null })
    dispatch({ type: Type.SAVE_CURRENT_SESSION, payload: null })
    dispatch({ type: Type.CHANGE_NOT_RELOAD, payload: false})
    dispatch({ type: Type.SAVE_PAGEINDEX_WS, payload: null })
    dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: 0 })

    document.getElementById("my_offcanvas1").classList.remove("show");
    document
      .getElementsByTagName("body")[0]
      .classList.remove("offcanvas-active");
    document
      .getElementsByClassName("screen-overlay")[0]
      .classList.remove("show");
  }

  function showMenu(){
    document.getElementById("my_offcanvas1").classList.add("show");
    document
      .getElementsByTagName("body")[0]
      .classList.add("offcanvas-active");
    document
      .getElementsByClassName("screen-overlay")[0]
      .classList.add("show");
  }

  /**
  |----------------------------------------------------------------------------
  | Clear all data redux in session
  |----------------------------------------------------------------------------
  */
  function clearDataRedux() {
    dispatch({ type: Type.SAVE_USER_INFO, payload: {} })
    dispatch({ type: Type.SAVE_CLASS_ID, classId: '' })
    dispatch({ type: Type.SELECTWS, payload: [] })
    dispatch({ type: Type.SET_STUDENT_ID, studentId: "" })
    dispatch({ type: Type.SAVE_TOKEN, payload: "" })
    dispatch({ type: Type.SAVE_ROLE, payload: [] })
    dispatch({ type: Type.SAVE_PARAMS_SEARCH, payload: null })
    dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Exercise' })
    dispatch({ type: Type.SET_ACTIVE_TAB_MYWORK, activeTab: "Workbook" })
    dispatch({ type: Type.SAVE_CURRENT_CHAPTER, payload: null })
    dispatch({ type: Type.SAVE_CURRENT_SESSION, payload: null })
    dispatch({ type: Type.SAVE_ASSIGNMENT_DATE, payload: ""})
    dispatch({ type: Type.CHANGE_NOT_RELOAD, payload: false})
    dispatch({ type: Type.PERMISSION_WRITENOTE, payload: false })
    dispatch({ type: Type.PERMISSION_SOLUTION, payload: false })
    dispatch({ type: Type.SAVE_STUDENT_CLASS_LIST, payload: []})
    dispatch({ type: Type.SAVE_CURRENT_CHAT, payload: null });
    dispatch({ type: Type.SAVE_LIST_ROOM, payload: [] });
    dispatch({ type: Type.SAVE_USER_LOGIN, payload: {} });
    dispatch({ type: Type.SAVE_PAGEINDEX_WS, payload: null })
    dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: 0 })
  }
  //===========================================================================

  function logOut(e) {
    // if (socket) socket.disconnect()
    e.preventDefault();
    Fc.setToken("");
    Fc.setRole("");
    Fc.setOperator("");
    Fc.setAccountMethod("")
    clearDataRedux()
    props.history.push("/");
  }

  return (
    <LayoutComponent
      operatorId={operatorId}
      matchUrl={matchUrl}
      hideMenu={hideMenu}
      showMenu={showMenu}
      logOut={logOut}
      operatorInfo={operatorInfo}
      role={role}
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      socket={socket}
      message={message}
      setMessage={setMessage}
      messageList={messageList}
      setMessageList={setMessageList}
      listClass={listClass}
      ClassId={ClassId}
      setClassId={setClassId}
      ListStudent={ListStudent}
      ListTeacher={ListTeacher}
      setSelectedId={setSelectedId}
      selectedId={selectedId}
      room={room}
      tabChat={tabChat}
      setTabChat={setTabChat}
      userType={userType} setUserType={setUserType}
      userName={userName} setUserName={setUserName}
      getListNotif={getListNotif}
      scrollToBottom={scrollToBottom}
      messageEndRef={messageEndRef}
      setUserSendMessagge={setUserSendMessagge}
      getListMessageInGroup={getListMessageInGroup}
      GetChatMessages={GetChatMessages}
      getDetailClass={getDetailClass}
      setPageIndex={setPageIndex}
      onScrollTop={onScrollTop}
      pageIndex={pageIndex}
      {...props} />
  );
}

export default React.memo(_Layout);
