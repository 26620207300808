import React from 'react';
import { Link } from 'react-router-dom'
import * as Const from '../../../constants/Const'

import useCheckdevice from '../../../hooks/useCheckdevice'
import classNames from 'classnames'
import { isDesktop, isMobile } from "react-device-detect"

function Document(props) {
    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))

    const {
        item, matchUrl,
        openDeleteModal, firstDocumentId,
        accountMethod,
        setShowAddAlertModal, setAlertMessage,
        banner
    } = props
    
    let url = ''
    let handleClick = null
    let errorMessage = 'This features is locked. Upgrade now to use all Epen App features.'
    if (firstDocumentId === item._id || accountMethod !== Const.AccountMethod.RESTRICTED) {
        url = matchUrl.url.substring(0, matchUrl.url.length - 7) + `/document/${item._id}`
        handleClick = null
    } else {
        url = '#'
        handleClick = () => {
            setAlertMessage(errorMessage)
            setShowAddAlertModal(true)
        }
    }
    
    return (
        <>
            <Link to={url} className="position-relative box-img mh-150" onClick={handleClick}>

                <img className="img-fluid bot-0" src={item.Cover} alt="" />
                <span style={{ background: 'rgba(0, 0, 0, 0.5)'}} className="box-sp position-absolute">
                    <strong style={{ width: "90%", margin: "auto" }} className="align-middle-center">{item.Title.length > 15 ? (item.Title.substring(0, 15) + "...") : item.Title}</strong>
                </span>
            </Link><span className="close" onClick={() => openDeleteModal(item._id)}>X</span>
        </>
    );
}

export default Document;