import React from 'react';
import './style.css';
//new
function EditToolbarView(props) {
    const { 
        menuPosition,
        toolId,
        isShowPenMenu,
        isShowTrash,
        onClickPenColor,
        setPenLineWidth,
        penLineWidth,
        selectTool,
        penColor,
        isShowHightLighterMenu,
        onClickHightLighterColor,
        highlighterLineWidth,
        highlighterColor,
        isRestricted,
        onShowFormulaModal,
        onShowGraphingCalculator,
        onShowCalculator,
        isShowTypeMenu,
        typeFontFamily,
        setTypeFontFamily,
        listFontFamily,
        setTypeFontSize,
        listFontSize,
        typeBold,
        setTypeBold,
        typeItalic,
        setTypeItalic,
        typeUnderline,
        setTypeUnderline,
        isShowShapesMenu,
        undo,
        redo,
        onClickMenuPosition,
        setHighlighterLineWidth,
        typeFontSize,
        isNote,
        addNotePage,
        onChangeBackgroundSketchpad,
        clearAll,
        onClickShape,
        showMathEditor,
        isBrowserDesktop
    } = props
    return (
        <div className={menuPosition === "left" ? "right-col1" : "right-col2"}>
            <div className="inner text-center">
                <div onClick={() => selectTool('null')}
                    className={`icon pointer  tran-80 ${toolId === 'null' ? "active" : ""}`} data-toggle="tooltip" data-placement="right" title="Pointer">
                    <i className="fas fa-mouse-pointer"></i>
                </div>
                <div className={`topnav tran-80 ${menuPosition === "left" ? "left-tool-option" : "right-tool-option"}`}
                    style={{ display: isShowPenMenu ? "block" : "none" }}>
                    <a><i onClick={onClickPenColor} className="fas fa-circle fa-2x pointer" style={{ color: 'rgb(0, 0, 180)' }}></i></a>
                    <a><i onClick={onClickPenColor} className="fas fa-circle fa-2x pointer" style={{ color: 'rgb(255, 0, 0)' }}></i></a>
                    <a><i onClick={onClickPenColor} className="fas fa-circle fa-2x pointer" style={{ color: 'rgb(0, 0, 0)' }}></i></a>
                    <a onClick={() => setPenLineWidth(4)}><i className={`line1 pointer ${penLineWidth === 4 ? "active1" : ""}`}></i></a>
                    <a onClick={() => setPenLineWidth(3)}><i className={`line2 pointer ${penLineWidth === 3 ? "active1" : ""}`}></i></a>
                    <a onClick={() => setPenLineWidth(2)}><i className={`line3 pointer ${penLineWidth === 2 ? "active1" : ""}`}></i></a>
                    <a onClick={() => setPenLineWidth(1)}><i className={`line4 pointer ${penLineWidth === 1 ? "active1" : ""}`}></i></a>
                </div>
                <div onClick={() => selectTool('pen')} className={`icon pointer tran-80 position-relative ${toolId === 'pen' ? "active" : ""}`} data-toggle="tooltip" data-placement="right" title="Pen">
                    <i className="fas fa-pen"></i>
                    <span className="cham-tron" style={{
                        background: `rgba(${penColor[0]},${penColor[1]},${penColor[2]}, 1)`,
                        border: "solid 1px #5f5f5f"
                    }}></span>
                </div>

                <div className={`pointer tran-80 ${menuPosition === "left" ? "left-tool-option" : "right-tool-option"}`}
                    style={{ display: isShowTrash ? "block" : "none", width: 100, height: 38, lineHeight: 2.5}}
                    onClick={clearAll}
                >
                    Clear All
                </div>
                <div onClick={() => selectTool('eraser')} className={`icon pointer tran-80 ${toolId === 'eraser' ? "active" : ""}`}
                    data-toggle="tooltip" data-placement="right" title="Eraser"
                >
                    <i className="fas fa-eraser"></i>
                </div>

                <div className={`topnav tran-80 ${menuPosition === "left" ? "left-tool-option" : "right-tool-option"}`}
                    style={{ display: isShowHightLighterMenu ? "block" : "none" }}>
                    <a><i onClick={onClickHightLighterColor} className="fas fa-circle fa-2x pointer" style={{ color: 'rgba(255,255,0,1)' }}></i></a>
                    <a><i onClick={onClickHightLighterColor} className="fas fa-circle fa-2x pointer" style={{ color: 'rgba(80,255,0,1)' }}></i></a>
                    <a><i onClick={onClickHightLighterColor} className="fas fa-circle fa-2x pointer" style={{ color: 'rgba(0,255,255,1)' }}></i></a>
                    <a onClick={() => setHighlighterLineWidth(4)}><i className={`line1 pointer ${highlighterLineWidth === 4 ? "active1" : ""}`}></i></a>
                    <a onClick={() => setHighlighterLineWidth(3)}><i className={`line2 pointer ${highlighterLineWidth === 3 ? "active1" : ""}`}></i></a>
                    <a onClick={() => setHighlighterLineWidth(2)}><i className={`line3 pointer ${highlighterLineWidth === 2 ? "active1" : ""}`}></i></a>
                    <a onClick={() => setHighlighterLineWidth(1)}><i className={`line4 pointer ${highlighterLineWidth === 1 ? "active1" : ""}`}></i></a>
                </div>
                <div onClick={() => selectTool('highlighter')} className={`icon pointer tran-80 position-relative ${toolId === 'highlighter' ? "active" : ""}`}
                    data-toggle="tooltip" data-placement="right" title="Highlight"
                >
                    <i className="fas fa-highlighter"></i>
                    <span className="cham-tron" style={{
                        background: `rgba(${highlighterColor[0]},${highlighterColor[1]},${highlighterColor[2]}, 1)`,
                        border: "solid 1px #5f5f5f"
                    }}></span>
                </div>
                <div className={`icon pointer ${isRestricted ? "bg02" : "tran-80"}`} onClick={onShowFormulaModal}
                    data-toggle="tooltip" data-placement="right" title="Formulas"
                >
                    <i className="fas fa-superscript"></i>
                </div>
                {/* <div className={`icon ${isRestricted ? "bg02" : "tran-80"}`}>
                    <i className="fas fa-vector-square fa-2x"></i>
                </div> */}
                <div className={`icon pointer ${isRestricted ? "bg02" : "tran-80"}`} onClick={onShowGraphingCalculator} 
                    data-toggle="tooltip" data-placement="right" title="Graphing Calculator"
                >
                    <i className="fas fa-chart-line"></i>
                </div>
                <div className={`icon pointer ${isRestricted ? "bg02" : "tran-80"}`} onClick={onShowCalculator}
                    data-toggle="tooltip" data-placement="right" title="Calculator"
                >
                    <i className="fas fa-calculator"></i>
                </div>
                <div onClick={() => selectTool('type')} className={`icon pointer ${isRestricted ? "bg02" : "tran-80"} text-center lin3 ${toolId === "type" ? "active-text" : ""}`}
                    data-toggle="tooltip" data-placement="right" title="TextBox"
                >
                    <span className={isRestricted ? "op02" : ""}></span>
                </div>
                <div className={`topnav pointer tran-80 edit-text ${menuPosition === "left" ? "left-tool-option" : "right-tool-option"}`}
                    style={{
                        width: 395,
                        display: isShowTypeMenu ? 'block' : 'none'
                    }}>
                    <div className="form-inline">
                        <select name="cars" className="custom-select pointer custom-select15 radius100 mr-sm-2 custom-select-sm"
                            value={typeFontFamily} onChange={(e) => { setTypeFontFamily(e.target.value) }}>
                            {listFontFamily.map((item, index) => {
                                return (
                                    <option value={item.font} key={index.toString()}>{item.text}</option>
                                )
                            })}
                        </select>
                        <select value={typeFontSize} onChange={(e) => setTypeFontSize(e.target.value)} className="custom-select pointer custom-select15 radius100 mr-sm-2 custom-select-sm">
                            {listFontSize.map((item, index) => {
                                return (
                                    <option value={item} key={index.toString()}>{item}</option>
                                )
                            })}
                        </select>
                        <button className={"btn text-white" + (typeBold ? " active" : "")} type="button" onClick={() => setTypeBold(!typeBold)}>
                            <i className="fas fa-bold"></i>
                        </button>
                        <button className={"btn text-white" + (typeItalic ? " active" : "")} type="button" onClick={() => setTypeItalic(!typeItalic)}>
                            <i className="fas fa-italic"></i>
                        </button>
                        <button className={"btn text-white" + (typeUnderline ? " active" : "")} type="button" onClick={() => setTypeUnderline(!typeUnderline)}>
                            {/* <i className="fas fa-square-root-alt"></i> */}
                            <i className="fas fa-underline"></i>
                        </button>
                    </div>
                </div>
                { 
                    isBrowserDesktop &&
                    <div onClick={showMathEditor} className={`icon pointer ${isRestricted ? "bg02" : "tran-80"}`}
                        data-toggle="tooltip" data-placement="right" title="Equation"
                    >
                        <i className="fas fa-square-root-alt"></i>
                    </div>
                }
                <div className={`topnav tran-80 ${menuPosition === "left" ? "left-tool-option" : "right-tool-option"}`}
                    style={{ display: isShowShapesMenu ? "block" : "none" }}>
                    <a className={`line5 pointer ${toolId === "line" ? "active-line" : ""}`}
                        onClick={() => selectTool("line")}><span></span></a>
                    <a className={toolId === "rectangle" ? "active" : "" + "pointer"} onClick={() => selectTool("rectangle")}>
                        <i className={`fas fa-square-full fa-2x`}></i>
                    </a>
                    <a className={toolId === "circle" ? "active" : "" + "pointer"} onClick={() => selectTool("circle")}>
                        <i className={`fas fa-circle fa-2x`}></i>
                    </a>
                </div>
                <div className={`icon pointer ${isRestricted ? "bg02" : "tran-80"} ${['rectangle', 'line', 'circle'].includes(toolId) ? "active" : ""}`}
                    onClick={() => {
                        onClickShape()
                        //cclick toolid shape only triggle menu
                        if (['rectangle', 'line', 'circle'].includes(toolId))
                            selectTool('shape')
                        else
                            selectTool('line')
                    }}
                    data-toggle="tooltip" data-placement="right" title="Shape"
                >
                    <i className="fas fa-shapes"></i>
                </div>
                <div onClick={undo} className={`icon pointer ${isRestricted ? "bg02" : "tran-80"}`}
                    data-toggle="tooltip" data-placement="right" title="Undo"
                >
                    <i className="fas fa-undo-alt"></i>
                </div>
                <div onClick={redo} className={`icon pointer ${isRestricted ? "bg02" : "tran-80"}`}
                    data-toggle="tooltip" data-placement="right" title="Redo"
                >
                    <i className="fas fa-redo-alt"></i>
                </div>
                { 
                    isNote &&
                    <>
                    <div onClick={addNotePage} className={`icon pointer ${isRestricted ? "bg02" : "tran-80"}`}
                        data-toggle="tooltip" data-placement="right" title="New page"
                    >
                        <i className="fas fa-file"></i>
                    </div>
                    <div onClick={onChangeBackgroundSketchpad} className={`icon pointer ${isRestricted ? "bg02" : "tran-80"}`}
                        data-toggle="tooltip" data-placement="right" title="Style"
                    >
                        <i className="fas fa-th"></i>
                    </div>
                    </>
                }
                {
                    menuPosition === "left"
                    ?   <div className={`icon pointer ${isRestricted ? "bg02" : "tran-80"}`} onClick={onClickMenuPosition}
                            data-toggle="tooltip" data-placement="right" title={'Right Bar'}
                        >
                            <i className={`fas fa-play ${menuPosition === "left" ? "text-epen" : "text-epen-right"}`}></i>
                        </div>
                    :   <div className={`icon pointer ${isRestricted ? "bg02" : "tran-80"}`} onClick={onClickMenuPosition}
                            data-toggle="tooltip" data-placement="left" title={"Left Bar"}
                        >
                            <i className={`fas fa-play ${menuPosition === "left" ? "text-epen" : "text-epen-right"}`}></i>
                        </div>
                }
                
            </div>
        </div>
    );
}

export default React.memo(EditToolbarView);
