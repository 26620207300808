import React from 'react';
import { Role } from '../../constants/Const'
import moment from 'moment';
import './style.css'

function ClassTab(props) {
    const {
        messageList
    } = props


    return (
        <>
        {messageList && messageList.length > 0 && messageList.map((item, index) => {
            if(item.data) {
                return (
                    <div style={{width: '100%'}} key={index.toString()} className="item_chat">
                        <p style={{textAlign: 'center'}}><span className="date-color">
                            {moment(new moment(item.date, 'DD/MM/YYYY')).format('LL')}
                        </span></p>
                        {
                            item.data.map((e, i) => {
                                if (e.From === Role.VENDOR || e.From.Role === Role.VENDOR) {
                                    return (
                                        <div key={i.toString()} className="text-justify" style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <p key={i.toString()} style={{display: 'inline-block'}} className="font-Paytone vendor-text">{e.FromRole || e.From.Role} - {e.Body}</p>
                                            <p style={{display: 'inline-block'}}><span className="time-text">{e.Time}</span></p>
                                        </div>
                                    )
                                }
                                else if (e.FromRole === Role.OPERATOR || e.From.Role === Role.OPERATOR) {
                                    return (
                                        <div key={i.toString()} className="text-justify" style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <p style={{display: 'inline-block'}}><span className="operator-text"><strong>{typeof e.From === "string" ? e.FromName : e.From.Name}</strong></span>: {e.Body}</p>
                                            <p style={{display: 'inline-block'}}><span className="time-text">{e.Time}</span></p>
                                        </div>
                                    )
                                }
                                return(
                                    <div key={i.toString()} className="text-justify" style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <p style={{display: 'inline-block'}}><span className="text-info"><strong>{typeof e.From === "string" ? e.FromName : e.From.Name}:</strong> </span>{e.Body}</p>
                                        <p style={{display: 'inline-block'}}><span className="time-text">{e.Time}</span></p>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        })}
        </>
    );
}

export default ClassTab;