/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import BannerLayout from './BannerLayout'
import API from '../../../utils/Api'
import * as Fc from '../../../utils/Functions'
import * as Const from '../../../constants/Const'

function Banner(props) {
    const role = Fc.getRole()
    const operatorId = role === Const.Role.VENDOR ? props.match.params.id : Fc.getOperator()
    const [file, setFile] = useState(null)
    const [fileTitle, setFileTitle] = useState("")
    const [fileData, setFileData] = useState({})
    const [hideTabToZoom, setShowTabToZoom] = useState(true)
    const [showZoomModal, setShowZoomModal] = useState(false)
    const [imageRatio, setImageRatio] = useState(1.5);

    useEffect(() => {
        let staticBanner = new Image()
        staticBanner.onload = function () {
            setImageRatio(this.width / this.height)
        }
        staticBanner.src = window.location.origin + '/page-big.jpg'
    }, [])

    useEffect(() => {
        getBanner()
        const interval = setInterval(() => {
            setShowTabToZoom(false)
        }, 3000);
        return () => clearInterval(interval);

    }, [])

    function hideZoomModal() {
        setShowZoomModal(false)
    }

    function getBanner() {
        API.getBannerByOperator(operatorId)
            .then((res) => {
                if (res.status === 200 && res.data.Success) {
                    setFile(res.data.Data);
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            })
            .catch(err => {
                Fc.showError("Can not connect to server")
            })
    }

    function handleChangeBanner(event) {
        setFile(URL.createObjectURL(event.target.files[0]));
        setFileTitle(event.target.files[0].name)
        setFileData(event.target.files[0])
    }

    function upload() {
        var formData = new FormData();
        formData.append("file", fileData);
        if (role === "VENDOR" || Fc.getAccountMethod() === Const.AccountMethod.PAID) {
            API.uploadBanner(operatorId, formData)
                .then((res) => {
                    if (res.status === 200 && res.data.Success) {
                        Fc.showSuccess("Upload success")
                        setFileData({})
                        setFileTitle('')
                    } else {
                        Fc.showError(res.data.Message || res.statusText)
                    }
                })
                .catch((err) => {
                    Fc.showError("Can not connect to server")
                })
        } else {
            Fc.showError('This feature is locked. Upgrade now to use all Epen App Feature.')
        }

    }

    return (
        <BannerLayout
            file={file} setFile={setFile}
            handleChangeBanner={handleChangeBanner}
            upload={upload} fileTitle={fileTitle}
            showZoomModal={showZoomModal} hideZoomModal={hideZoomModal}
            setShowZoomModal={setShowZoomModal}
            hideTabToZoom={hideTabToZoom}
            imageRatio={imageRatio}
        />
    );
}

export default Banner;