import React from 'react';
import "./style.css"

function HeaderBack(props) {
    const { goBack, userName } = props
    return (
        <section    className="container-fluid container-header-back-desktop"   >
            <div className="row align-items-center wrap-header-back-desktop">
                <div className="col-4 mr-auto ml-4 text-left">
                    {   userName ? userName : "UserID/Email"    }
                </div>
                <div className="col-auto pr-0 pointer" onClick={goBack}>
                    <div className="triangle-left float-left"></div>
                    <span className="bg-trang-10 font-Paytone bt-back" >Back</span>
                </div>
            </div>
        </section>
    );
}

export default HeaderBack;