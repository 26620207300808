import React from 'react';
import { Link } from "react-router-dom"

function ConfirmSendMail(props) {
    return (
        <>
            <div className="bg-xanh"></div>
            <nav className="header fixed-top p-3 z-5">
                <div className="row align-items-center">
                    <div className="col-auto"><Link className="" to="/"><i className="fas fa-arrow-left fa-3x"></i></Link></div>
                </div>
            </nav>
            <main style={{ paddingTop: 0 }}>
                <div id="login">
                    <div id="login-column" className="modal-dialog modal-dialog-centered modal-dialog-login">
                        <div id="login-box" className="col-md-12">
                            <h4 className="text-center mb-5"><i className="fas fa-check-circle mr-2 text-success"></i>Please Check your Email inbox</h4>
                        </div>
                    </div>
                </div>

            </main>
        </>
    );
}

export default ConfirmSendMail;