import React, { useState, useEffect, useRef } from 'react'
import API from '../../../utils/Api'
import * as Fc from '../../../utils/Functions'
import * as Const from '../../../constants/Const'
import { useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import _ from 'lodash'
import * as type from "../../../constants/actionType"
import * as $ from 'jquery'

import NoteView from './NoteView'
import useIsMounted from '../../../hooks/useIsMounted'
import useDidMount from '../../../hooks/useDidMount'

export default function Note(props) {
    const dispatch = useDispatch();
    let classIdRedux = useSelector(state => state.ws.classId)
    let selectFolderRx = useSelector(state => state.ws.selectedFolder)
    let studentIdRx = useSelector(state => state.ws.studentId)
    let activeTabRx = useSelector(state => state.ws.activeTab)
    let isNotReloadRx = useSelector(state => state.ws.isNotReload)

    const role = Fc.getRole()
    const accountMethod = Fc.getAccountMethod()
    const matchUrl = useRouteMatch()
    const operatorId = role === Const.Role.VENDOR ? matchUrl.params.id : Fc.getOperator()
    const studentId = props.studentId

    const [folderName, setFolderName] = useState("")
    const [isShowFolderModal, setIsShowFolderModal] = useState(false)
    const [keyword, setKeyword] = useState("")
    const [listFolders, setListFolders] = useState([])
    const [noteName, setNoteName] = useState("")
    const [isShowNoteModal, setIsShowNoteModal] = useState(false)
    const [selectedFolder, setSelectedFolder] = useState(selectFolderRx || {})

    const [listNotes, setListNotes] = useState([])
    const [selectedNote, setSelectedNote] = useState("")
    const [isShowDeleteFolderModal, setIsShowDeleteFolderModal] = useState(false)
    const [isShowDeleteNotesModal, setIsShowDeleteNotesModal] = useState(false)
    const [isShowDeleteAllFolderModal, setIsShowDeleteAllFolderModal] = useState(false)
    const [isShowMoveNoteModal, setIsShowMoveNoteModal] = useState(false)
    const [isShowArrowBtnFolder, setIsShowArrowBtnFolder] = useState(false)

    //list all folders for dropdown
    const [allFolders, setAllFolders] = useState([])
    const [targetFolder, setTargetFolder] = useState("")
    let keywordRef = useRef("");
    let isMounted = useIsMounted()
    let didMount = useDidMount()

    useEffect(() => {
        //check isNotReloadRx to not reset selected folder
        if(!isNotReloadRx) {
            dispatch({ type: type.SET_SELECTED_FOLDER, selectedFolder: {} })
        }
        if(activeTabRx !== "Note") return
        setKeyword('')
        getFolders();
        getAllFolders();
        getNotes();
    }, [classIdRedux, studentIdRx, activeTabRx])
    
    useEffect(() => {
        if(!isMounted.current || props.activeTab !== "Note" || !didMount) return
        setKeyword("")
        setListNotes([])
        getNotes()
    }, [selectedFolder])

    useEffect(() => {
        if(listFolders && listFolders.length){
            try{
                const folderScrollWth = $('#scroll-folder').width();
                const firstFolderOffsetLeft = $(".el-folder").first() && $(".el-folder").last().offset()
                    ?   Math.abs($(".el-folder").first().offset().left)
                    :   0
                const lastFolderOffsetLeft = $(".el-folder").last() 
                    && $(".el-folder").last().offset() && $(".el-folder").last().offset().left 
                    ?   $(".el-folder").last().offset().left 
                    :   0;
                const lastFolderWth = $(".el-folder").last().width();
                if((lastFolderOffsetLeft + lastFolderWth + firstFolderOffsetLeft) > folderScrollWth) {
                    setIsShowArrowBtnFolder(true)
                } else {
                    setIsShowArrowBtnFolder(false)
                }
                return 
            } catch(err) {
                console.log("ERROR: ", err)
            }
            
        }
        setIsShowArrowBtnFolder(false)
    }, [listFolders])

    useEffect(() => {
        if (selectFolderRx) {
            setSelectedFolder(selectFolderRx)
        }
        return () => {}
    }, [selectFolderRx])

    //trigger after keyword change 500ms
    useEffect(() => {
        keywordRef.current = keyword;
        let timerStr = setTimeout(() => {
            search(keywordRef.current)
        }, 500)
        return () => clearTimeout(timerStr) 
    }, [keyword])

    function createFolder() {
        if(!isMounted.current) return
        if (!folderName) {
            Fc.showError("Folder's name is required")
            return
        }

        API.createFolder(studentId, classIdRedux, folderName).then(res => {
            if(!isMounted.current) return 
            if (res.status && res.data.Success && isMounted.current) {
                getAllFolders()
                setKeyword('')
                getFolders()
                hideFolderModal()
                Fc.showSuccess("Create folder success")
            } else {
                Fc.showError(res.data.Message || res.statusText)
            }
        }).catch((err) => {
            console.log(err);
            Fc.showError("Can not connect to server")
        })
    }

    function showFolderModal() {
        if (selectedFolder._id) {
            setFolderName(selectedFolder.Name)
        }
        setIsShowFolderModal(true)
    }

    function hideFolderModal() {
        setIsShowFolderModal(false)
        setFolderName("")
    }

    function getFolders(kw) {
        if (studentId && classIdRedux)
            API.searchFolder(kw || '', classIdRedux, studentId).then(res => {
                if(!isMounted.current) return 
                if (res.status && res.data.Success && isMounted.current) {
                    setListFolders(res.data.Data)
                } 
            }).catch((err) => {
                console.log(err);
                Fc.showError("Can not connect to server")
            })
    }

    function getAllFolders() {
        if(!isMounted.current) return
        if (studentId && classIdRedux)
            API.searchFolder('', classIdRedux, studentId).then(res => {
                if(!isMounted.current) return 
                if (res.status && res.data.Success && isMounted.current) {
                    setAllFolders(res.data.Data)
                    if (res.data.Data && res.data.Data.length <= 0) setSelectedFolder({})
                    if (res.data.Data.filter(e => (e._id === selectFolderRx._id)).length === 0) {
                        setSelectedFolder({})
                    }
                } else {
                    console.log(res);
                    Fc.showError(res.data.Message || res.statusText)
                }
            }).catch((err) => {
                console.log(err);
                Fc.showError("Can not connect to server")
            })
    }

    function createNote() {
        if(!isMounted.current) return
        if (!noteName) {
            Fc.showError("Note's name is required")
            return
        }
        API.saveNote(studentId, classIdRedux, noteName, selectedFolder._id || "", "", "NormalNote", "").then(res => {
            if(!isMounted.current) return 
            if (res.status && res.data.Success && isMounted.current) {
                setKeyword('')
                getNotes()
                hideNoteModel()
                Fc.showSuccess("Create note success")
            } else {
                console.log(res);
                Fc.showError(res.data.Message || res.statusText)
            }
        }).catch((err) => {
            console.log(err);
            Fc.showError("Can not connect to server")
        })
    }

    function showCreateNoteModel() {
        setIsShowNoteModal(true)
    }

    function getNotes(kw) {
        // console.log('Get note: ', classIdRedux, studentId , selectedFolder )
        if(!isMounted.current) return
        if (studentId && classIdRedux)
            API.searchNote(kw || '', classIdRedux, studentId, selectedFolder._id || "").then(res => {
                if(!isMounted.current) return 
                // console.log('res: ', res.data.Data)
                if (res.status && res.data.Success && isMounted.current) {
                    setListNotes(res.data.Data)
                } else {
                    console.log(res);
                    // Fc.showError(res.data.Message || res.statusText)
                }
            }).catch((err) => {
                console.log(err);
                Fc.showError("Can not connect to server")
            })
    }

    function search(keyword = '') {
        getFolders(keyword)
        getNotes(keyword)
    }

    function clickNote(id, checked) {
        let notes = _.cloneDeep(listNotes)
        let note = notes.filter(x => x._id === id)[0]
        note.Checked = checked
        setListNotes(notes)
    }

    function clickFolder(id, checked) {
        let folders = _.cloneDeep(listFolders)
        let folder = folders.filter(x => x._id === id)[0]
        folder.Checked = checked
        setListFolders(folders)
    }

    function selectAllNotes() {
        let notes = _.cloneDeep(listNotes)
        notes.map(item => {
            item.Checked = true
        })
        setListNotes(notes)
    }

    function unSelectAllNotes() {
        let notes = _.cloneDeep(listNotes)
        notes.map(item => {
            item.Checked = false
        })
        setListNotes(notes)
    }

    function selectAllFolder() {
        let folder = _.cloneDeep(listFolders)
        folder.map(item => {
            item.Checked = true
        })
        setListFolders(folder)
    }

    function unSelectAllFolder() {
        let folder = _.cloneDeep(listFolders)
        folder.map(item => {
            item.Checked = false
        })
        setListFolders(folder)
    }

    function showRenameNoteModel(note) {
        setSelectedNote(note._id)
        setNoteName(note.Name)
        setIsShowNoteModal(true)
    }

    function hideNoteModel() {
        setSelectedNote()
        setIsShowNoteModal(false)
        setNoteName("")
    }

    function renameNote() {
        if(!isMounted.current) return
        if (!noteName) {
            Fc.showError("Note's name is required")
            return
        }
        API.saveNote(studentId, classIdRedux, noteName.trim(), selectedFolder._id || "", "", "NormalNote", selectedNote).then(res => {
            if(!isMounted.current) return 
            if (res.status && res.data.Success && isMounted.current) {
                setKeyword('')

                getNotes()
                hideNoteModel()
                Fc.showSuccess("Rename note success")
            } else {
                console.log(res);
                Fc.showError(res.data.Message || res.statusText)
            }
        }).catch((err) => {
            console.log(err);
            Fc.showError("Can not connect to server")
        })
    }

    function showRenameFolderModel() {
        setFolderName(selectedFolder.Name.trim())
        setIsShowFolderModal(true)
    }

    function renameFolder() {
        if(!isMounted.current) return
        if (!folderName) {
            Fc.showError("Folder's name is required")
            return
        }
        API.renameFolder(selectedFolder._id, folderName).then(res => {
            if(!isMounted.current) return 
            if (res.status && res.data.Success && isMounted.current) {
                setKeyword('')
                getFolders()
                hideFolderModal()
                setSelectedFolder({ ...selectedFolder, Name: folderName })
                getAllFolders()
                Fc.showSuccess("Rename folder success")
            } else {
                console.log(res);
                Fc.showError(res.data.Message || res.statusText)
            }
        }).catch((err) => {
            console.log(err);
            Fc.showError("Can not connect to server")
        })
    }

    function deleteFolder() {
        if(!isMounted.current) return
        API.deleteFolder(selectedFolder._id).then(res => {
            if(!isMounted.current) return 
            if (res.status && res.data.Success && isMounted.current) {
                getFolders(keyword)
                setSelectedFolder({})
                setIsShowDeleteFolderModal(false)
                getAllFolders()
                Fc.showSuccess("Delete folder success")
            } else {
                console.log(res);
                Fc.showError(res.data.Message || res.statusText)
            }
        }).catch((err) => {
            console.log(err);
            Fc.showError("Can not connect to server")
        })
    }

    function deleteNote() {
        if(!isMounted.current) return
        let deleteNoteIds = listNotes.filter(x => x.Checked).map(i => i._id)
        if (deleteNoteIds.length === 0)
            return
        API.deleteNote(deleteNoteIds.join(',')).then(res => {
            if(!isMounted.current) return 
            if (res.status && res.data.Success && isMounted.current) {
                getNotes(keyword)
                getAllFolders() //===
                setIsShowDeleteNotesModal(false)
                Fc.showSuccess("Delete notes success")
            } else {
                console.log(res);
                Fc.showError(res.data.Message || res.statusText)
            }
        }).catch((err) => {
            console.log(err);
            Fc.showError("Can not connect to server")
        })
    }

    function deleteAllFolder() {
        if(!isMounted.current) return
        let deleteFolderIds = listFolders.filter(x => x.Checked).map(i => i._id)
        if (deleteFolderIds.length === 0)
            return

        API.deleteFolder(deleteFolderIds.join(',')).then(res => {
            if(!isMounted.current) return 
            if (res.status && res.data.Success && isMounted.current) {
                getFolders(keyword)
                setSelectedFolder({})
                setIsShowDeleteAllFolderModal(false)
                getAllFolders()
                Fc.showSuccess("Delete folder success")
            } else {
                console.log(res);
                Fc.showError(res.data.Message || res.statusText)
            }
        }).catch((err) => {
            console.log(err);
            Fc.showError("Can not connect to server")
        })

    }

    function hideMoveNoteModal() {
        setIsShowMoveNoteModal(false)
        setTargetFolder('')
    }

    function moveNote() {
        if(!isMounted.current) return
        let noteIds = listNotes.filter(x => x.Checked).map(i => i._id)
        if (noteIds.length === 0)
            return

        let moveToFolder = targetFolder
        if(!targetFolder && selectFolderRx && Object.keys(selectFolderRx).length === 0 && allFolders && allFolders.length > 0){
            moveToFolder = allFolders[0]._id
        }

        API.moveNote(noteIds, moveToFolder).then(res => {
            if(!isMounted.current) return 
            if (res.status && res.data.Success && isMounted.current) {
                // console.log(res.data.Data);
                // setSelectFolderWhenMoveNote(targetFolder)
                // getNotes(keyword)
                setTargetFolder("")
                getNotes(keyword)
                setIsShowMoveNoteModal(false)
                Fc.showSuccess("Move notes success")
            } else {
                console.log(res);
                Fc.showError(res.data.Message || res.statusText)
            }
        }).catch((err) => {
            console.log(err);
            Fc.showError("Can not connect to server")
        })
    }

    function onClickFolderLeft() {
        let leftPos = $('#scroll-folder').scrollLeft();
        $("#scroll-folder").animate({scrollLeft: leftPos - 300}, 800);
    }

    function onClickFolderRight() {
        let leftPos = $('#scroll-folder').scrollLeft();
        $("#scroll-folder").animate({scrollLeft: leftPos + 300}, 800);
    }

    return (
        <NoteView {...props}
            folderName={folderName} setFolderName={setFolderName}
            createFolder={createFolder}
            isShowFolderModal={isShowFolderModal}
            showFolderModal={showFolderModal} hideFolderModal={hideFolderModal} showRenameFolderModel={showRenameFolderModel}
            keyword={keyword} setKeyword={setKeyword}
            listFolders={listFolders}
            noteName={noteName} setNoteName={setNoteName}
            selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder}
            isShowNoteModal={isShowNoteModal}
            showCreateNoteModel={showCreateNoteModel} hideNoteModel={hideNoteModel} showRenameNoteModel={showRenameNoteModel}
            createNote={createNote}
            listNotes={listNotes}
            search={search}
            getFolders={getFolders}
            clickNote={clickNote}
            selectAllNotes={selectAllNotes}
            unSelectAllNotes={unSelectAllNotes}
            selectedNote={selectedNote}
            renameNote={renameNote}
            renameFolder={renameFolder}
            deleteFolder={deleteFolder}
            deleteNote={deleteNote}
            isShowDeleteFolderModal={isShowDeleteFolderModal} setIsShowDeleteFolderModal={setIsShowDeleteFolderModal}
            isShowDeleteNotesModal={isShowDeleteNotesModal} setIsShowDeleteNotesModal={setIsShowDeleteNotesModal}
            isShowMoveNoteModal={isShowMoveNoteModal} setIsShowMoveNoteModal={setIsShowMoveNoteModal} hideMoveNoteModal={hideMoveNoteModal}
            allFolders={allFolders}
            targetFolder={targetFolder} setTargetFolder={setTargetFolder}
            moveNote={moveNote}
            selectAllFolder={selectAllFolder}
            unSelectAllFolder={unSelectAllFolder}
            clickFolder={clickFolder}
            isShowDeleteAllFolderModal={isShowDeleteAllFolderModal} setIsShowDeleteAllFolderModal={setIsShowDeleteAllFolderModal}
            deleteAllFolder={deleteAllFolder}
            isShowArrowBtnFolder={isShowArrowBtnFolder}
            onClickFolderRight={onClickFolderRight}
            onClickFolderLeft={onClickFolderLeft}
        />
    )
}

