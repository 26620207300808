import axios from "axios";
import * as UrlConst from "../constants/UrlConst";
import * as Fc from "./Functions";

export default class API {
  static async login(username, password) {
    return axios.post(UrlConst.UrlAccount + UrlConst.Login, {
      accountId: username,
      password,
    });
  }

  static async changePass(password, newPassword) {
    let token = Fc.getToken();
    return axios.put(
      UrlConst.UrlAccount + UrlConst.ChangePass,
      {
        password,
        newPassword,
      },
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  }

  static async changePassByEmail(password, tokenId) {
    return axios.put(
      UrlConst.UrlAccount + UrlConst.ChangePassByToken + `?tokenId=${tokenId}`,
      {
        newPassword: password
      }
    );
  }

  static async forgetPassword(email) {
    return axios.post(UrlConst.UrlAccount + UrlConst.ForgetPassword, {
      accountId: email,
    });
  }

  static async getAccountInfo() {
    let token = Fc.getToken();
    return axios.get(
      UrlConst.Domain + UrlConst.GetAccountInfo,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  }


  static async getClients(
    pageIndex = 1,
    pageSize = 10,
    keyword = "",
    operatorType = ""
  ) {
    let token = Fc.getToken();
    return axios.get(
      UrlConst.Domain +
      UrlConst.GetClients +
      `?keyword=${keyword}&operatorType=${operatorType}&pageIndex=${pageIndex}&pagesize=${pageSize}`,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  }

  static async createClient(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.CreateClient, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async updateClient(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.UpdateClient, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getDetailClient(id) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetDetailClient + `/${id}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async deleteClient(id) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.DeleteClient + `/${id}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async listUsers(
    operatorId,
    pageIndex,
    pageSize,
    keyword,
    exceedIds
  ) {
    let token = Fc.getToken();
    return axios.post(
      UrlConst.Domain +
      UrlConst.ListUsers +
      `/?operatorId=${operatorId}&pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`, exceedIds,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  }

  static async getDetailStudent(id) {
    let token = Fc.getToken();
    return axios.get(
      UrlConst.Domain +
      UrlConst.GetDetailStudent +
      `/${id}`,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  }

  static async getDetailTeacher(id) {
    let token = Fc.getToken();
    return axios.get(
      UrlConst.Domain +
      UrlConst.GetDetailTeacher +
      `/${id}`,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  }

  static async editTeacher(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.EditTeacher, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async editStudent(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.EditStudent, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async insertStudents(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.InsertStudents, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async insertTeachers(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.InsertTeachers, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getAccountId(firstName, lastName, gender, type) {
    let token = Fc.getToken();
    return axios.get(
      UrlConst.Domain +
      UrlConst.GetAccountId +
      `?firstName=${firstName}&lastName=${lastName}&gender=${gender}&type=${type}`,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  }

  static async deleteTeacher(id) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.DeleteTeacher + `/${id}`, null, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async deleteStudent(id) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.DeleteStudent + `/${id}`, null, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getAccountPayment() {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetAccountPayment, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getBannerPayment() {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetBannerPayment, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getBannerByOperator(id) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.UpdateBannerByOperator + `?operatorId=${id}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async updateAccountPayment(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.UpdateAccountPayment, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async updateBannerPayment(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.UpdateBannerPayment, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async listTeachers(operatorId, pageIndex = 1, pageSize = 0, keyword = '') {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.ListTeachers + `?operatorId=${operatorId}&pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async listStudent(operatorId, pageIndex = 1, pageSize = 1, keyword = '') {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.ListStudent + `?operatorId=${operatorId}&pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async CreateClass(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.CreateClass, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async listClass(operatorId, pageIndex = 1, pageSize = 0, keyword = '', isActive = '') {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.ListClass + `?operatorId=${operatorId}&pageIndex=${pageIndex}&pageSize=${pageSize}&isActive=${isActive}&keyword=${keyword}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async detailClass(id) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.DetailClass + `/${id}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async UpdateClass(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.UpdateClass, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async updateStudentSolution(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.UpdateStudentSolution, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async DeleteClass(id) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.DeleteClass + `/${id}`, null, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getMessageDateList(id, differentTimezoneHour) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetMessageDateList + `?classId=${id}&differentTimezoneHour=${differentTimezoneHour}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async StudentGetMessageDateList(userid, classId, differentTimezoneHour) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.StudentGetMesssageDateList + `?studentId=${userid}&classId=${classId}&differentTimezoneHour=${differentTimezoneHour}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async StudentGetMessageByDate(studentId, classId, dateTime, pageIndex, pageSize) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.StudentGetMessageByDate + `?studentId=${studentId}&classId=${classId}&sendDate=${dateTime}&pageIndex=${pageIndex}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getAssignmentDateList(userid, classId, differentTimezoneHour) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetAssignmentDateList + `?studentId=${userid}&classId=${classId}&differentTimezoneHour=${differentTimezoneHour}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async assignmentSearch(studentId, classId, assignDate, pageIndex, pageSize) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.AssignmentSearch + `?studentId=${studentId}&classId=${classId}&assignDate=${assignDate}&pageIndex=${pageIndex}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getMessageByDate(id, sendDate = '', pageIndex = 1, pageSize = 20) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetMessageByDate +
      `?classId=${id}&sendDate=${sendDate}&pageIndex=${pageIndex}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getClassDetail(classId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetClassDetail +
      `/${classId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getStudentDetail(studentId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.StudentDetail +
      `/${studentId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getTeacherDetail(id) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetDetailTeacher +
      `/${id}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async sendMessageToClass(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.SendMessageToClass, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async sendMessageToStudent(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.SendMessageToStudent, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getListCountry(pageIndex, pageSize, keyword) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetListCountry +
      `?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getListRegionByParent(pageIndex, pageSize, level, parentId, keyword) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetListRegionByParent +
      `?pageIndex=${pageIndex}&pageSize=${pageSize}&level=${level}&parentId=${parentId}&keyword=${keyword}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getAllCategories() {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetAllCategories, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getGetAllLevels() {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetAllLevels, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getGetAllSubjects() {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetAllSubjects, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async WorkBookSearch(category = "", subject = "", level = "", bookName = "", pageIndex = 1, pageSize = 30) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.WorkBookSearch +
      `?category=${category}&subject=${subject}&level=${level}&bookName=${bookName}&pageIndex=${pageIndex}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async WorkBookSearchWithKeyword(category, subject, level, bookName, keyword, pageIndex, pageSize) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.WorkBookSearchWithKeywords +
      `?category=${category}&subject=${subject}&level=${level}&bookName=${bookName}&keyword=${keyword}&pageIndex=${pageIndex}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async WorkBookSearchWithTab(tab, category, subject, level, bookName, keyword, pageIndex, pageSize) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.WorkBookSearchWithTab +
      `?tab=${tab}&category=${category}&subject=${subject}&level=${level}&bookName=${bookName}&keyword=${keyword}&pageIndex=${pageIndex}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async WorkBookDetail(id) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetWorkbookDetail +
      `/${id}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async uploadDocument(title, uploadForType, uploadForId, body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.UploadDocument +
      `?title=${title}&uploadForType=${uploadForType}&uploadForId=${uploadForId}`, body, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    });
  }

  static async uploadDocumentForStudent(title, uploadForId, classId, body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.UploadDocumentForStudent +
      `?title=${title}&uploadForId=${uploadForId}&classId=${classId}`, body, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    });
  }

  static async uploadBanner(operatorId, body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.UploadBanner +
      `?operatorId=${operatorId}`, body, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    });
  }

  static async searchDocument(keyword, accountType, accountId, pageIndex = 1, pageSize = 0) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.SearchDocument +
      `?keyword=${keyword}&accountType=${accountType}&accountId=${accountId}&pageIndex=${pageIndex}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async searchDocumentForStudent(keyword, accountId, classId, pageIndex = 1, pageSize = 0) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.SearchDocumentForStudent +
      `?keyword=${keyword}&accountId=${accountId}&classId=${classId}&pageIndex=${pageIndex}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async deleteDocument(id) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.DeleteDocument + `/${id}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async deleteAssignment(id) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.AssignmentDelete + `/${id}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getWorkSheetsByDocumentId(id, pageIndex = 1, pageSize = 0) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetWorkSheetsByDocumentId + `/${id}?pageIndex=${pageIndex}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async sendWorkSheetsToStudents(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.SendWorksheetsToStudents, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getClassByPrimaryTeacher(pageIndex = 1, pageSize = 0, keyword = '', isActive = true) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetClassByPrimaryTeacher + `?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}&isActive=${isActive}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getWorkSheetsBySectionId(id, pageIndex = 1, pageSize = 0) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetWorkSheetsBySectionId + `/${id}?pageIndex=${pageIndex}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getListWorksheetByBook(studentId, type, bookId, classId, assignDate = new Date()) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetListWorksheetByBook + `?studentId=${studentId}&type=${type}&bookId=${bookId}&classId=${classId}&assignDate=${assignDate}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getWorksheetByAssignmentAndIndex(assignmentId, worksheetIndex, classId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetWorksheetByAssignmentAndIndex + `?assignmentId=${assignmentId}&worksheetIndex=${worksheetIndex}&classId=${classId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async updateAssignment(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.UpdateAssignment, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async updateDrawOnWorksheet(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.UpdateDrawOnWorksheet, body, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async cleanAssignment(AssignmentId, WorksheetId) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.CleanAssignment + `?AssignmentId=${AssignmentId}&WorksheetId=${WorksheetId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  //AccountRestriction
  static async CheckCreateTeacherRestriction(operatorId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.CheckCreateTeacherRestriction + `?operatorId=${operatorId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async CheckCreateStudentRestriction(operatorId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.CheckCreateStudentRestriction + `?operatorId=${operatorId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async CheckAssignTeacherRestriction(operatorId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.CheckAssignTeacherRestriction + `?operatorId=${operatorId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async CheckTeacherUploadDocumentRestriction(teacherId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.CheckTeacherUploadDocumentRestriction + `?teacherId=${teacherId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async CheckOperatorUploadDocumentRestriction(operatorId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.CheckOperatorUploadDocumentRestriction + `?operatorId=${operatorId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async CheckStudentUploadDocumentRestriction(studentId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.CheckStudentUploadDocumentRestriction + `?studentId=${studentId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async GetCurrentAndLimitStudentAccount(operatorId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetCurrentAndLimitStudentAccount + `?operatorId=${operatorId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async GetDayLeftToRestriction(operatorId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetDayLeftToRestriction + `?operatorId=${operatorId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async GetFirstStudentAccount(operatorId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetFirstStudentAccount + `?operatorId=${operatorId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async GetFirstClassOfOperator(operatorId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetFirstClassOfOperator + `?operatorId=${operatorId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async GetFirstTeacherAccount(operatorId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetFirstTeacherAccount + `?operatorId=${operatorId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async GetFirstDocumentOfAccount(accountType, accountId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetFirstDocumentOfAccount + `?accountType=${accountType}&accountId=${accountId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async GetFirstDocumentOfStudentAndClass(studentId, classId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetFirstDocumentOfStudentAndClass + `?studentId=${studentId}&classId=${classId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async getWorksheetByDocumentAndIndex(documentId, worksheetIndex) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetWorksheetByDocumentAndIndex + `?documentId=${documentId}&worksheetIndex=${worksheetIndex}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async mergeImagesByWidth(mainImage, images) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.MergeImagesByWidth + `?mainImage=${mainImage}`, images, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  }

  static async SearchNotJoinClass(operatorId, pageIndex, pageSize, studentId, teacherId, keyword) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.SearchNotJoinClass + `?operatorId=${operatorId}&pageIndex=${pageIndex}&pageSize=${pageSize}&isActive=true&studentId=${studentId}&teacherId=${teacherId}&keyword=${keyword}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async assignmentUploadImage(id, dataUrl) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.UploadImage + `?assignmentId=${id}`, '"' + dataUrl + '"', {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async createFolder(studentId, classId, folderName) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.CreateFolder + `?studentId=${studentId}&classId=${classId}&folderName=${folderName}&parentId=`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async searchFolder(keyword, classId, studentId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.SearchFolderInFolder + `?keyword=${keyword}&classId=${classId}&studentId=${studentId}&folderId=`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async saveNote(studentId, classId, noteName, folderId, workSheetId, type, noteId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.SaveNote + `?noteId=${noteId}&type=${type}&noteName=${noteName}&studentId=${studentId}&classId=${classId}&folderId=${folderId}&workSheetId=${workSheetId}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async searchNote(keyword, classId, studentId, folderId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.SearchNoteInFolder + `?keyword=${keyword}&classId=${classId}&studentId=${studentId}&folderId=${folderId}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async folderDetail(folderId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetFolderDetail + `?folderId=${folderId}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async renameFolder(folderId, folderName) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.RenameFolder + `?folderId=${folderId}&folderName=${folderName}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async deleteFolder(folderId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.DeleteFolder + `?folderIds=${folderId}&studentId=`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async deleteNote(noteIds) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.DeleteNote + `?noteIds=${noteIds}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async moveNote(noteIds, folderId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.MoveNote + `?noteIds=${noteIds}&toFolderId=${folderId}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async getNoteOfWorksheet(workSheetId, classId, studentId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetNoteOfWorksheet + `?workSheetId=${workSheetId}&classId=${classId}&studentId=${studentId}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async drawNote(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.DrawNote, body, {
      headers: {
        Authorization: `bearer ${token}`,
        // "Content-Type": "text/json",
      },
    });
  }

  static async getNoteDetail(noteId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetNoteDetail + `?noteId=${noteId}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async GetFolderTree(classId, studentId) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetFolderTree + `?classId=${classId}&studentId=${studentId}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async GetListChatRoom(userId, userType) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetListChatRoom + `?userId=${userId}&userType=${userType}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async CheckPermission(operatorId, permissionType) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.CheckPermission + `?operatorId=${operatorId}&permissionType=${permissionType}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async insertMessage(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.InsertMessage, body, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json",
      },
    });
  }

  static async GetChatMessages(from, to, ts, pageSize, pageIndex) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetChatMessages + `?from=${from}&to=${to}&ts=${ts}&pageSize=${pageSize}&pageIndex=${pageIndex}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async GetChatMessagesInGroup(classId, ts, pageSize, pageIndex) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetChatMessagesInGroup + `?classId=${classId}&ts=${ts}&pageSize=${pageSize}&pageIndex=${pageIndex}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async InsertNotification(body) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.InsertNotification, body, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async GetListNotification(userId, userType, pageIndex, pageSize, keyword) {
    let token = Fc.getToken();
    return axios.get(UrlConst.Domain + UrlConst.GetListNotification + `?userId=${userId}&userType=${userType}&pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async DeleteNotification(from, to, userId) {
    let token = Fc.getToken();
    return axios.delete(UrlConst.Domain + UrlConst.DeleteNotification + `?fromId=${from}&toId=${to}&userId=${userId}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async DeleteMessage(messageId) {
    let token = Fc.getToken();
    return axios.delete(UrlConst.Domain + UrlConst.DeleteMessage + `?messageId=${messageId}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }

  static async SeenMessage(userSendId, UserSeenId) {
    let token = Fc.getToken();
    return axios.post(UrlConst.Domain + UrlConst.SeenMessage + `?userSendId=${userSendId}&UserSeenId=${UserSeenId}`, {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "text/json"
      },
    });
  }
}

