import React from 'react';
import { Link } from 'react-router-dom'

function Code(props) {
    const { item, classId, firstStudentId, setMessage, setShowRestrictedModal } = props
    function handleClick() {
        setMessage('This features is locked. Please contact your administrator for more infomation.')
        setShowRestrictedModal(true)
    }
    return (
        <>
            {firstStudentId === item._id ?
                <Link className="pointer text-underline button-join mr-3" style={{paddingLeft: 25}} to={`/Teacher/Student/${item._id}/${classId}`}>Join</Link> :
                <Link
                    className="pointer text-underline button-join mr-3"
                    to={'#'}
                    style={{paddingLeft: 25}}
                    onClick={handleClick}
                >Join</Link>
            }
        </>
    );
}

export default Code;