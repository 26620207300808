import React from 'react';
import useCheckdevice from '../../../../hooks/useCheckdevice';
import classNames from 'classnames';
import { isDesktop, isMobile } from "react-device-detect";

//component
import TableComponent from "./Component/TableComponent";
import HeaderBack from "../../../../components/HeaderDesktop/HeaderBack";
import './Style.css'

function SectionQuizTest(props) {
    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))

    const { data, matchUrl, goBack, userName } = props
    return (
        <>
            <div className="bg-black-alpha-05"></div>
            <main className="" style={{paddingTop: isBrowserDesktop ? 51 : 80 }}>
                <section className="container-fluid">
                    {/* ====== Header back desktop ======*/}
                    {   isBrowserDesktop  && <HeaderBack goBack={goBack} userName={userName}/>  }
                    {/* ================================ */}
                    <div className="p-4">
                        <ul id="clothingnav1" className="nav nav-tabs border-bottom-0 nav-tabs-epen position-fixed seclect-all1" role="tablist">
                            {
                                data.map((item, index) => {
                                    return (
                                        <li className="nav-item" key={index.toString()}>
                                            <a className={`nav-link ${index === 0 ? "active" : ""}`} href={`#home-${index.toString()}`}
                                                id={"hometab-" + index} role="tab" data-toggle="tab"
                                                aria-controls={`home-${index.toString()}`} aria-expanded="true">{item.Type}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div id="clothingnavcontent1" className="tab-content pt-10">
                            {
                                data.map((item, index) => {
                                    return (
                                        <div role="tabpanel" className={`tab-pane fade ${index === 0 ? "show active" : ""}`}
                                            id={`home-${index.toString()}`} aria-labelledby={"hometab-" + index} key={index.toString()}>
                                            <div className="hidden-scrollbar-scoll-40 position-absolute t-150">
                                                <div className="inner-scoll" id={item.Type}>
                                                    {
                                                        item.data.map((level, index) => {
                                                            if (level.Type === item.Type)
                                                                return (
                                                                    <TableComponent matchUrl={matchUrl} level={level} Index={index} key={index.toString()} />
                                                                )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>

                </section>
            </main>
        </>
    );
}

export default SectionQuizTest;