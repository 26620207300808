import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Api from "../../utils/Api";
import * as $ from 'jquery'
import * as Fc from "../../utils/Functions";
import * as Const from "../../constants/Const";

import { useDispatch, useSelector } from 'react-redux'
import * as type from "../../constants/actionType"

function Login(props) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  let token = useSelector(state => state.ws.token)
  let role = useSelector(state => state.ws.role)

  function login(e) {
    e.preventDefault();
    if (!username || username === "") {
      Fc.showError("Username is required");
      return;
    }

    if (!password || password === "") {
      Fc.showError("Password is required");
      return;
    }

    Api.login(username, password)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.responseStatusCode === 200) {
            if (window.innerWidth < 576){
                var DefaultAccess = "Upload";
            }else{
                var DefaultAccess = "Workbook";
            };
            Fc.setToken(res.data.content.tokenId);
            dispatch({ type: type.SAVE_TOKEN, payload: res.data.content.tokenId })
            dispatch({ type: type.SET_ACTIVE_TAB_MYWORK, activeTab: DefaultAccess })
            dispatch({ type: type.SET_SELECTED_FOLDER, selectedFolder: {} })
            dispatch({ type: type.SET_STUDENT_ID, studentId: "" })

            //set Fullscreen
            // Fc.GoInFullscreen($("#root").get(0));

            //get AccountInfo
            Api.getAccountInfo()
              .then((res) => {
                if (res.status === 200) {
                  if (res.data.Success) {
                    const userInfo = res.data.Data
                    // set role of current user
                    dispatch({ type: type.SAVE_USER_LOGIN, payload: res.data.Data })
                    dispatch({ type: type.SAVE_NOTIFY_ARRAY, payload: [] })

                    Fc.setRole(res.data.Data.Role);
                    Fc.setUserId(res.data.Data._id);

                    // Fc.setAccountMethod(res.data.Data.AccountMethod)
                    // console.log(res.data.Data.AccountMethod);
                    // if role vendor
                    if (res.data.Data.Role.includes(Const.Role.VENDOR)) {
                      // Fc.setRole(res.data.Data.Role)
                      props.history.push("/Vendor")
                    }
                    // if role operator
                    if (res.data.Data.Role.includes(Const.Role.OPERATOR)) {

                      Api.GetListChatRoom(res.data.Data._id, Const.Role.OPERATOR).then(res => {
                        if (res.status === 200 && res.data.Success) {
                          dispatch({ type: type.SAVE_LIST_ROOM, payload: res.data.Data })
                          // console.log(res.data.Data);
                        } else {
                          if (res.data.Message)
                            Fc.showError(res.data.Message)
                          else
                            Fc.showError(res.statusText)
                        }
                      }).catch(err => {
                        Fc.showError("Can not connect to server")
                      })

                      // set operator of current user
                      Fc.setOperator(res.data.Data._id);
                      Fc.setAccountMethod(res.data.Data.AccountMethod)
                      props.history.push("/operator")
                    }
                    // if role teacher
                    if (res.data.Data.Role.includes(Const.Role.TEACHER)) {
                      // Fc.showSuccess(`Welcome ${res.data.Data.FirstName}`);
                      // set operator of current user

                      Api.GetListChatRoom(res.data.Data._id, Const.Role.TEACHER).then(res => {
                        if (res.status === 200 && res.data.Success) {
                          dispatch({ type: type.SAVE_LIST_ROOM, payload: res.data.Data })
                          // console.log(res.data.Data);
                        } else {
                          if (res.data.Message)
                            Fc.showError(res.data.Message)
                          else
                            Fc.showError(res.statusText)
                        }
                      }).catch(err => {
                        Fc.showError("Can not connect to server")
                      })

                      Fc.setOperator(res.data.Data.OperatorId);
                      Fc.setAccountMethod(res.data.Data.AccountMethod)
                      if (window.innerWidth < 576){
                        props.history.push("/Teacher/Classes/")
                      }else{
                        props.history.push("/Teacher")
                      }
                    }
                    // if role student
                    if (res.data.Data.Role.includes(Const.Role.STUDENT)) {
                      if(userInfo && userInfo.DetailUserInfo && userInfo.DetailUserInfo.Classes && userInfo.DetailUserInfo.Classes.length > 0) {
                        const classId = userInfo.DetailUserInfo.Classes[0]._id
                        dispatch({ type: type.SAVE_CLASS_ID, classId: classId })
                      }

                      Api.GetListChatRoom(res.data.Data._id, Const.Role.STUDENT).then(res => {
                        if (res.status === 200 && res.data.Success) {
                          dispatch({ type: type.SAVE_LIST_ROOM, payload: res.data.Data })
                          // console.log(res.data.Data);
                        } else {
                          if (res.data.Message)
                            Fc.showError(res.data.Message)
                          else
                            Fc.showError(res.statusText)
                        }
                      }).catch(err => {
                        Fc.showError("Can not connect to server")
                      })

                      Fc.showSuccess(`Welcome ${res.data.Data.FirstName}`)
                      // set operator of current user
                      Fc.setOperator(res.data.Data.OperatorId);
                      Fc.setAccountMethod(res.data.Data.AccountMethod)
                      props.history.push("/Student")
                    }
                    dispatch({ type: type.SAVE_ROLE, payload: res.data.Data.Role })

                  } else {
                    Fc.showError(res.data.Message);
                  }
                } else {
                  Fc.showError(res.statusText);
                }
              })
              .catch((err) => {
                console.log(err);
                Fc.showError("Can not connect to server");
              });

          } else {
            Fc.showError(res.data.responseStatusMessage);
          }
        } else {
          Fc.showError(res.statusText);
        }
      })
      .catch((err) => {
        console.log(err);
        Fc.showError("Can not connect to server");
      });
  }
  if (token && Fc.getToken() && Fc.getRole() && Fc.getRole().includes(Const.Role.VENDOR))
    return <Redirect to="/Vendor" />
  else if (token && Fc.getToken() && Fc.getRole() && Fc.getRole().includes(Const.Role.OPERATOR))
    return <Redirect to="/Operator" />
  else if (token && Fc.getToken() && Fc.getRole() && Fc.getRole().includes(Const.Role.TEACHER))
    return <Redirect to="/Teacher" />
  else if (token && Fc.getToken() && Fc.getRole() && Fc.getRole().includes(Const.Role.STUDENT))
    return <Redirect to="/Student" />
  else
    return (
      <div className="bg-login">
        <div className="bg-transparent-black">
          <div className="modal-dialog-centered modal-dialog-login">
            <form className="col-md-12" onSubmit={login}>
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group mb-4">
                <button
                  className="btn btn-light font-Paytone btn-block mb-3"
                  type="submit"
                >
                  Login
              </button>
              </div>
              <p className="text-center">
                <Link to="/PasswordRecovery">Forgot password?</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    );
}
export default Login
