import React, { useState } from 'react'
import * as Fc from '../../../utils/Functions'
// import * as Const from '../../../constants/Const'
import API from '../../../utils/Api'
import { useRouteMatch } from 'react-router-dom'

import UploadView from './UploadView'

export default function Upload(props) {

    const matchUrl = useRouteMatch()
    const [userInfo, setUserInfo] = useState({})
    const [keyword, setKeyword] = useState('')
    const [submitKeyword, setSubmitKeyword] = useState('')

    useState(() => {
        getUserInfo()
    }, [])
    
    function getUserInfo() {
        API.getAccountInfo().then(res => {
            if (res.status === 200 && res.data.Success) {
                setUserInfo(res.data.Data.DetailUserInfo)
            } else {
                Fc.showError(res.data.Message || res.statusText)
            }
        })
    }

    function onSearch(e) {
        e && e.preventDefault()
        setSubmitKeyword(keyword)
    }

    return (
        <UploadView userInfo={userInfo}
            keyword={keyword} setKeyword={setKeyword}
            submitKeyword={submitKeyword}
            onSearch={onSearch}
            matchUrl={matchUrl}
            {...props} />
    )
}