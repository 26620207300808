/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import * as Const from "../../../../constants/Const"
import * as Fc from "../../../../utils/Functions"
import PropTypes from "prop-types"
//import { toast } from 'react-toastify';
// import { Dropdown, DropdownButton } from 'react-bootstrap'
import API from "../../../../utils/Api"
import Create from "./Create"

Create.propTypes = {
  history: PropTypes.object,
}

function CreateClient(props) {
  const [businessType, setType] = useState(Const.clientType.filter(x => x.Value)[0].Value)
  const [businessName, setBusinessName] = useState("")
  const [apt, setApt] = useState("")
  const [address, setAddress] = useState("")
  const [province, setProvince] = useState("")
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("")
  const [postcode, setPostcode] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [gender, setGender] = useState("Male")
  const [phone, setPhone] = useState("")
  const [accountId, setEmail] = useState("")
  const [bannerEdit, setBannerEdit] = useState(false)
  const [paidAccount, setPaidAccount] = useState(false)
  const [listRegion, setListRegion] = useState([])
  const [listProvince, setListProvince] = useState([])
  const [listRegionByParent, setListRegionByParent] = useState([])

  const [showModalSetting, setShowModalSetting] = useState(false)
  const [accountEnable, setAccountEnable] = useState(true)
  const [writeNote, setWriteNote] = useState(true)
  const [upload, setUpload] = useState(true)
  const [ResourceConcept, setResourceConcept] = useState(true)
  const [ResourceFoundation, setResourceFoundation] = useState(true)
  const [SolutionConcept, setSolutionConcept] = useState(true)
  const [SolutionFoundation, setSolutionFoundation] = useState(true)
  const [isDisable, setIsDisable] = useState(false)


  function clearForm() {
    setType(Const.clientType.filter(x => x.Value)[0].Value)
    setBusinessName("")
    setApt("")
    setAddress("")
    setProvince("")
    setCity("")
    setCountry(listRegion[0])
    setPostcode("")
    setFirstName("")
    setLastName("")
    setGender("")
    setPhone("")
    setEmail("")
    setBannerEdit(false)
    setPaidAccount(false)

    setAccountEnable(true)
    setWriteNote(true)
    setUpload(true)
    setResourceConcept(true)
    setResourceFoundation(true)
    setSolutionConcept(true)
    setSolutionFoundation(true)
  }

  function showBusinessName() {
    return ["School", "Business", "Free"].includes(
      Const.clientType.filter((x) => x.Value === businessType)[0].Type
    )
  }

  function createClient() {
    //han che multi submit
    if(isDisable) return
    setIsDisable(true)

    if (showBusinessName() && businessName.trim() === "") {
      Fc.showError("Business name is required")
      return
    }

    let body = {
      Type: businessType,
      BusinessName: showBusinessName() ? businessName.trim() : null,
      FirstName: firstName,
      LastName: lastName,
      Gender: gender,
      PhoneNumber: phone,
      Email: accountId,
      Address: {
        Apt: apt,
        HouseNoAndStreet: address,
        ProvinceId: province.value,
        CityId: city.value,
        CountryId: country.value,
        PostalCode: postcode,
      },
      PaymentSetting: {
        CanEditBanner: bannerEdit,
        IsPaidAccount: paidAccount
      },
      OperatorSetting: {
        ShowWarningTrialsAccessResource: false,
        ShowWarningTrialsAccessSolution: false,
        CanUpload: upload,
        CanWriteNote: writeNote,
        AccessResourceConcept: ResourceConcept,
        AccessResourceFoundation: ResourceFoundation,
        AccessSolutionConcept: SolutionConcept,
        AccessSolutionFoundation: SolutionFoundation
      },
      IsActive: accountEnable
    }

    API.createClient(body)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.Success) {
            props.history.push("/Vendor")
            Fc.showSuccess("Success")
          } else Fc.showError(res.data.Message)
        } else {
          // Fc.showError(res.statusText)
        }
        setIsDisable(false)
      })
      .catch(() => {
        setIsDisable(false)
        Fc.showError("Can not connect to server")
      })
  }

  useEffect(() => {
    getRegions()
  }, [])

  useEffect(() => {
    if (province !== '')
      getCities()
  }, [province])

  useEffect(() => {
    if (country !== '')
      getProvince()
  }, [country])

  function getRegions() {
    API.getListCountry(1, 0, "")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.Success) {
            let ListRegion = []
            res.data.Data.forEach((item, index) => {
              ListRegion.push({
                value: item._id,
                label: item.Name,
                ...item
              })
            })
            setCountry(ListRegion[0])
            setListRegion(ListRegion)
          } else Fc.showError(res.data.Message)
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        Fc.showError("Can not connect to server")
      })
  }

  function getCities() {
    API.getListRegionByParent(1, 0, 3, province.value, "")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.Success) {
            let ListRegion = []
            res.data.Data.forEach((item, index) => {
              ListRegion.push({
                value: item._id,
                label: item.Name,
                ...item
              })
            })
            setListRegionByParent(ListRegion)
            setCity('')
          } else Fc.showError(res.data.Message)
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        Fc.showError("Can not connect to server")
      })
  }

  function getProvince() {
    API.getListRegionByParent(1, 0, 2, country.value, "")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.Success) {
            let ListProvince = []
            res.data.Data.forEach((item, index) => {
              ListProvince.push({
                value: item._id,
                label: item.Name,
                ...item
              })
            })

            setListProvince(ListProvince)
            setProvince('')
          } else Fc.showError(res.data.Message)
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        // Fc.showError("Can not connect to server")
      })
  }

  return (
    <Create
      createClient={createClient}
      businessType={businessType} setType={setType}
      businessName={businessName} showBusinessName={showBusinessName}
      apt={apt} setApt={setApt}
      address={address} setAddress={setAddress}
      province={province} setProvince={setProvince}
      city={city} setCity={setCity}
      country={country} setCountry={setCountry}
      postcode={postcode} setPostcode={setPostcode}
      firstName={firstName} setFirstName={setFirstName}
      lastName={lastName} setLastName={setLastName}
      gender={gender} setGender={setGender}
      phone={phone} setPhone={setPhone}
      accountId={accountId} setEmail={setEmail}
      bannerEdit={bannerEdit} setBannerEdit={setBannerEdit}
      paidAccount={paidAccount} setPaidAccount={setPaidAccount}
      clearForm={clearForm} setBusinessName={setBusinessName}
      listRegionByParent={listRegionByParent}
      listRegion={listRegion}
      listProvince={listProvince}
      showModalSetting={showModalSetting} setShowModalSetting={setShowModalSetting}

      accountEnable={accountEnable} setAccountEnable={setAccountEnable}
      writeNote={writeNote} setWriteNote={setWriteNote}
      upload={upload} setUpload={setUpload}
      ResourceConcept={ResourceConcept} setResourceConcept={setResourceConcept}
      ResourceFoundation={ResourceFoundation} setResourceFoundation={setResourceFoundation}
      SolutionConcept={SolutionConcept} setSolutionConcept={setSolutionConcept}
      SolutionFoundation={SolutionFoundation} setSolutionFoundation={setSolutionFoundation}
      {...props}
    />
  )
}

export default CreateClient
