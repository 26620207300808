import React from 'react';
import PopupChat from './PopupChat'
import { useDispatch, useSelector } from 'react-redux';
import * as Type from './../../constants/actionType'
import API from './../../utils/Api'
import * as Fc from './../../utils/Functions'
import * as Const from './../../constants/Const'
import './style.css'

function Index(props) {
    const userLogin = useSelector(state => state.ws.userLogin)

    const {
        setOpenPopup,
        role,
        message,
        setMessage,
        sendMessage,
        messageList,
        listClass,
        ClassId,
        setClassId, ListStudent,
        ListTeacher, setSelectedId, selectedId,
        tabChat,
        setTabChat,
        userType, setUserType,
        userName, setUserName,
        notification, getListNotif,
        primaryTeacherId, scrollToBottom, messageEndRef, setUserSendMessagge, getListMessageInGroup,
        GetChatMessages,
        getDetailClass,
        setPageIndex,
        onScrollTop,
        pageIndex
    } = props

    const dispatch = useDispatch()

    function seenNotification(from, to, type, fromType, classId, ToRole, data) {
        API.DeleteNotification(from, to, userLogin._id)
            .then((res) => {
                if (res.data.Success) {
                    if (type === "normal") {
                        if (fromType === Const.Role.OPERATOR) {
                            setTabChat("contactAdmin")
                            setClassId(classId);
                            setPageIndex(1)
                            setUserType(data.FromRole)
                            setUserName(data.FromName)
                            dispatch({ type: Type.SAVE_CURRENT_CHAT, payload: {tabChat: "admin", classId: classId, fromId: userLogin.OperatorId, isClick: false} });
                        }
                        else if (ToRole === Const.Role.OPERATOR) {
                            setTabChat("ChatPersion")
                            setUserType(data.FromRole)
                            setUserName(data.FromName)
                            setClassId(classId)
                            setPageIndex(1)
                            dispatch({ type: Type.SAVE_CURRENT_CHAT, payload: {tabChat: "person", classId: classId, fromId: from} });
                        }
                        else {
                            setTabChat("ChatPersion")
                            setUserType(data.FromRole)
                            setUserName(data.FromName)
                            setClassId(classId);
                            setPageIndex(1)
                            dispatch({ type: Type.SAVE_CURRENT_CHAT, payload: {tabChat: "person", classId: classId, fromId: from} });
                        }
                    } else {
                        setClassId(to)
                        setSelectedId("")
                        setTabChat("ChatClass")
                        setPageIndex(1)
                        dispatch({ type: Type.SAVE_CURRENT_CHAT, payload: {tabChat: "class", classId: to, fromId: null} });
                    }
                    getListNotif(from, fromType)

                } else {
                    setPageIndex(1)
                    if (res.data.Message)
                        Fc.showError(res.data.Message)
                    else {
                        // Fc.showError(res.statusText)
                    }
                }

            }).catch((err) => {
                setPageIndex(1)
                Fc.showError("Can not connect to server")
            })
    }

    function MobileKeyboardPushUp() {
        if (window.innerWidth < 576){
            document.querySelector('.box-chat-ipad').style.top = '100px';
        }
    }
    function MobileKeyboardPushDown(){
        if (window.innerWidth < 576){
            document.querySelector('.box-chat-ipad').style.top = '100px';
        }
    }

    const sortMsgLst = Fc.sortAndGroupDatetime(messageList)
    return (
        <>
            <PopupChat
                setOpenPopup={setOpenPopup}
                MobileKeyboardPushUp={MobileKeyboardPushUp}
                MobileKeyboardPushDown={MobileKeyboardPushDown}
                setMessage={setMessage}
                message={message}
                sendMessage={sendMessage}
                messageList={sortMsgLst}
                role={role}
                listClass={listClass}
                ClassId={ClassId}
                setClassId={setClassId}
                ListStudent={ListStudent}
                ListTeacher={ListTeacher}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
                tabChat={tabChat}
                setTabChat={setTabChat}
                userType={userType} setUserType={setUserType}
                userName={userName} setUserName={setUserName}
                notification={notification}
                seenNotification={seenNotification}
                primaryTeacherId={primaryTeacherId}
                scrollToBottom={scrollToBottom}
                messageEndRef={messageEndRef}
                setPageIndex={setPageIndex}
                onScrollTop={onScrollTop}
                pageIndex={pageIndex}
            />
        </>
    );
}

export default Index;