import React, { useState, useEffect } from "react"
import * as Const from "../../../../constants/Const"
import * as Fc from "../../../../utils/Functions"

import API from "../../../../utils/Api"
import ClientUpdate from "./ClientUpdate"

function UpdateClient(props) {
  const [businessType, setType] = useState(Const.clientType[0].Value)
  //businessTypeTemp use for modal, wont trigger update bisenesstype when hide modal
  const [businessTypeTemp, setTypeTemp] = useState(Const.clientType[0].Value)

  const [businessName, setBusinessName] = useState("")
  //businessName use for modal, wont trigger update businessName when hide modal
  const [businessNameTemp, setBusinessNameTemp] = useState("")
  const [id, setId] = useState("")
  const [apt, setApt] = useState("")
  const [houseNoAndStreet, setHouseNoAndStreet] = useState("")
  const [province, setProvince] = useState("")
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("")
  const [postcode, setPostcode] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [gender, setGender] = useState("Male")
  const [phone, setPhone] = useState("")
  const [accountId, setEmail] = useState("")
  
  const [bannerEdit, setBannerEdit] = useState(true)
  const [paidAccount, setPaidAccount] = useState(true)
  const [isShowModal, setShowModal] = useState(false)
  const [isShowDeleteModal, setShowDeleteModal] = useState(false)
  const [listRegion, setListRegion] = useState([])
  const [listRegionByParent, setListCityByParent] = useState([])
  const [parentCityid, setParentCityId] = useState("")
  const [listProvince, setListProvince] = useState([])
  const [showModalSetting, setShowModalSetting] = useState(false)

  const [accountEnable, setAccountEnable] = useState(false)
  const [writeNote, setWriteNote] = useState(false)
  const [upload, setUpload] = useState(false)
  const [ResourceConcept, setResourceConcept] = useState(false)
  const [ResourceFoundation, setResourceFoundation] = useState(false)
  const [SolutionConcept, setSolutionConcept] = useState(false)
  const [SolutionFoundation, setSolutionFoundation] = useState(false)

  useEffect(() => {
    getDetailClient()
    getRegions()
  }, [])

  useEffect(() => {
    if (province !== '')
      getCities()
  }, [province])

  useEffect(() => {
    if (country !== '')
      getProvinces()
  }, [country])

  function getDetailClient() {
    API.getDetailClient(props.match.params.id)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.Data
          setId(data._id)
          setType(data.Type || "")
          setBusinessName(data.BusinessName || "")
          setApt(data.Address.Apt || "")
          setHouseNoAndStreet(data.Address.HouseNoAndStreet || "")
          if (data.Address.Province)
            setProvince({
              value: data.Address.Province._id,
              label: data.Address.Province.Name
            })
          if (data.Address.City)
            setCity({
              value: data.Address.City._id,
              label: data.Address.City.Name
            })
          if (data.Address.Country) {
            setCountry({
              value: data.Address.Country._id,
              label: data.Address.Country.Name
            })
            setParentCityId(data.Address && data.Address.City && data.Address.City._id || "")
          }

          setPostcode(data.Address.PostalCode || "")
          setFirstName(data.FirstName || "")
          setLastName(data.LastName || "")
          setGender(data.Gender || "Male")
          setPhone(data.PhoneNumber || "")
          setEmail(data.Email || '')
          setAccountEnable(data.IsActive)
          setBannerEdit(data.PaymentSetting.CanEditBanner)
          setPaidAccount(data.PaymentSetting.IsPaidAccount)
          setWriteNote(data.OperatorSetting.CanWriteNote || false)
          setUpload(data.OperatorSetting.CanUpload || false)
          setResourceConcept(data.OperatorSetting.AccessResourceConcept || false)
          setResourceFoundation(data.OperatorSetting.AccessResourceFoundation || false)
          setSolutionConcept(data.OperatorSetting.AccessSolutionConcept || false)
          setSolutionFoundation(data.OperatorSetting.AccessSolutionFoundation || false)
          // Region
        } else {
          props.history.goBack()
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        props.history.goBack()
        Fc.showError("Can not connect to server")
      })
  }

  function UpdateClient() {
    let body = {
      _id: id,
      Type: businessType,
      BusinessName: businessName,
      FirstName: firstName,
      LastName: lastName,
      Gender: gender,
      PhoneNumber: phone,
      Address: {
        Apt: apt,
        HouseNoAndStreet: houseNoAndStreet,
        ProvinceId: province.value,
        CityId: city.value,
        CountryId: country.value,
        PostalCode: postcode
      },
      PaymentSetting: {
        CanEditBanner: bannerEdit,
        IsPaidAccount: paidAccount,
      },
      IsActive: accountEnable,
      OperatorSetting: {
        ShowWarningTrialsAccessResource: false,
        ShowWarningTrialsAccessSolution: false,
        CanUpload: upload,
        CanWriteNote: writeNote,
        AccessResourceConcept: ResourceConcept,
        AccessResourceFoundation: ResourceFoundation,
        AccessSolutionConcept: SolutionConcept,
        AccessSolutionFoundation: SolutionFoundation
      },
    }
    API.updateClient(body)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.Success) {
            props.history.push("/Vendor")
            Fc.showSuccess("Update client success")
          } else {
            Fc.showError(res.data.Message)
          }
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        Fc.showError("Can not connect to server")
      })
  }

  function onChangeBusinessType(value) {
    if (["School", "Business", "Free"].includes(
      Const.clientType.filter((x) => x.Value === value)[0].Type
    )) {
      setTypeTemp(value)
      setBusinessNameTemp(businessName)
      setShowModal(true)
    } else {
      setType(value)
      setBusinessName("")
    }
  }

  function onHideModal() {
    setTypeTemp(businessType)
    setShowModal(false)
  }

  function confirmBusinessName() {
    if (!businessNameTemp || businessNameTemp === "") {
      Fc.showError("Business name is required")
      return
    }
    if ((businessNameTemp.length == 1 && businessNameTemp.includes(" ")) || (businessNameTemp.length > 1 && businessNameTemp.includes("  "))) {
      Fc.showError("Business name is not valid")
      return
    }
    setType(businessTypeTemp)
    setBusinessName(businessNameTemp)
    onHideModal()
  }

  function onHideDeleteModal() {
    setShowDeleteModal(false)
  }

  function deleteClient() {
    onHideDeleteModal()
    API.deleteClient(props.match.params.id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.Success) {
            Fc.showSuccess("Delete successfully")
            props.history.push("/Vendor")
          }
          else {
            Fc.showError(res.data.Message)
          }
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        Fc.showError("Can not connect to server")
      })
  }

  function getRegions() {
    API.getListCountry(1, 0, "")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.Success) {
            let ListCountry = []
            res.data.Data.map((item, index) => {
              ListCountry.push({
                value: item._id,
                label: item.Name
              })
            })
            setListRegion(ListCountry)
          } else Fc.showError(res.data.Message)
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        Fc.showError("Can not connect to server")
      })
  }

  function getCities() {
    API.getListRegionByParent(1, 0, 3, province.value, "")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.Success) {
            let ListRegionByParent = []
            res.data.Data.map((item, index) => {
              ListRegionByParent.push({
                value: item._id,
                label: item.Name,
                ...item
              })
            })
            setListCityByParent(ListRegionByParent)
          } else Fc.showError(res.data.Message)
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        Fc.showError("Can not connect to server")
      })
  }

  function getProvinces() {
    API.getListRegionByParent(1, 0, 2, country.value, "")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.Success) {
            let ListProvince = []
            res.data.Data.map((item, index) => {
              ListProvince.push({
                value: item._id,
                label: item.Name,
                ...item
              })
            })
            setListProvince(ListProvince)
          } else Fc.showError(res.data.Message)
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        Fc.showError("Can not connect to server")
      })
  }

  return (
    <ClientUpdate
      _id={id}
      UpdateClient={UpdateClient}
      businessType={businessType} businessName={businessName}
      apt={apt} setApt={setApt}
      houseNoAndStreet={houseNoAndStreet} setHouseNoAndStreet={setHouseNoAndStreet}
      province={province} setProvince={setProvince}
      city={city} setCity={setCity}
      country={country} setCountry={setCountry}
      postcode={postcode} setPostcode={setPostcode}
      firstName={firstName} setFirstName={setFirstName}
      lastName={lastName} setLastName={setLastName}
      gender={gender} setGender={setGender}
      phone={phone} setPhone={setPhone}
      accountId={accountId}
      bannerEdit={bannerEdit} setBannerEdit={setBannerEdit}
      onChangeBusinessType={onChangeBusinessType}
      paidAccount={paidAccount} setPaidAccount={setPaidAccount}
      isShowModal={isShowModal} setShowDeleteModal={setShowDeleteModal}
      isShowDeleteModal={isShowDeleteModal} onHideModal={onHideModal}
      businessNameTemp={businessNameTemp} setBusinessNameTemp={setBusinessNameTemp}
      confirmBusinessName={confirmBusinessName}
      onHideDeleteModal={onHideDeleteModal} deleteClient={deleteClient}
      listRegion={listRegion} listRegionByParent={listRegionByParent}
      parentCityid={parentCityid} listProvince={listProvince}

      accountEnable={accountEnable} setAccountEnable={setAccountEnable}
      writeNote={writeNote} setWriteNote={setWriteNote}
      upload={upload} setUpload={setUpload}
      ResourceConcept={ResourceConcept} setResourceConcept={setResourceConcept}
      ResourceFoundation={ResourceFoundation} setResourceFoundation={setResourceFoundation}
      SolutionConcept={SolutionConcept} setSolutionConcept={setSolutionConcept}
      SolutionFoundation={SolutionFoundation} setSolutionFoundation={setSolutionFoundation}
      showModalSetting={showModalSetting} setShowModalSetting={setShowModalSetting}
      {...props}
    />
  )
}

export default UpdateClient
