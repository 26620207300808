import React from 'react';

function Loading(props) {
    return (
        <div id="circle" style={{ pointerEvents: 'none', zIndex: 100, background: 'none' }} className="loading d-none">
            <div className="loader">
                <div className="loader">
                    <div className="loader">
                        <div className="loader">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loading;