import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';

function ButtonGoBack(props) {
    const {
        handleClick
    } = props
    if(isTablet)
        {return (
            <div onClick={handleClick}>
                <i className="fas fa-arrow-left fa-2x"></i>
            </div>
        )};
    return false
}

export default ButtonGoBack;