import React, { useState } from "react"
import { Link } from "react-router-dom"
import API from "../../utils/Api"
import * as Fc from "../../utils/Functions"

export default function PasswordRecovery(props) {
  const [email, setEmail] = useState("")

  function forgetPassword(e) {
    e.preventDefault()
    API.forgetPassword(email)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.responseStatusCode === 200) {
            Fc.showSuccess("Send mail successs.")
            props.history.push("/PasswordRecovery/SendMailSuccess")
          } else Fc.showError(res.data.responseStatusMessage)
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        Fc.showError("Can not connect to server")
      })
  }

  return (
    <div className="bg-xanh">
      <nav className="header fixed-top p-3 z-5">
        <div className="row align-items-center">
          <div className="col-auto">
            <Link to="/">
              <i className="fas fa-arrow-left fa-3x"></i>
            </Link>
          </div>
        </div>
      </nav>
      <div className="modal-dialog-centered modal-dialog-login">
        <div className="col-md-12">
          <h4 className="font-Paytone text-center mb-5">
            We will send you a recovery email
          </h4>
          <form className="form" onSubmit={forgetPassword}>
            <div className="form-group mb-4">
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder="Enter your Email Address/UserID"
              />
            </div>
            <button
              type="submit"
              className="btn btn-light font-Paytone btn-block mb-3"
            >
              Confirm
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
