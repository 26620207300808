import React from 'react';
import MetaTags from 'react-meta-tags';


function MetaTag(props) {
    const { title, description, content, path } = props
    return (
        <MetaTags>
            <title>{title}</title>
            <meta id="meta-description" name="description" content={description} />
            <meta id="og-title" property="og:title" content={content} />
            <meta id="og-image" property="og:image" content={path} />
        </MetaTags>
    );
}

export default MetaTag;