import React from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { Formik } from "formik"
import * as Yup from "yup"
// import ModalAlertForStudent from "./../../../components/ModalStudentTeacher"

import ModalAlertForOperator from "../../../components/ModalAlertOperator"
import * as Fc from "../../../utils/Functions"
import * as Const from '../../../constants/Const'
import * as type from "../../../constants/actionType"
import DocumentItem from './DocumentItem'
import {
    isTablet, isIPad13, 
    isMobile, isDesktop
} from "react-device-detect"
import useCheckdevice from '../../../hooks/useCheckdevice'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

export default function UploadDocumentsView(props) {
    const role = Fc.getRole()
    const dispatch = useDispatch();
    const checkDevices = useCheckdevice()
    const checkBrowser = (isDesktop && !isMobile && (checkDevices !== 'iPad'))

    const {
        fileTitle, setFileTitle,
        file, setFile,
        openUploadModal,
        listFiles,
        openDeleteModal,
        showUploadModal, hideUploadModal,
        upload,
        showDeleteModal, hideDeleteModal,
        deleteDocument,
        matchUrl,
        isShowAddAlertModal, onHideAddAlert,
        isShowAddErrorModal,
        onHideAddError, errorMessage, alertMessage, firstDocumentId,
        isShowDocumentUploadPage,
        setShowDocumentUploadPage,
        messageForDocumentUploadPage,
        setMessageForDocumentUploadPage,
        keyword, setKeyword,
        onSearch,
        display
    } = props

    const AccountMethod = Fc.getAccountMethod()

    const validateForm = Yup.object().shape({
        fileTitle: Yup.string().required("Required")
            .test('trim', 'Required', (value) => {
                return value && value.trim().length > 0
            }),
        file: Yup.mixed().required('Required')
            .test('fileFormat', 'Pdf or image file only', (value) => {
                return value && ['pdf', 'png', 'jpg', 'PDF', 'PNG', 'JPG'].includes(value.split('.').pop());
            }),
    })

    let fileName = ""
    if (file.name && file.name.length > 0) {
        if (file.name.length > 20) {
            fileName = file.name.substring(0, 20) + "..."
        } else {
            fileName = file.name
        }
    }
    return (
        <>
            <section className={classNames("container-fluid", "mb-3", [{"mt-desktop-5": checkBrowser}])} 
                style={{ display: display ? 'block' : 'none' }}
            > 
                <div className="row mb-1 mt-3">
                    <div className="col-lg-4 col-md-6">
                        <form className="input-group h-100" onSubmit={onSearch} >
                            <input
                                type="text"
                                className="form-control h-100"
                                placeholder="Enter Document Name"
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                            />
                            <div className="input-group-append ml-0 border-0" style={{ zIndex: 0 }}>
                                <button className="btn bg-input " type="submit"><i className="fas fa-search text-white"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            <section className="container-fluid position-relative custom-height" style={{ display: display ? 'block' : 'none' }}>
                <div
                    className={classNames("hidden-scrollbar-pos",  "up-tl", [{ "position-absolute": checkBrowser, "desktop-interface-student-item": checkBrowser }])}
                    // style={{ top: !checkBrowser ? 70 : 110 }}
                >
                    <div id="list" 
                        className={classNames("inner-pos", [{"desktop-interface-student-padding-fix": checkBrowser, "student-upload-desktop": checkBrowser,}])} 
                    >
                        <div className={classNames("tag_list_wrapper",  "row", "mb-4", "mt-4")}>
                            <div className="col-lg-2 col-md-3 col-6 mb-5 ar-1 pointer">
                                <div className="tran-60 box-upload text-center h-100" onClick={openUploadModal}>
                                    <p className="mb-0"><i className="fas fa-upload fa-3x"></i><br /><span className="font-Paytone font18">Upload</span></p>
                                </div>
                            </div>
                            {listFiles && listFiles.map((item, index) => {
                                return (
                                    <React.Fragment key={index.toString()}>
                                        {(AccountMethod !== Const.AccountMethod.RESTRICTED || role === Const.Role.VENDOR)
                                            ?
                                            (<div className="tag_list col-lg-2 col-md-3 col-6 mb-5" 
                                                onClick={() => dispatch({ type: type.CHANGE_NOT_RELOAD, payload: true })}
                                            >
                                                <Link to={`${matchUrl.url}/Edit/Document/${item._id}/0`} className="position-relative box-img mh-150">
                                                    <img className="img-fluid bot-0" src={item.Cover} alt="" />
                                                    <span style={{ background: 'rgba(0, 0, 0, 0.5)', bottom: '-1px' }} className="box-sp position-absolute">
                                                        <strong style={{ width: "90%", margin: "auto" }} className="align-middle-center">{item.Title && item.Title.length > 15 ? (item.Title && item.Title.substring(0, 15) + "...") : item.Title}</strong>
                                                    </span>
                                                </Link><span className="close" onClick={() => openDeleteModal(item._id)}>X</span>
                                            </div>)
                                            :
                                            <DocumentItem
                                                matchUrl={matchUrl}
                                                item={item}
                                                openDeleteModal={openDeleteModal}
                                                AccountMethod={AccountMethod}
                                                setMessageForDocumentUploadPage={setMessageForDocumentUploadPage}
                                                setShowDocumentUploadPage={setShowDocumentUploadPage}
                                                firstDocumentId={firstDocumentId}
                                            />
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                show={showUploadModal}
                onHide={hideUploadModal}
                className="modal fade"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <Formik
                        initialValues={{ fileTitle: '', file: undefined }}
                        onSubmit={upload}
                        validationSchema={validateForm}
                    >
                        {(properties) => {
                            const {
                                touched,
                                errors,
                                handleSubmit,
                                handleChange,
                            } = properties;
                            return (
                                <form className="modal-content bg-epen pte-auto py-4 px-4" onSubmit={handleSubmit}>
                                    <div className="modal-body px-3">
                                        <input type="text" name="fileTitle" className="form-control" placeholder="Title*" value={fileTitle} onChange={(e) => {
                                            setFileTitle(e.target.value)
                                            handleChange(e)
                                        }} />
                                        {errors.fileTitle && touched.fileTitle && (
                                            <div className="validate-input">* {errors.fileTitle}</div>
                                        )}
                                        <div className="custom-file my-3">
                                            <input type="file" name="file" className="custom-file-input" onChange={(e) => {
                                                setFile(e.target.files[0])
                                                handleChange(e)
                                            }} />
                                            {errors.file && touched.file && (
                                                <div className="validate-input">* {errors.file}</div>
                                            )}
                                            <label className="custom-file-label custom-file-label-epen" htmlFor="customFile"><span className="text-white">{fileName}</span></label>
                                        </div>
                                    </div>
                                    <div className="modal-footer block border-top-0 px-3">
                                        <div className="row">
                                            <div className="col-5 mr-auto"><button type="button" onClick={hideUploadModal} className="btn btn-light font-Paytone btn-block">Cancel</button></div>
                                            <div className="col-5"><button type="submit" className="btn btn-light font-Paytone float-right btn-block">Upload</button></div>
                                        </div>
                                    </div>
                                </form>)
                        }}
                    </Formik>
                </div>
            </Modal>

            <Modal
                show={showDeleteModal}
                onHide={hideDeleteModal}
                className="modal fade"
                id="myModal"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-epen pte-auto">
                        <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                            <h4 className="modal-title font-Paytone">
                                Are you sure you want to delete this document?
                                </h4>
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 mr-auto">
                                    <button
                                        type="button"
                                        onClick={hideDeleteModal}
                                        className="btn btn-light font-Paytone btn-block"
                                    >
                                        Cancel
                                        </button>
                                </div>
                                <div className="col-5">
                                    <button
                                        type="button"
                                        onClick={deleteDocument}
                                        className="btn btn-light font-Paytone float-right btn-block"
                                    >
                                        <span className="text-danger">Confirm</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <ModalAlertForOperator
                showModal={isShowAddAlertModal}
                alertMessage={(role === Const.Role.OPERATOR || role === Const.Role.TEACHER) ? alertMessage : alertMessage + ' Please contact your administrator for more information'}
                role={role}
                keyStorage={'PopupOperatorUploadForStudentAlert'}
                AccountMethod={AccountMethod}
                functionClose={onHideAddAlert}
            />

            <ModalAlertForOperator
                showModal={isShowAddErrorModal}
                alertMessage={(role === Const.Role.OPERATOR || role === Const.Role.TEACHER) ? errorMessage : 'This features is locked. Please contact your administrator for more infomation.'}
                role={role}
                keyStorage={'PopupOperatorUploadForStudentError'}
                AccountMethod={AccountMethod}
                functionClose={onHideAddError}
            />

            <ModalAlertForOperator
                showModal={isShowDocumentUploadPage}
                alertMessage={role === Const.Role.OPERATOR ? messageForDocumentUploadPage : 'Please contact your administrator for more information'}
                role={role}
                keyStorage={'PopupShowDocumentUploadPage'}
                AccountMethod={AccountMethod}
                functionClose={() => {
                    setShowDocumentUploadPage(false)
                }}
            />

        </>
    )
}