import React, {useEffect, useRef} from 'react';
import * as Const from './../../constants/Const'
import * as Fc from './../../utils/Functions'
import * as type from './../../constants/actionType'
import * as $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';

// import ScrollToBottom from 'react-scroll-to-bottom';
import { isDesktop, isMobile } from 'react-device-detect';
import useCheckdevice from '../../hooks/useCheckdevice'
import classNames from 'classnames'
import ClassTab from './ClassTab'
import PersonTab from './PersonTab'
import AdminTab from './AdminTab'
import NotifChat from './NotifChat'
import ChatInput from './ChatInput'
import HeaderChat from './HeaderChat'

function PopupChat(props) {
    const checkDevice = useCheckdevice()
    const dispatch = useDispatch()

    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))

    const {
        setOpenPopup,
        sendMessage,
        message,
        role,
        setMessage,
        MobileKeyboardPushUp,
        MobileKeyboardPushDown,
        messageList,
        ClassId,
        setClassId,
        listClass,
        ListStudent,
        ListTeacher,
        setSelectedId,
        selectedId,
        tabChat,
        setTabChat,
        setUserType,
        setUserName,
        primaryTeacherId,
        seenNotification, scrollToBottom, messageEndRef,
        setPageIndex,
        onScrollTop,
        pageIndex
    } = props

    const notification = useSelector(state => state.ws.notify)
    let countNotif = useSelector(state => state.ws.countNotif)
    let currentChatRx = useSelector(state => state.ws.currentChat)
    let userLoginRx = useSelector(state => state.ws.userLogin)
    let isLoading = useSelector(state => state.ws.isLoading)

    let isLoadingTmp = useRef(true)
    const  isChrome = navigator.userAgent.indexOf("Chrome") != -1
    const styleChromeIpad = checkDevice === 'iPad' && isChrome ? {bottom: 40} : {}

    useEffect(() => {
        isLoadingTmp.current = isLoading
    } , [isLoading])

    useEffect(() => {
        var el = document.getElementById('scroll_chat');
        el.addEventListener('scroll', function(event) {
            // if (event.target.scrollTop <= 0 && !isLoadingTmp.current) {
            if (event.target.scrollTop <= 0 && !isLoading) {
                if(onScrollTop) onScrollTop()
                
                if($(".item_chat").first() && $(".item_chat").first().offset())
                $("#scroll_chat").first().scrollTop($(".item_chat").first().offset().top);
            }
        }, false);  
        return () => { 
            el.removeEventListener('scroll', () => console.log("hihi"))
        }
    // }, [currentChatRx])
    }, [isLoading])

    function onChangeClasTab(e) {
        setClassId(e.target.value)
        setSelectedId("")
        setTabChat("ChatClass")
        if(e.target.value === "Classlist") {
            dispatch({ type: type.SAVE_CURRENT_CHAT, payload: null});
            return
        }
        dispatch({ type: type.SAVE_CURRENT_CHAT, payload: {tabChat: "class", classId: e.target.value, fromId: null} });
    }

    function onChangePersonTab(e) {
        setSelectedId(e.target.value)
        let selt = document.getElementById("selectUserChatbox");
        let userType = selt.options[selt.selectedIndex].getAttribute("usertype")
        setUserType(userType)
        let userName = selt.options[selt.selectedIndex].getAttribute("username")
        setUserName(userName)
        
        /*
        |   khi chuyen ve option "Teacher/Student" thi load lai du lieu cua lop hien tai
        */
        if(e.target.value === "Teacher/Student") {
            setTabChat("ChatClass")
            dispatch({ type: type.SAVE_CURRENT_CHAT, payload: {tabChat: "class", classId: ClassId, fromId: null} });
            return
        }

        setTabChat("ChatPersion")
        dispatch({ type: type.SAVE_CURRENT_CHAT, payload: {tabChat: "person", classId: ClassId, fromId: e.target.value} });
    }

    function onClickContactAdmin() {
        setTabChat("contactAdmin")
        setUserType(Const.Role.OPERATOR)
        setSelectedId(Fc.getOperator())
        dispatch({ type: type.SAVE_CURRENT_CHAT, payload: {tabChat: "admin", classId: ClassId, fromId: Fc.getOperator()}, isClick: true });
    }

    function closeChatPopUp() {
        setOpenPopup(false)
        setClassId("")
        setSelectedId("")
        setTabChat("ChatClass")
        dispatch({ type: type.SAVE_CURRENT_CHAT, payload: null});
    }

    return (
        <>
            <div 
                className={classNames("z-index-popupChat", 
                    [{ "box-chat": isBrowserDesktop, "box-chat-ipad": !isBrowserDesktop }])
                }
                style={{ backgroundColor: 'rgba(0, 0, 0, 1)', borderBottomLeftRadius: 10, borderBottomRightRadius: 13 }}
            >
                <section className="bg-chat-than">
                    <div className="">
                        <HeaderChat 
                            currentChatRx={currentChatRx}
                            onChangeClasTab={onChangeClasTab}
                            listClass={listClass}
                            ClassId={ClassId}

                            selectedId={selectedId}
                            onChangePersonTab={onChangePersonTab}
                            ListStudent={ListStudent}
                            ListTeacher={ListTeacher}

                            onClickContactAdmin={onClickContactAdmin}
                        />
                
                        {/* Content Panel */}
                        <div id="clothingnavcontent1" className="tab-content px-3">
                            {tabChat === "ChatClass" && 
                                <div className="tab-pane fade show active 1" >
                                    <div className="hidden-scrollbar">
                                        <div className="inner h300" id="scroll_chat">
                                            <ClassTab messageList={messageList}/>
                                            <div ref={messageEndRef} />
                                        </div>
                                    </div>
                                </div>
                            }

                            {tabChat === "ChatPersion" && 
                                <div className="tab-pane fade show active 3">
                                    <div className="hidden-scrollbar">
                                        <div className="inner h300" id="scroll_chat">
                                            <PersonTab messageList={messageList} ClassId={ClassId} userLoginRx={userLoginRx}/>
                                            <div ref={messageEndRef} />
                                        </div>
                                    </div>
                                </div>
                            }

                            {tabChat === "contactAdmin" && 
                                <div className="tab-pane fade show active 2">
                                    <div className="hidden-scrollbar">
                                        <div className="inner h300" id="scroll_chat">
                                            <AdminTab messageList={messageList} userLoginRx={userLoginRx}/>
                                            <div ref={messageEndRef} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        
                        <NotifChat 
                            countNotif={countNotif} 
                            notification={notification} 
                            seenNotification={seenNotification}
                            closePopup={closeChatPopUp}
                            scrollToBottom={scrollToBottom}
                            listClass={listClass}
                        />
                    </div>
                </section>
                
                <ChatInput 
                    role={role}
                    tabChat={tabChat}
                    primaryTeacherId={primaryTeacherId}
                    setMessage={setMessage}
                    MobileKeyboardPushUp={MobileKeyboardPushUp}
                    MobileKeyboardPushDown={MobileKeyboardPushDown}
                    sendMessage={sendMessage}
                    scrollToBottom={scrollToBottom}
                    currentChatRx={currentChatRx}
                    message={message}
                />
            </div>

        </>
    );
}

export default PopupChat;