import React from 'react';
import { Link } from 'react-router-dom';
import ClassMessage from '../../../components/ClassMessage';
import ModalAlertOperator from '../../../components/ModalAlertOperator';
import * as Const from './../../../constants/Const';
import * as Fc from './../../../utils/Functions';
import Code from './Item/Code';
import ModalShowInfoUser from './../../../components/ModalShowInfoUser/ModalShowInfoUser'
import Select from 'react-select';
import { customSelectStyles } from '../../../constants/Const';
import { Modal } from "react-bootstrap";
import './style.css';

export default function ClassView(props) {
    const role = Fc.getRole();
    const AccountMethod = Fc.getAccountMethod();
    const { userInfo,
        classId,
        classInfo,
        listStudents,
        enableAll, setEnableAllSolution, setEnableSolution,
        selectAll, selectAllUser, selectUser,
        setClass, isShowRestrictedModal, onHideRestrictedModal,
        firstStudentId, message, setMessage, setShowRestrictedModal, listClass,
        isShowInfoUser, setShowInfoUser,
        onHideInfoUser,
        setIdStudent, idUser,
        setTypeUser, typeUser, getListUser,
        userOptions, selectedUserForAddClass, selectedUser,
        onAddUser, onShowRemoveUserModal, hideRemoveUserModal,
        showRemoveUserModal, removeUser
    } = props
    
    listStudents.sort((a, b) => {
        if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
        if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
        return 0;
    })
    return (
        <>
            <div className="bg-xanh"></div>
            <div style={{ paddingTop: 51 }}>
                <div>
                    <section className="box-xam py-2 mb-3">
                        <div className="row">
                            <div className="col-6">
                                <div className="row justify-content-center">
                                    <div className="col-8 pl-0">
                                        <div className="input-group">
                                            <select className="custom-select dropdown-menu" value={classId} onChange={setClass}>
                                                {listClass && listClass.map((item, index) => {
                                                    return (
                                                        <option className={"dropdown-item py-5"} value={item._id} key={index.toString()}>{item.Name+' - '+item.Code}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col text-right">
                            </div>
                        </div>
                    </section>
                    <section className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="row justify-content-center">
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label htmlFor="usr" className="font-Paytone">Class Name*</label>
                                            <input type="text" className="form-control" value={classInfo.Name || ''} readOnly />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="usr" className="font-Paytone">Primary Teacher*</label>
                                            <input type="text" className="form-control" value={classInfo.PrimaryTeacher && classInfo.PrimaryTeacher.FirstName.charAt(0).toUpperCase() + classInfo.PrimaryTeacher.FirstName.slice(1)+' '+classInfo.PrimaryTeacher.LastName.charAt(0).toUpperCase() + classInfo.PrimaryTeacher.LastName.slice(1)} readOnly />
                                        </div>
                                        {/*<h4 class="text-uppercase text-center font-Paytone">ID: MATH09THANHQT1197</h4>*/}
                                    </div>
                                </div>
                                {/* <ClassMessage classId={classId} enableSendMessage={classInfo.PrimaryTeacher && userInfo._id !== classInfo.PrimaryTeacher._id} props /> */}
                            </div>
                            <div className="col">
                                <div className="p-4">
                                    <h4 className="font-Paytone tle23">Class List</h4>
                                    <div className="box-nd tran-60 posbox1">
                                        <div className="search-table z-5 position-absolute box-chon">
                                            <form className="" role="search">
                                                <div className="input-group">
                                                    <Select
                                                        className="form-control form-control-sista2 bol-10"
                                                        value={selectedUser}
                                                        options={userOptions}
                                                        onInputChange={getListUser}
                                                        onChange={(user) => {
                                                            selectedUserForAddClass(user)
                                                        }}
                                                        placeholder="Enter user's name or ID..."
                                                        styles={userDropdownStyles}
                                                        onFocus={getListUser}
                                                    />
                                                    <div className="input-group-append ml-20">
                                                        <button type='button' onClick={onAddUser} className="btn font-Paytone bg-30 btn-light2 radius10 pl-3 pr-3">Add</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="position-absolute tle-table tranTB-70 t-86">
                                            <div className="row">
                                                <div className="col-6 text-muted2">User Name</div>
                                                <div className="col-1 text-muted2 text-center" style={{paddingLeft: 0}}>Grade</div>
                                                <div className="col-3 text-muted2 text-center">Actions</div>
                                                <div className="col-2 text-muted2 text-center">Solution</div>
                                            </div>
                                        </div>
                                        <div className="hidden-scrollbar-scoll t-75 b-0">
                                            <div className="inner-scoll-edit-class-desktop">
                                                {
                                                    classInfo !== '' && listStudents.length > 0 &&
                                                    <div className="row mb-2" style={{marginRight: 0}}>
                                                        <div className="col-6">
                                                            <div className="align-middle">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="checkbox" className="custom-control-input" id="customRadio" checked={selectAll}
                                                                        onChange={e => selectAllUser(e.target.checked)} />
                                                                    <label className="custom-control-label" htmlFor="customRadio">Select All</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-1 text-right"></div>
                                                        <div className="col-3 text-right"></div>
                                                        <div className="col-2 text-center">
                                                            <span className="switch switch-xs" style={{paddingLeft: 25}}>
                                                                <input type="checkbox" className="switch" id="enableall" checked={enableAll}
                                                                    onChange={e => setEnableAllSolution(e.target.checked)} />
                                                                <label htmlFor="enableall"></label>
                                                            </span>
                                                        </div>
                                                    </div>}
                                                {listStudents && listStudents.sort().map((item, index) => {

                                                    return (
                                                        <div className="row mb-2" key={index.toString()} style={{marginRight: 0}}>
                                                            <div className="col-6">
                                                                <div className="align-middle">
                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                        <input type="checkbox" className="custom-control-input" id={`check${item._id}`} checked={item.Checked}
                                                                            onChange={(e) => selectUser(item._id, e.target.checked)} />
                                                                        <label style={{ textAlign: 'left' }} className="custom-control-label" htmlFor={`check${item._id}`}>
                                                                            <span>{item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)}</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-1 text-center" >{item.Grade ? item.Grade : 'N/A'}</div>
                                                            <div className="col-3 text-center">
                                                                {
                                                                    AccountMethod !== Const.AccountMethod.RESTRICTED ?
                                                                        <Link className="text-underline button-join" style={{paddingLeft: 15}} to={`/Teacher/Student/${item._id}/${classId}`}>Join</Link> :
                                                                        <Code item={item} classId={classId} firstStudentId={firstStudentId} setMessage={setMessage} setShowRestrictedModal={setShowRestrictedModal} />
                                                                }

                                                                <button
                                                                    className="text-underline button-join"
                                                                    data-toggle="modal"
                                                                    data-target="#myModal1"
                                                                    onClick={() => {
                                                                        setShowInfoUser(true)
                                                                        setIdStudent(item._id)
                                                                        setTypeUser("Student")
                                                                    }}
                                                                >View</button>

                                                            </div>
                                                            <div className="col-2 text-center">
                                                                <span className="switch switch-xs" style={{paddingLeft: 25}}>
                                                                    <input type="checkbox" className="switch" id={`idChk-normal${item._id}`} checked={item.EnableSolution}
                                                                        onChange={(e) => setEnableSolution(item._id, e.target.checked)} />
                                                                    <label htmlFor={`idChk-normal${item._id}`}></label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {classInfo.AssistantTeachers && classInfo.AssistantTeachers.map((item, index) => {
                                                    return (
                                                        <div className="row mb-2" key={index.toString()} style={{marginRight: 0}}>
                                                            <div className="col-5">
                                                                <div className="align-middle">
                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                        {item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4 text-right">

                                                            </div>
                                                            <div className="col-3 text-center"></div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="position-absolute bt-table"><button className="btn font-Paytone bg-30 btn-light1" style={{bottom: '22px !important'}} type="button" onClick={onShowRemoveUserModal}>Remove Users</button></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>

            </div>

            <ModalAlertOperator
                showModal={isShowRestrictedModal}
                alertMessage={message}
                role={role}
                AccountMethod={AccountMethod}
                keyStorage={'PopupChooseClassOfTeacher'}
                functionClose={onHideRestrictedModal}
                functionUpgrade={null}
            />

            {/*Modal show info account*/}
            {isShowInfoUser && <ModalShowInfoUser
                isShowInfoUser={isShowInfoUser}
                setShowInfoUser={setShowInfoUser}
                onHideInfoUser={onHideInfoUser}
                idUser={idUser}
                typeUser={typeUser}
            />}

            <Modal
                show={showRemoveUserModal}
                onHide={hideRemoveUserModal}
                className="modal fade"
                id="myModal"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-epen pte-auto">
                        <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                            <h4 className="modal-title font-Paytone">
                                Are you sure you want to remove?
              				</h4>
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 mr-auto">
                                    <button
                                        type="button"
                                        onClick={hideRemoveUserModal}
                                        className="btn btn-light font-Paytone btn-block"
                                    >
                                        Cancel
                  								</button>
                                </div>
                                <div className="col-5">
                                    <button
                                        type="button"
                                        onClick={removeUser}
                                        className="btn btn-light font-Paytone float-right btn-block"
                                    >
                                        <span className="text-danger">Confirm</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

const userDropdownStyles = {
    ...customSelectStyles,
    indicatorsContainer: (provided, state) => ({
        ...provided,
        display: 'none',
    })
}
