import React, { useState, useEffect, useRef } from 'react'
import * as Fc from '../../../utils/Functions'
import * as Const from '../../../constants/Const'
import WorksheetView from './WorksheetView'
import API from '../../../utils/Api'
import moment from 'moment'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import * as type from "../../../constants/actionType"

import useIsMounted from '../../../hooks/useIsMounted'
import useDidMount from '../../../hooks/useDidMount'

function Worksheet(props) {
    const dispatch = useDispatch();
    const role = Fc.getRole()
    const operatorId = props.operatorId
    // const [classId, setClassId] = useState(props.classId)
    const [assignmentDate, setAssignmentDate] = useState("")
    const [assignmentDateList, setAssignmentDateList] = useState([])
    const [listDocument, setListDocument] = useState([])
    const [showDeleteAssignMentModal, setShowDeleteAssignMentModal] = useState(false)
    const [selectedDocumentid, setSelectedDocumentid] = useState(null)
    const [pageIndex, setPageIndex] = useState(1)
    const pageSize = 20

    const [total, setTotal] = useState(0)
    const [banner, setBanner] = useState("")
    const [isShowDocumentAlertModal, setIsShowDocumentAlertModal] = useState(false)
    let classIdRedux = useSelector(state => state.ws.classId)
    let assignmentDateRedux = useSelector(state => state.ws.assignmentDate)
    let isNotReloadRx = useSelector(state => state.ws.isNotReload)

    let isMounted = useIsMounted()
    let didMount = useDidMount()

    function setClassId(id) {
        dispatch({ type: type.SAVE_CLASS_ID, classId: id })
    }
    window.onload = function() {
        if (window.innerWidth < 576) {
            document.getElementById('classSwitch').style.display = 'block';
        }
    }

    useEffect(() => {
        if(!isMounted.current) return
        getBanner()
        return () => {}
    }, [])

    useEffect(() => {
        if(!isMounted.current || !didMount) return
        refreshSearch()
    }, [assignmentDateRedux])
    // }, [assignmentDate])
    useEffect(() => {
        if(isNotReloadRx) return
        if(!isMounted.current) return
        if (props.listClass.length === 0) {
            setClassId("")
        } else {
            setClassId((props.listClass && props.listClass.length > 0) && props.listClass[0]._id)
        }
    }, [props.listClass])

    useEffect(() => {
        if(!isMounted.current || !didMount) return
        assignmentSearch()
    }, [pageIndex])

    useEffect(() => {
        if(!isMounted.current) return
        getAssignmentDateList()
        assignmentSearch()
    }, [classIdRedux, props.studentId])

    useEffect(() => {
        if(!isMounted.current) return
        // getAssignmentDateList()
    }, [props.studentId])
    //#region scroll load more document
    useEffect(() => {
        if(!isMounted.current) return
        const el = document.getElementById("wsScroll")
        el.addEventListener("scroll", loadMore)
        return function cleanupListener() {
            if(!isMounted.current) return
            el.removeEventListener("scroll", loadMore)
        }
    }, [pageIndex, assignmentDateRedux, classIdRedux, props.studentId])
    // }, [pageIndex, assignmentDate, classIdRedux, props.studentId])   

    function loadMore() {
        if(!isMounted.current) return
        const el = document.getElementById("wsScroll")
        if (el.scrollTop + el.clientHeight > el.scrollHeight - 100) {
            if (pageSize * pageIndex >= total) {
                return null
            } else {
                setPageIndex(pageIndex + 1)
            }
        }
    }
    //#endregion

    function assignmentSearch() {
        if(!isMounted.current) return
        if (assignmentDateRedux && classIdRedux) {
            API.assignmentSearch(props.studentId, classIdRedux, moment(assignmentDateRedux.substring(0, 10)).toISOString(), pageIndex, pageSize)
                .then((res) => {
                    if(!isMounted.current) return
                    if (res.status === 200 && res.data.Success && isMounted.current) {
                        let data = [...res.data.Data]
                        setListDocument(pageIndex === 1 ? data : listDocument.concat(data))
                        setTotal(res.data.Total)
                    } else {
                        Fc.showError(res.data.Message || res.statusText)
                    }
                })
                .catch(err => {
                    Fc.showError(err)
                })
        } else {
            setListDocument([])
            setTotal(0)
        }
    }

    function refreshSearch() {
        if (pageIndex !== 1)
            setPageIndex(1)
        else
            assignmentSearch()
    }

    function getBanner() {
        if(!isMounted.current) return
        API.getBannerByOperator(operatorId)
            .then((res) => {
                if(!isMounted.current) return
                if (res.status === 200 && res.data.Success && isMounted.current) {
                    setBanner(res.data.Data);
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            })
            .catch(err => {
                console.log(err);
                Fc.showError("Can not connect to server")
            })
    }

    function getAssignmentDateList() {
        if(!isMounted.current) return
        if (props.studentId && classIdRedux) {
            let differentTimezoneHour = (moment().utcOffset() / 60)
            API.getAssignmentDateList(props.studentId, classIdRedux, differentTimezoneHour)
                .then((res) => {
                    if(!isMounted.current) return
                    if (res.status === 200 && res.data.Success && isMounted.current) {
                        //
                        if(!isNotReloadRx){
                            dispatch({ type: type.SAVE_ASSIGNMENT_DATE, payload: res.data.Data[0] })
                            setAssignmentDate(res.data.Data[0]);
                        }
                        setAssignmentDateList(res.data.Data);
                    } else {
                        Fc.showError(res.data.Message || res.statusText)
                    }
                })
                .catch((err) => {
                    Fc.showError(err)
                })
        }
    }

    //#region delete
    function openDeleteAssignmentModal(id) {
        setSelectedDocumentid(id)
        setShowDeleteAssignMentModal(true)
    }

    function deleteAssignment() {
        API.deleteAssignment(selectedDocumentid).then(res => {
            if(!isMounted.current) return
            if (res.status === 200 && res.data.Success && isMounted.current) {
                Fc.showSuccess("Delete success")
                setShowDeleteAssignMentModal(false)
                if (listDocument.length === 1) {
                    getAssignmentDateList()
                } else {
                    assignmentSearch()
                }
            } else {
                Fc.showError(res.data.Message || res.statusText)
            }
        }).catch(err => {
            console.log(err);
            Fc.showError("Can not connect to server")
        })
    }

    function hideDeleteAssignMentModal() {
        setSelectedDocumentid('')
        setShowDeleteAssignMentModal(false)
    }
    //#endregion

    function onShowDocumentAlert() {
        setIsShowDocumentAlertModal(true)
    }

    function onHideDocumentAlert() {
        setIsShowDocumentAlertModal(false)
    }

    return (
        <WorksheetView
            {...props}
            role={role}
            classId={classIdRedux} setClassId={setClassId}
            assignmentDate={assignmentDate} setAssignmentDate={setAssignmentDate}
            assignmentDateList={assignmentDateList}
            listDocument={listDocument}
            openDeleteAssignmentModal={openDeleteAssignmentModal}
            showDeleteAssignMentModal={showDeleteAssignMentModal}
            hideDeleteAssignMentModal={hideDeleteAssignMentModal}
            deleteAssignment={deleteAssignment}
            banner={banner}
            onShowDocumentAlert={onShowDocumentAlert}
            isShowDocumentAlertModal={isShowDocumentAlertModal}
            onHideDocumentAlert={onHideDocumentAlert}
        />
    )
}

export default React.memo(Worksheet);