import React from "react"
import { Modal } from "react-bootstrap"
import TopNav from "../../Main/_TopNav"
import { Link } from "react-router-dom"
import * as Const from "../../../../constants/Const"
import PropTypes from "prop-types"
import { Formik, ErrorMessage } from "formik"
import * as Yup from "yup"
import Select, { components } from 'react-select'
import { customSelectStyles } from '../../../../constants/Const'

import useCheckdevice from '../../../../hooks/useCheckdevice'
import classNames from 'classnames'
import { isDesktop, isMobile } from "react-device-detect"
import './style.css'

function ClientUpdate(props) {
	const checkDevice = useCheckdevice()
	const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))
	  
	const {
		_id, UpdateClient, businessType,
		businessName, apt, setApt,
		houseNoAndStreet, setHouseNoAndStreet, province,
		setProvince, city, setCity,
		country, setCountry,
		postcode, setPostcode,
		firstName,
		setFirstName, lastName,
		setLastName, gender,
		setGender, phone, setPhone,
		accountId, bannerEdit,
		setBannerEdit, onChangeBusinessType,
		paidAccount, setPaidAccount,
		setShowDeleteModal, isShowModal,
		onHideModal, businessNameTemp,
		setBusinessNameTemp,
		isShowDeleteModal, onHideDeleteModal,
		deleteClient, confirmBusinessName, listRegion,
		listRegionByParent, listProvince,

		accountEnable, setAccountEnable,
		writeNote, setWriteNote,
		upload, setUpload,
		ResourceConcept, setResourceConcept,
		ResourceFoundation, setResourceFoundation,
		SolutionConcept, setSolutionConcept,
		SolutionFoundation, setSolutionFoundation,
		showModalSetting, setShowModalSetting,
	} = props

	const validateForm = Yup.object().shape({
		houseNoAndStreet: Yup.string().required("Required"),
		firstName: Yup.string().required("Required"),
		lastName: Yup.string().required("Required"),
		phone: Yup.string().required("Required").matches(Const.phoneRegExp, 'Invalid phone number format'),
	})
	return (
		<Formik
			initialValues={{
				houseNoAndStreet: houseNoAndStreet,
				postcode: postcode,
				firstName: firstName,
				lastName: lastName,
				phone: phone,
			}}
			enableReinitialize
			onSubmit={UpdateClient}
			validationSchema={validateForm}
		>
			{(properties) => {
				const {
					handleSubmit,
					handleChange,
					handleBlur,
				} = properties;
				return (
					<>
						<div className="bg-xanh"></div>
						<form style={{ paddingTop: 10 }} onSubmit={handleSubmit}>
							<input type="hidden" value={_id} />
							<nav className="header mb-4 sticky-top" id="banner">
								{isMobile && <Link className="bt-back" to="/Vendor">
									<i className="fas fa-arrow-left fa-3x"></i>
								</Link>}
								{!isMobile && isDesktop && <Link className="ml-5 font-Paytone font22 radius-0 text-white position-absolute" to="/Vendor">
									Back
                  				</Link>}
								<TopNav history={props.history} />
							</nav>
							<section>
								<div className={classNames("container-fluid", [{"container-fluid-desktop": isBrowserDesktop}])}>
									<div className="p-4 mb-3">
										<h3 className="font-Paytone text-center mb-4">
											{["School", "Business", "Free"].includes(Const.clientType.filter((x) => x.Value === businessType)[0].Type) ? businessName : (`${firstName.trim()} ${lastName.trim()}`)}
										</h3>
										<section className="form-row mb-2">
											<div className="col-3">
												<label className="font-Paytone">Apt</label>
												<input
													type="text"
													className="form-control"
													placeholder="Apt"
													value={apt}
													onChange={(e) => setApt(e.target.value)}
												/>
											</div>
											<div className="col-6">
												<label className="font-Paytone">
													House No. and Street Name
                  								</label>
												<input
													type="text"
													className="form-control"
													placeholder="House No. and Street Name"
													value={houseNoAndStreet}
													name="houseNoAndStreet"
													onBlur={handleBlur}
													onChange={(e) => {
														handleChange(e)
														setHouseNoAndStreet(e.target.value)
													}}
												/>
												<ErrorMessage name="houseNoAndStreet">{(msg) => <div className="validate-input mt-1">* {msg}</div>}</ErrorMessage>
											</div>
											<div className="col-3">
												<label className="font-Paytone">Province</label>
												<Select
													className="form-control form-control-sista2"
													value={province}
													options={listProvince}
													onInputChange={() => { setCity("") }}
													onChange={setProvince}
													placeholder="Province"
													styles={customSelectStyles}
													components={{ DropdownIndicator }}
												/>
											</div>
										</section>
										<section className="form-row mb-2">
											<div className="col-3">
												<label className="font-Paytone">City</label>
												<Select
													className="form-control form-control-sista2"
													value={city}
													options={listRegionByParent}
													onChange={setCity}
													placeholder="Enter City"
													styles={customSelectStyles}
													components={{ DropdownIndicator }}
												/>
											</div>
											<div className="col-6">
												<label className="font-Paytone">Country</label>
												<Select
													className="form-control form-control-sista2"
													value={country}
													options={listRegion}
													// onInputChange={}
													onChange={setCountry}
													placeholder="Country"
													styles={customSelectStyles}
													components={{ DropdownIndicator }}
												/>
											</div>
											<div className="col-3">
												<label className="font-Paytone">Postal Code</label>
												<input
													type="text"
													className="form-control"
													placeholder="Postal Code"
													value={postcode}
													name="postcode"
													onBlur={handleBlur}
													onChange={(e) => {
														handleChange(e)
														setPostcode(e.target.value)
													}}
												/>
												<ErrorMessage name="postcode">{(msg) => <div className="validate-input mt-1">* {msg}</div>}</ErrorMessage>
											</div>
										</section>
									</div>
								</div>
								<section className="box-xam mb-3">
									<section className={classNames("form-row", [{"form-row-desktop": isBrowserDesktop}])} style={{marginBottom: "0.4rem"}}>
										<div className="col-6 mb-2">
											<label className="font-Paytone">First name</label>
											<input
												type="text"
												className="form-control"
												placeholder="First name"
												value={firstName}
												name="firstName"
												onBlur={handleBlur}
												onChange={(e) => {
													handleChange(e)
													setFirstName(e.target.value)
												}}
											/>
											<ErrorMessage name="firstName">{(msg) => <div className="validate-input mt-1">* {msg}</div>}</ErrorMessage>
										</div>
										<div className="col-6 mb-2">
											<label className="font-Paytone">Last name</label>
											<input
												type="text"
												className="form-control"
												placeholder="Last Name"
												value={lastName}
												name="lastName"
												onBlur={handleBlur}
												onChange={(e) => {
													handleChange(e)
													setLastName(e.target.value)
												}}
											/>
											<ErrorMessage name="lastName">{(msg) => <div className="validate-input mt-1">* {msg}</div>}</ErrorMessage>
										</div>
										<div className="col-6">
											<label className="font-Paytone">Gender</label>
											<select
												className="custom-select"
												value={gender}
												onChange={(e) => {
													setGender(e.target.value)
												}}
											>
												<option value={"Male"}>Male</option>
												<option value={"Female"}>Female</option>
											</select>
										</div>
										<div className="col-6">
											<label className="font-Paytone">Phone number:</label>
											<input
												type="text"
												className="form-control"
												placeholder="Phone Number"
												value={phone}
												name="phone"
												onBlur={handleBlur}
												onChange={(e) => {
													handleChange(e)
													setPhone(e.target.value)
												}}
											/>
											<ErrorMessage name="phone">{(msg) => <div className="validate-input mt-1">* {msg}</div>}</ErrorMessage>
										</div>
									</section>
								</section>
								<section className={classNames("container-fluid", [{"container-fluid-desktop": isBrowserDesktop}])}>
									<div className="p-4">
										<section className="form-row align-items-center">
											<div className="col-6">
												<label className="font-Paytone">Login Email:</label>
												<br />
												{accountId}
												<br />
												<label className="font-Paytone mt-3">Account Type</label>
												<select
													className="custom-select"
													value={businessType}
													onChange={(e) => {
														onChangeBusinessType(e.target.value)
													}}
												>
													{Const.clientType.map((item, index) => {
														if (item.Value !== "") {
															return (
																<option value={item.Value} key={index.toString()}>
																	{item.Value}
																</option>
															)
														}
													})}
												</select>
											</div>
											<div className={classNames("col-3", "ml-auto", [{"col-3-desktop": isBrowserDesktop, "ml-12-5-percent": isBrowserDesktop}])}>
												<button
													type="button"
													className="btn btn-light font-Paytone btn-block mb-3"
													onClick={() => {
														setShowModalSetting(true)
													}}
												>
													Setting
												</button>

												<button
													type="button"
													className="btn btn-light font-Paytone btn-block mb-3"
													onClick={() => setShowDeleteModal(true)}
												>
													<span className="text-danger">Delete Client</span>
												</button>

												<button
													type="submit"
													className="btn btn-light font-Paytone float-right btn-block mb-3 mt-0"
													data-toggle="modal"
													data-target="#myModal"
												>
													Save
                								</button>

											</div>
										</section>
									</div>
								</section>

							</section>
						</form>

						{/* Modal Setting */}
						<Modal
							show={showModalSetting}
							className=""
							id="myModal"
							dialogClassName="custom-modal"
							onHide={() => { setShowModalSetting(false) }}
						>
							<div style={{ width: '100%', pointerEvents: 'auto' }} className="modal-dialog modal-dialog-centered modal-xl">
								<div className={classNames("modal-content", "bg-epen", [{"modal-desktop": isBrowserDesktop}])}>
									{/* Modal Header */}
									<div className="modal-header border-bottom-0">
										<div className="p-2">
											<label className="font-Paytone font18 mr-3">Account enable</label>
											<span className="switch mr-4">
												<input type="checkbox" className="switch" id="AccountEnable"
													checked={accountEnable}
													onChange={(e) => {
														setAccountEnable(e.target.checked)
													}}
												/>
												<label htmlFor="AccountEnable" />
											</span>
											<label className="font18 mr-3">Paid Account</label>
											<span className="switch">
												<input type="checkbox" className="switch" id="isPaidAccount" checked={paidAccount}
													onChange={(e) => setPaidAccount(e.target.checked)}
												/>
												<label htmlFor="isPaidAccount"></label>
											</span>
										</div>
										<button type="button" className="close close-im" data-dismiss="modal"
											onClick={() => { setShowModalSetting(false) }}
										>
											×
                                </button>
									</div>
									{/* Modal body */}
									<div className="modal-body box-xam p-4">
										<div className="row">
											<div className="col">
												<h4 className="font-Paytone font18 mb-3">Banner Control</h4>
												<div className="row">
													<div className="col-5">	
														<label className="float-left font-14"><small>Banner Edit</small></label>
													</div>
													<div className="col-7 float-left">
														<span className="switch switch-sm">
															<input type="checkbox" className="switch" id="isBannerEdit" checked={bannerEdit}
																onChange={(e) => {
																	setBannerEdit(e.target.checked)
																}}
															/>
															<label htmlFor="isBannerEdit"></label>
														</span>
													</div>
												</div>
											</div>
											<div className="col">
												<h4 className="font-Paytone font18 mb-3">Features Control</h4>
												<div className="row">
													<div className="col-5">
														<label className="float-left"><small>Note</small></label>
													</div>
													<div className="col-7 float-left">
														<span className="switch switch-sm">
															<input type="checkbox" className="switch" id="note"
																checked={writeNote}
																onChange={(e) => {
																	setWriteNote(e.target.checked)
																}}
															/>
															<label htmlFor="note" />
														</span>
													</div>
												</div>
												<div className="row">
													<div className="col-5">
														<label className="float-left"><small>Upload</small></label>
													</div>
													<div className="col-7 float-left">
														<span className="switch switch-sm">
															<input type="checkbox" className="switch" id="upload"
																checked={upload}
																onChange={(e) => {
																	setUpload(e.target.checked)
																}}
															/>
															<label htmlFor="upload" />
														</span>
													</div>
												</div>
											</div>

											<div className="col">
												<h4 className="font-Paytone font18 mb-3">Resource</h4>
												<div className="row">
													<div className="col-5">
														<label className="float-left"><small>Foundation</small></label>
													</div>

													<div className="col-7 float-left">
														<span className="switch switch-sm">
															<input type="checkbox" className="switch" id="ResourceFoundation"
																checked={ResourceFoundation}
																onChange={(e) => {
																	setResourceFoundation(e.target.checked)
																}}
															/>
															<label htmlFor="ResourceFoundation" />
														</span>
													</div>
												</div>

												<div className="row">
													<div className="col-5">
														<label className="float-left"><small>Concept</small></label>
													</div>

													<div className="col-7 float-left">
														<span className="switch switch-sm">
															<input type="checkbox" className="switch" id="ResourceConcept"
																checked={ResourceConcept}
																onChange={(e) => {
																	setResourceConcept(e.target.checked)
																}}
															/>
															<label htmlFor="ResourceConcept" />
														</span>
													</div>
												</div>
											</div>

											<div className="col">
												<h4 className="font-Paytone font18 mb-3">Solution</h4>
												<div className="row">
													<div className="col-5">
														<label className="float-left"><small>Foundation</small></label>
													</div>

													<div className="col-7 float-left">
														<span className="switch switch-sm">
															<input type="checkbox" className="switch" id="SolutionFoundation"
																checked={SolutionFoundation}
																onChange={(e) => {
																	setSolutionFoundation(e.target.checked)
																}}
															/>
															<label htmlFor="SolutionFoundation" />
														</span>
													</div>
												</div>

												<div className="row">
													<div className="col-5">
														<label className="float-left"><small>Concept</small></label>
													</div>

													<div className="col-7 float-left">
														<span className="switch switch-sm">
															<input type="checkbox" className="switch" id="SolutionConcept"
																checked={SolutionConcept}
																onChange={(e) => {
																	setSolutionConcept(e.target.checked)
																}}
															/>
															<label htmlFor="SolutionConcept" />
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* Modal footer */}
									<div className="modal-footer block border-top-0">
										<div className="text-center mb-0">
											<button className="btn btn-light font-Paytone btn-lg pl-5 pr-5"
												type="submit"
												onClick={() => {
													setShowModalSetting(false)
													UpdateClient()
												}}
											>Save</button>
										</div>
									</div>
								</div>
							</div>
						</Modal>
						{/* End Modal Setting */}

						<Modal
							show={isShowModal}
							onHide={onHideModal}
							className="modal fade"
							id="myModal"
						>
							<div className="modal-dialog modal-dialog-centered">
								<div className="modal-content bg-epen pte-auto">
									<div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
										<h4 className="modal-title font-Paytone">Enter Business name</h4>
									</div>

									<div className="modal-body pl-5 pr-5">
										<input
											type="text"
											className="form-control"
											placeholder=""
											value={businessNameTemp}
											onChange={(e) => setBusinessNameTemp(e.target.value)}
										/>
									</div>
									<div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
										<div className="row">
											<div className="col-5 mr-auto">
												<button
													type="button"
													onClick={onHideModal}
													className="btn btn-light font-Paytone btn-block"
												>
													Cancel
                  								</button>
											</div>
											<div className="col-5">
												<button
													type="button"
													onClick={confirmBusinessName}
													className="btn btn-light font-Paytone float-right btn-block"
												>
													Confirm
                  								</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Modal>

						<Modal
							show={isShowDeleteModal}
							onHide={onHideDeleteModal}
							className="modal fade"
							id="myModal"
						>
							<div className="modal-dialog modal-dialog-centered">
								<div className="modal-content bg-epen pte-auto">
									<div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
										<h4 className="modal-title font-Paytone">
											Are you sure you want to delete this client?
              							</h4>
									</div>
									<div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
										<div className="row">
											<div className="col-5 mr-auto">
												<button
													type="button"
													onClick={onHideDeleteModal}
													className="btn btn-light font-Paytone btn-block"
												>
													Cancel
                  								</button>
											</div>
											<div className="col-5">
												<button
													type="button"
													onClick={deleteClient}
													className="btn btn-light font-Paytone float-right btn-block"
												>
													<span className="text-danger">Confirm</span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Modal>
					</>
				)
			}}
		</Formik >
	)
}

const DropdownIndicator = props => {
	return (
		<components.DropdownIndicator {...props}>
		</components.DropdownIndicator>
	);
};

export default ClientUpdate
