import React, { useState } from 'react';
import { Modal } from "react-bootstrap"
import * as Const from '../../constants/Const'

ModalAlertOperator.defaultProps = {
    hideRadioButton: true
}

function ModalAlertOperator(props) {
    const {
        showModal,
        alertMessage, functionClose,
        functionUpgrade,
        role, AccountMethod, keyStorage, hideRadioButton
    } = props

    const [radioHidePopup, setRadioHidePopup] = useState(false)

    let storageKey = localStorage.getItem(keyStorage)
    if ((role !== Const.Role.VENDOR && (AccountMethod === Const.AccountMethod.TRIALS && !storageKey)) || AccountMethod === Const.AccountMethod.RESTRICTED)
        return (
            <>
                <Modal
                    show={showModal}
                    onHide={functionClose}
                    className="modal fade"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content bg-epen pte-auto">
                            <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                                <h4 className="modal-title font-Paytone">
                                    {alertMessage}
                                </h4>

                            </div>
                            {((!storageKey && hideRadioButton) && AccountMethod === Const.AccountMethod.TRIALS && keyStorage !== 'PopupAssignWorksheet') && (
                                <div style={{ width: '100%' }} className="custom-control custom-radio custom-control-inline">
                                    <input type="checkbox" className="custom-control-input m-auto" id="radioHidePopup" checked={radioHidePopup} onChange={(e) => setRadioHidePopup(e.target.checked)} />
                                    <label className="custom-control-label m-auto" htmlFor="radioHidePopup">Do not show this again</label>
                                </div>
                            )}
                            <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                                <div className="row">
                                    <div className="col-5 m-auto">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (radioHidePopup) {
                                                    localStorage.setItem(keyStorage, true)
                                                }
                                                functionClose()
                                            }}
                                            className="btn btn-light font-Paytone btn-block"
                                        >
                                            <span className="text-danger">Close</span>
                                        </button>
                                    </div>
                                    {role === Const.Role.OPERATOR && <div className="col-5 m-auto">
                                        <button
                                            type="button"
                                            className="btn btn-light font-Paytone btn-block"
                                            onClick={functionUpgrade}
                                        >
                                            <span className="text-danger">Upgrade</span>
                                        </button>
                                    </div>}
                                    {(role === Const.Role.TEACHER || role === Const.Role.STUDENT) && <div className="col-5 m-auto">
                                        <button
                                            type="button"
                                            className="btn btn-light font-Paytone btn-block"
                                            onClick={functionUpgrade}
                                        >
                                            <span className="text-danger">Contact Admin</span>
                                        </button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    return null
}

export default ModalAlertOperator;