/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import API from '../../../../utils/Api'
import * as Fc from '../../../../utils/Functions'
import * as Const from "../../../../constants/Const"
// import moment from 'moment'
import { useRouteMatch, useHistory } from "react-router-dom";
import EditClassForm from './EditClassForm'
import EditClassDesktop from './EditClassDesktop'
import useIsMounted from '../../../../hooks/useIsMounted'

import useCheckdevice from '../../../../hooks/useCheckdevice'
import { isDesktop, isMobile } from "react-device-detect"

function EditClass(props) {
    let history = useHistory();
    const accountMethod = Fc.getAccountMethod()
    const operatorId = Fc.getRole().includes(Const.Role.VENDOR) ? props.match.params.id : Fc.getOperator()

    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))

    // const accountMethod = sessionStorage.getItem('AccountMethod')
    const matchUrl = useRouteMatch()
    const [name, setName] = useState('')
    const [classOptions, setClassOption] = useState([])
    const [classId, setClassId] = useState('')
    const [classInfo, setClassInfo] = useState('')
    const [teacherOptions, setTeacherOption] = useState([])
    const [primaryTeacher, setPrimaryTeacher] = useState('')
    const [listStudents, setListStudents] = useState([])
    const [listTeachers, setListTeachers] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [selectedUser, setSelectedUser] = useState('')
    const [exceedIds, setExceedIds] = useState([])
    
    const [dayLeft, setDayLeft] = useState(0)
    const [isActive, setIsActive] = useState(true)
    const [enableAll, setEnableAll] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [showRemoveUserModal, setShowRemoveUserModal] = useState(false)
    const [isShowErrorModal, setShowErrorModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [firstStudentId, setFirstStudentId] = useState("")
    const [firstClassId, setFirstClassId] = useState("")
    const [firstTeacherId, setfirstTeacherId] = useState("")
    const [isShowTrialModal, setShowTrialModal] = useState(false)
    const [messageDayleftTeacherOrStudent, setMessageDayleftTeacherOrStudent] = useState('')

    const [isShowRestrictionModal, setShowRestrictionModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [isShowInfoUser, setShowInfoUser] = useState(false)
    const [idUser, setIdStudent] = useState("")
    const [typeUser, setTypeUser] = useState("")
    const [isDisable, setIsDisable] = useState(false)

    const  isMounted = useIsMounted()

    useEffect(() => {
        return () => {}
    }, [])

    function onHideInfoUser() {
        setShowInfoUser(false)
    }

    function onHideRestrictionModal() {
        setShowRestrictionModal(false)
    }

    useEffect(() => {
        let classID = { _id: '' }
        classID._id = props.match.params.classID
        props.match.params.classID !== undefined && detailClass(classID)
    }, [props.match.params.classID])



    function addParams(cl) {
        document.getElementById('shadow-overlay').style.display = 'none';
        if (props.match.params.classID === undefined) {
            history.push(`${matchUrl.url}/${cl._id}`)
        } else {
            history.push(`${matchUrl.url.substring(0, matchUrl.url.length - (cl._id.length + 1))}/${cl._id}`)
        }
    }

    useEffect(() => {
        if(!isMounted.current) return 
        // get firstStudentId
        API.GetFirstStudentAccount(operatorId)
            .then((res) => {
                if (res.status === 200 && isMounted.current) {
                    if (res.data.Success) {
                        setFirstStudentId(res.data.Data._id);
                    }
                }
            })
            .catch((err) => {
                Fc.showError(err)
            })

        // get firstClassId
        API.GetFirstClassOfOperator(operatorId)
            .then((res) => {
                if (res.status === 200 && isMounted.current) {
                    if (res.data.Success) {
                        setFirstClassId(res.data.Data._id);
                    }
                } 
            })
            .catch((err) => {
                Fc.showError(err)
            })

        // get firstTeacherId
        API.GetFirstTeacherAccount(operatorId)
            .then((res) => {
                if (res.status === 200 && isMounted.current) {
                    if (res.data.Success) {
                        setfirstTeacherId(res.data.Data._id);
                    }
                }
            })
            .catch((err) => {
                Fc.showError(err)
            })

        // get dayleft 
        API.GetDayLeftToRestriction(operatorId)
            .then((res) => {
                if (res.status === 200 && isMounted.current) {
                    if (res.data.Success) {
                        if (res.data.Data) {
                            setDayLeft(res.data.Data);
                        }
                    }
                }
            })
            .catch((err) => {
                Fc.showError(err)
            })
    }, [])

    useEffect(() => {
        let exceedStudents = listStudents.map(item => item._id)
        let exceedTeachers = listTeachers.map(item => item._id)
        let exceedUsers = exceedStudents.concat(exceedTeachers)
        if (primaryTeacher !== '')
            exceedUsers.push(primaryTeacher._id)
        setExceedIds(exceedUsers)
    }, [primaryTeacher, listTeachers, listStudents])

    function onHideTrialModal() {
        setShowTrialModal(false)
    }

    function onHideErrorModal() {
        setShowErrorModal(false)
    }

    function getListClass(val) {
        API.listClass(operatorId, 1, 20, val).then(res => {
            if (res.status === 200 && res.data.Success && isMounted.current) {
                let listOptions = []
                res.data.Data.map(item => (
                    listOptions.push({ value: item._id, label: `${item.Name+' - '+item.Code}`, ...item })
                ))
                setClassOption(listOptions.sort((a, b) => {
                    if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
                    if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
                    return 0;
                  }))
            }
    })
    }

    function getListTeacher(val) {
        API.listTeachers(operatorId, 1, 20, val).then(res => {
            if (res.status === 200 && res.data.Success && isMounted.current) {
                let listOptions = []
                res.data.Data.map(item => (
                    listOptions.push({ value: item._id, label: `${item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1) +' - '+ item.Code}`, ...item })
                ))
                setTeacherOption(listOptions.sort((a, b) => {
                    if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
                    if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
                    return 0;
                  }))
            }
        })
    }

    function getListUser(val) {
        //chan ky tu dac biet
        if(val && typeof val === 'string' && val.length > 0 && (val.includes('/') || val.includes("\\"))) return 

        API.listUsers(operatorId, 1, 20, val, exceedIds).then(res => {
            if (res.status === 200 && isMounted.current) {
                if (res.data.Success) {
                    let listOptions = []
                    res.data.Data.Students && res.data.Data.Students.map(item => {
                        item.Type = "Student"
                        listOptions.push({ value: item._id, label: `${item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)} - ${item.Code}`, ...item })
                    })
                    res.data.Data.Teachers && res.data.Data.Teachers.map(item => {
                        item.Type = "Teacher"
                        listOptions.push({ value: item._id, label: `${item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)} - ${item.Code}`, ...item })
                    })
                    setUserOptions(listOptions.sort((a, b) => {
                        if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
                        if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
                        return 0;
                      }))
                }
                else
                    Fc.showError(res.data.Message)
            } else {
            
            }
        }).catch(err => console.log(err))
    }

    /**
     * onChooseClass
     */

    function detailClass(cl) {
        API.detailClass(cl._id).then(res => {
            if (res.status === 200 && isMounted.current) {
                if (res.data.Success) {
                    let data = res.data.Data
                    setClassId(data._id)
                    setQuickUpdateStates(data)
                    setName(data.Name)
                    setTeacherOption([{ value: data.PrimaryTeacher._id, label: `${data.PrimaryTeacher.FirstName.charAt(0).toUpperCase() + data.PrimaryTeacher.FirstName.slice(1)+' '+data.PrimaryTeacher.LastName.charAt(0).toUpperCase() + data.PrimaryTeacher.LastName.slice(1)}`, ...data.PrimaryTeacher }])
                    setPrimaryTeacher(data.PrimaryTeacher)
                    setIsActive(data.IsActive)
                } else
                    Fc.showError(res.data.Message)
            } else {

            }
            document.getElementById('shadow-overlay').style.display = 'none';
        }).catch(err => {
            Fc.showError("Can not connect to server")
        })
    }

    function onChooseClass(cl) {
        if (accountMethod === 'Restricted' && firstClassId !== cl._id) {
            showRestrictionMessage("This feature is locked. Upgrade now to use all Epen App features")
            addParams(cl)
        } else
            addParams(cl)
    }
    /**
     *  end
     */

    function showRestrictionMessage(mes) {
        setErrorMessage(mes)
        setShowRestrictionModal(true)
    }

    function checkTeacherDisable(e) {
        switch (accountMethod) {
            case Const.AccountMethod.RESTRICTED:
                if (firstTeacherId !== e._id) {
                    showRestrictionMessage("This teacher account has been locked.")
                } else
                    setPrimaryTeacher(e)
                break;
            case Const.AccountMethod.TRIALS:
                setPrimaryTeacher(e)
                setMessageDayleftTeacherOrStudent("This teacher account will be blocked after " + dayLeft + " days")
                setShowTrialModal(true)
                break;
            default:
                setPrimaryTeacher(e)
                break;
        }
    }

    function checkClassName(e) {
        if (accountMethod === 'Restricted' && classInfo._id !== firstClassId) {
            if (name !== "") {
                showRestrictionMessage("You need to upgrade to use this features")
            }
        } else
            setName(e.target.value)
    }

    function checkActiveClass(e) {
        if (accountMethod === 'Restricted' && classInfo._id !== firstClassId) {
            showRestrictionMessage("You need to upgrade to use this features")
        } else
            setIsActive(e.target.checked)
    }

    function selectedUserForAddClass(user) {
        if (accountMethod === Const.AccountMethod.RESTRICTED && user.Type === "Student" && firstStudentId !== user._id) {
            showRestrictionMessage("This student account has been locked.")
        }
        if (accountMethod === Const.AccountMethod.RESTRICTED && user.Type === "Teacher" && firstTeacherId !== user._id) {
            showRestrictionMessage("This teacher account has been locked.")
        } else {
            setSelectedUser(user)
        }

    }

    function checkEnableAllSolution(e) {
        if (accountMethod === 'Restricted' && classInfo._id !== firstClassId) {
            if (listStudents.length > 0) {
                showRestrictionMessage("You need to upgrade to use this features")
            }
        } else
            setEnableAllSolution(e.target.checked)
    }

    function updateClass() {
        if (!classInfo || classInfo === {}) return
        if(isDisable) return

        //====== validate name ======
        if(!name.match(Const.classNameRegExpVn)){
            return
        }

        if(!name.match(Const.whiteSpaceReg)) {
            return
        }
        //============================
        if (!primaryTeacher || primaryTeacher === '' || primaryTeacher === {}) {
            Fc.showError("Please choose primary teacher!")
            return
        }
        if (accountMethod === Const.AccountMethod.RESTRICTED && classInfo._id !== firstClassId) {
            showRestrictionMessage("You need to upgrade to use this features")
            return;
        }
        let body = {
            _id: classInfo._id,
            Name: name,
            PrimaryTeacherId: primaryTeacher._id,
            IsActive: isActive,
            OperatorId: operatorId,
            AssistantTeacherIds: [...classInfo.AssistantTeacherIds],
            Students: [...classInfo.Students]
        }
        setIsDisable(true)

        API.UpdateClass(body).then(res => {
            if(!isMounted.current) return 
            if (res.status === 200 && res.data.Success && isMounted.current) {
                Fc.showSuccess("Update class success")
                let data = res.data.Data
                setClassInfo({
                    value: data._id,
                    label: `${data.Name+' - '+data.PrimaryTeacher.FullName}`,
                    _id: data._id,
                    Name: data.Name,
                    PrimaryTeacherId: data.PrimaryTeacher._id,
                    OperatorId: operatorId,
                    AssistantTeacherIds: data.AssistantTeacherIds ? data.AssistantTeacherIds.map(item => item._id) : [],
                    Students: data.Students
                })
                setIsDisable(false)
            } else {
                if (res.data.Message) {
                    if (res.data.Message === "Class Name already exist")
                        setShowErrorModal(true)
                    else
                        Fc.showError(res.data.Message)
                }
            }
            setIsDisable(false)
        }).catch(err => setIsDisable(false))
    }

    function onShowDeleteModal() {
        if (!classInfo || classInfo === '' || classInfo === {})
            return
        setShowDeleteModal(true)
    }

    function hideDeleteModal() {
        setShowDeleteModal(false)
    }

    function deleteClass() {
        if (!classInfo || classInfo === '' || classInfo === {})
            return
        API.DeleteClass(classInfo._id).then(res => {
            if (res.status === 200 && isMounted.current) {
                if (res.data.Success) {
                    Fc.showSuccess('Delete class success')
                    setClassId('')
                    setClassInfo('')
                    setName('')
                    setPrimaryTeacher('')
                    setListStudents([])
                    setListTeachers([])
                    setIsActive(false)
                    setSelectedUser('')
                    setSelectAll(false)
                    setEnableAll(false)
                    setShowDeleteModal(false)
                    getListClass()
                } else
                    Fc.showError(res.data.Message)
            } else {

            }
        })
    }
    //set some state always reload after update
    function setQuickUpdateStates(data) {
        setClassInfo({
            value: data._id,
            label: `${data.Name+' - '+data.PrimaryTeacher.FullName}`,
            _id: data._id,
            Name: data.Name,
            PrimaryTeacherId: data.PrimaryTeacher._id,
            OperatorId: operatorId,
            AssistantTeacherIds: data.AssistantTeachers ? data.AssistantTeachers.map(item => item._id) : [],
            Students: data.Students,
            IsActive: data.IsActive
        })
        setListStudents(data.Students.sort(function (a, b) { return a.Code > b.Code ? 1 : -1 }))
        setListTeachers(data.AssistantTeachers.sort(function (a, b) { return a.Code > b.Code ? 1 : -1 }))
    }
    //#region solution
    function setEnableSolution(id, enable) {
        if (accountMethod === 'Restricted' && classInfo._id !== firstClassId) {
            showRestrictionMessage("This feature is locked. Upgrade now to use all Epen App features")
            return
        }

        setEnableAll(false)
        let students = [...listStudents]
        let student = students.filter(x => x._id === id)[0]
        let submitStudents = []
        if (student.Checked) {
            let checkedStudents = students.filter(x => x.Checked === true)
            checkedStudents.map(item => {
                item.EnableSolution = enable
            })
            submitStudents = checkedStudents
        } else {
            student.EnableSolution = enable
            submitStudents = [student]
        }
        onSetEnableSolution(submitStudents, students)
    }

    function setEnableAllSolution(enable) {
        if (!listStudents || listStudents.length === 0) {
            setEnableAll(false)
            return
        }
        setEnableAll(enable)
        let students = [...listStudents]
        students.map(item => {
            item.EnableSolution = enable
        })
        onSetEnableSolution(students)
    }

    //change view before submit, then revert if error
    function onSetEnableSolution(submitStudents, afterSubmitStudents) {
        let originStudents = Fc.deepCopy(listStudents)
        if (!afterSubmitStudents)
            afterSubmitStudents = submitStudents
        setListStudents(afterSubmitStudents)
        let body = {
            ClassId: classId,
            Students: submitStudents
        }
        API.updateStudentSolution(body).then(res => {
            if(!isMounted.current) return
            if (res.status === 200 && res.data.Success && isMounted.current) {
                setQuickUpdateStates(res.data.Data)
                setSelectAll(false)
            } else {
                setListStudents(originStudents)
                if (res.data.Message)
                    Fc.showError(res.data.Message)
            }
        })
    }
    //#endregion

    function selectUser(id, type, selected) {
        setSelectAll(false)
        switch (type) {
            case 'Student':
                let students = [...listStudents]
                let student = students.filter(x => x._id === id)[0]
                student.Checked = selected
                setListStudents(students)
                break;
            case 'Teacher':
                if (accountMethod === Const.AccountMethod.TRIALS && selected === true) {
                    setMessageDayleftTeacherOrStudent("This teacher account will be blocked after " + dayLeft + " days")
                    setShowTrialModal(true)
                }
                let teachers = [...listTeachers]
                let teacher = teachers.filter(x => x._id === id)[0]
                teacher.Checked = selected
                setListTeachers(teachers)
                break;
            default:
                break;
        }
    }

    function selectAllUser(selected) {
        if ((!listStudents || listStudents.length === 0) && (!listTeachers || listTeachers.length === 0)) {
            setSelectAll(false)
            return
        }
        setSelectAll(selected)
        let students = [...listStudents]
        students.map(item => {
            item.Checked = selected
        })
        setListStudents(students)

        let teachers = [...listTeachers]
        teachers.map(item => {
            item.Checked = selected
        })
        setListTeachers(teachers)
    }

    //#region add-remove user
    function onAddUser() {
        if (!classInfo || classInfo === '' || classInfo === {})
            return
        if (accountMethod === Const.AccountMethod.RESTRICTED && selectedUser) {
            showRestrictionMessage("You need to upgrade to use this features")
            return
        }

        if (accountMethod === Const.AccountMethod.TRIALS && selectedUser !== '') {
            if (selectedUser.Type === 'Teacher') {
                setMessageDayleftTeacherOrStudent("This teacher account will be blocked after " + dayLeft + " days")
                setShowTrialModal(true)
            }
            // if (selectedUser.Type === 'Student') {
            //     setMessageDayleftTeacherOrStudent("This student account will be blocked after " + dayLeft + " days")
            //     setShowTrialModal(true)
            // }
        }
        setSelectedUser('')

        let body = Fc.deepCopy(classInfo)
        //change view before submit, then revert if error
        if (selectedUser.Type === 'Student') {
            if (listStudents.filter(x => x._id === selectedUser._id).length > 0)
                return
            let students = [...listStudents]
            students.push(selectedUser)
            setListStudents(students.sort(function (a, b) { return a.Code > b.Code ? 1 : -1 }))
            body.Students.push(selectedUser)
        }
        if (selectedUser.Type === 'Teacher') {
            if (listTeachers.filter(x => x._id === selectedUser._id).length > 0 || primaryTeacher._id === selectedUser._id)
                return
            let teachers = [...listTeachers]
            teachers.push(selectedUser)
            setListTeachers(teachers.sort(function (a, b) { return a.Code > b.Code ? 1 : -1 }))
            body.AssistantTeacherIds.push(selectedUser._id)
        }
        API.UpdateClass(body).then(res => {
            if(!isMounted.current) return
            if (res.status === 200 && res.data.Success) {
                setQuickUpdateStates(res.data.Data)
            } else {
                //revert data
                if (selectedUser.Type === 'Student') {
                    setListStudents(listStudents.filter(x => x._id !== selectedUser._id))
                }
                if (selectedUser.Type === 'Teacher') {
                    setListTeachers(listTeachers.filter(x => x._id !== selectedUser._id))
                }
                if (res.data.Message)
                    Fc.showError(res.data.Message)
            }
        })
        setEnableAll(false)
        setSelectAll(false)
    }

    function onShowRemoveUserModal() {
        let checkedStudents = listStudents ? [...listStudents].filter(x => x.Checked === true) : []
        let checkedTeachers = listTeachers ? [...listTeachers].filter(x => x.Checked === true) : []
        if (checkedStudents.length === 0 && checkedTeachers.length === 0)
            return
        setShowRemoveUserModal(true)
    }

    function hideRemoveUserModal() {
        setShowRemoveUserModal(false)
    }

    function removeUser() {
        if(!isMounted.current) return
        if (accountMethod === 'Restricted') {
            showRestrictionMessage("You need to upgrade to use this features")
            hideRemoveUserModal()
            return
        }
        let checkedStudents = listStudents ? [...listStudents].filter(x => x.Checked === true) : []
        let checkedTeachers = listTeachers ? [...listTeachers].filter(x => x.Checked === true) : []
        if (checkedStudents.length === 0 && checkedTeachers.length === 0)
            return

        let body = Fc.deepCopy(classInfo)
        checkedStudents.length > 0 && checkedStudents.map(item => {
            body.Students = body.Students.filter(x => x._id !== item._id)
        })
        checkedTeachers.length > 0 && checkedTeachers.map(item => {
            body.AssistantTeacherIds = body.AssistantTeacherIds.filter(x => x !== item._id)
        })
        API.UpdateClass(body).then(res => {
            if (res.status === 200 && isMounted.current) {
                if (res.data.Success) {
                    setQuickUpdateStates(res.data.Data)
                    setSelectAll(false)
                } else
                    Fc.showError(res.data.Message)
            } else {
                // Fc.showError(res.statusText)
            }
        })
        setShowRemoveUserModal(false)
    }
    //#endregion
    if (isDesktop && !isMobile) {
        return (
            <EditClassDesktop
                primaryTeacher={primaryTeacher} teacherOptions={teacherOptions}
                getListTeacher={getListTeacher} setPrimaryTeacher={setPrimaryTeacher}
                classOptions={classOptions} getListClass={getListClass}
                classInfo={classInfo} onChooseClass={onChooseClass}
                name={name} setName={setName}
                selectedUser={selectedUser} userOptions={userOptions} getListUser={getListUser}
                setSelectedUser={setSelectedUser}
                onAddUser={onAddUser} selectAll={selectAll} selectAllUser={selectAllUser}
                enableAll={enableAll} setEnableAllSolution={setEnableAllSolution} setEnableSolution={setEnableSolution} listStudents={listStudents}
                selectUser={selectUser} listTeachers={listTeachers} onShowRemoveUserModal={onShowRemoveUserModal}
                isActive={isActive} setIsActive={setIsActive} updateClass={updateClass} onShowDeleteModal={onShowDeleteModal} showRemoveUserModal={showRemoveUserModal}
                hideRemoveUserModal={hideRemoveUserModal} removeUser={removeUser}
                showDeleteModal={showDeleteModal} hideDeleteModal={hideDeleteModal} deleteClass={deleteClass}
                isShowErrorModal={isShowErrorModal} onHideErrorModal={onHideErrorModal}
                matchUrl={matchUrl} firstStudentId={firstStudentId}
                isShowRestrictionModal={isShowRestrictionModal} onHideRestrictionModal={onHideRestrictionModal}
                errorMessage={errorMessage} setErrorMessage={setErrorMessage} setShowRestrictionModal={setShowRestrictionModal}
                accountMethod={accountMethod} firstTeacherId={firstTeacherId}
                checkTeacherDisable={checkTeacherDisable} checkClassName={checkClassName}
                checkActiveClass={checkActiveClass} checkEnableAllSolution={checkEnableAllSolution}
                onHideTrialModal={onHideTrialModal} isShowTrialModal={isShowTrialModal}
                messageDayleftTeacherOrStudent={messageDayleftTeacherOrStudent}
                selectedUserForAddClass={selectedUserForAddClass}
                setShowInfoUser={setShowInfoUser}
                onHideInfoUser={onHideInfoUser}
                isShowInfoUser={isShowInfoUser}
                idUser={idUser}
                setIdStudent={setIdStudent}
                setTypeUser={setTypeUser}
                typeUser={typeUser}
            />
        )
    }

    return (
        <EditClassForm
            primaryTeacher={primaryTeacher} teacherOptions={teacherOptions}
            getListTeacher={getListTeacher} setPrimaryTeacher={setPrimaryTeacher}
            classOptions={classOptions} getListClass={getListClass}
            classInfo={classInfo} onChooseClass={onChooseClass}
            name={name} setName={setName}
            selectedUser={selectedUser} userOptions={userOptions} getListUser={getListUser}
            setSelectedUser={setSelectedUser}
            onAddUser={onAddUser} selectAll={selectAll} selectAllUser={selectAllUser}
            enableAll={enableAll} setEnableAllSolution={setEnableAllSolution} setEnableSolution={setEnableSolution} listStudents={listStudents}
            selectUser={selectUser} listTeachers={listTeachers} onShowRemoveUserModal={onShowRemoveUserModal}
            isActive={isActive} setIsActive={setIsActive} updateClass={updateClass} onShowDeleteModal={onShowDeleteModal} showRemoveUserModal={showRemoveUserModal}
            hideRemoveUserModal={hideRemoveUserModal} removeUser={removeUser}
            showDeleteModal={showDeleteModal} hideDeleteModal={hideDeleteModal} deleteClass={deleteClass}
            isShowErrorModal={isShowErrorModal} onHideErrorModal={onHideErrorModal}
            matchUrl={matchUrl} firstStudentId={firstStudentId}
            isShowRestrictionModal={isShowRestrictionModal} onHideRestrictionModal={onHideRestrictionModal}
            errorMessage={errorMessage} setErrorMessage={setErrorMessage} setShowRestrictionModal={setShowRestrictionModal}
            accountMethod={accountMethod} firstTeacherId={firstTeacherId}
            checkTeacherDisable={checkTeacherDisable} checkClassName={checkClassName}
            checkActiveClass={checkActiveClass} checkEnableAllSolution={checkEnableAllSolution}
            onHideTrialModal={onHideTrialModal} isShowTrialModal={isShowTrialModal}
            messageDayleftTeacherOrStudent={messageDayleftTeacherOrStudent}
            selectedUserForAddClass={selectedUserForAddClass}
            setShowInfoUser={setShowInfoUser}
            onHideInfoUser={onHideInfoUser}
            isShowInfoUser={isShowInfoUser}
            idUser={idUser}
            setIdStudent={setIdStudent}
            setTypeUser={setTypeUser}
            typeUser={typeUser}
        />
    )
}

export default React.memo(EditClass)
