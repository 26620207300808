import React, { useState } from 'react';
import { Modal } from "react-bootstrap"

function ModalYesOrNo(props) {
    const {
        showModal,
        functionNo,
        functionYes,
        title
    } = props

    return (
        <Modal
            show={showModal}
            onHide={functionNo}
            className="modal fade"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-epen pte-auto">
                    <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                        <h4 className="modal-title font-Paytone">
                            {title ? title : 'Please contact your administrator for more information'}
                        </h4>
                    </div>
                    
                    <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                        <div className="row">
                            <div className="col-5 m-auto">
                                <button
                                    type="button"
                                    onClick={functionNo}
                                    className="btn btn-light font-Paytone btn-block"
                                >
                                    <span className="text-danger">Cancel</span>
                                </button>
                            </div>
                            <div className="col-5 m-auto">
                                <button
                                    type="button"
                                    className="btn btn-light font-Paytone btn-block"
                                    onClick={functionYes}
                                >
                                    <span className="text-danger">Ok</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalYesOrNo;