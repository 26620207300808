import React from "react"
import { Link } from "react-router-dom"
import TopNav from "../../Main/_TopNav"
import ClientItem from "./ClientItem"
import MetaTag from '../../../MetaTags/MetaTag'
import PropTypes from "prop-types"
import classNames from 'classnames'
import './style.css'

import {
  isDesktop, isMobile
} from "react-device-detect"

import useCheckdevice from '../../../../hooks/useCheckdevice'

ListClient.propTypes = {
  onSearch: PropTypes.func,
  setKeyword: PropTypes.func,
  keyword: PropTypes.string,
  type: PropTypes.string,
  listClients: PropTypes.array,
  history: PropTypes.object,
}

ListClient.defaultProps = {
  onSearch: null,
  setKeyword: null,
  keyword: "",
  type: 0,
  listClients: [],
  history: {},
}

function ListClient(props) {
  const { onSearch, setKeyword, keyword, type, listClients } = props
  const device = useCheckdevice()
  const checkBrowser = (isDesktop && !isMobile && (device !== 'iPad'))
  const createClient = checkBrowser ? 'Create new Client' : 'Add new Client'
  return (
    <div className="bg-login" style={{ paddingTop: 30 }}>
      <MetaTag title="ePen" content="" />
      <nav className="mb-4 sticky-top">
        <TopNav history={props.history} />
      </nav>
      <section className="bg-transparent-black">
        <div>
          <div className={classNames({"box-tiemkiem": true, "mb-3": true, "desktop-interface-screen-vendor": checkBrowser})}>
            <div className={classNames({"row": true,  "desktop-interface-screen-vendor-width": checkBrowser})}>
              <div className={classNames({"col-10": true, "offset-1": !checkBrowser, "desktop-pl-0": checkBrowser, "desktop-interface-screen-vendor-offset": checkBrowser})}>
                <form
                  className="card card-sm card1"
                  onSubmit={(e) => onSearch(e)}
                >
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto">
                      <i className="fas fa-search h4 text-white"></i>
                    </div>
                    <div className="col">
                      <input
                        className="form-control form-control-lg form-control-borderless bg-transparent font30"
                        type="search"
                        placeholder="Input location name..."
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                    </div>
                  </div>
                </form>
                <div className="form-row mt-2">
                  <div className="col">
                    <Link
                      className={`box-filter font13 ${type === "All" ? "tran-70" : "tran-50 text-50" }`}
                      to={`/Vendor/All/${keyword}`}
                    >
                      <span className="line-H3">All</span>
                    </Link>
                  </div>
                  <div className="col">
                    <Link
                      className={`box-filter font13 ${type === "School" ? "tran-70" : "tran-50 text-50"}`}
                      to={`/Vendor/School/${keyword}`}
                    >
                      <span className="line-H3">School</span>
                    </Link>
                  </div>
                  <div className="col">
                    <Link
                      className={`box-filter font13 ${type === "Business" ? "tran-70" : "tran-50 text-50" }`}
                      to={`/Vendor/Business/${keyword}`}
                    >
                      <span className="line-H3">Business Tutor</span>
                    </Link>
                  </div>
                  <div className="col">
                    <Link
                      className={`box-filter font13 ${type === "Individual" ? "tran-70" : "tran-50 text-50" }`}
                      to={`/Vendor/Individual/${keyword}`}
                    >
                      <span className="line-H3">Individual Tutor</span>
                    </Link>
                  </div>
                  <div className="col">
                    <Link
                      className={`box-filter font13 ${type === "Parent" ? "tran-70" : "tran-50 text-50" }`}
                      to={`/Vendor/Parent/${keyword}`}
                    >
                      <span className="line-H3">Parent</span>
                    </Link>
                  </div>
                  <div className="col">
                    <Link
                      className={`box-filter font13 ${type === "Free" ? "tran-70" : "tran-50 text-50" }`}
                      to={`/Vendor/Free/${keyword}`}
                    >
                      <span className="line-H3">Free</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
                
            <h3 
              style={checkBrowser ? {} : {marginTop: 10}} 
              className={classNames({"font-Paytone": true,  "text-center": true,  "text-decoration": true, "desktop-interface-vendor-text": checkBrowser})}
            >
              <Link className={classNames({"desktop-interface-vendor-text-link": checkBrowser})}  to="/Vendor/CreateClient" >{createClient}</Link>
            </h3>
          </div>

          {/* <section className="container-fluid">
            <div className="p-4"> */}

              <div className={classNames("box-nd", "tran-60", "position-absolute", "posbox", [{"desktop-interface-screen-vendor-list-client": checkBrowser}])}>
                <div className="position-absolute tle-table-m tranTB-70">
                  <div className="row">
                    <div className="col-6 text-muted2"><strong className="text-white">Location</strong></div>
                    <div className="col-3 text-muted2 text-right"><strong className="text-white">Account type</strong></div>
                    <div className="col-3 text-muted2 text-center"><strong className={classNames("text-white", [{"ml-4": !checkBrowser}])}>Action</strong></div>
                  </div>
                </div>
                <div className="hidden-scrollbar-scoll position-absolute t-65">
                  <div className="inner-scoll" id="list">
                    {listClients &&
                      listClients.map((item, index) => {
                        return (
                          <ClientItem
                            key={index.toString()}
                            item={item}
                            index={index}
                          />
                        )
                      })}
                  </div>
                </div>
              </div>
            {/* </div>
          </section> */}
        </div>
      </section>
    </div>
  )
}

export default ListClient
