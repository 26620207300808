import React, { useState, useEffect } from 'react'
import StopwatchView from './StopwatchView'
import useLongPress from '../../utils/useLongPress'

export default function Stopwatch(props) {

    const [milisecs, setMilisecs] = useState(0)
    const [isPause, setIsPause] = useState(true)

    // const onLongPress = useLongPress(() => {
    //     setIsPause(true)
    //     setMilisecs(0)
    // }, 1000)

    useEffect(() => {
        if (!isPause) {
            let time = milisecs
            let start = setInterval(() => {
                time += 10
                setMilisecs(time)
            }, 10)

            return () => {
                clearInterval(start)
            }
        } 
    }, [isPause])

    useEffect(() => {
        if(milisecs === 0) {
            setIsPause(true)
        }
    }, [milisecs])

    function reset() {
        setMilisecs(0)
    }

    return (
        <StopwatchView
            milisecs={milisecs}
            isPause={isPause} setIsPause={setIsPause}
            reset={reset}
            {...props} />
    )
}
