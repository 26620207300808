/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { isDesktop, isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import API from "../../../utils/Api"
import * as Fc from '../../../utils/Functions'
import * as type from './../../../constants/actionType'
import * as Const from './../../../constants/Const'
import WorkbookView from './WorkbookView'
import Lession from './Lession/index'
import { useHistory } from 'react-router-dom'

export default function Workbook(props) {
    const workbookId = props.match.params.workbookId
    const [WorkbookDetail, setWorkbookDetail] = useState({})
    const [category, setCategory] = useState('')
    const matchUrl = useRouteMatch()
    let history = useHistory()
    const dispatch = useDispatch();

    useEffect(() => {
        GetWorkbookDetail(workbookId)
    }, [])

    useEffect(() => {
        if (Object.keys(WorkbookDetail).length === 0) {
            return 
        }

        if (isDesktop && !isMobile) {
            let idLession = (WorkbookDetail && WorkbookDetail.Chapters && WorkbookDetail.Chapters[0].Navigations && WorkbookDetail.Chapters[0].Navigations[0]) && WorkbookDetail.Chapters[0].Navigations[0]._id
            if (idLession !== undefined){
                //save current chapter to redux on Desktop when user redirect to book detail
                //first chapter of workbook
                let chapter = WorkbookDetail.Chapters[0];
                const SESSION = chapter.Navigations && chapter.Navigations.length > 0 
                    ?   chapter.Navigations.map(item => {return {Index: item.Index, _id: item._id, Label: item.Label}})
                    :   []
                chapter =  {Index: chapter.Index, _id: chapter._id, Label: chapter.Label, Session: SESSION}
                dispatch({ type: type.SAVE_CURRENT_CHAPTER, payload: chapter })

                //save current session to redux on Desktop when user redirect to book detail
                //first session of first chapter
                let session = WorkbookDetail.Chapters[0].Navigations[0]
                session =  {Index: session.Index, _id: session._id, Label: session.Label}
                dispatch({ type: type.SAVE_CURRENT_SESSION, payload: session })

                //redirect to Lesson
                history.push(`${matchUrl.url}/Lession/${idLession}`);
            }
        }

    }, [WorkbookDetail])

    useEffect(() => {
        let roleCategory
        let OperatorId
        if (Fc.getRole() === Const.Role.VENDOR) {
            OperatorId = props.match.params.id
        }
        else if (Fc.getRole() === Const.Role.OPERATOR) {
            OperatorId = Fc.getUserId()
        } else {
            OperatorId = Fc.getOperator()
        }

        if (category === "Concept") {
            roleCategory = "AccessSolutionConcept"
        } else {
            roleCategory = "AccessSolutionFoundation"
        }
        category !== "" && API.CheckPermission(OperatorId, roleCategory)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        dispatch({ type: type.PERMISSION_SOLUTION, payload: res.data.Data })
                    } else {
                        Fc.showError(res.data.Message)
                    }
                } else {
                    Fc.showError(res.statusText)
                }
            })
            .catch((err) => {
                Fc.showError("Can not connect to server")
            })
    }, [category])

    function GetWorkbookDetail(id) {
        API.WorkBookDetail(id)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        setCategory(res.data.Data.Category);
                        setWorkbookDetail(res.data.Data)
                    } else {
                        Fc.showError(res.data.Message)
                        props.history.goBack()
                    }
                } else {
                    // Fc.showError(res.statusText)
                    props.history.goBack()
                }
            }).catch(err => {
                // props.history.goBack()
                Fc.showError("Can not connect to server")
            })
    }

    if (isDesktop && !isMobile) {
        return <Lession />
    }
    return (
        <WorkbookView {...props} WorkbookDetail={WorkbookDetail} matchUrl={matchUrl} />
    )
}