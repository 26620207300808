/* eslint-disable array-callback-return */
/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import SectionQuizTest from './SectionQuizTest'
import * as Fc from '../../../../utils/Functions'
import {Role} from '../../../../constants/Const'
import API from "../../../../utils/Api"
import { useRouteMatch } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import * as Type from "../../../../constants/actionType"

function QuizTest(props) {
    const dispatch = useDispatch();
    const matchUrl = useRouteMatch()
    const role = Fc.getRole()
    const operatorId = Fc.getRole() === Role.VENDOR ? props.match.params.id : Fc.getOperator()
    const params = props.location.search.replace('?', '').split('&').reduce((r, e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});

    const [data, setData] = useState([
        { Type: "Section", data: [], pageIndex: 1, total: 0 },
        { Type: "Quiz", data: [], pageIndex: 1, total: 0 },
        { Type: "Test", data: [], pageIndex: 1, total: 0 }
    ])
    const [userName, setUsername] = useState(null)

    // loadmore
    const pageSize = 0
    /**
     * loadmore
     */

    // useEffect(() => {
    //     data.map((tab) => {
    //         const el = document.getElementById(tab.Type)
    //         el.addEventListener("scroll", loadMore(tab.Type))
    //         return function cleanupListener() {
    //             el.removeEventListener("scroll", loadMore(tab.Type))
    //         }
    //     })
    // }, [data])

    // async function loadMore(type) {
    //     let dcData = Fc.deepCopy(data)
    //     let tab = dcData.filter(x => x.Type === type)[0]

    //     const el = document.getElementById(type)
    //     if (el.scrollTop + el.clientHeight > el.scrollHeight - 100) {
    //         if (pageSize * tab.pageIndex >= tab.total) {
    //             return null
    //         } else {
    //             tab.pageIndex += 1
    //             tab = await getListWorkBookWithTab(tab)
    //         }
    //     }
    //     setData(dcData)
    // }

    /**
     * end Loadmore
     */

    useEffect(() => {
        getAccount()
        let dcData = Fc.deepCopy(data)
        dcData.map(async (item) => {
            item = await getListWorkBookWithTab(item);
            setTimeout(() => {
                setData(dcData)
            }, 100)
        })
    }, [])


    async function getListWorkBookWithTab(tab) {
        await API.WorkBookSearchWithTab(tab.Type, params.category, params.subject, params.level, params.bookname, params.keyword, tab.pageIndex, pageSize)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        if (res.data.Data) {
                            let dt = Fc.deepCopy(tab.data)
                            for (const lv in res.data.Data.Levels) {
                                dt.push({
                                    Type: res.data.Data.Type,
                                    Level: lv,
                                    DataOfBook: res.data.Data.Levels[lv]
                                })
                            }
                            tab.data = dt
                            tab.total = res.data.Data.Total
                        }
                    } else {
                        Fc.showError(res.data.Message)
                    }

                } else {
                    Fc.showError(res.statusText)
                }

            })
            .catch((err) => {
                Fc.showError(err)
            })
        return tab
    }

    function goBack() {
        dispatch({ type: Type.SAVE_PARAMS_SEARCH, payload: null })
        if (matchUrl.params.id) {
            if (role === Role.VENDOR) {
                props.history.push(`/Vendor/ManageClient/${operatorId}`)
            } 
        } else if (role === Role.OPERATOR) {
            props.history.push(`/operator`)
        } else {
            props.history.push(`/Teacher`)
        }
    }

    function getAccount() {
        API.getAccountInfo().then(res => {
            if (res.status === 200 && res.data.Success) {
                if (Fc.getRole() === Role.VENDOR) {
                    //vendor show email 
                    setUsername(res.data.Data.Email)
                } else if (Fc.getRole() === Role.OPERATOR) {
                    //operator show account id
                    setUsername(res.data.Data.Email)
                } else {
                    setUsername(res.data.Data.SSOAccountId)
                }
            } else {
                if (res.data.Message)
                    Fc.showError(res.data.Message)
                else
                    Fc.showError(res.statusText)
            }
        }).catch(err => {
        })
    }

    return (
        <SectionQuizTest
            data={data}
            matchUrl={matchUrl}
            goBack={goBack}
            userName={userName}
        />
    );
}

export default QuizTest;