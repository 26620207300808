/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import PaymentUI from './Payment'
import API from '../../../utils/Api'
import * as Fc from '../../../utils/Functions'

function Payment(props) {
    const [accountInfo, setAccountInfo] = useState([])
    const [bannerInfo, setBannerInfo] = useState([])
    const [currency, setCurrency] = useState('CAD')

    useEffect(() => {
        getAccountPayment()
        getBannerPayment()
    }, [])

    function getAccountPayment() {
        API.getAccountPayment().then(res => {
            if (res.status === 200 && res.data.Success) {
                if (res.data.Data.length > 0) {
                    let data = res.data.Data
                    data.map(item => {
                        item.FromStr = item.From ? item.From.toString() : ''
                        item.ToStr = item.To ? item.To.toString() : ''
                        item.MonthlyValueStr = item.MonthlyValue ? item.MonthlyValue.toString() : ''
                        item.YearlyValueStr = item.YearlyValue ? item.YearlyValue.toString() : ''
                    })
                    setCurrency(data[0].Currency)
                    setAccountInfo(data)
                }
            } else {
                if (res.data.Message)
                    Fc.showError(res.data.Message)
                else
                    Fc.showError(res.statusText)
            }
        })
    }

    function getBannerPayment() {
        API.getBannerPayment().then(res => {
            if (res.status === 200 && res.data.Success) {
                if (res.data.Data.length > 0) {
                    let data = res.data.Data
                    data.map(item => {
                        item.MonthlyValueStr = item.MonthlyValue ? item.MonthlyValue.toString() : ''
                        item.YearlyValueStr = item.YearlyValue ? item.YearlyValue.toString() : ''
                    })
                    setBannerInfo(data)
                }
            } else {
                if (res.data.Message)
                    Fc.showError(res.data.Message)
                else
                    Fc.showError(res.statusText)
            }
        })
    }

    function setAccountValue(field, value, accountType) {
        let accounts = [...accountInfo]
        if (accounts.filter(x => x.AccountType === accountType).length > 0) {
            let account = accounts.filter(x => x.AccountType === accountType)[0]
            account[field] = Number(value.replace(/[$,]+/g, ""))
            account[field + 'Str'] = value
            setAccountInfo(accounts)
        } else {
            let account = { AccountType: accountType }
            account[field] = Number(value.replace(/[$,]+/g, ""))
            account[field + 'Str'] = value
            accounts.push(account)
            setAccountInfo(accounts)
        }
    }

    function setBannerValue(field, value, accountType) {
        let banners = [...bannerInfo]
        if (banners.filter(x => x.AccountType === accountType).length > 0) {
            let banner = banners.filter(x => x.AccountType === accountType)[0]
            banner[field] = Number(value.replace(/[$,]+/g, ""))
            banner[field + 'Str'] = value
            setBannerInfo(banners)
        } else {
            let banner = { AccountType: accountType }
            banner[field] = Number(value.replace(/[$,]+/g, ""))
            banner[field + 'Str'] = value
            banners.push(banner)
            setBannerInfo(banners)
        }
    }

    function updateAccountPayment() {
        let accounts = [...accountInfo]
        accounts.map(item => {
            item.Currency = currency
        })
        API.updateAccountPayment(accounts).then(res => {
            if (res.status === 200 && res.data.Success) {
                Fc.showSuccess('Update account payment success')
            } else {
                if (res.data.Message)
                    Fc.showError(res.data.Message)
                else
                    Fc.showError(res.statusText)
            }
        })
    }

    function updateBannerPayment() {
        let banners = [...bannerInfo]
        banners.map(item => {
            item.Currency = currency
        })
        API.updateBannerPayment(banners).then(res => {
            if (res.status === 200) {
                Fc.showSuccess('Update banner payment success')
            } else {
                if (res.data.Message)
                    Fc.showError(res.data.Message)
                else
                    Fc.showError(res.statusText)
            }
        })
    }


    return (
        <PaymentUI
            accountInfo={accountInfo}
            setAccountValue={setAccountValue}
            currency={currency} setCurrency={setCurrency}
            setBannerValue={setBannerValue}
            bannerInfo={bannerInfo}
            updateAccountPayment={updateAccountPayment}
            updateBannerPayment={updateBannerPayment}
            {...props} />
    );
}

export default Payment;