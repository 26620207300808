import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as type from '../../../constants/actionType'
import classNames from 'classnames'
import { Link } from "react-router-dom"

export default function WorkbookView(props) {
    const dispatch = useDispatch()
    let selectedWs = useSelector(state => state.ws.selectWs)
    const { WorkbookDetail, matchUrl } = props

    function onClickChapter(chapter) {
        const SESSION = chapter.Navigations && chapter.Navigations.length > 0 
            ?   chapter.Navigations.map(item => {return {Index: item.Index, _id: item._id, Label: item.Label}})
            :   []
        chapter =  {Index: chapter.Index, _id: chapter._id, Label: chapter.Label, Session: SESSION}
        dispatch({ type: type.SAVE_CURRENT_CHAPTER, payload: chapter })
    }

    function onClickSession(session) {
        session =  {Index: session.Index, _id: session._id, Label: session.Label}
        dispatch({ type: type.SAVE_CURRENT_SESSION, payload: session })
    }

    return (
        <>
            <div className="bg-xam77"></div>
            {/* <Link className="upload shadow" to={matchUrl.url}><i className="fas fa-book-medical fa-2x"></i></Link> */}
            <main className="" style={{ paddingTop: "80px" }}>
                <section className="container-fluid">
                    <div className="p-4">
                        <h4 className="font-Paytone mb-0 bg-epen p-3">{WorkbookDetail.Title}</h4>
                        <div id="accordion1" role="tablist">
                            {WorkbookDetail.Chapters && WorkbookDetail.Chapters.map((chapter, cindex) => {
                                const selectedWorksheets = (selectedWs && selectedWs.length > 0 ) 
                                    ?   selectedWs[0].worksheet
                                    :   []
                                const isSelectChapter = (selectedWorksheets && selectedWorksheets.length > 0 )
                                    ?   selectedWorksheets.some(i => chapter._id === i.Chapter._id)
                                    :   false
                                return (
                                    <div className="card border-0 radius-0 bg-xam03" key={cindex.toString()}>
                                        
                                            <a className={classNames([{"text-black": !isSelectChapter, "text-red": isSelectChapter}])} 
                                                data-toggle="collapse" 
                                                href={`#collapseOne${cindex.toString()}`} role="button" 
                                                aria-expanded="false" aria-controls={`collapseOne${cindex.toString()}`}
                                            >
                                            <div className="card-header bg-white" 
                                                role="tab" 
                                                id={`headingOne1-${cindex.toString()}`}
                                                onClick={() => onClickChapter(chapter)}
                                            >
                                                {chapter.Label}
                                            </div>
                                            </a>


                                        <div id={`collapseOne${cindex.toString()}`} 
                                            className="collapse" role="tabpanel" 
                                            aria-labelledby={`headingOne1-${cindex.toString()}`} 
                                            data-parent="#accordion1"
                                        >
                                            <div className="card-body p-0">
                                                <table className="table mb-0">
                                                    <tbody className="table-epen">
                                                        {chapter.Navigations.map((item, index) => {
                                                            const selectedWorksheets = (selectedWs && selectedWs.length > 0 ) 
                                                            ?   selectedWs[0].worksheet
                                                            :   []
                                                            const isSelectSession = (selectedWorksheets && selectedWorksheets.length > 0 )
                                                            ?   selectedWorksheets.some(i => item._id === i.Session._id)
                                                            :   false
                                                            return (
                                                                <tr key={index.toString()} 
                                                                    onClick={() => { 
                                                                        onClickSession(item)
                                                                        props.history.push(`${matchUrl.url}/Lession/${item._id}`) 
                                                                    }}
                                                                >
                                                                    <td className="border-bottom border-top-0 pointer focus">
                                                                        <span className={classNames([{"text-black": !isSelectSession, "text-red": isSelectSession}])} >
                                                                            {item.Label}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
}