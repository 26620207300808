import React from 'react'
import { Link } from 'react-router-dom'

function Code(props) {
    const { item, firstStudentId, matchUrl, classInfo, setErrorMessage, setShowRestrictionModal } = props
    
            if(item._id === firstStudentId)
                return (<Link className="pointer text-underline button-join mr-3" style={{paddingLeft: 20}} to={`${matchUrl.url.substring(0, matchUrl.url.length - 45)}/Student/${item._id}/${classInfo._id}`}>Join</Link>)
            else 
                return (
                <Link className="pointer text-underline button-join mr-3" to="#" 
                    onClick={() => {
                        setErrorMessage("This features is locked. Upgrade now to use all Epen App features.")
                        setShowRestrictionModal(true)
                    }}
                    style={{paddingLeft: 20}}
                >
                    {/* {item.Code} */}
                    Join
                </Link>
                )
}

export default Code;