import React from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { Formik } from "formik"
import * as Yup from "yup"
import * as Fc from '../../utils/Functions'
import ModalAlertOperator from "../ModalAlertOperator"
import ModalAlertForTeacher from "../ModalStudentTeacher"
import * as Const from "../../constants/Const"
import Document from './Item/Document'

import useCheckdevice from '../../hooks/useCheckdevice'
import classNames from 'classnames'
import { isDesktop, isMobile } from "react-device-detect"


export default function UploadDocumentsView(props) {
    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))

    const role = Fc.getRole()
    const AccountMethod = Fc.getAccountMethod()
    const {
        fileTitle, setFileTitle,
        file, setFile,
        openUploadModal,
        listFiles,
        openDeleteModal,
        showUploadModal, hideUploadModal,
        showDeleteModal, hideDeleteModal,
        deleteDocument,
        matchUrl, upload, isShowAddAlertModal,
        onHideAddAlert, alertMessage,
        isShowAddErrorModal,
        onHideAddError,
        errorMessage, firstDocumentId, accountMethod,
        ShowAddError, setShowAddAlertModal, setAlertMessage, setErrorMessage,
        banner, dayLeft, alertMessageForTeacherUpload
    } = props

    let fileName = ""
    if (file.name && file.name.length > 0) {
        if (file.name.length > 20) {
            fileName = file.name.substring(0, 20) + "..."
        } else {
            fileName = file.name
        }
    }

    const validateForm = Yup.object().shape({
        fileTitle: Yup.string().required("Required")
            .test('trim', 'Required', (value) => {
                return value && value.trim().length > 0
            }),
        file: Yup.mixed().required('Required')
            .test('fileFormat', 'Pdf or image file only', (value) => {
                return value && ['pdf', 'png', 'jpg', 'PDF', 'PNG', 'JPG','HEIF','heif'].includes(value.split('.').pop());
            }),
    })

    return (
        <>
            <div className="tag_list_wrapper row mb-4">
                <div 
                    className={classNames( "mb-4", "pointer",
                        [{
                            "col-md-3": !isBrowserDesktop,
                            "col-6": !isBrowserDesktop,  
                            "col": isBrowserDesktop, 
                            "line-7-items": isBrowserDesktop, 
                            "ar-1": isBrowserDesktop, 
                            "min-height-upload": !isBrowserDesktop
                        }]
                    )}
                >
                    <div className="tran-60 box-upload text-center h-100" onClick={openUploadModal}>
                        <p className="mb-0"><i className="fas fa-upload fa-3x"></i><br /><span className="font-Paytone font18">Upload</span></p>
                    </div>
                </div>

                {listFiles && listFiles.map((item, index) => {
                    return (
                        <div className={classNames("tag_list", "mb-4", [{"col-md-3": !isBrowserDesktop, "col-6": !isBrowserDesktop, "col": isBrowserDesktop, "line-7-items": isBrowserDesktop}])}  key={index.toString()}>
                            {role !== Const.Role.VENDOR ? <Document
                                item={item}
                                matchUrl={matchUrl}
                                openDeleteModal={openDeleteModal}
                                firstDocumentId={firstDocumentId}
                                accountMethod={accountMethod}
                                ShowAddError={ShowAddError}
                                setShowAddAlertModal={setShowAddAlertModal}
                                setAlertMessage={setAlertMessage}
                                setErrorMessage={setErrorMessage}
                                role={role}
                                banner={banner}
                            /> :
                                <>
                                    <Link to={matchUrl.url.substring(0, matchUrl.url.length - 7) + `/document/${item._id}`} className="position-relative box-img mh-150">
                                        <img className="img-fluid bot-0" src={item.Cover} alt="" />
                                        <span style={{ background: 'rgba(0, 0, 0, 0,5)' }} className="box-sp position-absolute">
                                            <strong style={{ width: "90%", margin: "auto" }} className="align-middle-center">
                                                {item.Title.length > 10 ? (item.Title.substring(0, 15) + "...") : item.Title}
                                            </strong>
                                        </span>
                                    </Link><span className="close" onClick={() => openDeleteModal(item._id)}>X</span>
                                </>}
                        </div>
                    )
                })}
            </div>
            <Modal
                show={showUploadModal}
                onHide={hideUploadModal}
                className="modal fade"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <Formik
                        initialValues={{ fileTitle: '', file: undefined }}
                        onSubmit={upload}
                        validationSchema={validateForm}
                    >
                        {(properties) => {
                            const {
                                touched,
                                errors,
                                handleSubmit,
                                handleChange,
                            } = properties;
                            return (
                                <form className="modal-content bg-epen pte-auto" onSubmit={handleSubmit}>
                                    <div className="modal-header border-bottom-0">
                                    </div>
                                    <div className="modal-body pl-5 pr-5">
                                        <input type="text" name="fileTitle" className="form-control" placeholder="Title*" value={fileTitle} onChange={(e) => {
                                            setFileTitle(e.target.value)
                                            handleChange(e)
                                        }} />
                                        {errors.fileTitle && touched.fileTitle && (
                                            <div className="validate-input">* {errors.fileTitle}</div>
                                        )}
                                        <div className="custom-file mt-3 mb-3">
                                            <input type="file" name="file" className="custom-file-input" onChange={(e) => {
                                                setFile(e.target.files[0])
                                                handleChange(e)
                                            }} />
                                            {errors.file && touched.file && (
                                                <div className="validate-input">* {errors.file}</div>
                                            )}
                                            <label className="custom-file-label custom-file-label-epen" htmlFor="customFile"><span className="text-white">{fileName}</span></label>
                                        </div>
                                    </div>
                                    <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                                        <div className="row">
                                            <div className="col-5 mr-auto"><button type="reset" onClick={hideUploadModal} className="btn btn-light font-Paytone btn-block">Cancel</button></div>
                                            <div className="col-6 col-md-5"><button type="submit" className="btn btn-light font-Paytone float-right btn-block">Add File</button></div>
                                        </div>
                                    </div>
                                </form>)
                        }}
                    </Formik>
                </div>
            </Modal>

            <Modal
                show={showDeleteModal}
                onHide={hideDeleteModal}
                className="modal fade"
                id="myModal"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-epen pte-auto">
                        <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                            <h4 className="modal-title font-Paytone">
                                Are you sure you want to delete this document?
                            </h4>
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 mr-auto">
                                    <button
                                        type="button"
                                        onClick={hideDeleteModal}
                                        className="btn btn-light font-Paytone btn-block"
                                    >
                                        Cancel
                                        </button>
                                </div>
                                <div className="col-5">
                                    <button
                                        type="button"
                                        onClick={deleteDocument}
                                        className="btn btn-light font-Paytone float-right btn-block"
                                    >
                                        <span className="text-danger">Confirm</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {<ModalAlertOperator
                showModal={isShowAddAlertModal}
                alertMessage={role === Const.Role.OPERATOR ? alertMessage : alertMessageForTeacherUpload}
                role={role}
                AccountMethod={AccountMethod}
                keyStorage={'PopupUploadDocumentForOperator'}
                functionClose={onHideAddAlert}
                functionUpgrade={null}
            />}

            {<ModalAlertOperator
                showModal={isShowAddErrorModal}
                // hideModal={onHideAddError}
                alertMessage={errorMessage}
                role={role}
                keyStorage={'PopupUploadDocumentForOperator'}
                AccountMethod={AccountMethod}
                functionClose={onHideAddError}
                functionUpgrade={null}
            />}

            <ModalAlertForTeacher
                showModal={isShowAddErrorModal}
                keyStorage={'PopupErrorUploadDocumentForOperator'}
                message={errorMessage}
                role={role}
                AccountMethod={AccountMethod}
                showRadiobutton={false}
                functionClose={() => {
                    onHideAddError()
                }}
                functionContactAdmin={null}
            />
        </>
    )
}