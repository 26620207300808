/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import * as Fc from '../../../../utils/Functions'
import API from '../../../../utils/Api'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import * as type from '.././../../../constants/actionType'
import * as Const from '.././../../../constants/Const'
// import { useRouteMatch } from 'react-router-dom'

import SendWorksheets from '../../../../components/SendWorksheets'

export default function Lession(props) {
    const [WorkbookDetail, setWorkbookDetail] = useState({})
    const [category, setCategory] = useState('')
    const matchUrl = useRouteMatch()
    const [listWorksheets, setListWorksheets] = useState([])
    const dispatch = useDispatch();
    const lessionId = matchUrl.params.lessionId
    const workbookId = matchUrl.params.workbookId
    useEffect(() => {
        getWorksheets();
        GetWorkbookDetail(workbookId);
    }, [])

    useEffect(() => {
        if(!lessionId) return 
        getWorksheets()
    }, [lessionId])

    useEffect(() => {
        let roleCategory
        let OperatorId
        if (Fc.getRole() === Const.Role.VENDOR) {
            OperatorId = matchUrl.params.id
        }
        else if (Fc.getRole() === Const.Role.OPERATOR) {
            OperatorId = Fc.getUserId()
        } else {
            OperatorId = Fc.getOperator()
        }

        if (category === "Concept") {
            roleCategory = "AccessSolutionConcept"
        } else {
            roleCategory = "AccessSolutionFoundation"
        }
        category !== "" && API.CheckPermission(OperatorId, roleCategory)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        dispatch({ type: type.PERMISSION_SOLUTION, payload: res.data.Data })
                    } else {
                        Fc.showError(res.data.Message)
                    }
                } else {
                    Fc.showError(res.statusText)
                }
            })
            .catch((err) => {
                Fc.showError("Can not connect to server")
            })
    }, [category])

    function getWorksheets() {
        if(!lessionId) return 
        API.getWorkSheetsBySectionId(lessionId).then(res => {
            if (res.status === 200 && res.data.Success) {
                setListWorksheets(res.data.Data)
            } else {
                Fc.showError(res.data.Message || res.statusText)
                props.history.goBack()
            }
        }).catch(err => {
            Fc.showError("Can not connect to server")
        })
    }

    function GetWorkbookDetail(id) {
        API.WorkBookDetail(id)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        setCategory(res.data.Data.Category);
                        setWorkbookDetail(res.data.Data)
                    } else {
                        Fc.showError(res.data.Message)
                        props.history.goBack()
                    }
                } else {
                    Fc.showError(res.statusText)
                    props.history.goBack()
                }
            }).catch(err => {
                Fc.showError("Can not connect to server")
            })
    }


    return (
        listWorksheets.length > 0 
        ?   <SendWorksheets
                listWorksheets={listWorksheets}
                WorkbookDetail={WorkbookDetail}
                matchUrl={matchUrl}
                {...props} 
            /> 
        :   <div className="bg-xam77"></div>
    )
}