import React from 'react';
import useCheckdevice from '../../../hooks/useCheckdevice'
import { isDesktop, isMobile, isTablet } from "react-device-detect"
import './style.css';

function ChangePageView(props) {
    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))

    const { 
        onKeyPress,
        value,
        onChange,
        totalPage,
        onRightClick,
        onLeftClick
    } = props
    return (
        <div className="box-page" style={{display: "flex", zIndex: 10 }}>
            {isTablet&&(window.innerWidth>window.innerHeight)?
            <div 
                className="back pointer" onClick={onLeftClick} 
                style={{marginTop: 1, marginRight: 5}}
            >
                <i className="fas fa-caret-left fa-4x" />
            </div>:''
            }
            <div className="tran-70" style={{ zIndex: 10 }}>
                <span className="box-time">
                    <div className="form-inline">
                        <label htmlFor="formula-page" style={{marginRight: 10}}>Page</label>
                        <input 
                            onKeyPress={onKeyPress} 
                            value={value} 
                            onChange={onChange} 
                            type="text" className="form-control w60" 
                            placeholder={`1-${totalPage}`} 
                            id="formula-page" 
                        />/{totalPage}
                    </div>
                </span>
            </div>
            {isTablet&&(window.innerWidth>window.innerHeight)?
            <div 
                className="back pointer" onClick={onRightClick} 
                style={{marginTop: 1, marginLeft: 5}}
            >
                <i className="fas fa-caret-right fa-4x" />
            </div>
            :''
            }
        </div>
    );
}

export default ChangePageView;
