import React, { useRef } from "react"
import { Link, Redirect, Route, useHistory } from "react-router-dom"
import * as Const from "../../../constants/Const"
import ChangePassword from "../../Login/_ChangePassword"
import Edit from "../../Student/Edit"
import studentComponent from "../../Student/MyWork"
import API from "../../../utils/Api";

//screens
import Accounts from "../Accounts"
import Banner from '../Banner'
import Browse from "../Browse"
import SectionQuizTest from "../Browse/SearchSectionQuizTest"
import Upload from "../Browse/Upload"
import AddClass from "../Class/AddClass"
import EditClass from "../Class/EditClass"

import Document from "../Document"
import Workbook from "../Workbook"
import Lession from "../Workbook/Lession"
import PopupChat from './../../../components/PopupChat'
import * as Type from './../../../constants/actionType'
import * as Fc from './../../../utils/Functions'
import LayoutSearch from "./LayoutSearch"

//component
import ButtonBack from '../../../components/ButtonGoBack'
import Loading from '../../../components/Loading'
import Drawer from '../../../components/HeaderIpad/Drawer'

import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  isDesktop,
  isTablet, isIPad13,
  isMobile
} from "react-device-detect";
import HeaderDesktop from '../../../components/HeaderDesktop/HeaderDesktop'
import HeaderEdit from '../../../components/HeaderDesktop/HeaderEdit'

function LayoutComponent(props) {
  let dispatch = useDispatch()
  const {
    hideMenu,
    showMenu,
    matchUrl,
    logOut,
    operatorInfo,
    role,
    openPopup,
    setOpenPopup,
    operatorId,
    socket,
    message,
    setMessage,
    messageList,
    setMessageList,
    listClass,
    ClassId,
    setClassId,
    ListStudent,
    ListTeacher,
    setSelectedId,
    selectedId, room,
    tabChat,
    setTabChat,
    userType, setUserType,
    userName, setUserName,
    getListNotif,
    scrollToBottom,
    messageEndRef,
    setUserSendMessagge,
    getListMessageInGroup,
    GetChatMessages,
    getDetailClass,
    setPageIndex,
    onScrollTop,
    pageIndex
  } = props

  let history = useHistory();
  let user = useSelector(state => state.ws.userLogin)
  let countNotif = useSelector(state => state.ws.countNotif)
  let searchParams = useSelector(state => state.ws.params)

  const sendMessage = async () => {
    if (message === "") {
      return
    }

    let messageContent = {
      room: room,
      Token: Fc.getToken(),
      content: {
        From: Fc.getRole() === Const.Role.VENDOR ? Const.Role.VENDOR : Fc.getUserId(),
        To: (selectedId === "" || selectedId === "Teacher/Student" ) ? room : selectedId,
        Type: (selectedId !== "" && selectedId !== "Teacher/Student") ? "normal" : "groupchat",
        Body: message,
        FromRole: Fc.getRole(),
        ToRole: (selectedId !== "" && selectedId !== "Teacher/Student") ? userType : "CLASS",
        FromName: user.FirstName + " " + user.LastName,
        ToName: (selectedId !== "" && selectedId !== "Teacher/Student") ? userName : ClassId,
        ClassId: ClassId,
        CreatedDate: new Date()
      },
    };

    await socket.emit("send_message", messageContent);
    setMessageList([...messageList, messageContent.content]);
    setMessage("");
  };

  function onHideIpad() {
    hideMenu();
    // dispatch({ type: Type.SELECTWS, payload: [] })
    dispatch({ type: Type.SAVE_PARAMS_SEARCH, payload: null });
  }

  if (Fc.getToken() === null) {
    return (<Redirect to="/" />)
  } else
    return (
      <>
        <Loading />
        <b className="screen-overlay"></b>

        {/*====== Drawer ipad======*/}
        <Drawer
          role={role}
          hideMenu={onHideIpad}
          matchUrl={matchUrl}
          operatorInfo={operatorInfo}
          logOut={logOut}
        />
        {/*====== End Drawer ipad======*/}

        {/*====== Modal Search ======*/}
        <div className="modal fade" id="myModal">
          <LayoutSearch matchUrl={matchUrl} {...props} />
        </div>
        {/*====== End Modal search ======*/}

        {/*====== Header Desktop ======*/}
        { (isDesktop && !isMobile)
          && ((history.location.pathname.includes('/Edit/Assignment') || history.location.pathname.includes('/Edit/Notes') || history.location.pathname.includes('/Edit/Document'))
            ? <HeaderEdit operatorInfo={operatorInfo} />
            : <HeaderDesktop
              role={role}
              hideMenu={hideMenu}
              matchUrl={matchUrl}
              operatorInfo={operatorInfo}
              logOut={logOut}
            />)
        }
        {/*======End Header Desktop ======*/}

        {/*====== Header Ipad ======*/}
        {   (isTablet || isMobile || isIPad13)
          && <nav className="header fixed-top p-3 z-5">
            <div className="row align-items-center">
              <div className="col-auto">
                <Link to="#" 
                  onClick={showMenu}
                  // data-trigger="#my_offcanvas1"
                >
                  <i className="fas fa-bars fa-2x"></i>
                </Link>
              </div>

              <div className="mx-auto">
                <h3 className="font-Paytone">
                  {
                    !(operatorInfo.BusinessName || operatorInfo.FullName)
                      ? 'Epen'
                      : (operatorInfo.BusinessName || operatorInfo.FullName)
                  }
                </h3>
              </div>

              <div className="col-auto" >
                {
                  matchUrl.isExact
                    ? (
                      <Link className="" to="#" data-toggle="modal" data-target="#myModal">
                        <i className="fas fa-search fa-3x"></i>
                      </Link>
                    )
                    : (<>
                      <Route path={`${matchUrl.path}/Search`} exact>
                        <ButtonBack
                          handleClick={() => {
                            hideMenu();
                            history.push(`${matchUrl.url}`);
                            dispatch({ type: Type.SAVE_PARAMS_SEARCH, payload: null });
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Search?keyword=:keyword`}>
                        <ButtonBack
                          handleClick={() => {
                            hideMenu();
                            history.push(`${matchUrl.url}`);
                            // dispatch({ type: Type.SAVE_PARAMS_SEARCH, payload: null });
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Accounts`}>
                        <ButtonBack
                          handleClick={() => {
                            dispatch({ type: Type.SAVE_USER_INFO, payload: {} });
                            hideMenu(); history.push(`${matchUrl.url}`);
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/AddClass`}>
                        <ButtonBack
                          handleClick={() => { hideMenu(); history.push(`${matchUrl.url}`); }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Classes`} exact>
                        <ButtonBack
                          handleClick={() => { hideMenu(); history.push(`${matchUrl.url}`); }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Classes/:classID`}>
                        <ButtonBack
                          handleClick={() => { hideMenu(); history.push(`${matchUrl.url}`); }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Student/:studentId/:classId`} exact>
                        <ButtonBack
                          handleClick={() => {
                            hideMenu()
                            let url = window.location.href

                            while (url.endsWith("/")) {
                              url = url.substring(0, url.length - 1)
                            }
                            let classId = url.split("/").reverse()[0]
                            history.push(`${matchUrl.url}/Classes/${classId}`)
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Student/:studentId/:classId/Edit/:type/:assignId/:pageIndex`}>
                        <ButtonBack
                          handleClick={() => {
                            // hideMenu()
                            dispatch({ type: Type.SAVE_USER_INFO, payload: {} })
                            dispatch({ type: Type.SAVE_CURRENT_CHAPTER, payload: null })
                            dispatch({ type: Type.SAVE_CURRENT_SESSION, payload: null })
                            dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Exercise' })
                            dispatch({ type: Type.SAVE_PAGEINDEX_WS, payload: null })
                            dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: 0 })
                            
                            let url = window.location.href
                            let eIndex = url.toLowerCase().indexOf("/edit/")

                            url = url.substring(0, eIndex)
                            let sIndex = url.toLowerCase().indexOf("/student/")

                            url = url.substring(sIndex)
                            props.history.push(`${matchUrl.url}${url}`)
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/ChangePassword`}>
                        <ButtonBack
                          handleClick={() => { hideMenu(); history.push(`${matchUrl.url}`); }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Upload`}>
                        <ButtonBack
                          handleClick={() => { hideMenu(); history.push(`${matchUrl.url}`); }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Document/:documentId`}>
                        <ButtonBack
                          handleClick={() => {
                            hideMenu();
                            dispatch({ type: Type.SELECTWS, payload: [] })
                            history.push(`${matchUrl.url}/Upload`);
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Workbook/:workbookId`} exact>
                        <ButtonBack
                          handleClick={() => {
                            hideMenu();
                            // dispatch({ type: Type.SELECTWS, payload: [] })
                            props.history.push(`${matchUrl.url}`);
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Workbook/:workbookId/Lession/:lessionId`}>
                        <ButtonBack
                          handleClick={() => {
                            hideMenu()
                            if (!searchParams) {
                              let url = window.location.href
                              let eIndex = url.toLowerCase().indexOf("/lession/")

                              url = url.substring(0, eIndex)
                              let sIndex = url.toLowerCase().indexOf("/workbook/")

                              url = url.substring(sIndex)
                              props.history.push(`${matchUrl.url}${url}`)
                              return
                            }

                            if (matchUrl.params.id) {
                              if (role === Const.Role.VENDOR) {
                                props.history.push(`/Vendor/ManageClient/${operatorId}/${searchParams}`)
                              }
                            } else if (role === Const.Role.OPERATOR) {
                              props.history.push(`/operator/${searchParams}`)
                            } else {
                              props.history.push(`/Teacher/${searchParams}`)
                            }
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Banner`}>
                        <ButtonBack
                          handleClick={() => { hideMenu(); history.push(`${matchUrl.url}`); }}
                        />
                      </Route>
                    </>)
                }
              </div>
            </div>
          </nav>
        }
        {/*====== End Header Ipad ======*/}

        <main>
          <Route path={`${matchUrl.path}`} exact component={Browse} />
          <Route path={`${matchUrl.path}/Search`} exact component={SectionQuizTest} />
          <Route path={`${matchUrl.path}/Search?keyword=:keyword`} component={SectionQuizTest} />
          <Route path={`${matchUrl.path}/Accounts`} component={Accounts} />
          <Route path={`${matchUrl.path}/AddClass`} component={AddClass} />
          <Route path={`${matchUrl.path}/Classes`} exact component={EditClass} />

          <Route path={`${matchUrl.path}/Classes/:classID`} component={EditClass} />
          <Route path={`${matchUrl.path}/Student/:studentId/:classId`} exact component={studentComponent} />
          <Route path={`${matchUrl.path}/Student/:studentId/:classId/Edit/:type/:assignId/:pageIndex`} component={Edit} />
          <Route path={`${matchUrl.path}/ChangePassword`}>
            <div className="bg-xanh"></div>
            {/* <div className="row">
              <div className="col-6 offset-3" style={{top: "150px"}}>
                <div className="row align-items-center">
                  <div className="mx-auto">
                    <h3 className="font-Paytone">Change Password</h3>
                  </div>
                </div>
              </div>
            </div> */}
            <ChangePassword {...props} />
          </Route>

          <Route path={`${matchUrl.path}/Upload`} component={Upload} />
          <Route path={`${matchUrl.path}/Document/:documentId`} component={Document} />
          <Route path={`${matchUrl.path}/Workbook/:workbookId`} exact component={Workbook} />
          <Route path={`${matchUrl.path}/Workbook/:workbookId/Lession/:lessionId`} component={Lession} />
          <Route path={`${matchUrl.path}/Banner`} component={Banner} />

          {/* ====== Icon chat ====== */}
          <div className="chat" style={{ zIndex: 1000 }}>
            {countNotif > 0 && <span className="badge radius-10 badge-danger position-absolute badge-form">{countNotif}</span>}

            <button
              className="button-show-popupchat"
              style={{ backgroundColor: 'transparent' }}
              type="button"
              onClick={() => { setOpenPopup(true) }}
            ><i className="fas fa-comments fa-2x" /></button>
          </div>
          {/* ======================= */}

          {/* ====== */}
          {openPopup &&
            <PopupChat
              role={role}
              message={message}
              setMessage={setMessage}
              messageList={messageList}
              setOpenPopup={setOpenPopup}
              operatorId={operatorId}
              sendMessage={sendMessage}

              listClass={listClass}
              ClassId={ClassId}
              setClassId={setClassId}
              ListStudent={ListStudent}
              ListTeacher={ListTeacher}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              tabChat={tabChat}

              setTabChat={setTabChat}
              userType={userType}
              setUserType={setUserType}
              userName={userName}
              setUserName={setUserName}
              getListNotif={getListNotif}
              scrollToBottom={scrollToBottom}
              messageEndRef={messageEndRef}
              setUserSendMessagge={setUserSendMessagge}
              getListMessageInGroup={getListMessageInGroup}
              GetChatMessages={GetChatMessages}
              getDetailClass={getDetailClass}
              setPageIndex={setPageIndex}
              onScrollTop={onScrollTop}
              pageIndex={pageIndex}
            />}
        </main>
      </>
    )
}

export default React.memo(LayoutComponent)