import React from 'react';

function ZoomPageView(props) {
    const { 
        onZoomIn,
        onZoomOut,
        onFitPage,
    } = props
    return (
        <div className="box-zoom" style={{zIndex: 1000}}>
            <div className="zoom mr-1 pointer" onClick={onZoomOut} >
                <i className="fas fa-search-minus font-19"></i>
            </div>
            <div className="tran-70 p-1" onClick={onFitPage} >
                <span className="box-time">
                    <button type="button" className="btn px-3 tran-80 text-white text-nowrap">
                        Fit Page
                    </button>
                </span>
            </div>
            <div className="zoom ml-1 pointer" onClick={onZoomIn} >
                <i className="fas fa-search-plus font-19"></i>
            </div>
        </div>
    );
}

export default ZoomPageView;
