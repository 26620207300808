import { combineReducers } from 'redux';
import ws from './ws';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const rootPersistConfig = {
    key: 'root',
    storage: storage,
};

const authPersistConfig = {
    key: 'auth',
    storage: storage
};

const rootReducer = combineReducers({
    ws: persistReducer(authPersistConfig, ws)
});

export default persistReducer(rootPersistConfig, rootReducer);