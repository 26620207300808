import React from "react"
// import { Link, useRouteMatch } from "react-router-dom"
import { Link } from "react-router-dom"
import ChangePassword from "../../Login/_ChangePassword"

function _TopNav(props) {
  return (
    <>
      <div className="bg-xanh"></div>
      <nav className="header fixed-top p-3 z-5">
        <div className="row align-items-center">
          <div className="mx-auto">
            <h3 className="font-Paytone">Change Password</h3>
          </div>
          <div className="col-auto">
            {/* <Link to="#" onClick={() => props.history.goBack()}> */}
            <div onClick={() => props.history.goBack()}>
              <i className="fas fa-arrow-left fa-3x"></i>
            </div>
            {/* </Link> */}
          </div>
        </div>
      </nav>
      <ChangePassword {...props} />
    </>
  )
}

export default _TopNav
