import React from 'react';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';
import ClassMessage from '../../../../components/ClassMessage';
import ModalAlertOperator from '../../../../components/ModalAlertOperator';

import { useSelector } from 'react-redux'
import { customSelectStyles, classNameRegExpVn } from '../../../../constants/Const';
import * as Fc from "../../../../utils/Functions";
import Code from "./Item/Code";
import ModalShowInfoUser from './../../../../components/ModalShowInfoUser/ModalShowInfoUser'

import classNames from 'classnames'
import { Formik } from "formik"
import * as Yup from "yup"
import './style.css';

function EditClassForm(props) {
    const role = Fc.getRole()
    const isLandscape = useSelector(state => state.ws.isLandscape)
    const AccountMethod = Fc.getAccountMethod()

    const {
        primaryTeacher, teacherOptions,
        getListTeacher,
        classOptions, getListClass,
        classInfo, onChooseClass,
        name, checkActiveClass,
        selectedUser, userOptions, getListUser,
        onAddUser, selectAll, selectAllUser,
        enableAll, setEnableSolution, listStudents,
        selectUser, listTeachers, onShowRemoveUserModal,
        isActive, updateClass, onShowDeleteModal, showRemoveUserModal,
        hideRemoveUserModal, removeUser,
        showDeleteModal, hideDeleteModal, deleteClass,
        isShowErrorModal, onHideErrorModal, matchUrl,
        firstStudentId, isShowRestrictionModal,
        onHideRestrictionModal, errorMessage, setErrorMessage, setShowRestrictionModal,
        accountMethod, checkTeacherDisable, checkClassName,
        checkEnableAllSolution, onHideTrialModal,
        isShowTrialModal,
        messageDayleftTeacherOrStudent, selectedUserForAddClass,
        isShowInfoUser, setShowInfoUser,
        onHideInfoUser,
        setIdStudent, idUser,
        setTypeUser, typeUser
    } = props
    // .matches(/^(.*)?\S+(.*)?$/, 'Required')
    const validateForm = Yup.object().shape({
        name: Yup.string().matches(classNameRegExpVn, 'Please enter valid name').max(40, 'Class name must be at most 40 characters').required("Required").matches(/^(.*)?\S+(.*)?$/, 'Required')
    })
    listStudents.sort((a, b) => {
        if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
        if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
        return 0;
    })
    return (
        <Formik
            initialValues={{  name: name }}
            enableReinitialize
            onSubmit={(values) => { updateClass();}}
            validationSchema={validateForm}
        >
            {(properties) => {
                const {
                    touched,
                    errors,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                } = properties

                return (
                    <>
                    {/* <form onSubmit={handleSubmit}> */}
                    <div style={{ paddingTop: 73 }} className='noOverScroll'>
                        <div className="bg-xanh noOverScroll"></div>
                        <div id="shadow-overlay noOverScroll"  style={{ top:128 }}></div>
                        <section className="box-xam">
                            <div className="d-flex wrapper">
                                <div className="input-group w-50" style={{zIndex:11}}>
                                    <Select
                                        className="form-control form-control-sista2"
                                        value={classInfo}
                                        options={classOptions}
                                        onInputChange={getListClass}
                                        onChange={(cl) => {
                                            onChooseClass(cl)
                                        }}
                                        placeholder="Enter class ID"
                                        styles={customSelectClassStyles}
                                        components={{ DropdownIndicator }}
                                        onFocus={getListClass}
                                    />
                                </div>
                                <div className="col-auto ml-auto pr-0"><Link to={classInfo ? matchUrl.url.substring(0, matchUrl.url.length - 44) + `AddClass` : matchUrl.url.substring(0, matchUrl.url.length - 7) + `AddClass`} className="btn font-Paytone bg-30 radius10 pl-3 pr-3 bg-white"><i className="fas fa-plus mr-2"></i>Add</Link></div>
                            </div>
                        </section>
                        <section className="container-fluid">
                            <div className="p-4">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="usr" className="font-Paytone">Class Name*</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="name"
                                            value={name} 
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleChange(e);
                                                checkClassName(e)
                                            }}
                                        />
                                        {
                                            errors.name && touched.name
                                            &&  ( <div className="validate-input mt-1">* {errors.name}</div>  )
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="usr" className="font-Paytone">Primary Teacher*</label>
                                        <Select
                                            className="form-control"
                                            value={teacherOptions.filter(option => option.value === primaryTeacher._id)}
                                            options={teacherOptions}
                                            onInputChange={getListTeacher}
                                            onChange={(e) => { checkTeacherDisable(e) }}
                                            placeholder=""
                                            styles={customSelectStyles}
                                            components={{ DropdownIndicator }}
                                            onFocus={getListTeacher}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <ClassMessage classId={classInfo._id} props /> HUYNH */} 
                        <section className="container-fluid">
                            <div className="p-4">
                                <h4 className="font-Paytone mt-0 font16">Class List</h4>

                                <div style={{top: errors.name && touched.name ? 348 : 325}}
                                    className={classNames("box-nd", "tran-60", "position-absolute", "posbox2", [{"posbox2-edit-class-landscape": isLandscape}])} 
                                >
                                    <div 
                                        className="position-absolute z-5 search-table box-chon"
                                        style={{ left: 10, right: 10, zIndex: 2 }}
                                    >
                                        <div className="" role="search">
                                            <div className="input-group">
                                                <Select
                                                    className="form-control form-control-add-class-ipad form-control-sista2 bol-10"
                                                    value={selectedUser}
                                                    options={userOptions}
                                                    onInputChange={getListUser}
                                                    onChange={(user) => {
                                                        selectedUserForAddClass(user)
                                                    }}
                                                    placeholder="Enter user's name or ID..."
                                                    styles={userDropdownStyles}
                                                    onFocus={getListUser}
                                                />
                                                <div className="input-group-append ml-20">
                                                    <button type='button' onClick={onAddUser} className="btn font-Paytone bg-30 btn-light2 radius10 pl-3 pr-3">Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        style={{ left: 10, right: 10 }}
                                        className="position-absolute tle-table tranTB-70"
                                        // className="tle-table tranTB-70" style={{marginTop: -12}}
                                    >
                                        <div className="row" style={{paddingLeft: 3, paddingRight: 3}}>
                                            <div className="col-5 text-muted2">User Name</div>
                                            <div className="col-1 text-muted2 text-center" style={{paddingLeft: 0}}>Grade</div>
                                            <div className="col-3 text-muted2 text-center" style={{paddingLeft: 0}}>Actions</div>
                                            <div className="col-3 text-muted2 text-center">Solution</div>
                                        </div>
                                    </div>
                                    <div className="hidden-scrollbar-scoll position-absolute top-115" style={{bottom: 52}}>
                                        <div className={classNames([{"inner-scroll-ipad": !isLandscape, "inner-scroll-edit-clas-landscape": isLandscape}])}>
                                            {
                                                classInfo !== '' && listTeachers.length + listStudents.length > 0 &&
                                                <div className="row mb-2" style={{marginRight: 0}}>
                                                    <div className="col-5">
                                                        <div className="align-middle">
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" id='selectall' checked={selectAll}
                                                                    onChange={e => selectAllUser(e.target.checked)} />
                                                                <label className="custom-control-label" htmlFor="selectall">Select All</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 text-right"></div>
                                                    <div className="col-3 text-center">
                                                        {listStudents.length > 0 && <span className="switch switch-xs" style={{paddingLeft: 25}}>
                                                            <input type="checkbox" className="switch" id="enableall" checked={enableAll}
                                                                onChange={e => checkEnableAllSolution(e)} />
                                                            <label htmlFor="enableall"></label>
                                                        </span>}
                                                    </div>
                                                </div>
                                            }
                                            {listStudents && listStudents.sort().map((item, index) => {
                                                return (
                                                    <div className="row mb-2" key={index.toString()} style={{marginRight: 0}}>
                                                        <div className="col-5">
                                                            <div className="align-middle">
                                                                <div style={{ textAlign: 'left' }} className="custom-control custom-radio custom-control-inline">
                                                                    <input type="checkbox" className="custom-control-input" id={`check${item._id}`} checked={item.Checked}
                                                                        onChange={(e) => selectUser(item._id, 'Student', e.target.checked)} />
                                                                    <label style={{ textAlign: 'left' }} className="custom-control-label" htmlFor={`check${item._id}`}>
                                                                        <span style={{ cursor: "pointer" }} >{item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)}</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-1 text-center">{item.Grade ? item.Grade : 'N/A'}</div>
                                                        <div className="col-3 text-center">
                                                            {accountMethod === 'Restricted' && <Code
                                                                item={item}
                                                                firstStudentId={firstStudentId}
                                                                matchUrl={matchUrl}
                                                                classInfo={classInfo}
                                                                setErrorMessage={setErrorMessage}
                                                                setShowRestrictionModal={setShowRestrictionModal}
                                                            />}
                                                            {accountMethod !== 'Restricted' && (
                                                                <Link className="text-underline button-join mr-3" style={{paddingLeft: 20}} to={`${matchUrl.url.substring(0, matchUrl.url.length - 45)}/Student/${item._id}/${classInfo._id}`}>Join</Link>
                                                            )}

                                                            <button
                                                                className="text-underline button-view"
                                                                href="true" data-toggle="modal"
                                                                data-target="#myModal1"
                                                                onClick={() => {
                                                                    setShowInfoUser(true)
                                                                    setIdStudent(item._id)
                                                                    setTypeUser("Student")
                                                                }}
                                                            >View</button>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="switch switch-xs" style={{paddingLeft: 25}}>
                                                                <input type="checkbox" className="switch" id={`idChk-normal${item._id}`} checked={item.EnableSolution}
                                                                    onChange={(e) => setEnableSolution(item._id, e.target.checked)} />
                                                                <label htmlFor={`idChk-normal${item._id}`}></label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {listTeachers && listTeachers.sort().map((item, index) => {
                                                return (
                                                    <div className="row mb-2" key={index.toString()} style={{marginRight: 0}}>
                                                        <div className="col-6">
                                                            <div className="align-middle">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="checkbox" className="custom-control-input" id={`check${item._id}`} checked={item.Checked}
                                                                        onChange={(e) => selectUser(item._id, 'Teacher', e.target.checked)} />
                                                                    <label style={{ textAlign: 'left' }} className="custom-control-label" htmlFor={`check${item._id}`}>{item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-1 text-center"></div>
                                                        <div className="col-3 text-center">
                                                            {/* {item.Code} */}
                                                            <Link className="text-underline button-join mr-3 mr-3rem-i" style={{paddingLeft: 20}}></Link>
                                                            <button
                                                                className="text-underline button-view"
                                                                href="true"
                                                                data-toggle="modal"
                                                                data-target="#myModal1"
                                                                onClick={() => {
                                                                    setShowInfoUser(true)
                                                                    setIdStudent(item._id)
                                                                    setTypeUser("Teacher")
                                                                }}
                                                            >View</button>
                                                        </div>
                                                        <div className="col-2 text-center"></div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    <div className="position-absolute" style={{bottom: 10}}>
                                        <button className="btn font-Paytone bg-30 btn-light1" type="button" onClick={onShowRemoveUserModal}>Remove Users</button>
                                    </div>
                                </div>
                                <div className="row align-items-center position-absolute m-0" style={{bottom: isLandscape ? 16 : 150, left: 40, right: 40}}>
                                    <div className="d-flex justify-content-between w-100">
                                        <button type="button" onClick={updateClass} className="btn btn-light font-Paytone btn-block col-3" >Save</button>
                                        <div className="row align-items-center">
                                        <label className="font-Paytone font18 mr-2" htmlFor="isEnable">Class {isActive ? 'enable' : "disable"}</label>
                                        <span className="switch">
                                            <input type="checkbox" className="switch" id="isEnable" checked={isActive}
                                                onChange={(e) => {
                                                    checkActiveClass(e)
                                                }} />
                                            <label htmlFor="isEnable"></label>
                                        </span>
                                        </div>
                                        <button type="button" className="btn btn-light font-Paytone btn-block col-3">
                                            <span className="text-danger" onClick={onShowDeleteModal}>Delete Class</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Modal
                            show={showRemoveUserModal}
                            onHide={hideRemoveUserModal}
                            className="modal fade"
                            id="myModal"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content bg-epen pte-auto">
                                    <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                                        <h4 className="modal-title font-Paytone">
                                            Are you sure you want to remove?
                                        </h4>
                                    </div>
                                    <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                                        <div className="row">
                                            <div className="col-5 mr-auto">
                                                <button
                                                    type="button"
                                                    onClick={hideRemoveUserModal}
                                                    className="btn btn-light font-Paytone btn-block"
                                                >
                                                    Cancel
                                                            </button>
                                            </div>
                                            <div className="col-5">
                                                <button
                                                    type="button"
                                                    onClick={removeUser}
                                                    className="btn btn-light font-Paytone float-right btn-block"
                                                >
                                                    <span className="text-danger">Confirm</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            show={showDeleteModal}
                            onHide={hideDeleteModal}
                            className="modal fade"
                            id="myModal"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content bg-epen pte-auto">
                                    <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                                        <h4 className="modal-title font-Paytone">
                                            Are you sure you want to delete this class?
                                        </h4>
                                    </div>
                                    <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                                        <div className="row">
                                            <div className="col-5 mr-auto">
                                                <button
                                                    type="button"
                                                    onClick={hideDeleteModal}
                                                    className="btn btn-light font-Paytone btn-block"
                                                >
                                                    Cancel
                                                            </button>
                                            </div>
                                            <div className="col-5">
                                                <button
                                                    type="button"
                                                    onClick={deleteClass}
                                                    className="btn btn-light font-Paytone float-right btn-block"
                                                >
                                                    <span className="text-danger">Confirm</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            show={isShowErrorModal}
                            onHide={onHideErrorModal}
                            className="modal fade"
                            id="myModal"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content bg-epen pte-auto">
                                    <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5 text-center m-auto">
                                        <span>Class Name already exists.
                                            <br />
                                    Please change you class name into <br /> <b>{name}A</b> or <b>{name}B</b> instead</span>
                                    </div>
                                    <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                                        <div className="row">
                                            <div className="col-5 m-auto">
                                                <button
                                                    type="button"
                                                    onClick={onHideErrorModal}
                                                    className="btn btn-light font-Paytone btn-block"
                                                >
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/*Modal show info account*/}
                        {isShowInfoUser && <ModalShowInfoUser
                            isShowInfoUser={isShowInfoUser}
                            setShowInfoUser={setShowInfoUser}
                            onHideInfoUser={onHideInfoUser}
                            idUser={idUser}
                            typeUser={typeUser}
                        />}

                        {/* Account Trials */}
                        <ModalAlertOperator
                            showModal={isShowTrialModal}
                            alertMessage={messageDayleftTeacherOrStudent}
                            role={role}
                            AccountMethod={AccountMethod}
                            keyStorage={'PopupDayleftTeacher'}
                            functionClose={onHideTrialModal}
                            functionUpgrade={null}
                        />

                        {/* Account Restricted */}
                        <ModalAlertOperator
                            showModal={isShowRestrictionModal}
                            alertMessage={errorMessage}
                            role={role}
                            AccountMethod={AccountMethod}
                            functionClose={() => {
                                onHideRestrictionModal()
                            }}
                            functionUpgrade={null}
                        />

                    </div>
                    {/* </form> */}
                    </>
                )
            }}
        </Formik>
    )
}

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="fa fa-search text-white"></i>
        </components.DropdownIndicator>
    );
};

const userDropdownStyles = {
    ...customSelectStyles,
    indicatorsContainer: (provided, state) => ({
        ...provided,
        display: 'none',
    })
}

const customSelectClassStyles = {
    ...customSelectStyles,
    singleValue: (provided, state) => ({
        ...provided,
        color: '#858585',
    })
}

export default React.memo(EditClassForm);