import React from 'react';
import { Role } from '../../constants/Const'
import moment from 'moment';
import './style.css'
import classNames from 'classnames'

function PersonTab(props) {
    const {
        messageList,
        userLoginRx,
        ClassId
    } = props

    return (
        <>
        {(ClassId !== "" && messageList && messageList.length > 0) && messageList.map((item, index) => {
            if(item.data) {
                return (
                    <div style={{width: '100%'}} key={index.toString()} className="item_chat">
                        <p style={{textAlign: 'center'}}><span className="date-color">
                            {moment(new moment(item.date, 'DD/MM/YYYY')).format('LL')}
                        </span></p>
                        {
                            item.data.map((e, i) => {
                                if (e.Type === "normal") {
                                    if (e.From === Role.VENDOR || e.From.Role === Role.VENDOR) {
                                        return (
                                            <> </>
                                        )
                                    }
                                    else if (e.FromRole === Role.OPERATOR || e.From.Role === Role.OPERATOR) {
                                        return (
                                            <div key={i.toString()} className="text-justify" style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <p style={{display: 'inline-block'}}><span className="operator-text"><strong>{typeof e.From === "string" ? e.FromName : e.From.Name}</strong></span>: {e.Body}</p>
                                                <p style={{display: 'inline-block'}}><span className="time-text">{e.Time}</span></p>
                                            </div>
                                        )
                                    }

                                    return(
                                        <div key={i.toString()} className="text-justify" style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <p style={{display: 'inline-block'}}>
                                                <span className={classNames([{
                                                    "current-user-text":(e.From._id === userLoginRx._id || e.From === userLoginRx._id),
                                                    "user-text":!(e.From._id === userLoginRx._id || e.From === userLoginRx._id),
                                                }])}><strong>{typeof e.From === "string" ? e.FromName : e.From.Name}:</strong> </span>{e.Body}
                                            </p>
                                            <p style={{display: 'inline-block'}}><span className="time-text">{e.Time}</span></p>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                )
            }
        })}
        </>
    );
}

export default PersonTab;