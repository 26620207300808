import React, { useState } from "react"
import API from "../../utils/Api"
import * as Fc from "../../utils/Functions"
import { Formik } from "formik"
import * as Yup from "yup"
import { passwordRegExp } from '../../constants/Const'

export default function _ChangePassword(props) {
  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  function changePassword() {
    API.changePass(password, newPassword)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.responseStatusCode === 200) {
            props.history.goBack()
            Fc.showSuccess("Change password success")
          } else Fc.showError(res.data.responseStatusMessage)
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        console.log(err);
        Fc.showError("Can not connect to server")
      })
  }

  const validateForm = Yup.object().shape({
    password: Yup.string().required("Required"),
    newPassword: Yup.string().required("Required")
      .matches(passwordRegExp, 'Password must have at least 8 characters, at least one uppercase letter, one lowercase letter and one number'),
    confirmPassword: Yup.string().required("Required")
      .equals([newPassword], 'Confirm password is not match'),
  })

  return (

    <div className="modal-dialog-centered modal-dialog-login">
      <div className="col-md-12">
        <Formik
          initialValues={{
            password,
            newPassword,
            confirmPassword
          }}
          enableReinitialize
          onSubmit={changePassword}
          validationSchema={validateForm}
        >
          {(properties) => {
            const {
              touched,
              errors,
              handleSubmit,
              handleChange,
              handleBlur,
            } = properties

            return (
              <form className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Recent password"
                    value={password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      setPassword(e.target.value)
                    }}
                  />
                  {errors.password && touched.password && (
                    <div className="validate-input mt-1">* {errors.password}</div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="New password"
                    value={newPassword}
                    name="newPassword"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      setNewPassword(e.target.value)
                    }}
                  />
                  {errors.newPassword && touched.newPassword && (
                    <div className="validate-input mt-1">* {errors.newPassword}</div>
                  )}
                </div>
                <div className="form-group mb-4">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      setConfirmPassword(e.target.value)
                    }}
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <div className="validate-input mt-1">* {errors.confirmPassword}</div>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-light font-Paytone btn-block mb-3"
                >
                  Confirm
            </button>
              </form>

            )
          }}
        </Formik>
      </div>
    </div>
  )
}
