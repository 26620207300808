import React from 'react';
import { Link, NavLink, useHistory } from "react-router-dom";
import * as Const from "../../constants/Const";
import { useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'

function HeaderDesktop(props) {
    const url = useRouteMatch()
    const history = useHistory()
    let { role, hideMenu, matchUrl, operatorInfo, logOut } = props
    const pathName = history.location.pathname
    
    const isUser = pathName.includes("/Student") || pathName.includes("/Accounts")
    const isClassroom = pathName.includes("/Classes") || pathName.includes("/AddClass")
    const isBanner = pathName.includes("/Banner")
    const isChangePass = pathName.includes("/ChangePassword")
    const isBrowse = !isUser && !isClassroom && !isBanner && !isChangePass 
    const isStudent = pathName.includes("/Student")

    return (
        <>
            <nav className="header fixed-top z-5 px-4 header-shadow" style={{height: 50,}}>
                <div className="row align-items-center">
                    <div className="col-auto mr-auto"><h4 className="font-Paytone py-2 pl-3 mb-0 three-dot-100">
                    {   
                        !(operatorInfo && operatorInfo.BusinessName || operatorInfo && operatorInfo.FullName)
                        ?   (operatorInfo.FirstName &&  operatorInfo.LastName) ? operatorInfo.FirstName + " " + operatorInfo.LastName : ""
                        :   (operatorInfo && operatorInfo.BusinessName || operatorInfo && operatorInfo.FullName)
                    }
                    </h4></div>
                    <div className="col-auto">
                        <ul className="nav w-100 justify-content-end">
                        {role.includes(Const.Role.VENDOR) && (
                            <li className="nav-item">
                                
                                        <NavLink
                                            onClick={() => { hideMenu() }}
                                            to="/Vendor"
                                            className="list-group-item bg-transparent font-Paytone font16 border-0 px-4"
                                        >
                                            Location
                                        </NavLink>
                                    
                            </li>
                        )}
                        {(role === Const.Role.VENDOR || role === Const.Role.OPERATOR || role === Const.Role.TEACHER) &&
                            <li className="nav-item active">
                                 
                                <NavLink
                                    onClick={() => { hideMenu() }}
                                    to={`${matchUrl.url}`}
                                    className="list-group-item bg-transparent font-Paytone font16 border-0 px-4"
                                    activeClassName={isBrowse ? "active-desktop" : ""}
                                >
                                    Browse
                                </NavLink>
                            </li>}

                            {role === Const.Role.STUDENT && 
                            <li className="nav-item ">

                                <NavLink
                                    onClick={() => {  hideMenu() }}
                                    to={`${matchUrl.url}`}
                                    className="list-group-item bg-transparent font-Paytone font16 border-0 px-4"
                                    activeClassName={isStudent && !isChangePass ? "active-desktop" : ""}
                                >
                                    My Work
                                </NavLink>
                            </li>}
                            {(role === Const.Role.VENDOR || role === Const.Role.OPERATOR) &&
                            <li className="nav-item">
                                 
                                <NavLink
                                    onClick={() => { hideMenu()  }}
                                    to={`${matchUrl.url}/Accounts`}
                                    className={classNames("list-group-item", "bg-transparent", "font-Paytone", "font16 border-0", "px-4", [{"active-desktop" :isUser}])}
                                    activeClassName={isUser ? "active-desktop" : ""}
                                >
                                    Users
                                </NavLink>
                            </li>}
                                                        
                            <li className="nav-item ">
                                {role !== Const.Role.STUDENT && 
                                (
                                    !pathName.includes("/AddClass") 
                                    ?
                                        <NavLink
                                            onClick={() => { hideMenu() }}
                                            to={`${matchUrl.url}/Classes`}
                                            className="list-group-item bg-transparent font-Paytone font16 border-0 px-4"
                                            activeClassName={isClassroom ? "active-desktop" : ""}
                                        >
                                            Classroom
                                        </NavLink>
                                    :
                                        <NavLink
                                            onClick={() => { hideMenu() }}
                                            to={`${matchUrl.url}/AddClass`}
                                            className="list-group-item bg-transparent font-Paytone font16 border-0 px-4"
                                            activeClassName={isClassroom ? "active-desktop" : ""}
                                        >
                                            Classroom
                                        </NavLink>
                                )
                                }
                            </li>
                            
                            {(role.includes(Const.Role.VENDOR) || (operatorInfo && operatorInfo.DetailUserInfo && operatorInfo.DetailUserInfo.PaymentSetting.CanEditBanner)) &&
                            <li className="nav-item">
                                
                                    <NavLink
                                        to={`${matchUrl.url}/Banner`}
                                        onClick={() => { hideMenu() }}
                                        className="list-group-item bg-transparent font-Paytone font16 border-0 px-4"
                                        activeClassName={isBanner ? "active-desktop" : ""}
                                    >
                                        Banner
                                    </NavLink>
                              
                            </li> }
                            <li className="nav-item">
                                <NavLink
                                    onClick={() => { hideMenu() }}
                                    to={`${matchUrl.url}/ChangePassword`}
                                    className="list-group-item bg-transparent font-Paytone font16 border-0 px-4"
                                    activeClassName={isChangePass ? "active-desktop" : ""}
                                >
                                    Change Password
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="#"
                                    onClick={logOut}
                                    className="list-group-item bg-transparent font-Paytone font16 border-0 px-4"
                                >
                                    <span className="text-danger">Log Out</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default HeaderDesktop;