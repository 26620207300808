/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import API from "../../../../utils/Api"
import * as Fc from "../../../../utils/Functions"
// import _ from "lodash"
import * as Const from "../../../../constants/Const"
import AddClassForm from "./AddClassForm"
import AddClassFormDesktop from "./AddClassFormDesktop"
import { useRouteMatch, useHistory } from "react-router-dom";
import { isDesktop, isMobile } from "react-device-detect"

import { useDispatch } from 'react-redux'
import * as type from "../../../../constants/actionType"
import './style.css'

export default function AddClass(props) {
  let matchUrl = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const operatorId = Fc.getRole().includes(Const.Role.VENDOR) ? props.match.params.id : Fc.getOperator()
  const [name, setName] = useState("")
  const [primaryTeacher, setPrimaryTeacher] = useState("")
  const [teacherOptions, setTeacherOption] = useState([])
  const [listUsers, setListUser] = useState([])
  //get all student
  const [listAllUser, setListAllUser] = useState([])
  const [userKeyword, setUserKeyword] = useState("")
  const [classId, setClassId] = useState("")
  const [isShowModal, setShowModal] = useState(false)
  const [listTeachers, setListTeachers] = useState([])

  // Check create Alert
  const [alertMessage, setAlertMessage] = useState("")
  const [isShowAddClassAlertModal, setShowAddClassAlertModal] = useState(false)
  const [dayLeft, setDayLeft] = useState(0)

  const [errorMessage, setErrorMessage] = useState("")
  const [isShowAddClassErrorModal, setShowAddClassErrorModal] = useState(false)

  const [isShowInfoUser, setShowInfoUser] = useState(false)
  const [idUser, setIdStudent] = useState("")
  const [typeUser, setTypeUser] = useState("")
  const [isDisable, setIsDisable] = useState(false)

  function onHideInfoUser() {
    setShowInfoUser(false)
  }

  function ShowAddError() {
    setShowAddClassErrorModal(true)
  }

  function onHideAddError() {
    setShowAddClassErrorModal(false)
  }

  function ShowAddAlert() {
    setShowAddClassAlertModal(true)
  }

  function onHideAddAlert() {
    setShowAddClassAlertModal(false)
  }

  useEffect(() => {
    getListTeacher()
    getListUser()
  }, [])

  useEffect(() => {
    if (name && name !== "" && primaryTeacher !== "")
      setClassId(name.replace(/[^a-zA-Z0-9]/g, "") + primaryTeacher.Code)
    else
      setClassId('')
  }, [name, primaryTeacher])

  useEffect(() => {
    filterUsers()
  }, [userKeyword, listAllUser, primaryTeacher])

  useEffect(() => {
    if (Fc.getAccountMethod() === Const.AccountMethod.RESTRICTED) {
      setAlertMessage("This teacher account has been locked.")
    }
    primaryTeacher && ShowAddAlert()
  }, [primaryTeacher])

  useEffect(() => {
    if (dayLeft <= 0) {
      setAlertMessage("This teacher account will be blocked after today.")
    }
    else {
      setAlertMessage("This teacher account will be blocked after " + dayLeft + " days")
    }

  }, [dayLeft])

  useEffect(() => {
    API.GetDayLeftToRestriction(operatorId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.Success) {
            if (res.data.Data) {
              setDayLeft(res.data.Data);
            }
          } else {
            setAlertMessage(res.data.Message)
            // ShowAddAlert()
          }
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        Fc.showError(err)
      })
  }, [])

  // useEffect(() => {
  //   if (listTeachers.length > 0) {
  //     ShowAddAlert()
  //   }
  // }, [listTeachers])

  function onHideModal() {
    setShowModal(false)
  }

  function getListTeacher(val) {
    API.listTeachers(operatorId, 1, 20, val).then((res) => {
      if (res.status === 200 && res.data.Success) {
        let listOptions = []
        res.data.Data && res.data.Data.map((item) => {
          listOptions.push({
            value: item.Code,
            label: `${item.FirstName} ${item.LastName} - ${item.Code}`,
            ...item,
          })
        })
        setTeacherOption(listOptions.sort((a, b) => {
          if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
          if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
          return 0;
        }))
      }
    })
  }

  function getListUser() {
    API.listUsers(operatorId, 1, 20, "")
      .then((res) => {
        if (res.status === 200 && res.data.Success) {
          res.data.Data.Students && res.data.Data.Students.map(item => {
            item.Type = "Student"
          })
          res.data.Data.Teachers && res.data.Data.Teachers.map(item => {
            item.Type = "Teacher"
          })
          let listUsers = res.data.Data.Students.concat(res.data.Data.Teachers)
          setListAllUser(listUsers)
          setListUser(listUsers)
        } else {
          if (res.data.Message)
            Fc.showError(res.data.Message)
          else
            Fc.showError(res.statusText)
        }
      })
      .catch((err) => console.log(err))
  }

  function clearForm() {
    setName("")
    setPrimaryTeacher("")
    setClassId("")
    let allUsers = [...listAllUser]
    allUsers && allUsers.map((item) => (item.checked = false))
    setListAllUser(allUsers)
    setListUser(allUsers)
    setUserKeyword("")
  }

  function filterUsers() {
    let keyword = userKeyword ? userKeyword.toLowerCase() : "";
    let users = listAllUser.filter(
      x => (primaryTeacher === '' || x._id !== primaryTeacher._id)
        && (x.FullName && x.FullName.toLowerCase().includes(keyword.trim()) || x.Code.toLowerCase().includes(keyword.trim()))
    )
    setListUser(users.sort((a, b) => (a.checked === b.checked) ? 0 : (a.checked ? -1 : 1)))
  }

  function onCheckStudents(id, checked) {
    let allUsers = [...listAllUser]
    let student = allUsers.filter((x) => x.Code === id)[0]

    if (student.Type === "Teacher") {
      let teacher = []
      teacher.push(student)
      setListTeachers(teacher)
    }

    student.checked = checked
    setListAllUser(allUsers)
    // p
    if (student.Type === "Teacher" && student.checked) {
      if (Fc.getAccountMethod() === Const.AccountMethod.RESTRICTED) {
        setAlertMessage("This teacher account has been locked.")
      }
      ShowAddAlert()
    }
  }

  function insertClass() {
    //han che multi submit
    if(isDisable) return
    setIsDisable(true)

    if (!primaryTeacher || primaryTeacher === {}) {
      Fc.showError('Please choose primary teacher!')
      setIsDisable(false)
      return
    }
    let students = listAllUser.filter(x => x.Type === 'Student' && x.checked)
    let teachers = listAllUser.filter(x => x.Type === 'Teacher' && x.checked && x._id !== primaryTeacher._id)
    let body = {
      Name: name,
      PrimaryTeacherId: primaryTeacher._id,
      OperatorId: operatorId,
      StudentIds: students.map(item => item._id),
      AssistantTeacherIds: teachers.map(item => item._id)
    }

    API.CreateClass(body).then((res) => {
      if (res.status === 200) {
        if (res.data.Success) {
          Fc.showSuccess("Create class success")
          clearForm()
        } else {
          if (res.data.Message === "Class Name already exist") {
            setShowModal(true)
          }
          else if (res.data.Message === "You need to upgrade to use this feature") {
            if (Fc.getRole() === "VENDOR") {
              Fc.showError(res.data.Message)
            }
            setErrorMessage(res.data.Message)
            ShowAddError()
          }
          else
            Fc.showError(res.data.Message)
        }
      } 
      setIsDisable(false)
    })
      .catch((err) => setIsDisable(false))
  }
  
  function saveUserToRedux(user) {
    dispatch({ type: type.SAVE_USER_INFO, payload: user })
  }

  function handleEdit(item){
    const typeUser = item.Type;
    const idUser = item._id;
    const operatorId = Fc.getRole() === "VENDOR" ? matchUrl.params.id : Fc.getUserId()
    if(!operatorId) return

    const pathUrl = Fc.getRole() === "VENDOR" ? `/Vendor/ManageClient/${operatorId}/Accounts` : '/operator/Accounts'
    if (typeUser === "Student") {
        API.getStudentDetail(idUser)
        .then((res) => {
            if (res.status === 200 && res.data.Success) {
              saveUserToRedux(res.data.Data)
              history.push(pathUrl);
            }
        })
        .catch((err) => {
            Fc.showError(err)
        })
    } else {
        API.getTeacherDetail(idUser)
        .then((res) => {
            if (res.status === 200 && res.data.Success) {
              saveUserToRedux(res.data.Data)
              history.push(pathUrl);
            }
        })
        .catch((err) => {
            Fc.showError(err)
        })
    }
  }

  if (isDesktop && !isMobile) {
    return (
      <AddClassFormDesktop
        name={name}
        setName={setName}
        primaryTeacher={primaryTeacher}
        setPrimaryTeacher={setPrimaryTeacher}
        teacherOptions={teacherOptions}
        setTeacherOption={setTeacherOption}
        userKeyword={userKeyword}
        setUserKeyword={setUserKeyword}
        classId={classId}
        getListTeacher={getListTeacher}
        clearForm={clearForm}
        insertClass={insertClass}
        onCheckStudents={onCheckStudents}
        listUsers={listUsers}
        isShowModal={isShowModal}
        onHideModal={onHideModal}
        alertMessage={alertMessage}
        isShowAddClassAlertModal={isShowAddClassAlertModal}
        ShowAddAlert={ShowAddAlert}
        onHideAddAlert={onHideAddAlert}
        isShowAddClassErrorModal={isShowAddClassErrorModal} onHideAddError={onHideAddError}
        errorMessage={errorMessage}
        isShowInfoUser={isShowInfoUser}
        setShowInfoUser={setShowInfoUser}
        onHideInfoUser={onHideInfoUser}
        idUser={idUser}
        setIdStudent={setIdStudent}
        setTypeUser={setTypeUser}
        typeUser={typeUser}
        matchUrl={matchUrl}
        handleEdit={handleEdit}
      />
    )

  }

  return (
    <AddClassForm
      name={name}
      setName={setName}
      primaryTeacher={primaryTeacher}
      setPrimaryTeacher={setPrimaryTeacher}
      teacherOptions={teacherOptions}
      setTeacherOption={setTeacherOption}
      userKeyword={userKeyword}
      setUserKeyword={setUserKeyword}
      classId={classId}
      getListTeacher={getListTeacher}
      clearForm={clearForm}
      insertClass={insertClass}
      onCheckStudents={onCheckStudents}
      listUsers={listUsers}
      isShowModal={isShowModal}
      onHideModal={onHideModal}
      alertMessage={alertMessage}
      isShowAddClassAlertModal={isShowAddClassAlertModal}
      ShowAddAlert={ShowAddAlert}
      onHideAddAlert={onHideAddAlert}
      isShowAddClassErrorModal={isShowAddClassErrorModal} onHideAddError={onHideAddError}
      errorMessage={errorMessage}
      isShowInfoUser={isShowInfoUser}
      setShowInfoUser={setShowInfoUser}
      onHideInfoUser={onHideInfoUser}
      idUser={idUser}
      setIdStudent={setIdStudent}
      setTypeUser={setTypeUser}
      typeUser={typeUser}
      matchUrl={matchUrl}
    />
  )
}
