/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import * as Fc from '../../../utils/Functions'
import * as Const from '../../../constants/Const'
import API from '../../../utils/Api'
// import { useRouteMatch } from 'react-router-dom'

import SendWorksheets from '../../../components/SendWorksheets'

export default function Document(props) {
    const documentId = props.match.params.documentId
    const [listWorksheets, setListWorksheets] = useState([])

    useEffect(() => {
        getWorksheets();
    }, [])

    function getWorksheets() {
        API.getWorkSheetsByDocumentId(documentId).then(res => {
            if (res.status === 200 && res.data.Success) {
                setListWorksheets(res.data.Data)
            } else {
                Fc.showError(res.data.Message || res.statusText)
                props.history.goBack()
            }
        }).catch(err => {
            Fc.showError("Can not connect to server")
        })
    }

    return (
        listWorksheets.length > 0 
        ?   <SendWorksheets
                listWorksheets={listWorksheets}
                {...props} 
            /> 
        :   <div className="bg-xam77"></div>
    )
}