import React from 'react';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';

import ClassMessage from '../../../../components/ClassMessage';
import ModalAlertOperator from '../../../../components/ModalAlertOperator';
import { customSelectStyles, classNameRegExpVn, whiteSpaceReg } from '../../../../constants/Const';
import * as Fc from "../../../../utils/Functions";
import Code from "./Item/Code";
import ModalShowInfoUser from './../../../../components/ModalShowInfoUser/ModalShowInfoUser'

import { Formik } from "formik"
import * as Yup from "yup"
import './style.css';
import { isDesktop, isMobile } from 'react-device-detect';
import useCheckdevice from '../../../../hooks/useCheckdevice'
import classNames from 'classnames'

function EditClassDesktop(props) {
    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))
    const role = Fc.getRole()
    const AccountMethod = Fc.getAccountMethod()

    const {
        primaryTeacher, teacherOptions,
        getListTeacher,
        classOptions, getListClass,
        classInfo, onChooseClass,
        name, checkActiveClass,
        selectedUser, userOptions, getListUser,
        onAddUser, selectAll, selectAllUser,
        enableAll, setEnableSolution, listStudents,
        selectUser, listTeachers, onShowRemoveUserModal,
        isActive, updateClass, onShowDeleteModal, showRemoveUserModal,
        hideRemoveUserModal, removeUser,
        showDeleteModal, hideDeleteModal, deleteClass,
        isShowErrorModal, onHideErrorModal, matchUrl,
        firstStudentId, isShowRestrictionModal,
        onHideRestrictionModal, errorMessage, setErrorMessage, setShowRestrictionModal,
        accountMethod, checkTeacherDisable, checkClassName,
        checkEnableAllSolution, onHideTrialModal,
        isShowTrialModal,
        messageDayleftTeacherOrStudent, selectedUserForAddClass,
        isShowInfoUser, setShowInfoUser,
        onHideInfoUser,
        setIdStudent, idUser,
        setTypeUser, typeUser
    } = props

    listStudents.sort((a, b) => {
        if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
        if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
        return 0;
    })
    const validateForm = Yup.object().shape({
        name: Yup.string()
        .matches(classNameRegExpVn, 'Please enter valid name').max(40, 'Class name must be at most 40 characters')
        .required("Required")
        .matches(whiteSpaceReg, 'Required')
    })

    return (
        <Formik
            initialValues={{
                name: name
            }}
            enableReinitialize
            onSubmit={updateClass}
            validationSchema={validateForm}
        >
            {(properties) => {
                const {
                    touched,
                    errors,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                } = properties
                return (
                    <>
                        {/* <form onSubmit={handleSubmit}> */}
                            <div className="bg-xanh"></div>
                            <div id="shadow-overlay"></div>
                            <div style={{ paddingTop: 51 }}>
                            <section className="box-xam py-2 mb-3">
                                <div className="d-flex w-100 justify-content-center">
                                    <div className="w-50 d-flex justify-content-end">
                                        <div className="w-75">
                                            <div className="w-100 pl-0">
                                                <div className="input-group" style={{zIndex:11}}>
                                                    <Select
                                                        className="form-control form-control-sista2"
                                                        value={classInfo}
                                                        options={classOptions}
                                                        onInputChange={getListClass}
                                                        onChange={(cl) => {
                                                            onChooseClass(cl)
                                                        }}
                                                        placeholder="Search for Class..."
                                                        styles={customSelectClassStyles}
                                                        components={{ DropdownIndicator }}
                                                        onFocus={getListClass}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-right ml-1">
                                        {isMobile && <Link to={classInfo ? matchUrl.url.substring(0, matchUrl.url.length - 44) + `AddClass` : matchUrl.url.substring(0, matchUrl.url.length - 7) + `AddClass`} className="btn font-Paytone bg-30 radius10 pl-3 pr-3 tran-60 text-white"><i className="fas fa-plus mr-2"></i>Add</Link>}
                                        {(!isMobile && isDesktop) &&
                                            <>
                                                <Link to="#" className="btn bg-30 radius10 pl-3 pr-3 d-none" style={{ backgroundColor: '#fff', color: "#000" }}>
                                                    <i className="fas fa-edit mr-3"></i>Manage Class
                                                </Link>
                                                <Link to={classInfo ? matchUrl.url.substring(0, matchUrl.url.length - 44) + `AddClass` : matchUrl.url.substring(0, matchUrl.url.length - 7) + `AddClass`} className="btn pl-3 pr-3 radius10 ml-2 font-Paytone" style={{ backgroundColor: '#fff', color: "#000" }}>
                                                <i className="fas fa-plus mr-3"></i>Create a new Class 
                                                </Link>
                                            </>
                                        }
                                    </div>
                                </div>
                            </section>
                            <section className="container-fluid">
                                <div className="row align-items-center">
                                    {/* <div className={classNames("col", [{"mb-auto": isBrowserDesktop}])}> */}
                                    <div className={classNames("col")}>
                                        <div className={classNames("row", "justify-content-center", [{"mt-2": isBrowserDesktop}])}>
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label htmlFor="usr" className="font-Paytone">Class Name*</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        name="name"
                                                        value={name} 
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            checkClassName(e);
                                                        }} 
                                                    />
                                                    {errors.name && touched.name && (
                                                        <div className="validate-input mt-1">* {errors.name}</div>
                                                    )}
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="usr" className="font-Paytone">Primary Teacher*</label>
                                                    <Select
                                                        className="form-control"
                                                        value={teacherOptions.filter(option => option.value === primaryTeacher._id)}
                                                        options={teacherOptions}
                                                        onInputChange={getListTeacher}
                                                        onChange={(e) => { checkTeacherDisable(e) }}
                                                        placeholder=""
                                                        styles={customSelectStyles}
                                                        components={{ DropdownIndicator }}
                                                        onFocus={getListTeacher}
                                                    />
                                                </div>
                                                {/*<h4 class="text-uppercase text-center font-Paytone">ID: MATH09THANHQT1197</h4>*/}
                                            </div>
                                        </div>
                                        {/* <ClassMessage classId={classInfo._id} props /> */}
                                    </div>

                                    <div className="col">
                                        <div className="p-4">
                                            <h4 className="font-Paytone tle23">Class List</h4>
                                            <div className="box-nd tran-60 posbox1 edit-class-desktop-height">
                                                <div className="search-table z-4 position-absolute box-chon">
                                                    {/* <form className="" role="search"> */}
                                                        <div className="input-group">
                                                            <Select
                                                                className="form-control form-control-sista2 bol-10"
                                                                value={selectedUser}
                                                                options={userOptions}
                                                                onInputChange={getListUser}
                                                                onChange={(user) => {
                                                                    selectedUserForAddClass(user)
                                                                }}
                                                                placeholder="Enter user's name or ID..."
                                                                styles={userDropdownStyles}
                                                                onFocus={getListUser}
                                                            />
                                                            <div className="input-group-append ml-20">
                                                                <button type='button' onClick={onAddUser} className="btn font-Paytone bg-30 btn-light2 radius10 pl-3 pr-3">Add</button>
                                                            </div>
                                                        </div>
                                                    {/* </form> */}
                                                </div>
                                                <div className="position-absolute tle-table tranTB-70 t-86">
                                                    <div className="row">
                                                        <div className="col-6 text-muted2">User Name</div>
                                                        <div className="col-1 text-muted2 text-center" style={{paddingLeft: 0}}>Grade</div>
                                                        <div className="col-3 text-muted2 text-center" style={{paddingLeft: 0}}>Actions</div>
                                                        <div className="col-2 text-muted2 text-center">Solution</div>
                                                    </div>
                                                </div>
                                                <div className="hidden-scrollbar-scoll t-75 b-0">
                                                    <div className="inner-scoll-edit-class-desktop-operator">
                                                        {
                                                            classInfo !== '' && listTeachers.length + listStudents.length > 0 &&
                                                            <div className="row mb-2" style={{marginRight: 0}}>
                                                                <div className="col-6">
                                                                    <div className="align-middle">
                                                                        <div className="custom-control custom-radio custom-control-inline">
                                                                            <input type="checkbox" className="custom-control-input" id='selectall' checked={selectAll}
                                                                                onChange={e => selectAllUser(e.target.checked)} />
                                                                            <label className="custom-control-label" htmlFor="selectall">Select All</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 text-right"></div>
                                                                <div className="col-3 text-right"></div>
                                                                <div className="col-2 text-center">
                                                                    {listStudents.length > 0 && <span className="switch switch-xs" style={{paddingLeft: 25}}>
                                                                        <input type="checkbox" className="switch" id="enableall" checked={enableAll}
                                                                            onChange={e => checkEnableAllSolution(e)} />
                                                                        <label htmlFor="enableall"></label>
                                                                    </span>}
                                                                </div>
                                                            </div>
                                                        }
                                                        {listStudents && listStudents.sort().map((item, index) => {
                                                            return (
                                                                <div className="row mb-2" key={index.toString()} style={{marginRight: 0}}>
                                                                    <div className="col-6">
                                                                        <div className="align-middle">
                                                                            <div style={{ textAlign: 'left' }} className="custom-control custom-radio custom-control-inline">
                                                                                <input type="checkbox" className="custom-control-input" id={`check${item._id}`} checked={item.Checked}
                                                                                    onChange={(e) => selectUser(item._id, 'Student', e.target.checked)} />
                                                                                <label style={{ textAlign: 'left' }} className="custom-control-label" htmlFor={`check${item._id}`}>
                                                                                    <span style={{ cursor: "pointer" }} >{item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)}</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-1 text-center">{item.Grade ? item.Grade : "N/A"}</div>
                                                                    <div className="col-3 text-center">
                                                                        {/* {accountMethod === 'Restricted' && <Code
                                                                            item={item}
                                                                            firstStudentId={firstStudentId}
                                                                            matchUrl={matchUrl}
                                                                            classInfo={classInfo}
                                                                            setErrorMessage={setErrorMessage}
                                                                            setShowRestrictionModal={setShowRestrictionModal}
                                                                        />} */}
                                                                        {accountMethod === 'Restricted' && 
                                                                        <Code
                                                                            item={item}
                                                                            firstStudentId={firstStudentId}
                                                                            matchUrl={matchUrl}
                                                                            classInfo={classInfo}
                                                                            setErrorMessage={setErrorMessage}
                                                                            setShowRestrictionModal={setShowRestrictionModal}
                                                                        />}
                                                                        {accountMethod !== 'Restricted' && (
                                                                            <Link className="text-underline button-join mr-3" to={`${matchUrl.url.substring(0, matchUrl.url.length - 45)}/Student/${item._id}/${classInfo._id}`}>Join</Link>
                                                                        )}

                                                                        <a
                                                                            className="text-underline button-view"
                                                                            // href="true" 
                                                                            data-toggle="modal"
                                                                            data-target="#myModal1"
                                                                            onClick={() => {
                                                                                setShowInfoUser(true)
                                                                                setIdStudent(item._id)
                                                                                setTypeUser("Student")
                                                                            }}
                                                                        >View</a>
                                                                    </div>
                                                                    <div className="col-2 text-center">
                                                                        <span className="switch switch-xs" style={{paddingLeft: 25}}>
                                                                            <input type="checkbox" className="switch" id={`idChk-normal${item._id}`} checked={item.EnableSolution}
                                                                                onChange={(e) => setEnableSolution(item._id, e.target.checked)} />
                                                                            <label htmlFor={`idChk-normal${item._id}`}></label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        {listTeachers && listTeachers.sort().map((item, index) => {
                                                            return (
                                                                <div className="row mb-2" key={index.toString()} style={{marginRight: 0}}>
                                                                    <div className="col-6">
                                                                        <div className="align-middle">
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <input type="checkbox" className="custom-control-input" id={`check${item._id}`} checked={item.Checked}
                                                                                    onChange={(e) => selectUser(item._id, 'Teacher', e.target.checked)} />
                                                                                <label style={{ textAlign: 'left' }} className="custom-control-label" htmlFor={`check${item._id}`}>{item.FullName}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        {/* {item.Code} */}
                                                                        <a className="text-underline button-join mr-3 mr-3rem-i" > </a>
                                                                        <a
                                                                            className="text-underline button-view"
                                                                            // href="true"
                                                                            data-toggle="modal"
                                                                            data-target="#myModal1"
                                                                            onClick={() => {
                                                                                setShowInfoUser(true)
                                                                                setIdStudent(item._id)
                                                                                setTypeUser("Teacher")
                                                                            }}
                                                                        >View</a>
                                                                    </div>
                                                                    <div className="col-3 text-center"></div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                </div>
                                                <div className="bt-table">
                                                    <button 
                                                        className="btn font-Paytone bg-30 btn-light1" type="button" 
                                                        onClick={onShowRemoveUserModal}
                                                        style={{marginTop: 7}}
                                                    >Remove Users</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-4">
                                            <div className="row justify-content-center">
                                                <div className="col-4"><button type="button"  onClick={updateClass} className="btn btn-light font-Paytone btn-block" >Save</button></div>
                                                <div className="col-4 mx-auto text-center">
                                                    <label className="font-Paytone font14 mr-3 mb-4" htmlFor="isEnable">Class {isActive ? 'enable' : "disable"}</label>
                                                    <span className="switch mb-4">
                                                        <input type="checkbox" className="switch" id="isEnable" checked={isActive}
                                                            onChange={(e) => {
                                                                checkActiveClass(e)
                                                            }} />
                                                        <label htmlFor="isEnable"></label>
                                                    </span>
                                                </div>
                                                <div className="col-4"><button type="button" className="btn btn-light font-Paytone btn-block"><span className="text-danger" onClick={onShowDeleteModal}>Delete Class</span></button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* The Modal */}
                            <Modal
                                show={showRemoveUserModal}
                                onHide={hideRemoveUserModal}
                                className="modal fade"
                                id="myModal"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content bg-epen pte-auto">
                                        <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                                            <h4 className="modal-title font-Paytone">
                                                Are you sure you want to remove?
                                        </h4>
                                        </div>
                                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                                            <div className="row">
                                                <div className="col-5 mr-auto">
                                                    <button
                                                        type="button"
                                                        onClick={hideRemoveUserModal}
                                                        className="btn btn-light font-Paytone btn-block"
                                                    >
                                                        Cancel
                                                            </button>
                                                </div>
                                                <div className="col-5">
                                                    <button
                                                        type="button"
                                                        onClick={removeUser}
                                                        className="btn btn-light font-Paytone float-right btn-block"
                                                    >
                                                        <span className="text-danger">Confirm</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal
                                show={showDeleteModal}
                                onHide={hideDeleteModal}
                                className="modal fade"
                                id="myModal"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content bg-epen pte-auto">
                                        <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                                            <h4 className="modal-title font-Paytone">
                                                Are you sure you want to delete this class?
                                        </h4>
                                        </div>
                                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                                            <div className="row">
                                                <div className="col-5 mr-auto">
                                                    <button
                                                        type="button"
                                                        onClick={hideDeleteModal}
                                                        className="btn btn-light font-Paytone btn-block"
                                                    >
                                                        Cancel
                                                            </button>
                                                </div>
                                                <div className="col-5">
                                                    <button
                                                        type="button"
                                                        onClick={deleteClass}
                                                        className="btn btn-light font-Paytone float-right btn-block"
                                                    >
                                                        <span className="text-danger">Confirm</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal
                                show={isShowErrorModal}
                                onHide={onHideErrorModal}
                                className="modal fade"
                                id="myModal"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content bg-epen pte-auto">
                                        <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5 text-center m-auto">
                                            <span>Class Name already exists.
                                            <br />
                                    Please change you class name into <br /> <b>{name}A</b> or <b>{name}B</b> instead</span>
                                        </div>
                                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                                            <div className="row">
                                                <div className="col-5 m-auto">
                                                    <button
                                                        type="button"
                                                        onClick={onHideErrorModal}
                                                        className="btn btn-light font-Paytone btn-block"
                                                    >
                                                        Ok
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            {/*Modal show info account*/}
                            {isShowInfoUser && <ModalShowInfoUser
                                isShowInfoUser={isShowInfoUser}
                                setShowInfoUser={setShowInfoUser}
                                onHideInfoUser={onHideInfoUser}
                                idUser={idUser}
                                typeUser={typeUser}
                            />}

                            {/* Account Trials */}
                            <ModalAlertOperator
                                showModal={isShowTrialModal}
                                alertMessage={messageDayleftTeacherOrStudent}
                                role={role}
                                AccountMethod={AccountMethod}
                                keyStorage={'PopupDayleftTeacher'}
                                functionClose={onHideTrialModal}
                                functionUpgrade={null}
                            />

                            {/* Account Restricted */}
                            <ModalAlertOperator
                                showModal={isShowRestrictionModal}
                                alertMessage={errorMessage}
                                role={role}
                                AccountMethod={AccountMethod}
                                functionClose={() => {
                                    onHideRestrictionModal()
                                }}
                                functionUpgrade={null}
                            />
                        </div>
                        {/* </form> */}
                    </>
                )
            }}
        </Formik>
    )
}

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="fa fa-search text-white"></i>
        </components.DropdownIndicator>
    );
};

const userDropdownStyles = {
    ...customSelectStyles,
    indicatorsContainer: (provided, state) => ({
        ...provided,
        display: 'none',
    })
}

const customSelectClassStyles = {
    ...customSelectStyles,
    singleValue: (provided, state) => ({
        ...provided,
        color: '#858585',
    })
}

export default EditClassDesktop;