import { Formik } from "formik"
import React from 'react'
import * as Yup from "yup"
import ModalAlertOperator from '../../../components/ModalAlertOperator'
import { phoneRegExp, nameRegExpVn } from '../../../constants/Const'
import * as Fc from "../../../utils/Functions"
import PopupAddToClass from "./popupAddToClass/popupAddToClass"

import useCheckdevice from '../../../hooks/useCheckdevice'
import classNames from 'classnames'
import { isDesktop, isMobile } from "react-device-detect"

export default function EditTeacher(props) {
    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))
    
    const role = Fc.getRole()
    const AccountMethod = Fc.getAccountMethod()
    const {
        firstName, onChangeFirstName,
        lastName, onChangeLastName,
        gender, onChangeGender,
        available, onChangeAvailable,
        phone, onChangePhone,
        email, onChangeEmail,
        additionalComment, onChangeComment,
        password, onChangePassword,
        confirmPassword, onChangeConfirmPassword,
        onSubmit,
        passwordShown, togglePasswordVisiblity,
        confirmPasswordShown, toggleConfirmPasswordVisiblity,
        showDeleteModal, selectedUser, accountId, isShowAddErrorMessageModal,
        HideAddErrorMessageModal,
        errorAddMessage, showButtonAddToClass,
        showPopupAddToClass,
        setShowPopupAddToClass, getAllClass, listClass,
        setListClass, chooseClass, selectedClass,
        setShowButtonAddToClass, setSelectedClass, hidePopupAddUserToClass

    } = props

    const validateForm = Yup.object().shape({
        firstName: Yup.string().trim().matches(nameRegExpVn, 'Please enter valid Firstname').required("Required"),
        lastName: Yup.string().trim().matches(nameRegExpVn, 'Please enter valid Lastname').required("Required"),
        phone: Yup.string().matches(phoneRegExp, 'Invalid phone number format').required("Required"),
        email: Yup.string().email("Invalid Email").required("Required"),
        password: Yup
            .string().matches(/^(.*)?\S+(.*)?$/, 'Password is invalid'), //not contain backspace
        confirmPassword: Yup
            .string()
            .test('passwords-match', 'Confirm password is not match', function (value) {
                return this.parent.password === value;
            }),
    })

    return (
        <Formik
            initialValues={{
                firstName: firstName, lastName: lastName, email: email,
                phone: phone, password: password, confirmPassword: confirmPassword
            }}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validateForm}
        >
            {(properties) => {
                const {
                    touched,
                    errors,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                } = properties;

                return (
                    <>
                        <form onSubmit={handleSubmit}>
                            <section className={classNames("container-fluid", [{"container-fluid-desktop": isBrowserDesktop}])}>
                                <div className="p-4">
                                    <h4 className="font-Paytone text-center mb-3">{accountId ? 'Teacher Information' : "Manage User"}</h4>
                                    <section className="form-row">

                                        {accountId ? <>
                                            <div className="col-6 mb-2">
                                                <label htmlFor="usr" className="font-Paytone">First name*</label>
                                                <input type="text" className="form-control" value={firstName} name="firstName"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        onChangeFirstName(e)
                                                    }}
                                                />
                                                {errors.firstName && touched.firstName && (
                                                    <div className="validate-input mt-1">* {errors.firstName}</div>
                                                )}
                                            </div>
                                            <div className="col-6 mb-2">
                                                <label htmlFor="usr" className="font-Paytone">Last name*</label>
                                                <input type="text" className="form-control" value={lastName} name="lastName"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        onChangeLastName(e)
                                                    }}
                                                />
                                                {errors.lastName && touched.lastName && (
                                                    <div className="validate-input mt-1">* {errors.lastName}</div>
                                                )}
                                            </div>
                                            <div className="col-6 mb-2">
                                                <label htmlFor="usr" className="font-Paytone">Gender*</label>
                                                <select className="custom-select" value={gender} onChange={onChangeGender}>
                                                    <option value={"Male"}>Male</option>
                                                    <option value={"Female"}>Female</option>
                                                </select>
                                            </div>
                                        </> : <>
                                            <div className="col-6 mb-2">
                                                <label htmlFor="usr" className="font-Paytone">First name*</label>
                                                <input type="text" className="form-control" value={firstName} name="firstName"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        onChangeFirstName(e)
                                                    }}
                                                />
                                                {errors.firstName && touched.firstName && (
                                                    <div className="validate-input mt-1">* {errors.firstName}</div>
                                                )}
                                            </div>
                                            <div className="col-6 mb-2">
                                                <label htmlFor="usr" className="font-Paytone">Last name*</label>
                                                <input type="text" className="form-control" value={lastName} name="lastName"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        onChangeLastName(e)
                                                    }}
                                                />
                                                {errors.lastName && touched.lastName && (
                                                    <div className="validate-input mt-1">* {errors.lastName}</div>
                                                )}
                                            </div>

                                            <div className="col-6 mb-2 d-flex">
                                                <div className="col-6 pl-0 m-auto">
                                                    <label htmlFor="usr" className="font-Paytone">Gender*</label>
                                                    <select className="custom-select" value={""} >
                                                        <option value={"Male"}>Male</option>
                                                        <option value={"Female"}>Female</option>
                                                    </select>
                                                </div>

                                                <div className="col-6 m-auto">
                                                    <label htmlFor="usr" className="font-Paytone">Grade*</label>
                                                    <select className="custom-select" value={""}>
                                                        <option value={1}>1</option>
                                                        {
                                                            [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((item, index) => {
                                                                return (
                                                                    <option key={index.toString()} value={item}>{item}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-2">
                                                <label htmlFor="usr" className="font-Paytone">Phone number</label>
                                                <input type="text" className="form-control" value="" name="phone"

                                                />
                                            </div>
                                        </>}
                                    </section>
                                </div>
                            </section>
                            <section className="box-xam mb-3">
                                <section className={classNames("form-row", "align-items-center", [{"form-row-desktop": isBrowserDesktop}])} style={{marginBottom: "0.4rem"}}>
                                    <div className="col-6">
                                        <label htmlFor="usr" className="font-Paytone">New Password</label>
                                        <div className="input-group mb-2">
                                            <input type={passwordShown ? "text" : "password"} className="form-control border-0" value={password} name="password" autoComplete="new-password"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    onChangePassword(e)
                                                }}
                                            />
                                            <div className="input-group-append ml-0 border-0">
                                                <span className="input-group-text input-group-text1 show-hide-password" onClick={togglePasswordVisiblity} >{passwordShown ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</span>
                                            </div>
                                        </div>
                                        {errors.password && touched.password && (
                                            <div className="validate-input mb-2">* {errors.password}</div>
                                        )}
                                        <label htmlFor="usr" className="font-Paytone">Confirm New Password</label>
                                        <div className="input-group">
                                            <input type={confirmPasswordShown ? "text" : "password"} className="form-control border-0" value={confirmPassword} autoComplete="new-password" name="confirmPassword"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    onChangeConfirmPassword(e)
                                                }}
                                            />
                                            <div className="input-group-append ml-0 border-0">
                                                <span className="input-group-text input-group-text1 show-hide-password" onClick={toggleConfirmPasswordVisiblity} >{confirmPasswordShown ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</span>
                                            </div>
                                        </div>
                                        {errors.confirmPassword && touched.confirmPassword && (
                                            <div className="validate-input mt-1">* {errors.confirmPassword}</div>
                                        )}
                                    </div>
                                    <div className="col-6 text-center">
                                        <h5 className="font-Paytone three-dot-100">{accountId ? accountId : 'UserId'}</h5>
                                        {accountId ? 'Teacher' : "Role"}
                                        {showButtonAddToClass && <div className="col mt-2 mr-auto ml-auto">
                                            <button
                                                className="btn radius10 pl-3 pr-3 bg-white"
                                                type="button"
                                                onClick={() => {
                                                    setShowPopupAddToClass(true)
                                                }}
                                            >
                                                <i className="fas fa-plus"></i> Add To Class
                                            </button>
                                        </div>}

                                        {(selectedClass && !showButtonAddToClass) && <div className="alert alert-secondary alert-dismissible fade show radius-10 pt-1 pb-1 mb-0 ml-4 mr-4">
                                            <button
                                                type="button"
                                                className="ix close pt-1 pb-1"
                                                onClick={() => {
                                                    setSelectedClass('')
                                                    setShowButtonAddToClass(true)
                                                }}
                                            >&times;</button>
                                            <div className="three-dot-120">
                                                {selectedClass.Code}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </section>
                            </section>
                            <section className={classNames("container-fluid", [{"container-fluid-desktop": isBrowserDesktop}])}>
                                <div className="p-4">
                                    <label htmlFor="usr" className="font-Paytone">Comments:</label>
                                    <textarea className="form-control mb-4" rows="5" onChange={onChangeComment} value={additionalComment}></textarea>
                                    <h4 className="font-Paytone text-center mb-3">Additional Information</h4>
                                    
                                    {   
                                        // ========= Edit Teacher Desktop =========
                                        !isBrowserDesktop
                                        ?   <section className="form-row align-items-center">
                                                <div className="col-7 mr-auto">
                                                    {accountId ? null : <>
                                                        <label htmlFor="usr" className="font-Paytone">Name*</label>
                                                        <input type="text" className="form-control mb-2" value="" name="Name" />
                                                    </>}


                                                    <label htmlFor="usr" className="font-Paytone">Email Address*</label>
                                                    <input type="text" className="form-control mb-2" value={email} name="email"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangeEmail(e)
                                                        }}
                                                    />
                                                    {errors.email && touched.email && (
                                                        <div className="validate-input mb-2">* {errors.email}</div>
                                                    )}
                                                    <label htmlFor="usr" className="font-Paytone">Phone Number*</label>
                                                    <input type="text" className="form-control" value={phone} name="phone"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangePhone(e)
                                                        }}
                                                    />
                                                    {errors.phone && touched.phone && (
                                                        <div className="validate-input mt-1">* {errors.phone}</div>
                                                    )}
                                                </div>

                                                <div className="col-4 mb-2">
                                                    <div className="row">
                                                        <div className="col-12 mb-3">
                                                            <label className="font-Paytone font18 mr-3" htmlFor="isEnable">Account enable</label>
                                                            <span className="switch">
                                                                <input type="checkbox" className="switch" id="isEnable" checked={available}
                                                                    onChange={onChangeAvailable} />
                                                                <label htmlFor="isEnable"></label>
                                                            </span>
                                                        </div>
                                                        <div className="col-12"><button type="submit" className="btn btn-light font-Paytone btn-block mb-3">Save</button></div>
                                                        <div className="col-12"><button type="button" className="btn btn-light font-Paytone float-right btn-block text-danger" onClick={() => {
                                                            if (selectedUser) {
                                                                showDeleteModal()
                                                            }
                                                        }}>Delete Account</button></div>
                                                    </div>
                                                </div>
                                            </section>

                                        // ========= Edit Teacher Desktop =========
                                        :    <section className="form-row align-items-center">
                                                {
                                                    accountId 
                                                    ?   null
                                                    :   <div className="col-6">
                                                            <label htmlFor="usr" className="font-Paytone">Name*</label>
                                                            <input type="text" className="form-control mb-2" value="" name="Name" />
                                                        </div>
                                                }

                                                <div className="col-6">
                                                    <label htmlFor="usr" className="font-Paytone">Email Address*</label>
                                                    <input type="text" className="form-control mb-2" value={email} name="email"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangeEmail(e)
                                                        }}
                                                    />
                                                    {errors.email && touched.email && (
                                                        <div className="validate-input mb-2">* {errors.email}</div>
                                                    )}
                                                </div>
                                                
                                                <div className="col-6">
                                                    <label htmlFor="usr" className="font-Paytone">Phone Number*</label>
                                                    <input type="text" className="form-control mb-2" value={phone} name="phone"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangePhone(e)
                                                        }}
                                                    />
                                                    {errors.phone && touched.phone && (
                                                        <div className="validate-input mt-1">* {errors.phone}</div>
                                                    )}
                                                </div>
                                                
                                                {
                                                    !accountId 
                                                    ?   null
                                                    :   <div className="col-6"></div>
                                                }
                                                <div className="col-6 row" style={{paddingLeft: 12, marginTop: !accountId ? 30 : 20}}>
                                                    <div className="col-10 ml-auto">
                                                        <label className="font-Paytone font18 mr-3" htmlFor="isEnable">Account enable</label>
                                                        <span className="switch">
                                                            <input type="checkbox" className="switch" id="isEnable" checked={available}
                                                                onChange={onChangeAvailable} />
                                                            <label htmlFor="isEnable"></label>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col-4 mt-3"><button type="submit" className="btn btn-light font-Paytone btn-block">Save</button></div>
                                                <div className="col-4 offset-4 mt-3"><button type="button" className="btn btn-light font-Paytone float-right btn-block text-danger" 
                                                    onClick={() => {
                                                        if (selectedUser) {
                                                            showDeleteModal()
                                                        }
                                                    }}
                                                >Delete Account</button>
                                                </div>
                                            </section>
                                    }
                                    
                                </div>
                            </section>
                        </form>
                        <ModalAlertOperator
                            showModal={isShowAddErrorMessageModal}
                            alertMessage={errorAddMessage}
                            role={role}
                            AccountMethod={AccountMethod}
                            functionClose={HideAddErrorMessageModal}
                            functionUpgrade={null}
                        />
                        {showPopupAddToClass && <PopupAddToClass
                            showPopupAddToClass={showPopupAddToClass}
                            setShowPopupAddToClass={setShowPopupAddToClass}
                            hidePopupAddUserToClass={hidePopupAddUserToClass}
                            getAllClass={getAllClass}
                            listClass={listClass}
                            setListClass={setListClass}
                            chooseClass={chooseClass}
                            selectedClass={selectedClass}
                            setShowButtonAddToClass={setShowButtonAddToClass}
                        />}
                    </>
                )
            }}
        </Formik>
    )
}