import React from 'react';
import { Link } from "react-router-dom";
import * as Const from "../../constants/Const";

function Drawer(props) {
    const {role, hideMenu, logOut, operatorInfo, matchUrl} = props
    const classStyle = "list-group-item bg-transparent font-Paytone font18 border-0";
    const isEditBanner = (operatorInfo && operatorInfo.DetailUserInfo && operatorInfo.DetailUserInfo.PaymentSetting.CanEditBanner)

    return (
        <aside className="offcanvas" id="my_offcanvas1">
          <header className="mt-5a">
          <button className="btn btn-close" onClick={hideMenu}>
              <i className="fas fa-times fa-2x text-white"></i>{" "}
            </button>
          </header>
          <nav className="list-group list-group-flush">
            {
              role.includes(Const.Role.VENDOR) 
              &&  (<Link onClick={hideMenu} className={classStyle} to="/Vendor" >
                      Location
                    </Link>
                  )
            }
            <Link onClick={hideMenu} className={classStyle} to={`${matchUrl.url}`} >
                  Browse
            </Link>
  
            <Link onClick={hideMenu} className={classStyle} to={`${matchUrl.url}/Classes`} >
              Classroom
            </Link>

            <Link onClick={hideMenu} className={classStyle} to={`${matchUrl.url}/Accounts`} >
              Users
            </Link>
           
            { 
              ( role.includes(Const.Role.VENDOR) || isEditBanner) 
              &&  <Link onClick={hideMenu} className={classStyle} to={`${matchUrl.url}/Banner`} >
                    Banner
                  </Link>
            }
            <Link onClick={hideMenu} to={`${matchUrl.url}/ChangePassword`} className={classStyle} >
              Change Password
            </Link>

            <Link onClick={logOut} className={classStyle} to="#" >
              <span className="text-danger">Log Out</span>
            </Link>
          </nav>
        </aside>
    );
}

export default Drawer;