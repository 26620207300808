import React, { useEffect, useRef, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import * as Fc from "../../../utils/Functions";
import LayoutComponent from "./LayoutComponent";
import API from '../../../utils/Api';
import * as Const from './../../../constants/Const'
import * as UrlConst from './../../../constants/UrlConst'

import * as Type from './../../../constants/actionType'
import { cloneDeep } from "lodash"

import { useDispatch, useSelector } from 'react-redux'
import useDidMount from '../../../hooks/useDidMount'
import io from "socket.io-client";
import * as type from "../../../constants/actionType"
import _ from 'lodash'

let socket
function _Layout(props) {
  const dispatch = useDispatch();
  let didMount = useDidMount()
  let role = Fc.getRole()
  const [operatorInfo, setOperatorInfo] = useState({})
  const buttonEl = useRef(null)
  const [openPopup, setOpenPopup] = useState(false)
  let matchUrl = useRouteMatch();

  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState([]);

  const [listClass, setListClass] = useState([])
  const [ClassId, setClassId] = useState("")
  const [ListStudent, setListStudent] = useState([])
  const [ListTeacher, setListTeacher] = useState([])
  const [selectedId, setSelectedId] = useState("")
  const [room, setRoom] = useState("")
  const [tabChat, setTabChat] = useState('ChatClass')
  const [userType, setUserType] = useState("")
  const [userName, setUserName] = useState("")

  const [pageIndex, setPageIndex] = useState(1)
  // const [pageSize, setPageSize] = useState(20)
  const pageSize = 20;


  // let classIDTemp = useRef("")
  // let selectedIdTemp = useRef("")
  let messageTemp = useRef([])
  let messageEndRef = useRef(null)
  let currentChatTmp = useRef(null)
  let pageIndexRef = useRef(1)
  // let timerLstMsgGr = null
  // let timerLstMsgPsn = null
  let isLoadTmp = useRef(false)
  let lstNotifTmp = useRef([])
  let lstClassTmp = useRef([])
  let userLoginIdRef = useRef("")

  const scrollToBottom = () => {
    messageEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  // GET LIST CHAT ROOM
  let allRoom = useSelector(state => state.ws.listRoom)
  let listNotifRedux = useSelector(state => state.ws.notify)
  let currentChatRx = useSelector(state => state.ws.currentChat)
  const userLogin = useSelector(state => state.ws.userLogin)
  // const token = useSelector(state => state.ws.token)
  const isLoading = useSelector(state => state.ws.isLoading)

  function onScrollTop() {
    if(isLoadTmp.current) return

    pageIndexRef.current = pageIndexRef.current + 1
    if(currentChatTmp.current && currentChatTmp.current.tabChat === "class" && pageIndexRef.current > 1) {
      getListMessageInGroup(currentChatTmp.current.classId, pageIndexRef.current)
      dispatch({ type: type.SAVE_IS_LOADING, payload: true})
    }

    if(currentChatTmp.current && (currentChatTmp.current.tabChat === "person" || currentChatTmp.current.tabChat === "admin") && pageIndexRef.current > 1) {
      GetChatMessages(Fc.getUserId(), currentChatRx.fromId, pageIndexRef.current)
      dispatch({ type: type.SAVE_IS_LOADING, payload: true})
    }
  }

  useEffect(() => {
    isLoadTmp.current = isLoading
  }, [isLoading])

  useEffect(() => {
    if(userLogin)
    userLoginIdRef.current = userLogin._id
    return () => { userLoginIdRef.current = "" }
  }, [userLogin])

  useEffect(() => {
    if(!currentChatRx) {
      setMessageList([])
      setListStudent([])
      setListTeacher([])
      setClassId("")
      setSelectedId("")
      setTabChat("ChatClass")
      currentChatTmp.current = {tabChat: null, classId: null, fromId: null}
      return
    }
    currentChatTmp.current = currentChatRx
  }, [currentChatRx])

  useEffect(() => {
    messageTemp.current = []
    setMessageList([])
    setListStudent([])
    setListTeacher([])
    setClassId("")
    setSelectedId("")
    setTabChat("ChatClass")
    /**
    |-------------------------------------------------------------------------------------------
    | kiem tra socket 5s 1 lan 
    |-------------------------------------------------------------------------------------------
    */
    let timeInterval = setInterval(() => {
      if(!socket || Object.keys(socket).length === 0 || !socket.connected) {
        socket = io(UrlConst.CONNECTION_PORT, {transports: [ "websocket" ]}).connect();
        //JOIN ALL ROOM
        if (Fc.getRole() === Const.Role.STUDENT) {
          socket.emit('join_all_room', allRoom)
          socket.emit('join_room', `${Fc.getOperator()}|${Fc.getUserId()}`)
        }
      }
    }, 4999)
    //==========================================================================================

    if(!socket || Object.keys(socket).length === 0 || !socket.connected)
    socket = io(UrlConst.CONNECTION_PORT, {transports: [ "websocket" ]}).connect();

    //JOIN ALL ROOM
    if (Fc.getRole() === Const.Role.STUDENT) {
      socket.emit('join_all_room', allRoom)
      socket.emit('join_room', `${Fc.getOperator()}|${Fc.getUserId()}`)
    }

    cloneDeep(lstNotifTmp.current)
    socket.on('show_notification', (data) => {
      if (data.From !== Fc.getUserId()) {
        // seen message when operator send message, and teacher is on current contact Operaton tab chat
        if(currentChatTmp.current && currentChatTmp.current.tabChat === "admin" && data.Type !== "groupchat" && data.ClassId === currentChatTmp.current.classId && data.From === currentChatTmp.current.fromId ){
          return
        }
        //khong hien thi notification cua lop chat khi dang o lop do
        if(currentChatTmp.current && currentChatTmp.current.tabChat === "class" && data.Type === "groupchat" && data.ClassId === currentChatTmp.current.classId ) {
          return
        } 

        if(currentChatTmp.current && currentChatTmp.current.tabChat === "person" && data.ClassId === currentChatTmp.current.classId && data.From === currentChatTmp.current.fromId ) {
          return
        } 

        let a = lstNotifTmp.current.some(x => (x.From === data.From  && x.To === data.To))

        if (a === true) {
          let index = lstNotifTmp.current.findIndex(item => item.From === data.From && item.To === data.To)
          lstNotifTmp.current.splice(index, 1)
        }

        lstNotifTmp.current.push(data)
        dispatch({ type: Type.SAVE_NOTIFY, payload: cloneDeep(lstNotifTmp.current) })
        return 
      }

      //dong bo tin nhan khi 1 user dang nhap tren 2 thiet bi
      if (data.From === Fc.getUserId()) {
        if(currentChatTmp.current && currentChatTmp.current.tabChat === "person" && data.ClassId === currentChatTmp.current.classId && data.To === currentChatTmp.current.fromId ) {
          messageTemp.current.push(data)
          setMessageList([...messageTemp.current]);
          return
        } 
        
        if(currentChatTmp.current && currentChatTmp.current.tabChat === "admin" && data.Type !== "groupchat" && data.To === currentChatTmp.current.fromId ){
          messageTemp.current.push(data)
          setMessageList([...messageTemp.current]);
          return
        }
      }
    });

    // Get Notifi in DB
    Fc.getRole() === Const.Role.STUDENT && getListNotif()

    window.toogleMenuEvent()

    getAccountInfo()

    return () => {
      clearInterval(timeInterval)
    }
  }, [allRoom])

  function delNotification(from, to, userId, callback = null) {
    API.DeleteNotification(from, to, userId)
    .then((res) => {
        if (res.data.Success) {
          if(callback) callback()
        } 
    }).catch((err) => {
        // Fc.showError("Can not connect to server")
    })
  }

  function getAccountInfo() {
    API.getAccountInfo()
      .then(res => {
        if (res.status === 200 && res.data.Success) {
          setOperatorInfo(res.data.Data.DetailUserInfo.Operator);
          setListClass(res.data.Data.DetailUserInfo.Classes);

        } else {
          Fc.showError(res.data.Message || res.statusText)
        }
      })
      .catch((err) => {
        Fc.showError(err)
      })
  }

  useEffect(() => {
    //JOIN ALL ROOM
    if (Fc.getRole() === Const.Role.STUDENT) {
      socket.emit('join_all_room', allRoom)
    }
  })

  useEffect(() => {
    if (listNotifRedux && listNotifRedux.length >= 0) {
      lstNotifTmp.current = listNotifRedux
      dispatch({ type: type.COUNT_NOTIFY, payload: listNotifRedux.length })
    }
  }, [listNotifRedux])

  // GET LIST STUDENT TEACHER IN CLASS
  function getDetailClass(ClassId, callback = null) {
    API.detailClass(ClassId).then(res => {
      if (res.status === 200 && res.data.Success) {
        let primaryTeacher = res.data.Data.PrimaryTeacher
        let AssistantTeachers = res.data.Data.AssistantTeachers
        AssistantTeachers.push(primaryTeacher)

        let teachers = AssistantTeachers.filter(x => x._id !== Fc.getUserId())
        setListTeacher(teachers.sort((a, b) => {
          if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
          if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
          return 0;
        }));
        setListStudent(res.data.Data.Students.filter(x => x._id !== Fc.getUserId()).sort((a, b) => {
          if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
          if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
          return 0;
        }))
        if(callback) callback()
      } else {
        if (res.data.Message)
          Fc.showError(res.data.Message)
        else
          Fc.showError(res.statusText)
      }
    }).catch(err => {
      Fc.showError("Cant not connect to server")
    })
  }

  useEffect(() => {
    setPageIndex(1)
    dispatch({ type: type.SAVE_IS_LOADING, payload: false})
    pageIndexRef.current = 1
    messageTemp.current = []

    //lan dau render ma curenChatRx ton tai thi gan ve gia tri mac dinh
    if(!didMount && currentChatRx) {
      dispatch({ type: type.SAVE_CURRENT_CHAT, payload: null });
      return
    }

    if(!currentChatRx) return

    if (currentChatRx.tabChat === 'class' && Fc.getRole() === Const.Role.STUDENT) {
      currentChatRx.classId &&  getDetailClass(currentChatRx.classId)

      // JOIN CLASS ROOM CHAT
      socket.emit("join_room", `${currentChatRx.classId}`);
      setRoom(`${currentChatRx.classId}`)

      // Get Message in group with role Teacher
      getListMessageInGroup(currentChatRx.classId, pageIndexRef.current, 
        () =>  {
          setTabChat("ChatClass")
          getDetailClass(currentChatRx.classId); 
        }
      )
    }

    if((currentChatRx.tabChat === 'person') && Fc.getRole() === Const.Role.STUDENT){
      const selectedId = currentChatRx.fromId || null
      if(!selectedId) return 

      socket.emit("join_room", `${Fc.getUserId()}|${selectedId}`);
      socket.emit("join_room", `${selectedId}|${Fc.getUserId()}`);
      
      setRoom(`${Fc.getUserId()}|${selectedId}`)

      GetChatMessages(selectedId, Fc.getUserId(), pageIndexRef.current, 
        () => {
          setTabChat("ChatPersion");  
          getDetailClass(currentChatRx.classId, () => setSelectedId(selectedId)); 
        }
      )
    }

    if((currentChatRx.tabChat === 'admin') && Fc.getRole() === Const.Role.STUDENT){
      const selectedId = currentChatRx.fromId || null

      if(!selectedId) return 

      socket.emit("join_room", `${Fc.getUserId()}|${selectedId}`);
      socket.emit("join_room", `${selectedId}|${Fc.getUserId()}`);
      socket.emit("join_room", `${selectedId}`);
      setRoom(`${Fc.getUserId()}|${selectedId}`) 

      GetChatMessages(Fc.getUserId(), Fc.getOperator(), pageIndexRef.current, 
        () => {
          setTabChat("contactAdmin")
          getDetailClass(currentChatRx.classId); 
        }
      )
    }

  }, [currentChatRx])

  useEffect(() => {
    (messageList && messageEndRef.current) && scrollToBottom()
    messageTemp.current = messageList
  }, [messageList])

  useEffect(() => {
    lstClassTmp.current = allRoom
  }, [allRoom])

  useEffect(() => {
    let timerDelNotif = null

    // if(!socket || Object.keys(socket).length == 0) return

    socket.on("receive_message_1", (data) => {
      if(!currentChatTmp.current || !currentChatTmp.current.tabChat ) return 
      
      if (data.Type === "groupchat") {
        if (currentChatTmp.current && data.To === currentChatTmp.current.classId && !currentChatTmp.current.fromId) {
          pageIndexRef.current = 1
          messageTemp.current.push(data)
          setMessageList([...messageTemp.current]);

          //xoa notify khi dang trong chat class
          timerDelNotif = setTimeout(() => {
            delNotification(data.From, data.To, userLoginIdRef.current)
          }, 1000)
        }

      } else {
        //chan hien thi tin nhan ko dung tab, ko dung ng dung
        if(!currentChatTmp.current || (currentChatTmp.current.tabChat === 'person' && currentChatTmp.current.fromId !== data.From)) {
          return
        }

        if(!currentChatTmp.current || (currentChatTmp.current.tabChat === 'class')) { 
          return
        }

        if (data.From === currentChatTmp.current.fromId || data.From === Fc.getOperator()) {
          pageIndexRef.current = 1
          messageTemp.current.push(data)
          setMessageList([...messageTemp.current]);

          //xoa notify khi dang truc tiep voi user
          timerDelNotif = setTimeout(() => {
            delNotification(data.From, data.To, userLoginIdRef.current)
          }, 1000)
        }
      }

    });

    window.addEventListener('focus', () => {
      // reconnect 
      if (!socket || Object.keys(socket).length === 0 || !socket.connected) 
      socket = io(UrlConst.CONNECTION_PORT, {transports: [ "websocket" ]}).connect();

      //JOIN ALL ROOM
      if (Fc.getRole() === Const.Role.STUDENT) {
        socket.emit('join_all_room', lstClassTmp.current)
        socket.emit('join_room', `${Fc.getOperator()}|${Fc.getUserId()}`)
        socket.emit('join_room', `${Fc.getUserId()}|${Fc.getOperator()}`)
      }
      
      // Get Notifi in DB
      Fc.getRole() === Const.Role.STUDENT && getListNotif()
    })

    return () => {
      window.removeEventListener('focus', () => console.log('remove listener focus'))
      if(timerDelNotif) clearTimeout(timerDelNotif)
    }
  }, [])

  // Get List Message Group
  function getListMessageInGroup(classID, pageIndex1 = 1, callback = null) {
    if(classID === 'Classlist') return
    dispatch({ type: type.SAVE_IS_LOADING, payload: true})

    API.GetChatMessagesInGroup(classID, '', pageSize, pageIndex1)
      .then((res) => {
        if (res.status === 200 && res.data.Success) {

          const sortData = _.sortBy(res.data.Data, "CreatedDate")
          if(sortData.length  === 0) dispatch({ type: type.SAVE_IS_LOADING, payload: true})

          if(sortData.length > 0) {
            sortData.forEach(item => messageTemp.current.push(item))
            setTimeout(() => {
              dispatch({ type: type.SAVE_IS_LOADING, payload: false})
            }, 1000)
          }


          setMessageList([...messageTemp.current]);

          if (callback !== null)
            callback()
        } else {
          setTimeout(() => {
            dispatch({ type: type.SAVE_IS_LOADING, payload: false})
          }, 1000)

          if (res.data.Message)
            Fc.showError(res.data.Message)
          else
            Fc.showError(res.statusText)
        }
      }).catch(err => {
        setTimeout(() => {
          dispatch({ type: type.SAVE_IS_LOADING, payload: false})
        }, 1000)

        Fc.showError("Cant not connect to server")
      })
  }

  // Get List Message Personal
  function GetChatMessages(from, to, pageIndex1 = 1, callback = null) {
    dispatch({ type: type.SAVE_IS_LOADING, payload: true})

    API.GetChatMessages(from, to, '', pageSize, pageIndex1)
      .then((res) => {
        if (res.status === 200 && res.data.Success) {
          const sortData = _.sortBy(res.data.Data, "CreatedDate")
          if(sortData.length  === 0) dispatch({ type: type.SAVE_IS_LOADING, payload: true})

          if(sortData.length > 0) {
            sortData.forEach(item => messageTemp.current.push(item))
            const timerLstMsgPsn = () => setTimeout(() => {
              dispatch({ type: type.SAVE_IS_LOADING, payload: false})
            }, 1000)
          } 

          setMessageList([...messageTemp.current]);
          if(callback) callback()
        } else {
          const timerLstMsgPsn = () => setTimeout(() => {
            dispatch({ type: type.SAVE_IS_LOADING, payload: false})
          }, 1000)

          if (res.data.Message)
            Fc.showError(res.data.Message)
          else
            Fc.showError(res.statusText)
        }
      }).catch(err => {
        const timerLstMsgPsn = ()=> setTimeout(() => {
          dispatch({ type: type.SAVE_IS_LOADING, payload: false})
        }, 1000)
        
        Fc.showError("Cant not connect to server")
      })
  }

  // GetList Notif
  function getListNotif(from = null, fromType = null) {
    API.GetListNotification(Fc.getUserId(), Fc.getRole(), 1, 0, "").then((res) => {
      if (res.status === 200 && res.data.Success) {
        dispatch({ type: type.SAVE_NOTIFY, payload: res.data.Data })
        dispatch({ type: type.COUNT_NOTIFY, payload: (listNotifRedux && listNotifRedux.length > 0) ? listNotifRedux.length : 0 })
      } else {
        if (res.data.Message){
          // Fc.showError(res.data.Message)
        }
        else {
          // Fc.showError(res.statusText)
        }
      }
    }).catch((err) => {
      // Fc.showError("Can not connect to server")
    })
  }

  function hideMenu() {
    dispatch({ type: type.SET_ACTIVE_TAB_MYWORK, activeTab: "Workbook" })
    dispatch({ type: type.CHANGE_NOT_RELOAD, payload: false})
    dispatch({ type: type.SAVE_PAGEINDEX_WS, payload: null })
    dispatch({ type: type.SAVE_PAGEINDEX_NOTE, payload: 0 })
    
    document.getElementById("my_offcanvas1").classList.remove("show");
    document
      .getElementsByTagName("body")[0]
      .classList.remove("offcanvas-active");
    document
      .getElementsByClassName("screen-overlay")[0]
      .classList.remove("show");
  }

  function showMenu(){
    document.getElementById("my_offcanvas1").classList.add("show");
    document
      .getElementsByTagName("body")[0]
      .classList.add("offcanvas-active");
    document
      .getElementsByClassName("screen-overlay")[0]
      .classList.add("show");
  } 

  function logOut(e) {
    if (socket) socket.disconnect()

    e.preventDefault();
    Fc.setToken("");
    Fc.setRole("");
    Fc.setOperator("");
    Fc.setAccountMethod("");
    clearDataRedux();
    props.history.push("/");
  }

  function clearDataRedux() {
    dispatch({ type: type.SAVE_USER_INFO, payload: {} })
    dispatch({ type: type.SAVE_CLASS_ID, classId: '' })
    dispatch({ type: type.SELECTWS, payload: [] })
    dispatch({ type: type.SET_STUDENT_ID, studentId: "" })
    dispatch({ type: type.SAVE_TOKEN, payload: "" })
    dispatch({ type: type.SAVE_ROLE, payload: [] })
    dispatch({ type: type.SAVE_PARAMS_SEARCH, payload: null })
    dispatch({ type: type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Exercise' })
    dispatch({ type: type.SET_ACTIVE_TAB_MYWORK, activeTab: "Workbook" })
    dispatch({ type: type.SAVE_ASSIGNMENT_DATE, payload: ""})
    dispatch({ type: type.CHANGE_NOT_RELOAD, payload: false})
    dispatch({ type: type.PERMISSION_WRITENOTE, payload: false })
    dispatch({ type: type.PERMISSION_SOLUTION, payload: false })
    dispatch({ type: type.SAVE_STUDENT_CLASS_LIST, payload: []})
    dispatch({ type: type.SAVE_CURRENT_CHAT, payload: null });
    dispatch({ type: type.SAVE_LIST_ROOM, payload: [] });
    dispatch({ type: type.SAVE_USER_LOGIN, payload: {} });
    dispatch({ type: type.SAVE_PAGEINDEX_WS, payload: null })
    dispatch({ type: type.SAVE_PAGEINDEX_NOTE, payload: 0 })
  }

  return (
    <LayoutComponent
      matchUrl={matchUrl}
      hideMenu={hideMenu}
      showMenu={showMenu}
      logOut={logOut}
      buttonEl={buttonEl}
      operatorInfo={operatorInfo}

      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      socket={socket}
      message={message}
      role={role}
      setMessage={setMessage}
      listClass={listClass}
      messageList={messageList}
      setMessageList={setMessageList}
      ClassId={ClassId}
      setClassId={setClassId}
      ListStudent={ListStudent}
      ListTeacher={ListTeacher}
      setSelectedId={setSelectedId}
      selectedId={selectedId}
      room={room}
      tabChat={tabChat} setTabChat={setTabChat}
      userType={userType} setUserType={setUserType}
      userName={userName} setUserName={setUserName}
      getListNotif={getListNotif}
      scrollToBottom={scrollToBottom}
      messageEndRef={messageEndRef}
      getListMessageInGroup={getListMessageInGroup}
      GetChatMessages={GetChatMessages}
      getDetailClass={getDetailClass}
      setPageIndex={setPageIndex}
      onScrollTop={onScrollTop}
      pageIndex={pageIndex}
      {...props} />
  );
}

export default _Layout;
