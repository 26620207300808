import React from 'react';
import { Link } from 'react-router-dom'
import * as type from "../../../constants/actionType"
import { useDispatch } from 'react-redux'

function DocumentItem(props) {
    const dispatch = useDispatch();

    const { matchUrl, item, openDeleteModal,
        AccountMethod,
        setMessageForDocumentUploadPage,
        setShowDocumentUploadPage, firstDocumentId
    } = props

    let url = ''
    let handleClick = null
    if (firstDocumentId === item._id) {
        dispatch({ type: type.CHANGE_NOT_RELOAD, payload: true })
        url = `${matchUrl.url}/Edit/Document/${item._id}/0`
        handleClick = null
    }
    else if (AccountMethod === "Restricted") {
        url = '#'
        handleClick = () => {
            setMessageForDocumentUploadPage('This features is locked. Upgrade now to use all Epen App features.')
            setShowDocumentUploadPage(true)
        }
    }

    return (
        <>
            <div className="tag_list col-3 mb-4">
                <Link to={url} className="position-relative box-img mh-150" onClick={handleClick}>
                    <img className="img-fluid bot-0" src={item.Cover} alt="" />
                    <span style={{ background: 'rgba(0, 0, 0, 0.5)' }} className="box-sp position-absolute">
                        <strong style={{ width: "90%", margin: "auto" }} className="align-middle-center">{item.Title.length > 15 ? (item.Title.substring(0, 15) + "...") : item.Title}</strong>
                    </span>
                </Link><span className="close" onClick={() => openDeleteModal(item._id)}>X</span>
            </div>
        </>
    );
}

export default DocumentItem;