import React from 'react';
import { Modal } from "react-bootstrap"
import Select, { components } from 'react-select'
import { useDrag, useWheel } from 'react-use-gesture'
import { Lethargy } from 'lethargy'

import * as Const from "../../constants/Const"
import * as type from "../../constants/actionType"
import * as Fc from '../../utils/Functions'
import './style.css' 

import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"
import { Swipeable } from 'react-swipeable'
import ModalAlertOperator from "../ModalAlertOperator"
import ModalAlertForTeacher from "../ModalStudentTeacher"
import ImageWithBanner from '../ImageWithBanner'

import useCheckdevice from '../../hooks/useCheckdevice'
import classNames from 'classnames'
import { isDesktop, isMobile } from "react-device-detect"
import { GetListRegionByParent } from '../../constants/UrlConst';
import _ from 'lodash'
//component
import HeaderBack from '../HeaderDesktop/HeaderBack'


function SendWorksheetsView(props) {
    const dispatch = useDispatch();
    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))
    
    const AccountMethod = Fc.getAccountMethod()
    const role = Fc.getRole()
    let history = useHistory()
    const { listWs,
        selectWorksheet,
        zoomWorksheet, setZoomWorksheet,
        zoomImages,
        zoomIndex,
        selectAllWorksheet,
        clearAllWorksheet,
        showZoomInstruct,
        showSelected, setShowSelected, pushAppBottom,
        showZoomModal, setShowZoomModal,
        hideZoomModal,
        showAssignModal, setShowAssignModal,
        hideAssignModal,
        classOptions,
        classId, setClassId,
        getClassList,
        listStudents,
        selectStudent,
        selectAll,
        selectAllStudent,
        onSwipeLeft, onSwipeRight,
        listSolutions,
        showSolution, setShowSolution,
        isShowAssignAlertModal,
        onHideAssignAlert,
        alertMessage,
        assignWorksheets, banner, method, setAlertMessage,
        setShowAssignAlertModal, listWbFromRedux, workbookId,
        operatorId, WorkbookDetail, matchUrl,
        goBack,
        userName,
        onClickRightWs,
        onClickLeftWs,
        isShowArrowBtnWs
    } = props
    let permisSolution = useSelector(state => state.ws.permissionSolution)

    const worksheet = listWbFromRedux && listWbFromRedux.filter(x => x.workbookId === workbookId).length > 0 
    ?   listWbFromRedux.filter(x => x.workbookId === workbookId)[0].worksheet 
    :   [];

    const zoomWsWidth = (window.innerWidth/2) > (window.innerHeight-132) 
    ?    ((window.innerHeight-132) * 0.772386)
    :    (window.innerWidth/2 - 40) 
    const wsWidthStyle = isBrowserDesktop ? {width: zoomWsWidth} : {}

    const scroolWidth = (window.innerWidth/2) > (window.innerHeight-132) 
    ?    ((window.innerHeight - 30) * 0.772386)
    :    (window.innerWidth/2 - 40) 
    const scroolWidthStyle = isBrowserDesktop ? {width: scroolWidth, margin: '0px auto'} : {}

    const lethargy = new Lethargy()
    const bind = useWheel(({ event, last, memo: wait = false }) => {
        if (!last) {
          const s = lethargy.check(event)
          if (s) {
            if(s < 0) { onSwipeRight();}  
            if(s > 0) { onSwipeLeft();}
          } else return false
        } else {
          return false
        }
    })
    listStudents.sort((a, b) => {
        if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
        if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
        return 0;
    })
    
    function onClickChapter(chapter) {
        //save current chapter to redux on Desktop when user click chapter
        const SESSION = chapter.Navigations && chapter.Navigations.length > 0 
            ?   chapter.Navigations.map(item => {return {Index: item.Index, _id: item._id, Label: item.Label}})
            :   []
        chapter =  {Index: chapter.Index, _id: chapter._id, Label: chapter.Label, Session: SESSION}
        dispatch({ type: type.SAVE_CURRENT_CHAPTER, payload: chapter })

        //save current session to redux on Desktop when usr click chapter
        //firs session of chapter
        let session = chapter.Session[0]
        session =  {Index: session.Index, _id: session._id, Label: session.Label}
        dispatch({ type: type.SAVE_CURRENT_SESSION, payload: session })

        let url = matchUrl.url.split("Lession")
        if(chapter.Session && chapter.Session.length > 0)
        history.push(`${url[0]}Lession/${chapter.Session[0]._id}`)
    }

    function onClickSession(session) {
        //save current session to redux on Desktop when usr click session
        session =  {Index: session.Index, _id: session._id, Label: session.Label}
        dispatch({ type: type.SAVE_CURRENT_SESSION, payload: session })
        let url = matchUrl.url.split("Lession")
        history.push(`${url[0]}Lession/${session._id}`)
    }

    return (
        <>
            <div 
                className="bg-xam77"
                ></div>
            
            <div style={{paddingTop: isBrowserDesktop ? 51 : 80 }}>

            {/* ======Header back desktop ====== */}
            {   isBrowserDesktop  && <HeaderBack goBack={goBack} userName={userName}/>  }
            {/* ================================ */}
                <section    
                    className="container-fluid" 
                    style={{marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: isBrowserDesktop ? 51 : 0}}
                >
                    <div id="tableOfContent-container" className="p-4">
                        <div className="outersec row justify-content-between" >
                            <div id="tableOfContent" className={WorkbookDetail === undefined ? "col-5 row" : "col-6 row"} >
                                {   
                                    ((isDesktop && !isMobile) && WorkbookDetail !== undefined) 
                                    &&  <div className={classNames("p-2", [{"col-6": isBrowserDesktop, "pt-0": isBrowserDesktop}])}>

                                    <h4 className="font-Paytone mb-0 bg-epen p-3">{WorkbookDetail.Title}</h4>
                                    <div 
                                        id="accordion" 
                                        role="tablist">
                                        {WorkbookDetail.Chapters && WorkbookDetail.Chapters.map((chapter, cindex) => {
                                            const selectedWorksheets = (listWbFromRedux && listWbFromRedux.length > 0 ) 
                                            ?   listWbFromRedux[0].worksheet
                                            :   []
                                            const isSelectChapter = (selectedWorksheets && selectedWorksheets.length > 0 )
                                            ?   selectedWorksheets.some(i => 
                                                    {   
                                                        if(i.Chapter)
                                                        return (chapter._id === i.Chapter._id)
                                                    })
                                            :   false
                                            return (
                                                <div className="card border-0 radius-0 bg-xam03" key={cindex.toString()}>
                                                    <a 
                                                            className={classNames([{"text-black": !isSelectChapter, "text-red": isSelectChapter}])}
                                                            onClick={() => {onClickChapter(chapter);pushAppBottom(`${cindex.toString()}`);}}
                                                        >
                                                    <div className="card-header bg-white" 
                                                        role="tab"
                                                        id={`headingOne1-${cindex.toString()}`}>
                                                        {chapter.Label}
                                                    </div></a>
                                                    <div 
                                                        id={`collapseOne${cindex.toString()}`} 
                                                        className="cus-collapse"
                                                        data-parent="#accordion"
                                                    >
                                                        <div className="card-body p-0">
                                                            <table className="table mb-0">
                                                                <tbody className="table-epen">
                                                                    {chapter.Navigations.map((item, index) => {
                                                                        const selectedWorksheets = (listWbFromRedux && listWbFromRedux.length > 0 ) 
                                                                        ?   listWbFromRedux[0].worksheet
                                                                        :   []
                                                                        const isSelectSession = (selectedWorksheets && selectedWorksheets.length > 0 )
                                                                        ?   selectedWorksheets.some(i => {
                                                                                if(i.Session)
                                                                                return (item._id === i.Session._id)}
                                                                            )
                                                                        :   false
                                                                        return (
                                                                            <tr key={index.toString()} 
                                                                                onClick={() => onClickSession(item)}
                                                                            >
                                                                                <td className="border-bottom border-top-0 pointer focus">
                                                                                    <span className={classNames([{"text-red": isSelectSession, "text-black": !isSelectSession}])} >
                                                                                        {item.Label}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>}

                                <div className={classNames("inner", [{"col-6 row": isBrowserDesktop}])}>
                                    <div className={classNames("col-12")}>
                                        <div style={{ zIndex: 10 }} className={isMobile ? "seclect-all mb-3" : "seclect-all mb-3 p-0"}>
                                            <button type="button" 
                                                className={isMobile ? "btn bg-epen text-white radius-0" : "btn bg-epen text-white radius-0 col-12"} 
                                                onClick={selectAllWorksheet}
                                            >Select all</button>
                                        </div>
                                        <div className={isMobile ? "form-row" : "form-row"}>
                                            {listWs && listWs.map((item, index) => {

                                                return (
                                                    <div className="col-6 mb-2" key={index.toString()} 
                                                        onClick={() => selectWorksheet(item._id)}
                                                    >
                                                        <div id='getWidthHeightBanner' className="position-relative box-img pos-a mh-150 pointer"  >
                                                            {(worksheet.some(a => a.worksheetId === item._id) || item.Checked) && <div style={{ zIndex: 4 }} 
                                                                className="chon-sach position-absolute d-flex justify-content-center pointer"
                                                            >
                                                                <div className="align-self-center">
                                                                    <i className={classNames("far", "fa-check-circle", [{"fa-5x": !isBrowserDesktop, "fa-3x": isBrowserDesktop}])}></i>
                                                                </div>
                                                            </div>}

                                                            {(item.Workbook && item.Workbook.Category === "Foundation") ?
                                                                <ImageWithBanner mainImage={item.Url} dynamicBanner={banner} /> :
                                                                <img className="img-fluid" src={item.Url} alt="" />
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div style={{ zIndex: 10}} className={WorkbookDetail === undefined ? "col-5" : "col-6"}>
                                <div className="inner text-center" style={wsWidthStyle}>
                                    {zoomWorksheet._id &&
                                        <div className="position-relative pos-a" onClick={() => setShowZoomModal(true)}>
                                            <div style={{ zIndex: 10 }} className={(showZoomInstruct ? "fadeIn" : "fadeOut") + " chon-sach position-absolute d-flex justify-content-center pointer"}>
                                                <div className="align-self-center">
                                                    <i className="fas fa-search fa-6x"></i> <br />
                                                    <span className="font-Paytone font-20 w-100">Tap to zoom</span>
                                                </div>
                                            </div>
                                            {(zoomWorksheet.Workbook && zoomWorksheet.Workbook.Category === "Foundation") ?
                                                <ImageWithBanner mainImage={zoomWorksheet.Url} dynamicBanner={banner} /> :
                                                <img className="img-fluid" src={zoomWorksheet.Url} alt="" />
                                            }

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-fix" style={{ zIndex: 10 }}>
                        <div className="row align-items-end" style={{marginBottom: isBrowserDesktop ? 7 : 4}}> 
                            <div className="col-auto mr-auto">
                                <ul className="nav">
                                    <li className="nav-item bg-epen">
                                        <div className="nav-link pointer" onClick={() => {
                                            if (listWbFromRedux.length > 0) {
                                                if ((method === Const.AccountMethod.TRIALS || method === Const.AccountMethod.RESTRICTED) && role !== Const.Role.VENDOR) {
                                                    setAlertMessage("You need to update to use this features")
                                                    setShowAssignAlertModal(true)
                                                    setShowAssignModal(false)

                                                } else
                                                    setShowAssignModal(true)
                                            }
                                        }} >Send to Students</div>
                                    </li>
                                    <li className="nav-item bg-xam33">
                                        <div className="nav-link pointer" onClick={clearAllWorksheet}>Clear</div>
                                    </li>
                                    <li className="nav-item bg-xam1a">
                                        <div className="nav-link pointer" onClick={() => setShowSelected(!showSelected)}>{showSelected ? 'Hide' : 'Show'}</div>
                                    </li>
                                </ul>
                            </div>
                            {(listWs[0].Workbook && permisSolution && isMobile && WorkbookDetail && WorkbookDetail.Solutions && WorkbookDetail.Solutions.length > 0) &&
                                <div className="col-auto">
                                    {!showSolution ?
                                        <div style={{ marginRight: 100, marginBottom: 25 }} className="upload1 drop-shadow d-flex flex-column justify-content-center" onClick={() => setShowSolution(true)}><i className="fas fa-book-medical fa-2x"></i></div>
                                        :
                                        <div className="close1 drop-shadow d-flex flex-column justify-content-center" onClick={() => setShowSolution(false)}><i className="fas fa-times"></i></div>
                                    }
                                </div>}
                            {(listWs[0].Workbook && permisSolution && !isMobile && WorkbookDetail && WorkbookDetail.Solutions && WorkbookDetail.Solutions.length > 0) &&
                                <div className="col-auto">
                                    {/* commented by Thanh */}
                                    {/* {!showSolution ?
                                        <div style={{ marginRight: 100, marginBottom: 35, backgroundColor: "#08222d", color: "white",fontSize: 23, fontWeight: 900 }} className="btn pl-40 pr-40 drop-shadow radius10 pointer" onClick={() => setShowSolution(true)}>Include Solution</div>
                                        :
                                        <div className="close1 drop-shadow d-flex flex-column justify-content-center" onClick={() => setShowSolution(false)}><i className="fas fa-times"></i></div>
                                    } */}
                                </div>}
                        </div>

                        <div className={"tran-50 " + (showSelected ? "h-30" : "h-0")}>
                            {
                                showSelected && isShowArrowBtnWs && isBrowserDesktop &&
                                <div className="pointer"
                                    style={{
                                        top: "50%",
                                        left: 17,
                                        position: "absolute",
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        backgroundColor: "rgba(0,0,0,0.7)",
                                        borderRadius: "50%",
                                        zIndex: 200
                                    }}
                                    onClick={onClickLeftWs}
                                >
                                    <i className="fa fa-angle-left fa-2x"></i>
                                </div>
                            }

                            <div className="no-pad scroller" >
                                <div 
                                    className={isMobile 
                                        ?   "list-ws-assign-responsive col-lg-12 no-pad btn-header-links padding-align top-adjust d-flex" + (showSolution && listSolutions.length > 0 ? "" : " p-3") 
                                        :   "list-ws-assign-responsive col-lg-12 no-pad btn-header-links padding-align top-adjust d-flex" + (showSolution && listSolutions.length > 0 ? "" : " p-3")
                                    }
                                    id="scroll-worksheet"
                                >
                                    {worksheet.map((item, index) => {
                                        return (
                                            <div className="mr-1 el-worksheet" style={{ position: 'relative', margin: (showSolution && listSolutions.length > 0) ? "" : 0 }} key={index.toString()}>
                                                {(item.Category === "Foundation") ?
                                                    <ImageWithBanner styleMainImage={'height-190'} mainImage={item.url} dynamicBanner={banner}
                                                        onClick={() =>{ setZoomWorksheet(item); console.log(item)}} key={index.toString()}
                                                    /> :
                                                    <img src={item.url} alt="" height="190" onClick={() => setZoomWorksheet(item)} key={index.toString()} />
                                                }
                                            </div>

                                        )
                                    })}
                                    {showSolution &&
                                        worksheet.map((item, index) => {
                                            if (item.Category === "Concept") {
                                                return (
                                                    <div className="el-worksheet bg-chon pt-0" key={index.toString()}>
                                                        <img key={index.toString()} className="height-190 ml-1 mr-1" src={item.Solutions} alt=""/>
                                                    </div>
                                                )
                                            } else
                                                return (
                                                    <div className="el-worksheet bg-chon pt-0" key={index.toString()}>
                                                        <ImageWithBanner key={index.toString()} styleWrapMainImage={''} styleMainImage={'height-190'} mainImage={item.Solutions} dynamicBanner={banner} />
                                                    </div>
                                                )
                                        })
                                    }
                                </div>
                            </div>
                            {
                                showSelected && isShowArrowBtnWs && isBrowserDesktop &&
                                <div className="pointer"
                                    style={{
                                        top: "50%",
                                        right: 17,
                                        position: "absolute",
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        backgroundColor: "rgba(0,0,0,0.7)",
                                        borderRadius: "50%",
                                        zIndex: 200
                                    }}
                                    onClick={onClickRightWs}
                                >
                                    <i className="fa fa-angle-right fa-2x"></i>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </div>
            <Modal
                show={showZoomModal}
                onHide={hideZoomModal}
                className="modal pl-0 pr-0 fade"
                size="xl"
                id="zoomModal"
            >
                <div className="modal-dialog modal-xl modal-dialog-centered mt-0 mb-0">
                    <div style={{ position: 'relative' }} className="modal-content pte-auto">
                        {/* <button style={{ zIndex: 100, position: 'absolute', right: 0, top: '-10px' }} type="button" className="close" onClick={hideZoomModal}>&times;</button> */}
                        <div className="modal-body pointer" >
                            {/* <button style={{ zIndex: 100 }} type="button" className="close" onClick={hideZoomModal}>&times;</button> */}
                            {
                                !isBrowserDesktop  &&
                                <Swipeable
                                    onSwipedRight={e => {
                                        onSwipeRight()
                                    }}
                                    onSwipedLeft={e => { onSwipeLeft() }}
                                    style={{ position: 'relative', zIndex: 0, ...wsWidthStyle }}
                                >
                                    {zoomImages.map((item, index) => {
                                        return (
                                            <div className={index === zoomIndex ? "rotate_x_left_before" : "rotate_x_left_after"}
                                                style={{ position: index > 0 ? "absolute" : "relative", top: 0, zIndex: index === zoomIndex ? 1 : 0 }}
                                                key={index.toString()} 
                                            >
                                                {
                                                    (listWs[0].Workbook && listWs[0].Workbook.Category === "Foundation") ?
                                                        <ImageWithBanner mainImage={item} dynamicBanner={banner} onClick={hideZoomModal} />
                                                        : <img className="img-fluid" src={item} alt="" onClick={hideZoomModal} />
                                                }
                                            </div>
                                        )
                                    })}
                                </Swipeable>
                            }

                            {
                                isBrowserDesktop  &&
                                <div
                                    style={{ position: 'relative', zIndex: 0, ...scroolWidthStyle }}
                                    {...bind()}
                                >
                                    {zoomImages.map((item, index) => {
                                        return (
                                            <div className={index === zoomIndex ? "rotate_x_left_before" : "rotate_x_left_after"}
                                                style={{ position: index > 0 ? "absolute" : "relative", top: 0, zIndex: index === zoomIndex ? 1 : 0 }}
                                                key={index.toString()} 
                                            >
                                                {
                                                    (listWs[0].Workbook && listWs[0].Workbook.Category === "Foundation") ?
                                                        <ImageWithBanner mainImage={item} dynamicBanner={banner} onClick={hideZoomModal} />
                                                        : <img className="img-fluid" src={item} alt="" onClick={hideZoomModal} />
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </Modal>

            <Modal
                show={showAssignModal}
                onHide={hideAssignModal}
                className="modal fade"
                dialogClassName="custom-dialog"
            >
                <div className="modal-dialog modal-dialog-centered" style={{ width: '100%' }}>
                    <div className="modal-content bg-epen pte-auto">

                        <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                            <h4 className="modal-title font-Paytone text-center w-100">Send to</h4>
                        </div>

                        <div className="modal-body pl-5 pr-5">
                            {role.includes(Const.Role.TEACHER) ? <select className="custom-select mb-3" value={classId} onChange={e => setClassId(e.target.value)}>
                                {classOptions && classOptions.map((item, index) => {
                                    return (
                                        <option value={item._id} key={index.toString()}>{item.Name+' - '+item.Code}</option>
                                    )
                                })}
                            </select> :
                                <Select
                                    className="form-control"
                                    value={classOptions.filter(x => x._id === classId)[0]}
                                    options={classOptions}
                                    onInputChange={getClassList}
                                    onChange={(cl) => setClassId(cl._id)}
                                    placeholder="Enter class code"
                                    styles={customSelectClassStyles}
                                    components={{ DropdownIndicator }}
                                />
                            }
                            <h5 className="font-Paytone mt-2">Class List</h5>
                            <div className="box-nd tran-60 radius-0 h300">
                                <div className="table-responsive">
                                    <table id="sailorTable" className="table" width="100%">
                                        <thead>
                                            <tr>
                                                <th className="border-0 p-0"><span className="tranTB-70 radius-l16 p-2 btn-block pl-3">Student Name</span></th>
                                                <th className="border-0 p-0 text-right"><span className="tranTB-70 radius-r16 p-2 btn-block pr-3">Student ID</span></th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-epen1 top165 w380 ml-61">
                                            {listStudents.length > 0 && (<tr>
                                                <td className="border-top-0 align-middle">
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="checkbox" className="custom-control-input" id="customRadio" checked={selectAll} onChange={e => selectAllStudent(e.target.checked)} />
                                                        <label className="custom-control-label" htmlFor="customRadio">Select All</label>
                                                    </div>
                                                </td>
                                                <td className="border-top-0 text-right align-middle text-uppercase pr-3">
                                                </td>
                                            </tr>)}
                                            {listStudents && listStudents.sort().map((item, index) => {
                                                return (
                                                    <React.Fragment key={index.toString()}>
                                                        <tr >
                                                            <td className="border-top-0 align-middle">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="checkbox" className="custom-control-input" id={`cb-${item._id}`} checked={item.Checked} onClick={e => selectStudent(item._id)} />
                                                                    <label className="custom-control-label" htmlFor={`cb-${item._id}`}>{item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)}</label>
                                                                </div>
                                                            </td>
                                                            <td className="border-top-0 text-right align-middle text-uppercase pr-3">
                                                                {item.Code}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 mr-auto"><button type="button" onClick={hideAssignModal} className="btn btn-light font-Paytone btn-block">Cancel</button></div>
                                <div className="col-5"><button type="button" onClick={assignWorksheets} className="btn btn-light font-Paytone float-right btn-block">Assign</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <ModalAlertOperator
                showModal={isShowAssignAlertModal}
                alertMessage={alertMessage}
                role={role}
                AccountMethod={AccountMethod}
                keyStorage={'PopupAssignWorksheet'}
                functionClose={onHideAssignAlert}
                functionUpgrade={null}
            />

            <ModalAlertForTeacher
                showModal={isShowAssignAlertModal}
                role={role}
                showRadioButton={false}
                AccountMethod={AccountMethod}
                functionClose={() => {
                    onHideAssignAlert()
                }}
            />

        </>
    );
}

const customSelectClassStyles = {
    ...Const.customSelectStyles,
    singleValue: (provided, state) => ({
        ...provided,
        color: '#858585',
    })
}

export default React.memo(SendWorksheetsView);

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="fa fa-search text-white"></i>
        </components.DropdownIndicator>
    );
};