/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Select, { components } from 'react-select'
import API from '../../../utils/Api'
import * as Fc from '../../../utils/Functions'
import { Modal } from "react-bootstrap"
import * as Const from "../../../constants/Const"
import * as Type from './../../../constants/actionType'

//component
import CreateStudent from './CreateStudent'
import CreateTeacher from './CreateTeacher'
import EditStudent from './EditStudent'
import EditTeacher from './EditTeacher'

import { useSelector, useDispatch } from 'react-redux'
import useCheckdevice from '../../../hooks/useCheckdevice'
import classNames from 'classnames'
import { isDesktop, isMobile } from "react-device-detect"
//style
import './style.css'

export default function Accounts(props) {
    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))
    
    //action Create and Edit
    const [action, setAction] = useState('Edit')
    const operatorId = Fc.getRole().includes(Const.Role.VENDOR) ? props.match.params.id : Fc.getOperator()
    const [type, setType] = useState(Const.Role.TEACHER)
    const [options, setOption] = useState([])
    const [selectedUser, setSelectedUser] = useState('')
    const [listClass, setListClass] = useState([])

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState('Male')
    const [grade, setGrade] = useState(1)
    const [available, setAvailable] = useState(true)
    const [additionalComment, setComment] = useState('')
    const [parentEmail, setParentEmail] = useState('')
    const [parentName, setParentName] = useState('')
    const [parentPhone, setParentPhone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [accountId, setAccountId] = useState('')
    const [passwordShown, setPasswordShown] = useState(false)
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)

    const [isShowDeleteModal, setShowDeleteModal] = useState(false)
    const [isShowAddNewStudentModal, setShowAddNewStudentModal] = useState(false)
    const [isShowAddNewTeacherModal, setShowAddNewTeacherModal] = useState(false)
    const [codeTeacher, setCodeTeacher] = useState("")
    const [codeStudent, setCodeStudent] = useState("")

    // Check create Alert
    const [alertLimitStudent, setAlertLimitStudent] = useState("")
    const [isShowAddErrorMessageModal, setShowAddErrorMessageModal] = useState(false)
    const [errorAddMessage, setErrorAddMessage] = useState("")
    const [stateButton, setStateButton] = useState("Add")

    const [dayLeft, setDayLeft] = useState(0)
    const [isShowAddAlertModal, setShowAddAlertModal] = useState(false)
    const [isShowAlertLimitStudent, setShowAlertLimitStudent] = useState(false)

    const [totalTeacher, setTotalTeacher] = useState(0)
    const [totalStudent, setTotalStudent] = useState(0)

    const [maxAccount, setMaxAccount] = useState(0)
    const [currentAccount, setCurrentAccount] = useState(0)

    const [isShowAlertForPaidAccount, setShowAlertForPaidAccount] = useState(false)
    const [isShowErrorLimitedModal, setShowErrorLimitedModal] = useState(false)
    const [messageLimitedStudent, setMessageLimitedStudent] = useState('')

    const [showPopupAddToClass, setShowPopupAddToClass] = useState(false)
    const [showButtonAddToClass, setShowButtonAddToClass] = useState(true)
    const [selectedClass, setSelectedClass] = useState('')
    const [isDisable, setIsDisable] = useState(false)

    const dispatch = useDispatch();

    let userFromRedux = useSelector(state => state.ws.userInfo)

    function hidePopupAddUserToClass() {
        setShowPopupAddToClass(false)
        // setListClass([])
    }

    function HideErrorLimitedModal() {
        setShowErrorLimitedModal(false)
    }

    function ShowAlertForPaidAccount() {
        setShowAlertForPaidAccount(true)
    }

    function ShowAddErrorMessageModal() {
        setShowAddErrorMessageModal(true)
    }

    function HideAddErrorMessageModal() {
        setShowAddErrorMessageModal(false)
    }

    function ShowAddAlert() {
        setShowAddAlertModal(true)
    }

    function onHideAddAlert() {
        setShowAddAlertModal(false)
    }

    function ShowAlertLimitStudent() {
        setShowAlertLimitStudent(true)
    }

    function onHideAlertLimitStudent() {
        setShowAlertLimitStudent(false)
    }

    useEffect(() => {
        getListUsers("")
        GetDayLeftToRestriction()
        GetCurrentAndLimitStudentAccount()
        getAllClass()
        if (Object.keys(userFromRedux).length === 0 && userFromRedux.constructor === Object) {
            return
        } else
            getUserFromRedux()
    }, [])

    function closePopup() {
        onHideAddNewStudentModal()
        if (totalStudent >= 2) {
            ShowAddAlert()
        }
        if (maxAccount - currentAccount <= Const.WarningStudentToLimit) {
            ShowAlertForPaidAccount()
        }
        if (JSON.parse(localStorage.getItem('PopupBlockStudentAfterDay'))) {
            if (maxAccount - currentAccount <= Const.WarningStudentToLimit) {
                ShowAlertLimitStudent()
            }
        }
    }
    
    function getListUsers(val) {
        //tranh loi khi goi server với ky tu dac biet
        if(val && val.length > 0 && (val.includes("/") || val.includes("\\")))  return 

        API.listUsers(operatorId, 1, 20, val).then(res => {
            if (res.status === 200) {
                let listOptions = []
                res.data.Data.Students.map(item => (
                    listOptions.push({ type: Const.Role.STUDENT, value: item.Code, label: `${item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)} - ${item.Code}`, ...item })
                ))
                res.data.Data.Teachers.map(item => (
                    listOptions.push({ type: Const.Role.TEACHER, value: item.Code, label: `${item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)} - ${item.Code}`, ...item })
                ))
                setOption(listOptions.sort((a, b) => {
                    if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
                    if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
                    return 0;
                  }))
            }
        }).catch((err) => {
            Fc.showError("Can not connect to server")
        })
    }

    function clearForm() {
        setAccountId("")
        setFirstName("")
        setLastName("")
        setEmail("")
        setPhone("")
        setGender("Male")
        setAvailable("")
        setComment("")
        setParentEmail("")
        setParentName("")
        setParentPhone("")
        setPassword("")
        setConfirmPassword("")
        setAccountId("")
        setSelectedUser("")
        setGrade(1)

        setSelectedClass('')
        setShowButtonAddToClass(true)

    }

    function showDeleteModal() {
        setShowDeleteModal(true)
    }

    function togglePasswordVisiblity() {
        setPasswordShown(passwordShown ? false : true);
    };

    function toggleConfirmPasswordVisiblity() {
        setConfirmPasswordShown(confirmPasswordShown ? false : true);
    };

    function GetDayLeftToRestriction() {
        API.GetDayLeftToRestriction(operatorId)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        setDayLeft(res.data.Data)
                    } else {
                        if(res.data.Message && (res.data.Message.includes('Ok') || res.data.Message.includes('OK') || res.data.Message.includes('Unexpected character'))) return
                        Fc.showError(res.data.Message)
                    }
                } else {
                    if(res.statusText && (res.statusText.includes('Ok') || res.statusText.includes('OK') || res.statusText.includes('Unexpected character'))) return
                    Fc.showError(res.statusText)
                }
            })
            .catch((err) => {
                Fc.showError("Can not connect to server")
            })
    }

    function GetCurrentAndLimitStudentAccount() {
        API.GetCurrentAndLimitStudentAccount(operatorId)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        if (res.data.Data) {
                            setMaxAccount(res.data.Data.MaxAccount)
                            setCurrentAccount(res.data.Data.CurrentAccount)
                            if (res.data.Data.MaxAccount - res.data.Data.CurrentAccount <= Const.WarningStudentToLimit) {
                                setAlertLimitStudent(`You have ${res.data.Data.CurrentAccount}/${res.data.Data.MaxAccount} students account. Do you want to switch plans?`)
                            }
                        }
                    } else {
                        if(res.data.Message && (res.data.Message.includes('Ok') || res.data.Message.includes('OK') || res.data.Message.includes('Unexpected character'))) return
                        Fc.showError(res.data.Message)
                    }
                } else {
                    if(res.statusText && (res.statusText.includes('Ok') || res.statusText.includes('OK') || res.statusText.includes('Unexpected character'))) return
                    Fc.showError(res.statusText)
                }
            })
            .catch((err) => {
                Fc.showError("Can not connect to server")
            })
    }

    function getAllStudent() {
        API.listStudent(operatorId)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        setTotalStudent(res.data.Total)
                    } else {
                        if(res.data.Message && (res.data.Message.includes('Ok') || res.data.Message.includes('OK') || res.data.Message.includes('Unexpected character'))) return
                        Fc.showError(res.data.Message)
                    }
                } else {
                    if(res.statusText && (res.statusText.includes('Ok') || res.statusText.includes('OK') || res.statusText.includes('Unexpected character'))) return
                    Fc.showError(res.statusText)
                }
            }).catch((err) => {
                Fc.showError("Can not connect to server")
            })
    }

    function insertStudent() {
        //han che summit nhieu lan
        if(isDisable) return
        setIsDisable(true)

        let body = {
            FirstName: firstName,
            LastName: lastName,
            Gender: gender,
            PhoneNumber: phone,
            Comment: additionalComment,
            ParentInfo: {
                Name: parentName,
                Email: parentEmail,
                PhoneNumber: parentPhone
            },
            Password: password,
            ConfirmPassword: confirmPassword,
            OperatorId: operatorId,
            ClassId: selectedClass._id,
            Grade: grade
        }
        API.insertStudents(body).then((res) => {
            if (res.status === 200) {
                if (res.data.Success) {
                    if (res.data.Data) {
                        setCodeStudent(res.data.Data.Code)
                        GetCurrentAndLimitStudentAccount()
                        setShowAddNewStudentModal(true)
                        getListUsers("")
                        getAllStudent()

                        // xoa class dc chon khi them teacher thanh cong
                        setSelectedClass('')
                        setShowButtonAddToClass(true)
                    }
                } else {
                    if (res.data.Message === "You need to upgrade to use this feature") {
                        setErrorAddMessage(res.data.Message)
                        ShowAddErrorMessageModal()
                    }
                    else if (res.data.Message === "You have reached the maximum amount of account you can create. Do you want to switch plans?") {
                        setMessageLimitedStudent("You have reached the maximum amount of account you can create. Do you want to switch plans?")
                        setShowErrorLimitedModal(true)
                    }
                    else {
                        if(res.data.Message && (res.data.Message.includes('Ok') || res.data.Message.includes('OK') || res.data.Message.includes('Unexpected character'))) return
                        Fc.showError(res.data.Message)
                    }
                }
                setIsDisable(false)
            } else {
                setIsDisable(false)
                // Fc.showError(res.statusText)
            }
        }).catch((err) => {
            setIsDisable(false)
            Fc.showError("Can not connect to server")
        })
    }

    function getAllTeacher() {
        API.listTeachers(operatorId)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        setTotalTeacher(res.data.Total)
                    } else {
                        if(res.data.Message && (res.data.Message.includes('Ok') || res.data.Message.includes('OK') || res.data.Message.includes('Unexpected character'))) return
                        Fc.showError(res.data.Message)
                    }
                } else {
                    // Fc.showError(res.statusText)
                }
            }).catch((err) => {
                Fc.showError("Can not connect to server")
            })
    }

    function insertTeacher() {
        //han che summit nhieu lan
        if(isDisable) return
        setIsDisable(true)

        let body = {
            FirstName: firstName,
            LastName: lastName,
            Gender: gender,
            Email: email,
            PhoneNumber: phone,
            Comment: additionalComment,
            OperatorId: operatorId,
            Password: password,
            ConfirmPassword: confirmPassword,
            ClassId: selectedClass._id
        }

        API.insertTeachers(body).then((res) => {
            if (res.status === 200) {
                if (res.data.Success) {
                    if (res.data.Data) {
                        setCodeTeacher(res.data.Data.Code)
                        setShowAddNewTeacherModal(true)
                        getListUsers("")
                        getAllTeacher()

                        // xoa class dc chon khi them teacher thanh cong
                        setSelectedClass('')
                        setShowButtonAddToClass(true)
                    } else {
                        Fc.showError(res.data.Message)
                    }
                } else {
                    if (res.data.Message === "You need to upgrade to use this feature") {
                        setErrorAddMessage(res.data.Message)
                        ShowAddErrorMessageModal()
                    } else {
                        if(res.data.Message && (res.data.Message.includes('Ok') || res.data.Message.includes('OK') || res.data.Message.includes('Unexpected character'))) return
                        Fc.showError(res.data.Message)
                    }
                }
            } else {
                // Fc.showError(res.statusText)
            }
            setIsDisable(false)
        }).catch((err) => {
            setIsDisable(false)
            Fc.showError("Can not connect to server")
        })


    }

    function chooseUser(option) {
        removeOverlay()
        setSelectedUser(option)
        setType(`${option.type}`)
        if (option.type === Const.Role.STUDENT) {
            API.getDetailStudent(option._id)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.Success) {

                            setFirstName(res.data.Data.FirstName || '')
                            setLastName(res.data.Data.LastName || '')
                            setAvailable(res.data.Data.IsActive)
                            setGender(res.data.Data.Gender || 'Male')
                            setGrade(res.data.Data.Grade || 1)
                            setPhone(res.data.Data.PhoneNumber || '')
                            setEmail(res.email || '')
                            setComment(res.data.Data.Comment || '')
                            setParentEmail(res.data.Data.ParentInfo.Email || '')
                            setParentName(res.data.Data.ParentInfo.Name || '')
                            setParentPhone(res.data.Data.ParentInfo.PhoneNumber || '')
                            setPassword('')
                            setConfirmPassword('')
                            setAccountId(option.value || '')
                            // setListClass([])
                        } else {
                            if(res.data.Message && (res.data.Message.includes('Ok') || res.data.Message.includes('OK') || res.data.Message.includes('Unexpected character'))) return
                            Fc.showError(res.data.Message)
                        }
                    } else {
                        // Fc.showError(res.statusText)
                    }
                }).catch((err) => {
                    Fc.showError("Can not connect to server")
                })
        } else {
            API.getDetailTeacher(option._id)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.Success) {
                            setFirstName(res.data.Data.FirstName || '')
                            setLastName(res.data.Data.LastName || '')
                            setAvailable(res.data.Data.IsActive)
                            setGender(res.data.Data.Gender || 'Male')
                            setPhone(res.data.Data.PhoneNumber || '')
                            setEmail(res.data.Data.Email || '')
                            setComment(res.data.Data.Comment || '')
                            setPassword('')
                            setConfirmPassword('')
                            setAccountId(option.value || '')
                        } else {
                            if(res.data.Message && (res.data.Message.includes('Ok') || res.data.Message.includes('OK') || res.data.Message.includes('Unexpected character'))) return
                            Fc.showError(res.data.Message)
                        }
                    } else {
                        // Fc.showError(res.statusText)
                    }
                }).catch((err) => {
                    Fc.showError("Can not connect to server")
                })
        }
    }

    function onChangeFirstName(e) {
        setFirstName(e.target.value)
    }

    function onChangeLastName(e) {
        setLastName(e.target.value)
    }

    function onChangeEmail(e) {
        setEmail(e.target.value)
    }

    function onChangePhone(e) {
        setPhone(e.target.value)
    }

    function onChangeGender(e) {
        setGender(e.target.value)
    }

    function onChangeGrade(e) {
        setGrade(e.target.value)
    }

    function onChangeAvailable(e) {
        setAvailable(e.target.checked)
    }

    function onChangeComment(e) {
        setComment(e.target.value)
    }

    function onChangeParentEmail(e) {
        setParentEmail(e.target.value)
    }

    function onChangeParentName(e) {
        setParentName(e.target.value)
    }

    function onChangeParentPhone(e) {
        setParentPhone(e.target.value)
    }

    function onChangePassword(e) {
        setPassword(e.target.value)
    }

    function onChangeConfirmPassword(e) {
        setConfirmPassword(e.target.value)
    }

    function editTeacher() {
        if (!selectedUser || selectedUser === '')
            return
        //han che multi submit
        if(isDisable) return
        setIsDisable(true)

        let body = {
            _id: selectedUser._id,
            FirstName: firstName,
            LastName: lastName,
            Gender: gender,
            Email: email,
            PhoneNumber: phone,
            Comment: additionalComment,
            Password: password,
            ConfirmPassword: confirmPassword,
            IsActive: available,
            OperatorId: operatorId,
            ClassId: selectedClass._id
        }
        API.editTeacher(body).then(res => {
            if (res.status === 200) {
                if (res.data.Success) {
                    Fc.showSuccess("Edit teacher success")
                    if (userFromRedux) {
                        dispatch({ type: Type.SAVE_USER_INFO, payload: {} })
                        clearForm()
                    }

                } else {
                    if (res.data.Message === "You need to upgrade to use this feature") {
                        setErrorAddMessage(res.data.Message)
                        ShowAddErrorMessageModal()
                    } else {
                        if(res.data.Message && (res.data.Message.includes('Ok') || res.data.Message.includes('OK') || res.data.Message.includes('Unexpected character'))) return
                        Fc.showError(res.data.Message)
                    }
                }
            } else {
                // Fc.showError(res.statusText)
            }
            setIsDisable(false)
        }).catch(err => {
            setIsDisable(false)
        })

    }

    function editStudent(e) {
        if (!selectedUser || selectedUser === '')
            return
        
        //han che multi submit
        if(isDisable) return
        setIsDisable(true)

        let body = {
            _id: selectedUser._id,
            FirstName: firstName,
            LastName: lastName,
            Gender: gender,
            PhoneNumber: phone,
            Comment: additionalComment,
            ParentInfo: {
                Name: parentName,
                Email: parentEmail,
                PhoneNumber: parentPhone
            },
            Password: password,
            ConfirmPassword: confirmPassword,
            IsActive: available,
            OperatorId: operatorId,
            ClassId: selectedClass._id,
            Grade: grade
        }

        API.editStudent(body).then(res => {
            if (res.status === 200) {
                if (res.data.Success) {
                    if (userFromRedux) {
                        dispatch({ type: Type.SAVE_USER_INFO, payload: {} })
                        clearForm()
                    }
                    Fc.showSuccess("Edit student success")
                } else {
                    if (res.data.Message === "You need to upgrade to use this feature") {
                        setErrorAddMessage(res.data.Message)
                        ShowAddErrorMessageModal()
                    } else {
                        if(res.data.Message && (res.data.Message.includes('Ok') || res.data.Message.includes('OK') || res.data.Message.includes('Unexpected character'))) return
                        Fc.showError(res.data.Message)
                    }
                }
            } else {
                // Fc.showError(res.statusText)
            }
            setIsDisable(false)
        }).catch((err) => { setIsDisable(false) })

    }

    function deleteUser() {
        
        //han che multi submit
        if(isDisable) return
        setIsDisable(true)

        if (type === Const.Role.TEACHER) {
            API.deleteTeacher(selectedUser._id)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.Success) {
                            Fc.showSuccess("Delete Teacher Success")
                            clearForm()
                            onHideDeleteModal()
                            if (userFromRedux) {
                                dispatch({ type: Type.SAVE_USER_INFO, payload: {} })
                            }
                        } else {
                            if(res.data.Message && (res.data.Message.includes('Ok') || res.data.Message.includes('OK') || res.data.Message.includes('Unexpected character'))) return
                            Fc.showError(res.data.Message)
                        }
                    } else {
                        // Fc.showError(res.statusText)
                    }
                    setIsDisable(false)
                })
                .catch((err) => { setIsDisable(false) })
        }
        if (type === Const.Role.STUDENT) {
            API.deleteStudent(selectedUser._id)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.Success) {
                            Fc.showSuccess("Delete Student Success")
                            clearForm()
                            onHideDeleteModal()
                        } else {
                            if(res.data.Message && (res.data.Message.includes('Ok') || res.data.Message.includes('OK') || res.data.Message.includes('Unexpected character'))) return
                            Fc.showError(res.data.Message)
                        }
                    } else {
                        // Fc.showError(res.statusText)
                    }
                    setIsDisable(false)
                })
                .catch((err) => { setIsDisable(false) })
        }

    }

    function onHideDeleteModal() {
        setShowDeleteModal(false)
    }

    function onHideAddNewStudentModal() {
        setShowAddNewStudentModal(false)
        clearForm()
    }

    function onHideAddNewTeacherModal() {
        setShowAddNewTeacherModal(false)
        clearForm()
    }

    // ================= Phase 2 ===================== //

    function getUserFromRedux() {

        let type = userFromRedux.ParentInfo ? "STUDENT" : "TEACHER";
        let selectUser = {
            "type": type,
            "value": userFromRedux.Code,
            "label": userFromRedux.FullName + '-' + userFromRedux.Code,
            "_id": userFromRedux._id
        }
        setSelectedUser(selectUser)
        setType(type)
        if (type === Const.Role.STUDENT) {
            API.getDetailStudent(userFromRedux._id)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.Success) {
                            setFirstName(res.data.Data.FirstName || '')
                            setLastName(res.data.Data.LastName || '')
                            setAvailable(res.data.Data.IsActive)
                            setGender(res.data.Data.Gender || 'Male')
                            setGrade(res.data.Data.Grade || 1)
                            setPhone(res.data.Data.PhoneNumber || '')
                            setEmail(res.email || '')
                            setComment(res.data.Data.Comment || '')
                            setParentEmail(res.data.Data.ParentInfo.Email || '')
                            setParentName(res.data.Data.ParentInfo.Name || '')
                            setParentPhone(res.data.Data.ParentInfo.PhoneNumber || '')
                            setPassword('')
                            setConfirmPassword('')

                            setAccountId(userFromRedux.Code || '')
                        } else {
                            if(res.data.Message && (res.data.Message.includes('Unexpected character'))) return
                            Fc.showError(res.data.Message)
                        }
                    } else {
                        // Fc.showError(res.statusText)
                    }
                }).catch((err) => {
                    Fc.showError("Can not connect to server")
                })
        } else {
            API.getDetailTeacher(userFromRedux._id)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.Success) {
                            setFirstName(res.data.Data.FirstName || '')
                            setLastName(res.data.Data.LastName || '')
                            setAvailable(res.data.Data.IsActive)
                            setGender(res.data.Data.Gender || 'Male')
                            setPhone(res.data.Data.PhoneNumber || '')
                            setEmail(res.data.Data.Email || '')
                            setComment(res.data.Data.Comment || '')
                            setPassword('')
                            setConfirmPassword('')

                            setAccountId(userFromRedux.Code || '')
                        } else {
                            if(res.data.Message && (res.data.Message.includes('Unexpected character'))) return
                            Fc.showError(res.data.Message)
                        }
                    } else {
                        // Fc.showError(res.statusText)
                    }
                }).catch((err) => {
                    Fc.showError("Can not connect to server")
                })
        }
    }

    useEffect(() => {
        getAllClass()
        //=====
        setSelectedClass('')
        setShowButtonAddToClass(true)
        //=====
    }, [selectedUser])

    function getAllClass(val) {

        let idStudent = ""
        let idTeacher = ""
        if (action === "Edit") {
            idStudent = selectedUser.type === "STUDENT" ? selectedUser._id : null
            idTeacher = selectedUser.type === "TEACHER" ? selectedUser._id : null
        } else {
            idStudent = null
            idTeacher = null
        }
        API.SearchNotJoinClass(operatorId, 1, 0, idStudent, idTeacher, val ? val : "")
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.Success) {
                        let listOptions = []
                        res.data.Data.map(item => (
                            listOptions.push({ value: item._id, label: `${item.Code}`, ...item })
                        ))
                        setListClass(listOptions.sort((a, b) => {
                            if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
                            if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
                            return 0;
                          }));
                    } else {
                        if(res.data.Message && (res.data.Message.includes('Unexpected character'))) return
                        Fc.showError(res.data.Message)
                    }
                } else {
                    // Fc.showError(res.statusText)
                }
            })
            .catch((err) => {
            })

    }

    function chooseClass(option) {
        setSelectedClass(option)
    }

    return (
        <div style={{ paddingTop: isBrowserDesktop ? 51 : 73 }}>
            <div className='bg-xanh'></div>
            <section className="box-xam">
                <div>
                    <div className="form-row">
                        {   
                            action === 'Edit' 
                            // ========= Action Edit =========
                            ?   <div className={classNames({
                                    "col-8": !isBrowserDesktop, 
                                    "mr-auto": !isBrowserDesktop, 
                                    "col-6": isBrowserDesktop, 
                                    "offset-3": isBrowserDesktop, 
                                    "create-student-browser": isBrowserDesktop,
                                    "col-40-desktop": isBrowserDesktop,
                                    "offset-30-desktop": isBrowserDesktop,
                                })}>
                                    <div className="input-group" style={{zIndex:11}}>
                                        <Select
                                            className="form-control"
                                            value={selectedUser}
                                            options={options}
                                            onInputChange={getListUsers}
                                            onChange={chooseUser}
                                            placeholder={selectedUser ? selectedUser : "Enter Name or ID of Student/Teacher"}
                                            styles={customStyles}
                                            components={{ DropdownIndicator }}
                                        />
                                    </div>
                                </div>
                            // ========= Action Add =========
                            :   !isBrowserDesktop
                                ?
                                    <div className={classNames({
                                        "col-7": !isBrowserDesktop, 
                                        "mr-auto": true, 
                                        "col-6": isBrowserDesktop, 
                                        "offset-3": isBrowserDesktop, 
                                        
                                    })}>
                                        <select className={classNames("custom-select", [{"custom-select-desktop": isBrowserDesktop}])} value={type} onChange={(e) => {
                                            setType(e.target.value)
                                            setSelectedClass('')
                                            setShowButtonAddToClass(true)
                                            removeOverlay()
                                            clearForm()
                                        }}>
                                            <option value={Const.Role.STUDENT}>Student</option>
                                            <option value={Const.Role.TEACHER}>Teacher</option>
                                        </select>
                                    </div>
                                :   <div 
                                        className={classNames(
                                            "col-6", 
                                            "offset-3", 
                                            [{
                                                "create-student-browser": isBrowserDesktop,
                                                "col-40-desktop": isBrowserDesktop,
                                                "offset-30-desktop": isBrowserDesktop,
                                            }]
                                        )} 
                                        style={{paddingLeft: '1.5rem'}}
                                    >
                                        <div className="row" style={{paddingLeft: '1.5rem'}}>
                                        <div className="font-Paytone" style={{fontSize: 22, display: 'inline', marginRight: '1rem'}}>Select Role:</div>
                                    
                                              <button
                                                    className={classNames("btn", "font-Paytone", "radius10" ,"pl-3", "pr-3", "btn-black", 'text-white-50', 'mr-2', 'focus')}
                                                    onClick={() => { setType(Const.Role.STUDENT); setSelectedClass(''); setShowButtonAddToClass(true); clearForm(); thisSelect(0) }}
                                                >
                                                    Student
                                                </button>
                                              <button
                                                    className={classNames("btn", "font-Paytone", "radius10" ,"pl-3", "pr-3", "btn-black", 'ml-2','text-white-50', 'focus')}
                                                    onClick={() => { setType(Const.Role.TEACHER); setSelectedClass(''); setShowButtonAddToClass(true); clearForm(); thisSelect(1) }}
                                                >
                                                    Teacher
                                                </button>
                    
                                        </div>
                                    </div>
                        }

                        {
                            !isBrowserDesktop
                            //================IPAD=========================== 
                            ?   <div className="col-auto">
                                    <div className="col-auto pr-0">
                                        {stateButton === "Add" ? <button
                                            className="btn font-Paytone radius10 pl-3 pr-3 bg-white"
                                            onClick={() => { setAction('Create'); setStateButton('Manage'); clearForm() }}
                                        >
                                            <i className="fas fa-user-plus mr-2"></i>Add
                                        </button> :
                                            <button
                                                className="btn font-Paytone radius10 pl-3 pr-3 bg-white"
                                                onClick={() => { setAction('Edit');  setStateButton('Add'); clearForm() }}
                                            >
                                                {/* <i className="fas fa-user-plus mr-2"></i> */}
                                                Cancel
                                        </button>
                                        }
                                    </div>
                                </div>
                            
                            // ================= Desktop ====================
                            :   <div className="col-3">
                                   <div className="row">
                                   <button
                                        className={classNames("btn", "font-Paytone", "radius10", "ml-5", [{"d-none":  (stateButton === "Add"), "text-black": !(stateButton === "Add"), "bg-white": !(stateButton === "Add")} ])}
                                        onClick={() => {thisUnselect(); setAction('Edit'); setStateButton('Add'); clearForm();}}
                                    >
                                        {/* <i className="fas fa-edit mr-2"></i>Manage */}
                                        Cancel
                                    </button>
                                    <button
                                        className={classNames("btn", "font-Paytone", "radius10", "col-5", [{"d-none":  !(stateButton === "Add"), "text-black": (stateButton === "Add"), "bg-white": (stateButton === "Add")} ])}
                                        onClick={() => {thisUnselect(); setAction('Create'); setStateButton('Manage'); clearForm()}}
                                    >
                                        <i className="fas fa-user-plus mr-2"></i>Create
                                    </button> 
                                    </div>
                                </div>
                        }
                        
                    </div>
                        
                </div>
                {/* Modal delete */}
                <Modal
                    show={isShowDeleteModal}
                    onHide={onHideDeleteModal}
                    className="modal fade"
                    id="myModal"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content bg-epen pte-auto">
                            <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                                <h4 className="modal-title font-Paytone">
                                    Are you sure you want to delete this user?
                                </h4>
                            </div>
                            <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                                <div className="row">
                                    <div className="col-5 mr-auto">
                                        <button
                                            type="button"
                                            onClick={onHideDeleteModal}
                                            className="btn btn-light font-Paytone btn-block"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="col-5">
                                        <button
                                            type="button"
                                            onClick={() => deleteUser()}
                                            className="btn btn-light font-Paytone float-right btn-block"
                                        >
                                            <span className="text-danger">Confirm</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </section>
            <div id="shadow-overlay"  style={{ top: isBrowserDesktop ? 105 : 128 }}></div>
            {action === "Create" && type === Const.Role.STUDENT &&
                <CreateStudent
                    isDisable={isDisable}
                    firstName={firstName} onChangeFirstName={onChangeFirstName}
                    lastName={lastName} onChangeLastName={onChangeLastName}
                    phone={phone} onChangePhone={onChangePhone}
                    gender={gender} onChangeGender={onChangeGender}
                    available={available} onChangeAvailable={onChangeAvailable}
                    additionalComment={additionalComment} onChangeComment={onChangeComment}
                    parentEmail={parentEmail} onChangeParentEmail={onChangeParentEmail}
                    parentName={parentName} onChangeParentName={onChangeParentName}
                    parentPhone={parentPhone} onChangeParentPhone={onChangeParentPhone}
                    password={password} onChangePassword={onChangePassword}
                    confirmPassword={confirmPassword} onChangeConfirmPassword={onChangeConfirmPassword}
                    clearForm={clearForm} accountId={accountId}
                    passwordShown={passwordShown} togglePasswordVisiblity={togglePasswordVisiblity}
                    confirmPasswordShown={confirmPasswordShown} toggleConfirmPasswordVisiblity={toggleConfirmPasswordVisiblity}
                    isShowAddNewStudentModal={isShowAddNewStudentModal} onHideAddNewStudentModal={onHideAddNewStudentModal}
                    codeStudent={codeStudent}
                    alertMessage={"This student account will be blocked after " + dayLeft + " days"}
                    insertStudent={insertStudent}
                    isShowAddAlertModal={isShowAddAlertModal}
                    onHideAddAlert={onHideAddAlert} totalStudent={totalStudent} ShowAddAlert={ShowAddAlert}
                    isShowAlertLimitStudent={isShowAlertLimitStudent} onHideAlertLimitStudent={onHideAlertLimitStudent}
                    alertLimitStudent={alertLimitStudent} ShowAlertLimitStudent={ShowAlertLimitStudent}
                    isShowAddErrorMessageModal={isShowAddErrorMessageModal}
                    HideAddErrorMessageModal={HideAddErrorMessageModal}
                    errorAddMessage={errorAddMessage} WarningStudentToLimit={Const.WarningStudentToLimit}
                    maxAccount={maxAccount}
                    currentAccount={currentAccount}
                    isShowAlertForPaidAccount={isShowAlertForPaidAccount}
                    setShowAlertForPaidAccount={setShowAlertForPaidAccount}
                    closePopup={closePopup}
                    isShowErrorLimitedModal={isShowErrorLimitedModal}
                    setShowErrorLimitedModal={setShowErrorLimitedModal}
                    HideErrorLimitedModal={HideErrorLimitedModal}
                    messageLimitedStudent={messageLimitedStudent}

                    showPopupAddToClass={showPopupAddToClass} setShowPopupAddToClass={setShowPopupAddToClass}
                    hidePopupAddUserToClass={hidePopupAddUserToClass}
                    showButtonAddToClass={showButtonAddToClass} setListClass={setListClass}
                    getAllClass={getAllClass} listClass={listClass} selectedClass={selectedClass}
                    chooseClass={chooseClass} setShowButtonAddToClass={setShowButtonAddToClass}
                    setSelectedClass={setSelectedClass}
                    grade={grade} onChangeGrade={onChangeGrade}
                    {...props}
                />
            }
            {action === "Create" && type === Const.Role.TEACHER &&
                <CreateTeacher
                    firstName={firstName} onChangeFirstName={onChangeFirstName}
                    lastName={lastName} onChangeLastName={onChangeLastName}
                    email={email} onChangeEmail={onChangeEmail}
                    phone={phone} onChangePhone={onChangePhone}
                    gender={gender} onChangeGender={onChangeGender}
                    password={password} additionalComment={additionalComment} onChangeComment={onChangeComment}
                    onChangePassword={onChangePassword}
                    confirmPassword={confirmPassword} onChangeConfirmPassword={onChangeConfirmPassword}
                    passwordShown={passwordShown} togglePasswordVisiblity={togglePasswordVisiblity}
                    confirmPasswordShown={confirmPasswordShown} toggleConfirmPasswordVisiblity={toggleConfirmPasswordVisiblity}
                    accountId={accountId} clearForm={clearForm}
                    onHideAddNewTeacherModal={onHideAddNewTeacherModal} isShowAddNewTeacherModal={isShowAddNewTeacherModal}
                    codeTeacher={codeTeacher}
                    alertMessage={"This teacher account will be blocked after " + dayLeft + " days"} insertTeacher={insertTeacher}
                    isShowAddAlertModal={isShowAddAlertModal}
                    onHideAddAlert={onHideAddAlert}
                    totalTeacher={totalTeacher} ShowAddAlert={ShowAddAlert}
                    isShowAddErrorMessageModal={isShowAddErrorMessageModal}
                    HideAddErrorMessageModal={HideAddErrorMessageModal}
                    errorAddMessage={errorAddMessage}

                    showPopupAddToClass={showPopupAddToClass} setShowPopupAddToClass={setShowPopupAddToClass} hidePopupAddUserToClass={hidePopupAddUserToClass}
                    showButtonAddToClass={showButtonAddToClass} setListClass={setListClass}
                    getAllClass={getAllClass} listClass={listClass} selectedClass={selectedClass}
                    chooseClass={chooseClass} setShowButtonAddToClass={setShowButtonAddToClass}
                    setSelectedClass={setSelectedClass}
                    {...props}
                />}
            {action === "Edit" && type === Const.Role.STUDENT && <EditStudent firstName={firstName} onChangeFirstName={onChangeFirstName}
                lastName={lastName} onChangeLastName={onChangeLastName}
                gender={gender} onChangeGender={onChangeGender}
                available={available} onChangeAvailable={onChangeAvailable}
                phone={phone} onChangePhone={onChangePhone}
                additionalComment={additionalComment} onChangeComment={onChangeComment}
                parentEmail={parentEmail} onChangeParentEmail={onChangeParentEmail}
                parentName={parentName} onChangeParentName={onChangeParentName}
                parentPhone={parentPhone} onChangeParentPhone={onChangeParentPhone}
                password={password} onChangePassword={onChangePassword}
                confirmPassword={confirmPassword} onChangeConfirmPassword={onChangeConfirmPassword}
                accountId={accountId} onSubmit={editStudent}
                passwordShown={passwordShown} togglePasswordVisiblity={togglePasswordVisiblity}
                setShowDeleteModal={setShowDeleteModal}
                confirmPasswordShown={confirmPasswordShown} toggleConfirmPasswordVisiblity={toggleConfirmPasswordVisiblity}
                selectedUser={selectedUser} isShowAddErrorMessageModal={isShowAddErrorMessageModal}
                HideAddErrorMessageModal={HideAddErrorMessageModal}
                errorAddMessage={errorAddMessage} showPopupAddToClass={showPopupAddToClass}
                setShowPopupAddToClass={setShowPopupAddToClass} showButtonAddToClass={showButtonAddToClass} hidePopupAddUserToClass={hidePopupAddUserToClass}
                getAllClass={getAllClass} listClass={listClass}
                setListClass={setListClass}
                chooseClass={chooseClass} selectedClass={selectedClass}
                setShowButtonAddToClass={setShowButtonAddToClass}
                setSelectedClass={setSelectedClass}
                grade={grade} onChangeGrade={onChangeGrade}
                {...props} />}

            {action === "Edit" && type === Const.Role.TEACHER && <EditTeacher firstName={firstName} onChangeFirstName={onChangeFirstName}
                lastName={lastName} onChangeLastName={onChangeLastName}
                gender={gender} onChangeGender={onChangeGender}
                available={available} onChangeAvailable={onChangeAvailable}
                phone={phone} onChangePhone={onChangePhone}
                email={email} onChangeEmail={onChangeEmail}
                additionalComment={additionalComment} onChangeComment={onChangeComment}
                password={password} onChangePassword={onChangePassword}
                confirmPassword={confirmPassword} onChangeConfirmPassword={onChangeConfirmPassword}
                accountId={accountId} onSubmit={editTeacher}
                passwordShown={passwordShown} togglePasswordVisiblity={togglePasswordVisiblity}
                confirmPasswordShown={confirmPasswordShown} toggleConfirmPasswordVisiblity={toggleConfirmPasswordVisiblity}
                deleteTeacher={deleteUser}
                setShowDeleteModal={setShowDeleteModal}
                isShowDeleteModal={isShowDeleteModal}
                onHideDeleteModal={onHideDeleteModal}
                deleteUser={deleteUser} showDeleteModal={showDeleteModal}
                selectedUser={selectedUser} isShowAddErrorMessageModal={isShowAddErrorMessageModal}
                HideAddErrorMessageModal={HideAddErrorMessageModal}
                errorAddMessage={errorAddMessage} showPopupAddToClass={showPopupAddToClass}
                setShowPopupAddToClass={setShowPopupAddToClass} showButtonAddToClass={showButtonAddToClass} hidePopupAddUserToClass={hidePopupAddUserToClass}
                getAllClass={getAllClass} listClass={listClass}
                setListClass={setListClass}
                chooseClass={chooseClass}
                selectedClass={selectedClass} setShowButtonAddToClass={setShowButtonAddToClass}
                setSelectedClass={setSelectedClass}
                {...props} />}
        </div>
    )
}

export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted #FFF',
        color: '#FFF',
        backgroundColor: '#858585',
    }),
    input: (provided, state) => ({
        ...provided,
        color: '#FFF',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#808080',
    }),
    container: (provided, state) => ({
        ...provided,
        padding: 0,
        borderWidth: 0,
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderWidth: 0,
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#858585',
        color: '#FFF'
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        width: 0,
    }),
}


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="fa fa-search text-white"></i>
        </components.DropdownIndicator>
    );
};

function removeOverlay() {
    document.getElementById('shadow-overlay').style.display = 'none';
}
function thisSelect(e) {
    document.getElementById('shadow-overlay').style.display = 'none';
    for( var i=0; i<document.querySelectorAll('.focus').length; i++) {
        document.querySelectorAll('.focus')[i].classList.replace('bg-white','btn-black');
        document.querySelectorAll('.focus')[i].classList.replace('text-dark','text-white-50');
    }
    document.querySelectorAll('.focus')[e].classList.replace('btn-black','bg-white');
    document.querySelectorAll('.focus')[e].classList.replace('text-white-50','text-dark');
};

function thisUnselect(){
    document.getElementById('shadow-overlay').style.display = 'block';
}