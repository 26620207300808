export const clientType = [
    { Type: "All", Value: "" },
    { Type: "School", Value: "School" },
    { Type: "Business", Value: "Business Tutor" },
    { Type: "Individual", Value: "Individual Tutor" },
    { Type: "Parent", Value: "Parents" },
    { Type: "Free", Value: "Free" },
]

export const Role = {
    VENDOR: "VENDOR",
    OPERATOR: "OPERATOR",
    TEACHER: "TEACHER",
    STUDENT: "STUDENT"
}

export const AccountMethod = {
    RESTRICTED: 'Restricted',
    TRIALS: 'Trials',
    PAID: 'Paid',
}


export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$%+\\\/'!#$^?:.(){}[\]~_`-]{8,}$/
export const classNameRegExpVn = "^[a-zA-Z0-9&._ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼẾỀỂưăạảấầẩẫậắằẳẵặẹẻẽếềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\\s]+$";
export const nameRegExpVn = "^[a-zA-Z0-9._ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼẾỀỂưăạảấầẩẫậắằẳẵặẹẻẽềếểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\\s]+$";
export const whiteSpaceReg = /^(.*)?\S+(.*)?$/

export const WarningStudentToLimit = 2

export const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted #FFF',
        color: '#FFF',
        backgroundColor: '#858585',
    }),
    input: (provided, state) => ({
        ...provided,
        color: '#FFF',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#fff',
    }),
    container: (provided, state) => ({
        ...provided,
        padding: 0,
        borderWidth: 0,
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderWidth: 0,
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#858585',
        color: '#FFF',
        zIndex: 10
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#808080'
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        width: 0,
    }),
}

/**
 * Dynamic Banner percent of image width
 */

export const DynamicBanner = {
    WIDTH: 64.96,
    HEIGHT: 19.27,
    TOP: 14.17,
    LEFT: 29.53
}

/**
 * Static Banner percent of image width
 */
export const StaticBanner = {
    WIDTH: 89.18,
    HEIGHT: 28.03,
    TOP: 5.41,
    LEFT: 5.41
}

export const FILE_SIZE = 1024 * 1024;
export const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    'application/pdf',
    "image/png"
];

