import React, { useRef } from 'react';
import { Link, NavLink, useHistory } from "react-router-dom";
import * as Const from "../../constants/Const";
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import * as Type from "../../constants/actionType"
import classNames from 'classnames'
import './style.css';

function HeaderDesktop(props) {
    const url = useRouteMatch()
    const history = useHistory()
    let dispatch = useDispatch()
    let { operatorInfo } = props

    const preHeaderEditActive = useRef()
    const headerEditActiveRx = useSelector(state => state.ws.headerEditActive)
    preHeaderEditActive.current = headerEditActiveRx

    const pathName = history.location.pathname
    const urlBack = pathName.split('/Edit/')[0]

    const userName =    !(operatorInfo && operatorInfo.BusinessName || operatorInfo && operatorInfo.FullName)
                        ?   '' 
                        :   (operatorInfo && operatorInfo.BusinessName || operatorInfo && operatorInfo.FullName)
    
    const isNote = pathName.includes('/Edit/Notes')
    const isDocument = pathName.includes('/Edit/Document')

    const mainTitle = isDocument ? "Document" : "Exercise"
    const noteTitle = headerEditActiveRx === "CreateNote" ? "Note" : "Note";

    if(isNote)  
    dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: 'CreateNote' })

    function goBack() {
        dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Exercise' })
        dispatch({ type: Type.SAVE_PRE_HEADER_EDIT_ACTIVE, payload: preHeaderEditActive.current })
        dispatch({ type: Type.SAVE_PAGEINDEX_WS, payload: null })
        dispatch({ type: Type.SAVE_PAGEINDEX_NOTE, payload: 0 })
        history.replace(urlBack)
    }

    function onClickSolution() {
        dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Solution' })
        dispatch({ type: Type.SAVE_PRE_HEADER_EDIT_ACTIVE, payload: preHeaderEditActive.current })

    }

    function onClickExercise() {
        dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Exercise' })
        dispatch({ type: Type.SAVE_PRE_HEADER_EDIT_ACTIVE, payload: preHeaderEditActive.current })
    }

    function onClickCreateNote() {
        dispatch({ type: Type.SAVE_HEADER_EDIT_ACTIVE, payload: 'CreateNote' })
        dispatch({ type: Type.SAVE_PRE_HEADER_EDIT_ACTIVE, payload: preHeaderEditActive.current })
    }


    return (
        <section   className="container-fluid container-header-back-desktop header-shadow"  style={{top: 0, zIndex: 1000}}  >
            <div className="row align-items-center wrap-header-back-desktop" style={{backgroundColor: 'rgba(8, 34, 45, 1)'}}>
                <div className="col-4 mr-auto ml-4 text-left">
                    <h4 className="font-Paytone py-2 pl-3 mb-0 three-dot-100 font18">
                        {   userName ? userName : "UserID/Email"    }
                    </h4>
                </div>
                {
                    !isNote &&
                    <>
                        {
                            !isDocument &&
                            <div 
                                className={classNames("col-auto", "p-0", "pointer", [{"active-header-desktop": headerEditActiveRx === 'CreateNote'}])} 
                                onClick={onClickCreateNote}
                            >
                                <span className="font-Paytone bt-back" >{noteTitle}</span>
                            </div>
                        }

                        {
                            !isDocument &&
                            <div 
                                className={classNames("col-auto", "p-0", "pointer", [{"active-header-desktop": headerEditActiveRx === 'Exercise'}])} 
                                onClick={onClickExercise}
                            >
                                <span className="font-Paytone bt-back" >{mainTitle}</span>
                            </div>
                        }
                        

                        {
                            !isDocument &&
                            <div 
                                className={classNames("col-auto", "p-0", "pointer",  [{"active-header-desktop": headerEditActiveRx === 'Solution'}])} 
                                onClick={onClickSolution}
                            >
                                <span className="font-Paytone bt-back" >Solution</span>
                            </div>
                        }
                    </>
                }
                <div className="col-auto pr-0 pointer " onClick={goBack}>
                    <div className="triangle-left float-left"></div>
                    <span className="bg-trang-10 font-Paytone bt-back" >Back</span>
                </div>
            </div>
        </section>
    );
}

export default HeaderDesktop;