import { Formik } from "formik"
import React from 'react'
import { Modal } from "react-bootstrap"
import * as Yup from "yup"
import { phoneRegExp, nameRegExpVn } from '../../../constants/Const'
import * as Fc from "../../../utils/Functions"
import ModalAlertOperator from '../../../components/ModalAlertOperator'
import PopupAddToClass from "./popupAddToClass/popupAddToClass"

import useCheckdevice from '../../../hooks/useCheckdevice'
import classNames from 'classnames'
import { isDesktop, isMobile } from "react-device-detect"

export default function CreateTeacher(props) {
    const checkDevice = useCheckdevice()
	const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))
    const role = Fc.getRole()

    const {
        onChangeFirstName, onChangeLastName,
        onChangeEmail, onChangePhone, onChangeGender,
        onChangeComment, onChangePassword, onChangeConfirmPassword,
        clearForm, firstName, lastName, email, phone, gender,
        additionalComment, password, confirmPassword,
        insertTeacher, passwordShown, confirmPasswordShown,
        togglePasswordVisiblity, toggleConfirmPasswordVisiblity, onHideAddNewTeacherModal,
        isShowAddNewTeacherModal, codeTeacher, alertMessage, isShowAddAlertModal,
        onHideAddAlert, totalTeacher, ShowAddAlert, isShowAddErrorMessageModal,
        HideAddErrorMessageModal, errorAddMessage,

        showPopupAddToClass, setShowPopupAddToClass, showButtonAddToClass,
        setListClass, getAllClass, listClass, selectedClass, chooseClass,
        setShowButtonAddToClass, setSelectedClass, hidePopupAddUserToClass
    } = props;

    const AccountMethod = Fc.getAccountMethod()

    const initValue = {
        firstName: firstName, lastName: lastName, email: email,
        phone: phone, password: password, confirmPassword: confirmPassword,
    }

    const validateForm = Yup.object().shape({
        firstName: Yup.string().trim().matches(nameRegExpVn, 'Please enter valid Firstname').required("Required"),
        lastName: Yup.string().trim().matches(nameRegExpVn, 'Please enter valid Lastname').required("Required"),
        phone: Yup.string().matches(phoneRegExp, 'Invalid phone number format').required("Required"),
        password: Yup
            .string().trim()
            .required("Required"),
        confirmPassword: Yup
            .string().trim()
            .required("Required")
            .test('passwords-match', 'Confirm password is not match', function (value) {
                return this.parent.password === value;
            }),
        email: Yup.string().email("Invalid email format").required("Required"),
    })

    return (
        <Formik
            initialValues={initValue}
            onSubmit={insertTeacher}
            validationSchema={validateForm}
        >
            {(properties) => {
                const {
                    touched,
                    errors,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                } = properties
                return (
                    <>
                        <form onSubmit={handleSubmit}>
                            <section className={classNames("container-fluid", [{"container-fluid-desktop": isBrowserDesktop}])}>
                                <div className="p-4">
                                    <h4 className="font-Paytone text-center mb-3">Teacher Information</h4>
                                    <section className="form-row">
                                        <div className="col-6 mb-2">
                                            <input type="text" className="form-control" placeholder="First Name*" name="firstName" value={firstName}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    onChangeFirstName(e)
                                                }}
                                            />
                                            {errors.firstName && touched.firstName && (
                                                <div className="validate-input mt-1">* {errors.firstName}</div>
                                            )}
                                        </div>
                                        <div className="col-6 mb-2">
                                            <input type="text" className="form-control" placeholder="Last Name*" name="lastName" value={lastName}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    onChangeLastName(e)
                                                }}
                                            />
                                            {errors.lastName && touched.lastName && (
                                                <div className="validate-input mt-1">* {errors.lastName}</div>
                                            )}
                                        </div>
                                        <div className="col-6 mb-2">
                                            <select className="custom-select" value={gender} onChange={onChangeGender}>
                                                <option value={"Male"}>Male</option>
                                                <option value={"Female"}>Female</option>
                                            </select>
                                        </div>
                                    </section>
                                </div>
                            </section>
                            <section className="box-xam mb-3">
                                <section className={classNames("form-row", "align-items-center", [{"form-row-desktop": isBrowserDesktop}])}>
                                    <div className="col-6">
                                        <div className="input-group mb-2">
                                            <input type={passwordShown ? "text" : "password"} className="form-control border-0" placeholder="Password*" name="password" value={password} autoComplete="new-password"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    onChangePassword(e)
                                                }}
                                            />
                                            <div className="input-group-append ml-0 border-0">
                                                <span className="input-group-text input-group-text1 show-hide-password" onClick={togglePasswordVisiblity} >{passwordShown ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</span>
                                            </div>
                                        </div>
                                        {errors.password && touched.password && (
                                            <div className="validate-input mt-0 mb-2">* {errors.password}</div>
                                        )}
                                        <div className="input-group">
                                            <input type={confirmPasswordShown ? "text" : "password"} className="form-control border-0" placeholder="Confirm Password*" name="confirmPassword" value={confirmPassword} autoComplete="new-password"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    onChangeConfirmPassword(e)
                                                }}
                                            />
                                            <div className="input-group-append ml-0 border-0">
                                                <span className="input-group-text input-group-text1 show-hide-password" onClick={toggleConfirmPasswordVisiblity} >{confirmPasswordShown ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</span>
                                            </div>
                                        </div>
                                        {errors.confirmPassword && touched.confirmPassword && (
                                            <div className="validate-input mt-1">* {errors.confirmPassword}</div>
                                        )}
                                    </div>
                                    <div className="col-6 text-center">
                                        {showButtonAddToClass &&
                                            <button
                                                type="button"
                                                className="btn radius10 pl-3 pr-3 bg-white"
                                                onClick={() => {
                                                    setShowPopupAddToClass(true)
                                                }}
                                            >
                                                <i className="fas fa-plus"></i> Add To Class
                                            </button>
                                        }

                                        {(selectedClass && !showButtonAddToClass) && <div className="alert alert-secondary alert-dismissible fade show radius-10 pt-1 pb-1 mb-0 ml-4 mr-4">
                                            <button
                                                type="button"
                                                className="ix close pt-1 pb-1"
                                                onClick={() => {
                                                    setSelectedClass('')
                                                    setShowButtonAddToClass(true)
                                                }}
                                            >&times;</button>
                                            <div className={"three-dot-120"}>
                                                {selectedClass.Code}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </section>
                            </section>
                            <section className={classNames("container-fluid", [{"container-fluid-desktop": isBrowserDesktop}])}>
                                <div className="p-4">
                                    <textarea className="form-control mb-4" rows="5" id="comment" placeholder="Additional Comment" value={additionalComment} onChange={onChangeComment}></textarea>
                                    <h4 className="font-Paytone text-center mb-3">Additional Information</h4>
                                    {
                                        !isBrowserDesktop
                                        //====== IPAD ======
                                        ?   <section className="form-row align-items-center">
                                                <div className="col-7">
                                                    <input 
                                                        type="text" className={errors.email && touched.email ? "form-control" : "form-control mb-2"} 
                                                        placeholder="Email Address*" name="email" value={email}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangeEmail(e)
                                                        }}
                                                    />
                                                    {errors.email && touched.email && (
                                                        <div className="validate-input mb-2">* {errors.email}</div>
                                                    )}
                                                    <input type="text" className="form-control" placeholder="Phone Number*" name="phone" value={phone}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangePhone(e)
                                                        }}
                                                    />
                                                    {errors.phone && touched.phone && (
                                                        <div className="validate-input mt-1">* {errors.phone}</div>
                                                    )}
                                                </div>
                                                <div className="col-3 mb-2 offset-1">
                                                    <div className="row">
                                                        <div className="col-12"><button type="submit" className="btn btn-light font-Paytone btn-block mb-2">Create</button></div>
                                                        <div className="col-12"><button type="button" className="btn btn-light font-Paytone float-right btn-block" onClick={clearForm} >Clear Form</button></div>
                                                    </div>
                                                </div>
                                            </section>
                                        //====== DESKTOP =======
                                        :   <section className="form-row align-items-center">
                                                <div className="col-6">
                                                    <input type="text" className={errors.email && touched.email ? "form-control" : "form-control mb-2"} placeholder="Email Address*" name="email" value={email}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangeEmail(e)
                                                        }}
                                                    />
                                                    {errors.email && touched.email && (
                                                        <div className="validate-input mb-2">* {errors.email}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <input 
                                                        type="text" className={errors.phone && touched.phone ? "form-control" : "form-control mb-2"}
                                                        placeholder="Phone Number*" name="phone" value={phone}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangePhone(e)
                                                        }}
                                                    />
                                                    {errors.phone && touched.phone && (
                                                        <div className="validate-input mt-1">* {errors.phone}</div>
                                                    )}
                                                </div>

                                                <div className="col-3 mt-3"><button type="submit" className="btn btn-light font-Paytone btn-block mb-2">Create User</button></div>
                                                <div className="col-3 offset-6 mt-3">
                                                    <button type="button" className="btn btn-light font-Paytone float-right btn-block" onClick={clearForm} >Clear Form</button>
                                                </div>
                                            </section>
                                    }
                                    
                                </div>
                            </section>
                        </form>
                        <Modal
                            show={isShowAddNewTeacherModal}
                            onHide={onHideAddNewTeacherModal}
                            className="modal fade"
                            id="myModal"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content bg-epen pte-auto">
                                    <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                                        <h4 className="modal-title font-Paytone">
                                            Account {codeTeacher} Created!
              							</h4>
                                    </div>
                                    <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                                        <div className="row">
                                            <div className="col-5 m-auto">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        onHideAddNewTeacherModal()
                                                        if (totalTeacher >= 2) {
                                                            ShowAddAlert()
                                                        }
                                                    }}
                                                    className="btn btn-light font-Paytone float-right btn-block"
                                                >
                                                    <span className="text-danger">OK</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        {/* Modal Create Teacher alert */}
                        {<ModalAlertOperator
                            showModal={isShowAddAlertModal}
                            alertMessage={alertMessage}
                            role={role}
                            AccountMethod={AccountMethod}
                            keyStorage={'PopupBlockTeacherAfterDay'}
                            functionClose={onHideAddAlert}
                            functionUpgrade={null}
                        />}

                        <ModalAlertOperator
                            showModal={isShowAddErrorMessageModal}
                            alertMessage={errorAddMessage}
                            role={role}
                            AccountMethod={AccountMethod}
                            functionClose={HideAddErrorMessageModal}
                            functionUpgrade={null}
                        />

                        {(showPopupAddToClass) && <PopupAddToClass
                            showPopupAddToClass={showPopupAddToClass}
                            setShowPopupAddToClass={setShowPopupAddToClass}
                            hidePopupAddUserToClass={hidePopupAddUserToClass}
                            setListClass={setListClass}
                            getAllClass={getAllClass}
                            listClass={listClass}
                            selectedClass={selectedClass}
                            chooseClass={chooseClass}
                            setShowButtonAddToClass={setShowButtonAddToClass}
                        />}
                    </>
                )
            }}
        </Formik>
    )
}