import { fork, take, put } from 'redux-saga/effects';
import * as type from './../constants/actionType';
import io from 'socket.io-client'
import * as UrlConst from './../constants/UrlConst'

let socket

function* watchSelectWsAction() {
    while (true) {
        yield take(type.INITIALIZE);

        // Connect Nodejs Server Chat
        socket = io(UrlConst.CONNECTION_PORT);
        yield put({ type: type.SOCKET_STORE, payload: socket })
    }
}

function* rootSaga() {
    yield fork(watchSelectWsAction);
}

export default rootSaga;