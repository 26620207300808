import React, { useState, useEffect } from 'react'
import * as Fc from '../../../../utils/Functions'
import * as Const from '../../../../constants/Const'
import API from '../../../../utils/Api'
import { useRouteMatch } from 'react-router-dom'

import UploadView from './UploadView'

export default function Upload(props) {
    const operatorId = Fc.getRole().includes(Const.Role.VENDOR) ? props.match.params.id : Fc.getOperator()

    const matchUrl = useRouteMatch()
    const [role, setRole] = useState("Operator")
    const [userId, setUserId] = useState(operatorId)
    const [keyword, setKeyword] = useState('')
    const [submitKeyword, setSubmitKeyword] = useState('')
    const [teacherOptions, setTeacherOptions] = useState([])
    const [teacher, setTeacher] = useState('')

    useEffect(() => {
        getListTeachers()
    }, [])
    useEffect(() => {
        if (!teacher || teacher === '') {
            setRole('Operator')
            setUserId(operatorId)
        } else {
            setRole('Teacher')
            setUserId(teacher._id)
        }
    }, [teacher])

    function getListTeachers(val) {
        if(val && val.length > 0 && val.match("^[/]")) return
        if(val && val.length > 0 && val.includes("\\")) return 

        API.listTeachers(operatorId, 1, 20, val).then(res => {
            if (res.status === 200 && res.data.Success) {
                res.data.Data.map(item => {
                    item.value = item._id
                    item.label = `${item.FirstName} ${item.LastName} - ${item.Code}`
                })
                setTeacherOptions(res.data.Data)
            } else {
                Fc.showError(res.data.Message || res.statusText)
            }
        })
    }

    function onSearch(e) {
        e && e.preventDefault()
        setSubmitKeyword(keyword)
    }

    return (
        <UploadView
            role={role} userId={userId}
            keyword={keyword} setKeyword={setKeyword}
            submitKeyword={submitKeyword}
            onSearch={onSearch}
            teacherOptions={teacherOptions}
            getListTeachers={getListTeachers}
            teacher={teacher} setTeacher={setTeacher}
            matchUrl={matchUrl}
            {...props} />
    )
}