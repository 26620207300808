export const SELECTWS = 'SELECTWS';
export const UNSELECTWS = 'UNSELECTWS';
export const CLEAR_LIST_WS = 'CLEAR_LIST_WS';
export const SAVE_CLASS_ID = 'SAVE_CLASS_ID';
export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const CLEAR_DATA = 'CLEAR_DATA';
export const SET_ACTIVE_TAB_MYWORK = 'SET_ACTIVE_TAB_MYWORK';
export const SET_SELECTED_FOLDER = 'SET_SELECTED_FOLDER';
export const SET_STUDENT_ID = 'SET_STUDENT_ID';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const SAVE_ROLE = 'SAVE_ROLE';
export const INITIALIZE = 'INITIALIZE';
export const SOCKET_STORE = 'SOCKET_STORE';
export const PERMISSION_SOLUTION = 'PERMISSION_SOLUTION';
export const PERMISSION_WRITENOTE = 'PERMISSION_WRITENOTE';
export const SAVE_LIST_ROOM = 'SAVE_LIST_ROOM';
export const SAVE_USER_LOGIN = 'SAVE_USER_LOGIN';
export const SAVE_NOTIFY_ARRAY = 'SAVE_NOTIFY_ARRAY';
export const SAVE_NOTIFY = 'SAVE_NOTIFY';
export const SAVE_NOTIFY_CONTENT = 'SAVE_NOTIFY_CONTENT';
export const COUNT_NOTIFY = 'COUNT_NOTIFY';
export const SAVE_PARAMS_SEARCH = 'SAVE_PARAMS_SEARCH';
export const SAVE_ASSIGNMENT_DATE= 'SAVE_ASSIGNMENT_DATE';
export const SAVE_HEADER_EDIT_ACTIVE= 'SAVE_HEADER_EDIT_ACTIVE';
export const SAVE_PRE_HEADER_EDIT_ACTIVE= 'SAVE_PRE_HEADER_EDIT_ACTIVE';
export const SAVE_CURRENT_CHAPTER= 'SAVE_CURRENT_CHAPTER';
export const SAVE_CURRENT_SESSION= 'SAVE_CURRENT_SESSION';
export const CHANGE_ORIENTATION= 'CHANGE_ORIENTATION';
export const CHANGE_NOT_RELOAD= 'CHANGE_NOT_RELOAD';
export const SAVE_STUDENT_CLASS_LIST= 'SAVE_STUDENT_CLASS_LIST';
export const SAVE_CURRENT_CHAT= 'SAVE_CURRENT_CHAT';
export const SAVE_IS_LOADING= 'SAVE_IS_LOADING';
export const SAVE_PAGEINDEX_WS= 'SAVE_PAGEINDEX_WS';
export const SAVE_PAGEINDEX_NOTE= 'SAVE_PAGEINDEX_NOTE';





