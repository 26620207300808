import React from 'react'
import { Link } from "react-router-dom"

function TableComponent(props) {

    const { level, Index, matchUrl } = props
    let ListBook = []
    let BookName = []
    level.DataOfBook.map((item, index) => {
        for (const Lb in item) {
            ListBook.push(
                item[Lb]
            )
        }
    })
    ListBook.map((item) => {
        for (const Bn in item) {
            BookName.push({
                Id: item[Bn].Id,
                Level: level.Level,
                Name: item[Bn].Name,
                Data: item[Bn]
            })
        }
    })


    return (
        <>
            {BookName && BookName.map((name, bindex) => {
                let workbookId = name.Id;
                console.log(name)
                return (
                    <table className="table bg-white mb-3" key={bindex.toString()}>
                        <tbody className="">
                            <tr>
                                <td className="border-top-0 border-right text-center text-black" rowSpan="2" width="80px">
                                    {name.Level}
                                </td>
                                <td className="bg-epen border-top-0">
                                    <h4 className="font-Paytone mb-0">{name.Name}</h4>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-0">
                                    <div id={`accordion${level.Level}${bindex}`} role="tablist">
                                        {name.Data.Chapters.map((item, index) => {
                                            return (
                                               
                                                <div className="card border-0 radius-0" key={index.toString()}>
                                                    <a className="text-black" data-toggle="collapse"
                                                    href={`#collapseOne-${Index}-${bindex}-${index.toString()}`} role="button" aria-expanded="false"
                                                    aria-controls={`collapseOne-${Index}-${bindex}-${index.toString()}`}>
                                                        <div className="bg-white" role="tab" id={`headingOne-${Index}-${bindex}-${index.toString()}`}
                                                            style={{ padding: "0.75rem" }}>
                                                                {item.Name}
                                                        </div>
                                                    </a>
                                                    <div id={`collapseOne-${Index}-${bindex}-${index.toString()}`} className="collapse" role="tabpanel"
                                                        aria-labelledby={`headingOne-$${Index}-${bindex}-${index.toString()}`} data-parent={`#accordion${level.Level}${bindex}`}>
                                                        <div className="card-body p-0">
                                                            <table className="table mb-0">
                                                                <tbody className="table-epen">
                                                                    {item.Lessons.map((item, index) => {
                                                                        return (
                                                                            <tr key={index.toString()}>
                                                                                <td className="border-bottom border-top-0 text-black text-center border-right bg-xamec" style={{ width: 100 }}>{item.Index}</td>
                                                                                <td className="border-bottom border-top-0">
                                                                                    <Link className="text-black" to={matchUrl.url.substring(0, matchUrl.url.length - 7) + `/Workbook/${workbookId}/Lession/${item.Id}`}>{item.Name}</Link>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )
            })}
        </>
    );
}

export default TableComponent;