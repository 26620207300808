import { Formik } from "formik"
import React from 'react'
import * as Yup from "yup"
import { phoneRegExp, nameRegExpVn } from '../../../constants/Const'
import * as Fc from "../../../utils/Functions"
import ModalAlertOperator from '../../../components/ModalAlertOperator'
import PopupAddToClass from "./popupAddToClass/popupAddToClass"

import useCheckdevice from '../../../hooks/useCheckdevice'
import classNames from 'classnames'
import { isDesktop, isMobile } from "react-device-detect"

export default function EditStudent(props) {
    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))
    const role = Fc.getRole()
    const AccountMethod = Fc.getAccountMethod()
    const {
        firstName, onChangeFirstName,
        lastName, onChangeLastName,
        gender, onChangeGender,
        available, onChangeAvailable,
        phone, onChangePhone,
        additionalComment, onChangeComment,
        parentEmail, onChangeParentEmail,
        parentName, onChangeParentName,
        parentPhone, onChangeParentPhone,
        password, onChangePassword,
        confirmPassword, onChangeConfirmPassword,
        onSubmit, accountId,
        passwordShown, togglePasswordVisiblity,
        confirmPasswordShown, toggleConfirmPasswordVisiblity,
        setShowDeleteModal, selectedUser, isShowAddErrorMessageModal,
        HideAddErrorMessageModal,
        errorAddMessage, showPopupAddToClass,
        setShowPopupAddToClass, showButtonAddToClass,
        getAllClass, listClass,
        setListClass, chooseClass, selectedClass,
        setShowButtonAddToClass, setSelectedClass,
        grade, onChangeGrade, hidePopupAddUserToClass
    } = props

    const validateForm = Yup.object().shape({
        firstName: Yup.string().trim().matches(nameRegExpVn, 'Please enter valid Firstname').required("Required"),
        lastName: Yup.string().trim().matches(nameRegExpVn, 'Please enter valid Lastname').required("Required"),
        phone: Yup.string().matches(phoneRegExp, 'Invalid phone number format'),
        parentName: Yup.string().trim().matches(nameRegExpVn, 'Please enter valid Parent name').required("Required"),
        parentEmail: Yup.string().email("Invalid Email").required("Required"),
        parentPhone: Yup.string().required("Required").matches(phoneRegExp, 'Invalid phone number format'),
        password: Yup
            .string().matches(/^(.*)?\S+(.*)?$/, 'Password is invalid'),  //not contain backspace
        confirmPassword: Yup
            .string()
            .test('passwords-match', 'Confirm password is not match', function (value) {
                return this.parent.password === value;
            }),
    })

    return (
        <Formik
            initialValues={{
                firstName: firstName, lastName: lastName,
                phone: phone, password: password, confirmPassword: confirmPassword,
                parentName: parentName, parentEmail: parentEmail, parentPhone: parentPhone
            }}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validateForm}
        >
            {(properties) => {
                const {
                    touched,
                    errors,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                } = properties

                return (
                    <>
                        <form onSubmit={handleSubmit}>
                            <input type="hidden" value="something" />
                            <section className={classNames("container-fluid", [{"container-fluid-desktop": isBrowserDesktop}])}>
                                <div className="p-4">
                                    <h4 className="font-Paytone text-center mb-3">{accountId ? 'Student Information' : "Manage User"}</h4>
                                    <section className="form-row">
                                        <div className="col-6 mb-2">
                                            <label htmlFor="usr" className="font-Paytone">First name*</label>
                                            <input type="text" className="form-control" value={firstName} name="firstName"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    onChangeFirstName(e)
                                                }}
                                            />
                                            {errors.firstName && touched.firstName && (
                                                <div className="validate-input mt-1">* {errors.firstName}</div>
                                            )}
                                        </div>
                                        <div className="col-6 mb-2">
                                            <label htmlFor="usr" className="font-Paytone">Last name*</label>
                                            <input type="text" className="form-control" value={lastName} name="lastName"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    onChangeLastName(e)
                                                }}
                                            />
                                            {errors.lastName && touched.lastName && (
                                                <div className="validate-input mt-1">* {errors.lastName}</div>
                                            )}
                                        </div>
                                        <div className="col-6 mb-2 d-flex">
                                            <div className="col-6 pl-0 m-auto">
                                                <label htmlFor="usr" className="font-Paytone">Gender*</label>
                                                <select className="custom-select" value={gender} onChange={onChangeGender}>
                                                    <option value={"Male"}>Male</option>
                                                    <option value={"Female"}>Female</option>
                                                </select>
                                            </div>

                                            <div className="col-6 m-auto">
                                                <label htmlFor="usr" className="font-Paytone">Grade*</label>
                                                <select className="custom-select" value={grade} onChange={onChangeGrade}>
                                                    <option value={1}>1</option>
                                                    {
                                                        [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((item, index) => {
                                                            return (
                                                                <option key={index.toString()} value={item}>{item}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <label htmlFor="usr" className="font-Paytone">Phone number</label>
                                            <input type="text" className="form-control" value={phone} name="phone"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    onChangePhone(e)
                                                }}
                                            />
                                            {errors.phone && touched.phone && (
                                                <div className="validate-input mt-1">* {errors.phone}</div>
                                            )}
                                        </div>
                                    </section>
                                </div>
                            </section>
                            <section className="box-xam mb-3">
                                <section className={classNames("form-row", "align-items-center", [{"form-row-desktop": isBrowserDesktop}])} style={{marginBottom: "0.4rem"}}>
                                    <div className="col-6">
                                        <label htmlFor="usr" className="font-Paytone">New Password</label>
                                        <div className="input-group mb-2">
                                            <input type={passwordShown ? "text" : "password"} className="form-control border-0" value={password} name="password" autoComplete="new-password"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    onChangePassword(e)
                                                }}
                                            />
                                            <div className="input-group-append ml-0 border-0">
                                                <span className="input-group-text input-group-text1 show-hide-password" onClick={togglePasswordVisiblity} >{passwordShown ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</span>
                                            </div>
                                        </div>
                                        {errors.password && touched.password && (
                                            <div className="validate-input mb-2">* {errors.password}</div>
                                        )}
                                        <label htmlFor="usr" className="font-Paytone">Confirm New Password</label>
                                        <div className="input-group">
                                            <input type={confirmPasswordShown ? "text" : "password"} className="form-control border-0" value={confirmPassword} name="confirmPassword" autoComplete="new-password"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    onChangeConfirmPassword(e)
                                                }}
                                            />
                                            <div className="input-group-append ml-0 border-0">
                                                <span className="input-group-text input-group-text1 show-hide-password" onClick={toggleConfirmPasswordVisiblity} >{confirmPasswordShown ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</span>
                                            </div>
                                        </div>
                                        {errors.confirmPassword && touched.confirmPassword && (
                                            <div className="validate-input mt-1">* {errors.confirmPassword}</div>
                                        )}
                                    </div>
                                    <div className="col-6 text-center">
                                        <h5 className="font-Paytone mb-1 three-dot-100">{accountId ? accountId : 'UserId'}</h5>
                                        {accountId ? 'Student' : "Role"}
                                        {showButtonAddToClass &&
                                            <div className="col mt-2 mr-auto ml-auto">
                                                <button
                                                    type="button"
                                                    className="btn radius10 pl-3 pr-3 bg-white"
                                                    onClick={() => {
                                                        setShowPopupAddToClass(true)
                                                    }}
                                                >
                                                    <i className="fas fa-plus"></i> Add To Class
                                            </button>
                                            </div>
                                        }

                                        {(selectedClass && !showButtonAddToClass) && <div className="alert alert-secondary alert-dismissible fade show radius-10 pt-1 pb-1 mb-0 ml-4 mr-4">
                                            <button
                                                type="button"
                                                className="ix close pt-1 pb-1"
                                                onClick={() => {
                                                    setSelectedClass('')
                                                    setShowButtonAddToClass(true)
                                                }}
                                            >&times;</button>
                                            <div className="three-dot-120">
                                                {selectedClass.Code}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </section>
                            </section>
                            <section className={classNames("container-fluid", [{"container-fluid-desktop": isBrowserDesktop}])}>
                                <div className="p-4">
                                    <label htmlFor="usr" className="font-Paytone">Comments:</label>
                                    <textarea className="form-control mb-4" rows="5" value={additionalComment} onChange={onChangeComment} >{additionalComment || ""}</textarea>
                                    <h4 className="font-Paytone text-center mb-3">{accountId ? 'Parents/Guardian Information' : "Additional Information"}</h4>

                                    {   
                                        !isBrowserDesktop
                                        // ========= Edit Student Ipad =========
                                        ?   <section className="form-row align-items-center">
                                                <div className="col-7 mr-auto">
                                                    <label htmlFor="usr" className="font-Paytone">Name*</label>
                                                    <input type="text" className="form-control mb-2" value={parentName} name="parentName"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangeParentName(e)
                                                        }}
                                                    />
                                                    {errors.parentName && touched.parentName && (
                                                        <div className="validate-input mb-1">* {errors.parentName}</div>
                                                    )}
                                                    <label htmlFor="usr" className="font-Paytone">Email Address*</label>
                                                    <input type="text" className="form-control mb-2" value={parentEmail} name="parentEmail"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangeParentEmail(e)
                                                        }}
                                                    />
                                                    {errors.parentEmail && touched.parentEmail && (
                                                        <div className="validate-input mb-1">* {errors.parentEmail}</div>
                                                    )}
                                                    <label htmlFor="usr" className="font-Paytone">Phone Number*</label>
                                                    <input type="text" className="form-control" value={parentPhone} name="parentPhone"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangeParentPhone(e)
                                                        }}
                                                    />
                                                    {errors.parentPhone && touched.parentPhone && (
                                                        <div className="validate-input mb-1">* {errors.parentPhone}</div>
                                                    )}
                                                </div>
                                                <div className="col-4 mb-2">
                                                    <div className="row">
                                                        <div className="col-12 mb-3">
                                                            <label className="font-Paytone font18 mr-3" htmlFor="isEnable">Account enable</label>
                                                            <span className="switch">
                                                                <input type="checkbox" className="switch" id="isEnable" checked={available}
                                                                    onChange={onChangeAvailable} />
                                                                <label htmlFor="isEnable"></label>
                                                            </span>
                                                        </div>
                                                        <div className="col-12"><button type="submit" className="btn btn-light font-Paytone btn-block mb-3">Save</button></div>
                                                        <div className="col-12"><button type="button" className="btn btn-light font-Paytone float-right btn-block text-danger" onClick={() => {
                                                            if (selectedUser) {
                                                                setShowDeleteModal(true)
                                                            }
                                                        }}>Delete Account</button></div>
                                                    </div>
                                                </div>
                                            </section>
                                        // ========= Edit Student Desktop =========
                                        :   <section className="form-row align-items-center">
                                                <div className="col-6">
                                                    <label htmlFor="usr" className="font-Paytone">Name*</label>
                                                    <input type="text" className="form-control mb-2" value={parentName} name="parentName"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangeParentName(e)
                                                        }}
                                                    />
                                                    {errors.parentName && touched.parentName && (
                                                        <div className="validate-input mb-1">* {errors.parentName}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="usr" className="font-Paytone">Email Address*</label>
                                                    <input type="text" className="form-control mb-2" value={parentEmail} name="parentEmail"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangeParentEmail(e)
                                                        }}
                                                    />
                                                    {errors.parentEmail && touched.parentEmail && (
                                                        <div className="validate-input mb-1">* {errors.parentEmail}</div>
                                                    )}
                                                </div>

                                                <div className="col-6">
                                                    <label htmlFor="usr" className="font-Paytone">Phone Number*</label>
                                                    <input type="text" className="form-control" value={parentPhone} name="parentPhone"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            onChangeParentPhone(e)
                                                        }}
                                                    />
                                                    {errors.parentPhone && touched.parentPhone && (
                                                        <div className="validate-input mb-1">* {errors.parentPhone}</div>
                                                    )}
                                                </div>
                                                <div className="col-6 row" style={{paddingLeft: 12, marginTop: 30, flexDirection: 'row', justifyContent: 'space-between'}}>
                                                    <div className="col-10 ml-auto">
                                                        <label className="font-Paytone font18 mr-3" htmlFor="isEnable">Account enable</label>
                                                        <span className="switch">
                                                            <input type="checkbox" className="switch" id="isEnable" checked={available}
                                                                onChange={onChangeAvailable} />
                                                            <label htmlFor="isEnable"></label>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col-4 mt-3"><button type="submit" className="btn btn-light font-Paytone btn-block mb-3">Save</button></div>
                                                <div className="col-4 offset-4 mt-3">
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-light font-Paytone float-right btn-block text-danger" 
                                                        onClick={() => {
                                                            if (selectedUser) {
                                                                setShowDeleteModal(true)
                                                            }
                                                        }}
                                                    >Delete Account
                                                    </button>
                                                </div>
                                                
                                                {/*
                                                <div className="col-4 mb-2">
                                                    
                                                </div> */}
                                            </section>
                                    }
                                    

                                </div>
                            </section>
                        </form>
                        <ModalAlertOperator
                            showModal={isShowAddErrorMessageModal}
                            alertMessage={errorAddMessage}
                            role={role}
                            AccountMethod={AccountMethod}
                            functionClose={HideAddErrorMessageModal}
                            functionUpgrade={null}
                        />
                        {(showPopupAddToClass) && <PopupAddToClass
                            showPopupAddToClass={showPopupAddToClass}
                            setShowPopupAddToClass={setShowPopupAddToClass}
                            hidePopupAddUserToClass={hidePopupAddUserToClass}
                            getAllClass={getAllClass}
                            listClass={listClass}
                            setListClass={setListClass}
                            chooseClass={chooseClass}
                            selectedClass={selectedClass}
                            setShowButtonAddToClass={setShowButtonAddToClass}
                        />}
                    </>
                )
            }}
        </Formik>
    )
}