/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import * as Const from '../../../constants/Const'
import API from '../../../utils/Api'
import * as Fc from '../../../utils/Functions'
import { useDispatch, useSelector } from 'react-redux'
import * as type from "../../../constants/actionType"
import { orderBy } from 'lodash'

import MyWorkLayout from './MyWork'
import useIsMounted from '../../../hooks/useIsMounted'

function MyWork(props) {
    const dispatch = useDispatch();
    const role = Fc.getRole()
    const operatorId = role === Const.Role.VENDOR ? props.match.params.id : Fc.getOperator()
    const [userInfo, setUserInfo] = useState({})
    const [listClass, setListClass] = useState([])
    const [keyword, setKeyword] = useState('')
    const [studentId, setStudentId] = useState(Fc.getRole() === Const.Role.STUDENT ? Fc.getUserId() : props.match.params.studentId)
    const [classId, setClassId] = useState(Fc.getRole() === Const.Role.STUDENT ? "" : props.match.params.classId)
    const [studentList, setStudentList] = useState([])

    let isNotReloadRx = useSelector(state => state.ws.isNotReload)
    let studentClassListRx = useSelector(state => state.ws.studentClassList)
    let classIdRx = useSelector(state => state.ws.classId)
    let userLoginRx = useSelector(state => state.ws.userLogin)

    /**
     * upload document
     */
    const matchUrl = useRouteMatch()
    let isMounted = useIsMounted()

    useEffect(() => {
        getUserInfo()
        CheckPermissionNote()
        if (Fc.getRole() != Const.Role.STUDENT && !isNotReloadRx) dispatch({ type: type.SET_SELECTED_FOLDER, selectedFolder: {} })
        return () => {}
    }, [])

    useEffect(() => {
        dispatch({ type: type.SET_STUDENT_ID, studentId })
    }, [studentId])

    useEffect(() => {
        if (classIdRx) {
            getStudentList()
        }

        if(Fc.getRole() === Const.Role.STUDENT) return
        dispatch({ type: type.SAVE_CLASS_ID, classId: classId })
    }, [classIdRx, classId])

    function CheckPermissionNote() {
        if(!isMounted.current) return
        let OperatorId
        if (Fc.getRole() === Const.Role.VENDOR) {
            OperatorId = props.match.params.id
        }
        else if (Fc.getRole() === Const.Role.OPERATOR) {
            OperatorId = Fc.getUserId()
        }
        else if (Fc.getRole() === Const.Role.TEACHER || Fc.getRole() === Const.Role.STUDENT) {
            OperatorId = Fc.getOperator()
        }
        API.CheckPermission(OperatorId, "WriteNote")
            .then((res) => {
                if(!isMounted.current) return
                if (res.status === 200) {
                    if (res.data.Success && isMounted.current) {
                        dispatch({ type: type.PERMISSION_WRITENOTE, payload: res.data.Data })
                        // console.log(res.data.Data);
                    } else {
                        Fc.showError(res.data.Message)
                    }
                } else {
                    Fc.showError(res.statusText)
                }
            })
            .catch((err) => {
                Fc.showError("Can not connect to server")
            })
    }

    function getStudentList() {
        if(!isMounted.current) return
        API.detailClass(classIdRx)
            .then((res) => {
                if(!isMounted.current) return
                if (res.status === 200 && res.data.Success && isMounted.current) {
                    setStudentList(res.data.Data.Students.sort((a, b) => {
                        if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
                        if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
                        return 0;
                      }));
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            })
            .catch(err => {
                console.log(err);
                Fc.showError("Can not connect to server")
            })
    }

    function onSelectStudent(oldId, newId) {
        props.history.replace(matchUrl.url.replace(oldId, newId))
        setStudentId(newId)
        dispatch({ type: type.SET_STUDENT_ID, studentId: newId })
    }

    // if (window.innerWidth < 576){
    //     var wsActiveTab = "Upload";
    // }else{
    //     var wsActiveTab = "Workbook";
    // };

    let wsActiveTab = useSelector(state => state.ws.activeTab)
    const [activeTab, setActiveTab] = useState(wsActiveTab ? wsActiveTab : "Workbook")

    
    useEffect(() => {
        if (window.innerWidth < 576){
            setActiveTab("Upload")
            }else{
            setActiveTab(wsActiveTab)}
    }, [wsActiveTab])

    function getUserInfo() {
        if(!isMounted.current) return
        if (role === "STUDENT") {
            if(studentClassListRx && studentClassListRx.length > 0 && userLoginRx) {
                userLoginRx.Code = userLoginRx.SSOAccountId
                setUserInfo(userLoginRx)
                return 
            } 
            API.getAccountInfo()
                .then(res => {
                    if(!isMounted.current) return
                    if (res.status === 200 && res.data.Success && isMounted.current) {
                        setUserInfo(res.data.Data.DetailUserInfo)

                        let sortedClasses = []
                        if(res.data.Data.DetailUserInfo && res.data.Data.DetailUserInfo.Classes && res.data.Data.DetailUserInfo.Classes.length > 0)
                        sortedClasses = orderBy(res.data.Data.DetailUserInfo.Classes, 'Name', 'asc')
                        setListClass(sortedClasses)
                        dispatch({ type: type.SAVE_STUDENT_CLASS_LIST, payload: sortedClasses })

                        if (sortedClasses.length === 0) {
                            setClassId("")
                            dispatch({ type: type.SAVE_CLASS_ID, classId: "" })
                        } else {
                            setClassId(sortedClasses.length > 0 && sortedClasses[0]._id)
                            dispatch({ type: type.SAVE_CLASS_ID, classId: sortedClasses.length > 0 && sortedClasses[0]._id })
                        }
                            
                    } else {
                        Fc.showError(res.data.Message || res.statusText)
                    }
                })
                .catch((err) => {
                    Fc.showError("Can not connect to server")
                })
        } else {
            API.getDetailStudent(studentId)
                .then((res) => {
                    if(!isMounted.current) return
                    if (res.status === 200 && res.data.Success && isMounted.current) {
                        setUserInfo(res.data.Data)
                    } else {
                        Fc.showError(res.data.Message || res.statusText)
                    }
                })
                .catch((err) => {
                    Fc.showError("Can not connect to server")
                })
        }

    }
    return (
        <MyWorkLayout
            userInfo={userInfo}
            // listClass={listClass}
            listClass={ Fc.getRole() === Const.Role.STUDENT ? studentClassListRx : listClass}
            classId={classId} setClassId={setClassId}
            studentId={studentId} onSelectStudent={onSelectStudent}
            studentList={studentList}
            role={role}
            keyword={keyword} setKeyword={setKeyword}
            activeTab={activeTab} setActiveTab={setActiveTab}
            matchUrl={matchUrl}
            operatorId={operatorId}
            {...props}
        />
    );
}

export default React.memo(MyWork);