import React from "react";
import TopNav from "../Main/_TopNav";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { isDesktop, isMobile } from "react-device-detect"

var accountType = ["School", "Business Tutor", "Individual Tutor", "Parents"]
function Payment(props) {
  let { accountInfo,
    setAccountValue,
    currency, setCurrency,
    bannerInfo,
    setBannerValue,
    updateAccountPayment,
    updateBannerPayment } = { ...props }
  return (
    <div>
      <div className="bg-pay"></div>
      <div className="bg-transparent-black-pay"></div>
      <nav className="mb-4 sticky-top">
        {isMobile && <Link className="bt-back" to="/Vendor">
          <i className="fas fa-arrow-left fa-3x"></i>
        </Link>}
        {!isMobile && isDesktop && <Link className="ml-5 font-Paytone font22 radius-0 text-white" to="/Vendor">
          Back
        </Link>}
        <TopNav history={props.history} />
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div className="col-4">
            <select className="custom-select mb-2" value={currency}
              onChange={(e) => {
                setCurrency(e.target.value)
              }}
            >
              <option value='USD'>USD</option>
              <option value='CAD'>CAD</option>
            </select>
          </div>
        </div>
        <h3 className="font-Paytone text-center mb-2">
          Account Payment Control
        </h3>
        <div className="box-nd tran-60 posbox mb-2">
          <div className="table-responsive">
            <table className="table table-borderless">
              <thead className="table-epen1">
                <tr>
                  <th className="border-0 p-0">
                    <span className="tranTB-70 radius-l16 p-2 btn-block text-white-50 font-weight-light">
                      Account Type
                    </span>
                  </th>
                  <th className="border-0 p-0 text-center">
                    <span className="tranTB-70 p-2 btn-block text-white-50 font-weight-light">From</span>
                  </th>
                  <th className="border-0 p-0 text-center">
                    <span className="tranTB-70 p-2 btn-block text-white-50 font-weight-light">To</span>
                  </th>
                  <th className="border-0 p-0 text-center">
                    <span className="tranTB-70 p-2 btn-block text-white-50 font-weight-light">Monthtly</span>
                  </th>
                  <th className="border-0 p-0 text-center">
                    <span className="tranTB-70 radius-r16 p-2 btn-block text-white-50 font-weight-light">
                      Yearly
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="table-epen1">
                {accountType.map((item, index) => {
                  let { FromStr, ToStr, MonthlyValueStr, YearlyValueStr } = accountInfo.filter(x => x.AccountType == item).length > 0 ? accountInfo.filter(x => x.AccountType == item)[0] : {}
                  return (
                    <tr key={index.toString()}>
                      <td className="align-middle">{item}</td>
                      <td>
                        <NumberFormat thousandSeparator={true} className="form-control bg-black" value={FromStr} onChange={(e) => setAccountValue('From', e.target.value, item)} />
                      </td>
                      <td>
                        <NumberFormat thousandSeparator={true} className="form-control bg-black" value={ToStr} onChange={(e) => setAccountValue('To', e.target.value, item)} />
                      </td>
                      <td>
                        <div className="form-row align-items-center">
                          <div className="col-2 text-right">
                            <i className="fas fa-dollar-sign"></i>
                          </div>
                          <div className="col-10">
                            <NumberFormat
                              thousandSeparator={true}
                              className="form-control bg-black"
                              prefix={"$"}
                              value={MonthlyValueStr}
                              onChange={(e) => setAccountValue('MonthlyValue', e.target.value, item)}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="form-row align-items-center">
                          <div className="col-2 text-right">
                            <i className="fas fa-dollar-sign"></i>
                          </div>
                          <div className="col-10">
                            <NumberFormat
                              thousandSeparator={true}
                              className="form-control bg-black"
                              prefix={"$"}
                              value={YearlyValueStr}
                              onChange={(e) => setAccountValue('YearlyValue', e.target.value, item)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>)
                })}
              </tbody>
            </table>
            <div className="bg-black p-2 radius-16 text-right">
              <button type="button" className="btn font-Paytone btn-light7 pl-4 pr-4" onClick={updateAccountPayment}>
                Save
              </button>
            </div>
          </div>
        </div>

        <h3 className="font-Paytone text-center mb-2">
          Banner Payment Control
        </h3>
        <div className="box-nd tran-60 posbox mb-2">
          <div className="table-responsive">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th className="border-0 p-0" width="63%">
                    <span className="tranTB-70 radius-l16 p-2 btn-block text-white-50 font-weight-light">
                      Account Type
                    </span>
                  </th>
                  <th className="border-0 p-0 text-center">
                    <span className="tranTB-70 p-2 btn-block text-white-50 font-weight-light">Monthtly</span>
                  </th>
                  <th className="border-0 p-0 text-center">
                    <span className="tranTB-70 radius-r16 p-2 btn-block text-white-50 font-weight-light">
                      Yearly
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {accountType.map((item, index) => {
                  let { MonthlyValueStr, YearlyValueStr } = bannerInfo.filter(x => x.AccountType === item).length > 0 ? bannerInfo.filter(x => x.AccountType === item)[0] : {}
                  return (
                    <tr key={index.toString()}>
                      <td className="align-middle" width="63%">
                        {item}
                      </td>
                      <td>
                        <NumberFormat
                          thousandSeparator={true}
                          className="form-control bg-black"
                          prefix={"$"}
                          value={MonthlyValueStr}
                          onChange={(e) => setBannerValue('MonthlyValue', e.target.value, item)}
                        />
                      </td>
                      <td>
                        <NumberFormat
                          thousandSeparator={true}
                          className="form-control bg-black"
                          prefix={"$"}
                          value={YearlyValueStr}
                          onChange={(e) => setBannerValue('YearlyValue', e.target.value, item)}
                        />
                      </td>
                    </tr>)
                })}
              </tbody>
            </table>
            <div className="bg-black p-2 radius-16 text-right">
              <button type="button" className="btn font-Paytone btn-light7 pl-4 pr-4" onClick={updateBannerPayment}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
