import React from 'react';
import { Modal } from "react-bootstrap"
import Select, { components } from 'react-select'

function popupAddToClass(props) {
    const {
        showPopupAddToClass,
        setShowPopupAddToClass,
        getAllClass,
        listClass,
        setListClass,
        chooseClass,
        selectedClass,
        setShowButtonAddToClass,
        hidePopupAddUserToClass
    } = props

    return (
        <>
            <Modal
                show={showPopupAddToClass}
                onHide={hidePopupAddUserToClass}
                className="modal fade"
            >
                <div className="modal-dialog modal-dialog-centered mr-0 ml-0">
                    <div className="modal-content bg-epen pte-auto">
                        <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                            <Select
                                className="form-control"
                                value={selectedClass}
                                options={listClass}
                                onInputChange={getAllClass}
                                onChange={chooseClass}
                                placeholder={"Enter Class ID"}
                                styles={customStyles}
                                components={{ DropdownIndicator }}
                            />
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 mr-auto">
                                    <button
                                        type="button"
                                        onClick={
                                            hidePopupAddUserToClass
                                        }
                                        className="btn btn-light font-Paytone btn-block"
                                    >
                                        Cancel
                                        </button>
                                </div>
                                <div className="col-5">
                                    <button
                                        type="button"
                                        className="btn btn-light font-Paytone float-right btn-block"
                                        onClick={() => {
                                            setShowButtonAddToClass(false)
                                            setShowPopupAddToClass(false)

                                        }}
                                    >
                                        <span className="font-Paytone">Add</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="fa fa-search text-white"></i>
        </components.DropdownIndicator>
    );
};

export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted #FFF',
        color: '#FFF',
        backgroundColor: '#858585',
    }),
    input: (provided, state) => ({
        ...provided,
        color: '#FFF',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#808080',
    }),
    container: (provided, state) => ({
        ...provided,
        padding: 0,
        borderWidth: 0,
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderWidth: 0,
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#858585',
        color: '#FFF'
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        width: 0,
    }),
}

export default React.memo(popupAddToClass);