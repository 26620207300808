import React from 'react'
import moment from 'moment'
import * as Fc from '../../../utils/Functions'
import * as Const from '../../../constants/Const'
import * as type from "../../../constants/actionType"
import { useRouteMatch, Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

import ImageWithBanner from '../../../components/ImageWithBanner'
import ModalAlertOperator from '../../../components/ModalAlertOperator'
import {
    isTablet, isIPad13, 
    isMobile, isDesktop
} from "react-device-detect"
import useCheckdevice from '../../../hooks/useCheckdevice'
import { useDispatch, useSelector } from 'react-redux'

import classNames from 'classnames'

export default function WorksheetView(props) {
    const dispatch = useDispatch();
    const matchUrl = useRouteMatch()
    const checkDevices = useCheckdevice()
    const AccountMethod = Fc.getAccountMethod()
    let classIdRedux = useSelector(state => state.ws.classId)
    let assignmentDateRedux = useSelector(state => state.ws.assignmentDate)
    
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevices !== 'iPad'))

    let {
        role,
        classId, setClassId,
        listClass,
        assignmentDate, setAssignmentDate,
        assignmentDateList,
        listDocument,
        openDeleteAssignmentModal,
        showDeleteAssignMentModal,
        hideDeleteAssignMentModal,
        deleteAssignment,
        banner,
        onShowDocumentAlert,
        isShowDocumentAlertModal,
        onHideDocumentAlert,
        display
    } = { ...props }
    function handleClick(item) {
        if (AccountMethod !== Const.AccountMethod.RESTRICTED) {
            dispatch({ type: type.CHANGE_NOT_RELOAD, payload: true })
            props.history.push(`${matchUrl.url}/Edit/Assignment/${item._id}/0`)
        }
        else {
            onShowDocumentAlert()
        }
    }
    
    return (
        <>
            <section id="classSwitch" className={classNames("container-fluid noOverScroll", "mb-3", [{"mt-desktop-5": isBrowserDesktop}])}
                style={{ display: display ? 'block' : 'none' }}
            >
                <div className="row justify-content-md-center mb-1 mt-3 noOverScroll">
                    <div className="col-md-auto noOverScroll">
                        <form className="form-inline justify-content-center">

                            {
                                role === Const.Role.STUDENT && (
                                    <>
                                        <label className="mr-sm-3 font-Paytone">Assign from</label>
                                        <select name="class" className="custom-select mb-2 mr-sm-3 pr-5"
                                            value={classIdRedux}
                                            onChange={(e) => {
                                                setClassId(e.target.value); 
                                                dispatch({ type: type.SAVE_CLASS_ID, classId: e.target.value })
                                                dispatch({ type: type.CHANGE_NOT_RELOAD, payload: false })
                                            }}
                                            style={{width: 130}}
                                        >
                                            
                                            {
                                                listClass && listClass.map((item, index) => (

                                                    <option 
                                                        key={index.toString()} 
                                                        value={item._id}
                                                    >{item.Name}</option>
                                                ))
                                            }

                                        </select>
                                    </>
                                )
                            }
                            {role !== Const.Role.STUDENT && (<label className="mr-sm-3 font-Paytone mobile-d-none">Assign on</label>)}
                            {role === Const.Role.STUDENT && (<label className="mr-sm-3 font-Paytone mobile-d-none">On</label>)}
                            <select className="custom-select mb-2 mr-sm-3 pr-5 mobile-d-none"
                                value={assignmentDateRedux}
                                style={{width: 140}}
                                onChange={e => {
                                    setAssignmentDate(e.target.value)
                                    dispatch({ type: type.SAVE_ASSIGNMENT_DATE, payload: e.target.value })
                                }}
                            >
                                {
                                    assignmentDateList && assignmentDateList.map((item, index) => (
                                        <option key={index.toString()} value={item}>{moment(item.substring(0, 10)).format('l')}</option>
                                    ))
                                }
                            </select>
                        </form>
                    </div>
                </div>
            </section>

            <section className="container-fluid" style={{ display: display ? 'block' : 'none' }}>
                <div className="p-4">
                    <div className="inner-pos" id="wsScroll">
                        <div className="row">
                            {
                                listDocument.map((item, index) => {
                                    let assignment = {}
                                    if (item.AssignedWorkSheets[0].Exercise.Type === "Document") {
                                        assignment = item.AssignedWorkSheets[0].Exercise.Document
                                    } else {
                                        assignment = item.AssignedWorkSheets[0].Exercise.Workbook
                                    }

                                    return (
                                        <React.Fragment key={index.toString()}>
                                            {(Fc.getRole() === Const.Role.VENDOR || AccountMethod === Const.AccountMethod.PAID) ? (
                                                <div className="tag_list col-lg-2 col-md-3 mb-3" style={{ marginTop: '35px' }}>
                                                    {role !== Const.Role.STUDENT && <span className="close" style={{ zIndex: '10' }} onClick={() => openDeleteAssignmentModal(item._id)}>X</span>}
                                                    <Link className="position-relative box-img"
                                                        to={`${matchUrl.url}/Edit/Assignment/${item._id}/0`}
                                                        onClick={() => dispatch({ type: type.CHANGE_NOT_RELOAD, payload: true })}
                                                    >
                                                        {(item.AssignedWorkSheets[0].Exercise.Type !== "Document" && assignment.Category === "Foundation") ?
                                                            <ImageWithBanner
                                                                mainImage={item.AssignedWorkSheets[0].Exercise.Url} dynamicBanner={banner}
                                                            />
                                                            :
                                                            <img className="img-fluid" src={item.AssignedWorkSheets[0].Exercise.Url} alt="" />
                                                        }

                                                        <span style={{ background: 'rgba(0, 0, 0, 0.5)' }} className="box-sp position-absolute">
                                                            <em className="float-left">{assignment.Level || "\u00A0"}</em>
                                                            <strong style={{ textAlign: 'left' }} className="align-middle">{assignment.Title.length > 15 ? assignment.Title.substring(0, 15) + "..." : assignment.Title}</strong>
                                                        </span>
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div className="tag_list col-lg-2 col-md-3 mb-3" style={{ marginTop: '35px' }}>
                                                    {role !== Const.Role.STUDENT && <span className="close" style={{ zIndex: '10' }} onClick={() => openDeleteAssignmentModal(item._id)}>X</span>}
                                                    <div className="position-relative box-img" 
                                                        onClick={() => {
                                                            handleClick(item)
                                                        }}
                                                    >
                                                        {(item.AssignedWorkSheets[0].Exercise.Type !== "Document" && assignment.Category === "Foundation") ?
                                                            <ImageWithBanner
                                                                mainImage={item.AssignedWorkSheets[0].Exercise.Url} dynamicBanner={banner}
                                                            />
                                                            :
                                                            <img className="img-fluid" src={item.AssignedWorkSheets[0].Exercise.Url} alt="" />
                                                        }

                                                        <span style={{ background: 'rgba(0, 0, 0, 0.5)' }} className="box-sp position-absolute">
                                                            <em className="float-left">{assignment.Level || '\u00A0'}</em>
                                                            <strong style={{ textAlign: 'left' }} className="align-middle">{assignment.Title.length > 15 ? assignment.Title.substring(0, 15) + "..." : assignment.Title}</strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            )}

                                        </React.Fragment>
                                    )
                                })

                            }
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                show={showDeleteAssignMentModal}
                onHide={hideDeleteAssignMentModal}
                className="modal fade"
                id="myModal"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-epen pte-auto">
                        <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5">
                            <h4 className="modal-title font-Paytone">
                                Are you sure you want to delete this assignment?
                            </h4>
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 mr-auto">
                                    <button
                                        type="button"
                                        onClick={hideDeleteAssignMentModal}
                                        className="btn btn-light font-Paytone btn-block"
                                    >
                                        Cancel
                                        </button>
                                </div>
                                <div className="col-5">
                                    <button
                                        type="button"
                                        onClick={deleteAssignment}
                                        className="btn btn-light font-Paytone float-right btn-block"
                                    >
                                        <span className="text-danger">Confirm</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <ModalAlertOperator
                showModal={isShowDocumentAlertModal}
                alertMessage={'This features is locked. Please contact your administrator for more infomation.'}
                AccountMethod={AccountMethod}
                role={role}
                keyStorage={'PopupOperatorInStudentMain'}
                functionClose={onHideDocumentAlert}
                functionUpgrade={null}
            />
        </>
    )
}
