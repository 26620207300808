import React from "react"
import { Link } from "react-router-dom"
import * as Fc from "../../../utils/Functions"
import { useDispatch, useSelector } from 'react-redux'
import * as type from "../../../constants/actionType"

function _TopNav(props) {
  // let matchUrl = useRouteMatch()
  const dispatch = useDispatch();

  function logOut() {
    Fc.setToken("")
    Fc.setAccountMethod("")
    Fc.setRole("")
    clearDataRedux()
    props.history.push("/")
  }

  function clearDataRedux() {
    dispatch({ type: type.SAVE_USER_INFO, payload: {} })
    dispatch({ type: type.SAVE_CLASS_ID, classId: '' })
    dispatch({ type: type.SELECTWS, payload: [] })
    dispatch({ type: type.SET_STUDENT_ID, studentId: "" })
    dispatch({ type: type.SAVE_TOKEN, payload: "" })
    dispatch({ type: type.SAVE_ROLE, payload: [] })
    dispatch({ type: type.SAVE_PARAMS_SEARCH, payload: null })
    dispatch({ type: type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Exercise' })
    dispatch({ type: type.SET_ACTIVE_TAB_MYWORK, activeTab: "Workbook" })
    dispatch({ type: type.SAVE_CURRENT_CHAPTER, payload: null })
    dispatch({ type: type.SAVE_CURRENT_SESSION, payload: null })
    dispatch({ type: type.SAVE_ASSIGNMENT_DATE, payload: ""})
    dispatch({ type: type.CHANGE_NOT_RELOAD, payload: false})
    dispatch({ type: type.PERMISSION_WRITENOTE, payload: false })
    dispatch({ type: type.PERMISSION_SOLUTION, payload: false })
    dispatch({ type: type.SAVE_STUDENT_CLASS_LIST, payload: []})
    dispatch({ type: type.SAVE_CURRENT_CHAT, payload: null });
    dispatch({ type: type.SAVE_LIST_ROOM, payload: [] });
    dispatch({ type: type.SAVE_USER_LOGIN, payload: {} });
  }

  return (
    <ul className="nav justify-content-end">
      {/* <li className="nav-item text-center">
        <Link
          className="nav-link tran-50-2 font-Paytone font13 nav-link-LR15"
          to={'/Vendor/Payment'}
        >
          Payment <br /> Control
        </Link>
      </li> */}
      <li className="nav-item text-center">
        <Link
          className="nav-link tran-50-1 font-Paytone font13 nav-link-LR15"
          to={`/Vendor/ChangePassword`}
        >
          Change <br />
          Password
        </Link>
      </li>
      <li className="nav-item text-center">
        <button
          type="button"
          className="btn nav-link tran-50 font-Paytone font22 line175 radius-0 text-white"
          onClick={logOut}
        >
          Log Out
        </button>
      </li>
    </ul>
  )
}

export default _TopNav
