import React from 'react';
import { Role } from '../../constants/Const'
import { getUserId } from "../../utils/Functions";
import moment from 'moment';
import './style.css'
import classNames from 'classnames'

function ChatInput(props) {
    const {
        tabChat,
        primaryTeacherId,
        currentChatRx,
        message,
        MobileKeyboardPushUp,
        MobileKeyboardPushDown,
        setMessage,
        sendMessage,
        role
    } = props

    const isDisable = tabChat === "ChatClass" 
        &&  (   role === Role.STUDENT 
                || (primaryTeacherId !== getUserId() && role === Role.TEACHER)
            ) 
    ?   "disabled" 
    :   ""
    
    if(
        tabChat === "ChatClass" 
        &&  (    
                role === Role.STUDENT 
                || (primaryTeacherId !== getUserId() && role === Role.TEACHER) 
                || !currentChatRx
            )  
    ) {
        return (
            <div className="input-group ">
                <div className="bg-chat-gotext radius-top-right-0 radius-b10 border-0 radiusT-0" style={{height: 40}}>
                </div>
            </div>
        )
    }

    return (
        <div className="input-group ">
            <input
                id="usrInput"
                type="text"
                className="form-control border-0 radiusT-0 bg-chat-gotext"
                placeholder="Type your message here... "
                value={message}
                disabled={isDisable}
                onFocus={() => { MobileKeyboardPushUp() }}
                onBlur={() => { MobileKeyboardPushDown() }}
                onChange={(e) => { setMessage(e.target.value) }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {sendMessage();MobileKeyboardPushDown()}
                }}
            />
            <div className="input-group-append ml-0 border-0">
                <button
                    type="button"
                    className="btn p-0 radius-top-right-0"
                    onClick={sendMessage}
                >
                    <span className="input-group-text input-group-text1 radius-top-right-0 radius-b10 font-Paytone text-white pt-037 bg-chat-gotext">Send</span>
                </button>
            </div>
        </div>
    )
}


export default ChatInput;