import React from 'react';

function useCheckdevice(props) {
    if (navigator.appVersion.indexOf("iPod") !== -1) {
        return "iPod"
    } else if (navigator.appVersion.indexOf("iPad") !== -1) {
        return "iPad"
    } else if (navigator.appVersion.indexOf("iPhone") !== -1) {
        return "iPhone"
    } else if (navigator.appVersion.indexOf("Android") !== -1) {
        return "Android"
    } else if (navigator.appVersion.indexOf("Win") !== -1) {
        return "Win"
    } else if (navigator.appVersion.indexOf("Mac") !== -1) {
        return "Mac"
    } else if (navigator.appVersion.indexOf("X11") !== -1) {
        return "X11"
    } else if (navigator.appVersion.indexOf("Linux") !== -1) {
        return "Linux"
    } else
        return "undefined"
}

export default useCheckdevice;