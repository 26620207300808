import React from "react"
import './style.css'
import * as Const from "../../../../constants/Const"
import Select, { components } from 'react-select';
import { Modal } from "react-bootstrap"
import TopNav from "../../Main/_TopNav"
import { Link } from "react-router-dom"
import { Formik, ErrorMessage } from "formik"
import * as Yup from "yup"
import { phoneRegExp } from '../../../../constants/Const'
import { customSelectStyles } from '../../../../constants/Const'

import useCheckdevice from '../../../../hooks/useCheckdevice'
import classNames from 'classnames'
import { isDesktop, isMobile } from "react-device-detect"

function Create(props) {
  const checkDevice = useCheckdevice()
  const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))

  const {
    createClient, businessType,
    setType, showBusinessName,
    businessName, apt,
    setApt, address, setAddress,
    province, setProvince, city,
    setCity, country, listProvince,
    postcode, setPostcode, firstName,
    setFirstName, lastName, setLastName,
    gender, setGender, phone, setPhone,
    accountId, setEmail, bannerEdit,
    setBannerEdit, paidAccount, setPaidAccount,
    clearForm, setBusinessName, listRegionByParent,
    listRegion, showModalSetting, setShowModalSetting,

    accountEnable, setAccountEnable,
    writeNote, setWriteNote,
    upload, setUpload,
    ResourceConcept, setResourceConcept,
    ResourceFoundation, setResourceFoundation,
    SolutionConcept, setSolutionConcept,
    SolutionFoundation, setSolutionFoundation
  } = props

  const initValue = {
    businessName: businessName, firstName: firstName,
    lastName: lastName, phone: phone, accountId: accountId, address: address,
    postalcode: postcode,
  }

  const validateForm = Yup.object().shape({
    businessName: showBusinessName() ? Yup.string().trim().required("Required") : Yup.string(),
    firstName: Yup.string().trim().required("Required"),
    lastName: Yup.string().trim().required("Required"),
    phone: Yup.string().required("Required").matches(phoneRegExp, 'Invalid phone number format'),
    accountId: Yup.string().email("Invalid email format").required("Required"),
    address: Yup.string().required("Required"),
  })

  return (
    <>
      <Formik
        initialValues={initValue}
        onSubmit={
          createClient
        }
        validationSchema={validateForm}
      >
        {(properties) => {
          const {
            handleSubmit,
            handleChange,
            handleBlur,
          } = properties
          return (
            <>
              <div className="bg-xanh"></div>
              <form style={{ paddingTop: 10 }} onSubmit={handleSubmit}>
                <nav className="header mb-4 sticky-top">
                  {isMobile && <Link className="bt-back" to="/Vendor">
                    <i className="fas fa-arrow-left fa-3x"></i>
                  </Link>}
                  {!isMobile && isDesktop && <Link className="ml-5 font-Paytone font22 radius-0 text-white position-absolute" to="/Vendor">
                    Back
                  </Link>}
                  <TopNav history={props.history} />
                </nav>
                <section style={{ flex: 1 }}>
                  <div className={classNames("container-fluid", [{ "container-fluid-desktop": isBrowserDesktop }])}>
                    <div className="p-4 mb-3">
                      <h3 className="font-Paytone text-center mb-4">
                        Create New Client
                    </h3>
                      <section className="form-row mb-5">
                        <div className="col-5">
                          <select
                            className="custom-select"
                            value={businessType}
                            onChange={(e) => {
                              setType(e.target.value)
                            }}
                          >
                            {Const.clientType.filter(x => x.Value).map((item, index) => {
                              return (
                                <option value={item.Value} key={index.toString()}>
                                  {item.Value}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        {showBusinessName() && (
                          <div className="col-7">
                            <input type="text" className="form-control" placeholder="Business name" name="businessName" value={businessName}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e)
                                setBusinessName(e.target.value)
                              }}
                            />
                            <ErrorMessage name="businessName">{(msg) => <div className="validate-input">* {msg}</div>}</ErrorMessage>
                          </div>
                        )}
                      </section>
                      <section className="form-row mb-2">
                        <div className="col-3">
                          <input type="text" className="form-control" placeholder="Apt" value={apt}
                            onChange={(e) => setApt(e.target.value)}
                          />
                        </div>
                        <div className="col-6">
                          <input type="text" className="form-control" placeholder="House No. and Street Name" name="address" value={address}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e)
                              setAddress(e.target.value)
                            }}
                          />
                          <ErrorMessage name="address">{(msg) => <div className="validate-input">* {msg}</div>}</ErrorMessage>
                        </div>
                        <div className="col-3">
                          <Select
                            className="form-control form-control-sista2"
                            value={province}
                            options={listProvince}
                            // onInputChange={}
                            onChange={setProvince}
                            placeholder="Province"
                            styles={customSelectStyles}
                            components={{ DropdownIndicator }}
                          />
                        </div>
                      </section>
                      <section className="form-row">
                        <div className="col">
                          <Select
                            className="form-control form-control-sista2"
                            value={city}
                            options={listRegionByParent}
                            // onInputChange={}
                            onChange={setCity}
                            placeholder="City"
                            styles={customSelectStyles}
                            components={{ DropdownIndicator }}
                          />
                        </div>
                        <div className="col">
                          <Select
                            className="form-control form-control-sista2"
                            value={country}
                            options={listRegion}
                            placeholder="Country"
                            styles={customSelectStyles}
                            components={{ DropdownIndicator }}
                          />

                        </div>
                        <div className="col">
                          <input type="text" className="form-control" placeholder="Postal Code" name="postalcode" value={postcode}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e)
                              setPostcode(e.target.value)
                            }}
                          />
                          <ErrorMessage name="postalcode">{(msg) => <div className="validate-input">* {msg}</div>}</ErrorMessage>
                        </div>
                      </section>
                    </div>
                  </div>
                  <section className={classNames("mb-3", [{"box-xam": !isBrowserDesktop, "box-xam-desktop": isBrowserDesktop}])}>
                    <section className={classNames("form-row", [{ "form-row-desktop": isBrowserDesktop }])}>
                      <div className="col-6 mb-2">
                        <input type="text" className="form-control" placeholder="First name" name="firstName" value={firstName}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e)
                            setFirstName(e.target.value)
                          }}
                        />
                        <ErrorMessage name="firstName">{(msg) => <div className="validate-input">* {msg}</div>}</ErrorMessage>
                      </div>
                      <div className="col-6 mb-2">
                        <input type="text" className="form-control" placeholder="Last Name" name="lastName" value={lastName}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e)
                            setLastName(e.target.value)
                          }}
                        />
                        <ErrorMessage name="lastName">{(msg) => <div className="validate-input">* {msg}</div>}</ErrorMessage>
                      </div>
                      <div className="col-6">
                        <select className="custom-select" value={gender}
                          onChange={(e) => {
                            setGender(e.target.value)
                          }}
                        >
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <input type="text" className="form-control" placeholder="Phone Number" name="phone" value={phone}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e)
                            setPhone(e.target.value)
                          }}
                        />
                        <ErrorMessage name="phone">{(msg) => <div className="validate-input">* {msg}</div>}</ErrorMessage>
                      </div>
                    </section>
                  </section>
                  <section className={classNames("container-fluid", [{ "container-fluid-desktop": isBrowserDesktop }])}>
                    <div className="p-4">
                      <section className="form-row">
                        <div className="col-6 mr-auto">
                          <input type="text" className="form-control" placeholder="Email address" name="accountId" value={accountId}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e)
                              setEmail(e.target.value)
                            }}
                          />
                          <ErrorMessage name="accountId">{(msg) => <div className="validate-input">* {msg}</div>}</ErrorMessage>
                        </div>
                        <div className="col-6 text-center">
                          <div className="row">
                            <div className={classNames("col-6", "offset-3", [{ "col-6-desktop": isBrowserDesktop }])}>
                              <button
                                type="button"
                                className="btn btn-light font-Paytone mr-2 btn-block"
                                onClick={() => { setShowModalSetting(true) }}
                              >
                                Setting
                              </button>
                              <button
                                type="button"
                                onClick={clearForm}
                                className="btn btn-light font-Paytone btn-block">
                                Clear Form
                              </button>

                              <button type="submit" className="btn btn-light font-Paytone btn-block">
                                Create
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Modal */}

                        <Modal
                          show={showModalSetting}
                          className=""
                          id="myModal"
                          dialogClassName="custom-modal"
                          onHide={() => { setShowModalSetting(false) }}
                        >
                          <div style={{ width: '100%', pointerEvents: 'auto' }} className="modal-dialog modal-dialog-centered modal-xl">
                            <div className={classNames("modal-content", "bg-epen", [{ "modal-desktop": isBrowserDesktop }])}>
                              {/* Modal Header */}
                              <div className="modal-header border-bottom-0">
                                <div className="p-2">
                                  <label className="font-Paytone font18 mr-3">Account enable</label>
                                  <span className="switch mr-4">
                                    <input type="checkbox" className="switch" id="AccountEnable"
                                      checked={accountEnable}
                                      onChange={(e) => {
                                        setAccountEnable(e.target.checked)
                                      }}
                                    />
                                    <label htmlFor="AccountEnable" />
                                  </span>
                                  <label className="font18 mr-3">Paid Account</label>
                                  <span className="switch">
                                    <input type="checkbox" className="switch" id="isPaidAccount" checked={paidAccount}
                                      onChange={(e) => setPaidAccount(e.target.checked)}
                                    />
                                    <label htmlFor="isPaidAccount"></label>
                                  </span>
                                </div>
                                <button type="button" className="close close-im" data-dismiss="modal"
                                  onClick={() => { setShowModalSetting(false) }}
                                >
                                  ×
                                </button>
                              </div>
                              {/* Modal body */}
                              <div className="modal-body box-xam p-4">
                                <div className="row">
                                  <div className="col">
                                    <h4 className="font-Paytone font18 mb-3">Banner Control</h4>
                                    <div className="text-right">
                                      <label className="float-left font-14"><small>Banner Edit</small></label>
                                      <span className="switch switch-sm">
                                        <input type="checkbox" className="switch" id="isBannerEdit" checked={bannerEdit}
                                          onChange={(e) => {
                                            setBannerEdit(e.target.checked)
                                          }}
                                        />
                                        <label htmlFor="isBannerEdit"></label>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <h4 className="font-Paytone font18 mb-3">Features Control</h4>
                                    <div className="text-right">
                                      <label className="float-left"><small>Note</small></label>
                                      <span className="switch switch-sm">
                                        <input type="checkbox" className="switch" id="note"
                                          checked={writeNote}
                                          onChange={(e) => {
                                            setWriteNote(e.target.checked)
                                          }}
                                        />
                                        <label htmlFor="note" />
                                      </span>
                                    </div>
                                    <div className="text-right">
                                      <label className="float-left"><small>Upload</small></label>
                                      <span className="switch switch-sm">
                                        <input type="checkbox" className="switch" id="upload"
                                          checked={upload}
                                          onChange={(e) => {
                                            setUpload(e.target.checked)
                                          }}
                                        />
                                        <label htmlFor="upload" />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <h4 className="font-Paytone font18 mb-3">Resource</h4>
                                    <div className="text-right">
                                      <label className="float-left"><small>Foundation</small></label>
                                      <span className="switch switch-sm">
                                        <input type="checkbox" className="switch" id="ResourceFoundation"
                                          checked={ResourceFoundation}
                                          onChange={(e) => {
                                            setResourceFoundation(e.target.checked)
                                          }}
                                        />
                                        <label htmlFor="ResourceFoundation" />
                                      </span>
                                    </div>
                                    <div className="text-right">
                                      <label className="float-left"><small>Concept</small></label>
                                      <span className="switch switch-sm">
                                        <input type="checkbox" className="switch" id="ResourceConcept"
                                          checked={ResourceConcept}
                                          onChange={(e) => {
                                            setResourceConcept(e.target.checked)
                                          }}
                                        />
                                        <label htmlFor="ResourceConcept" />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <h4 className="font-Paytone font18 mb-3">Solution</h4>
                                    <div className="text-right">
                                      <label className="float-left"><small>Foundation</small></label>
                                      <span className="switch switch-sm">
                                        <input type="checkbox" className="switch" id="SolutionFoundation"
                                          checked={SolutionFoundation}
                                          onChange={(e) => {
                                            setSolutionFoundation(e.target.checked)
                                          }}
                                        />
                                        <label htmlFor="SolutionFoundation" />
                                      </span>
                                    </div>
                                    <div className="text-right">
                                      <label className="float-left"><small>Concept</small></label>
                                      <span className="switch switch-sm">
                                        <input type="checkbox" className="switch" id="SolutionConcept"
                                          checked={SolutionConcept}
                                          onChange={(e) => {
                                            setSolutionConcept(e.target.checked)
                                          }}
                                        />
                                        <label htmlFor="SolutionConcept" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Modal footer */}
                              <div className="modal-footer block border-top-0">
                                <div className="text-center mb-0">
                                  <button type="button" className="btn btn-light font-Paytone btn-lg pl-5 pr-5"
                                    onClick={() => {
                                      setShowModalSetting(false)
                                    }}
                                  >Save</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      </section>
                    </div>
                  </section>
                </section>
              </form>
            </>
          )
        }}
      </Formik>

    </>
  )
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
    </components.DropdownIndicator>
  );
};

export default React.memo(Create)
