import React from 'react';
import { Role } from '../../constants/Const'
import { getUserId, getRole } from "../../utils/Functions";
import moment from 'moment';
import './style.css'
import classNames from 'classnames'

import { isMobile } from 'react-device-detect';

function HeaderChat(props) {
    const {
        currentChatRx,
        onChangeClasTab,
        listClass,
        ClassId,

        selectedId,
        onChangePersonTab,
        ListStudent,
        ListTeacher,

        onClickContactAdmin
    } = props

    ListStudent.sort((a, b) => {
        if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
        if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
        return 0;
    })
    ListTeacher.sort((a, b) => {
        if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
        if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
        return 0;
    })

    return (
        <ul id="clothingnav1" className="nav nav-tabs bg-black nav-tabs-chat" role="tablist">
            <li className="nav-item" style={{ width: 240 }}>
                <button className="nav-link active" >
                    <select
                        className={classNames("select2", "custom-select", "font-Paytone", "text-white", "font14", "pr-5",
                            [{"active-chat-tab": currentChatRx && currentChatRx.tabChat === "class"}]
                        )}
                        style={{ width: 230 }}
                        value={ClassId}
                        onChange={onChangeClasTab}
                    >
                        <option defaultValue="">Classlist</option>
                        {
                            listClass && listClass.map((item, index) => {
                                return (
                                    <option
                                        key={index.toString()}
                                        value={item._id}
                                    >{item.Name}</option>
                                )
                            })
                        }
                    </select>
                </button>
            </li>
            {
                (getRole() !== Role.VENDOR)
                && <li className="nav-item" style={{ width: 240 }}>
                    <button className="nav-link" >
                        <select
                            id="selectUserChatbox"
                            className={classNames("select2", "custom-select", "font-Paytone", "text-white", "font14", "pr-5",
                                [{"active-chat-tab": currentChatRx && currentChatRx.tabChat === "person"}]
                            )}
                            style={{ width: 230 }}
                            value={selectedId}
                            onChange={onChangePersonTab}
                        >
                            <option defaultValue="">Teacher/Student</option>
                            {ListStudent && ListStudent.map((item, index) => {
                                return (
                                    <option
                                        value={item._id}
                                        key={index.toString()}
                                        defaultValue={selectedId}
                                        usertype={Role.STUDENT}
                                        username={item.FullName}
                                    >
                                        {item.Code}-{item.FullName}
                                    </option>
                                )
                            })}
                            {
                                ListTeacher && ListTeacher.map((item, index) => {
                                    return (
                                        <option
                                            value={item._id}
                                            key={index.toString()}
                                            defaultValue={selectedId}
                                            username={item.FullName}
                                            usertype={Role.TEACHER}
                                        >
                                            {item.Code}-{item.FullName}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </button>
                </li>
            }

            {(getRole() === Role.STUDENT || getRole() === Role.TEACHER) && ClassId !== "" && ClassId !== "Classlist"
                &&
                <li className={isMobile ? "nav-item mobile-width-50" : "nav-item", "mobile-d-none"}>
                    <button
                        className={classNames("nav-link", "font-Paytone", [{"active-chat-tab": currentChatRx && currentChatRx.tabChat === "admin"}])}
                        style={{ color: 'white', padding: "0.3rem 0.5rem", margin: "0.3rem 3rem 0.3rem 1rem", borderRadius: 6 }}
                        onClick={onClickContactAdmin}
                    >
                        <i className="fas fa-comments mr-2" />Contact admin
                    </button>   
                </li>
            }

        </ul>
    );
}

export default HeaderChat;