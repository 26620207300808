import React from 'react';
import Select, { components } from "react-select"
import { Formik, ErrorMessage } from "formik"
import * as Yup from "yup"
import { customSelectStyles } from '../../../../constants/Const'
import { Modal } from "react-bootstrap"
import ModalAlertOperator from '../../../../components/ModalAlertOperator'
import * as Fc from "../../../../utils/Functions"
import { Link } from 'react-router-dom';
import ModalShowInfoUser from '../../../../components/ModalShowInfoUser/ModalShowInfoUser'
import { isDesktop, isMobile } from 'react-device-detect';
import { classNameRegExpVn, whiteSpaceReg } from '../../../../constants/Const'

function AddClassFormDesktop(props) {
    const role = Fc.getRole()
    const {
        name,
        setName,
        primaryTeacher,
        setPrimaryTeacher,
        teacherOptions,
        userKeyword,
        setUserKeyword,
        classId,
        getListTeacher,
        clearForm,
        insertClass,
        onCheckStudents,
        listUsers,
        isShowModal,
        onHideModal,
        alertMessage,
        isShowAddClassAlertModal,
        onHideAddAlert, isShowAddClassErrorModal, onHideAddError, errorMessage,
        isShowInfoUser, setShowInfoUser,
        onHideInfoUser,
        setIdStudent, idUser,
        setTypeUser, typeUser,
        matchUrl,
        handleEdit
    } = props;
    const AccountMethod = Fc.getAccountMethod()
    const initValue = {
        name: name
    };

    const validateForm = Yup.object().shape({
        name: Yup.string().matches(classNameRegExpVn, 'Please enter valid name').max(40, 'Class name must be at most 40 characters').required("Required").matches(whiteSpaceReg, 'Required'),
    });

    listUsers.sort((a, b) => {
        if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
        if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
        return 0;
    })
    return (
        <Formik
            initialValues={initValue}
            onSubmit={insertClass}
            validationSchema={validateForm}
        >
            {function (properties) {
                const { handleSubmit, handleChange, handleBlur } = properties;
                return (
                    <>
                        <div className="bg-xanh"></div>
                        <form onSubmit={handleSubmit}>
                        <div style={{ paddingTop: 51 }}>
                            <section className="box-xam py-2 mb-3">
                                <div className="d-flex w-100 justify-content-center">
                                    <div className="w-50"><h4 className="font-Paytone mb-0 w-75 text-center">Add a New Class</h4></div>
                                    <div className="text-right">
                                        {isMobile && <Link to={matchUrl.url.substring(0, matchUrl.url.length - 8) + `Classes`} className="btn font-Paytone bg-30 radius10 pl-3 pr-3 tran-60 text-white"><i className="fas fa-edit mr-2" />
                                            Manage
                                        </Link>}
                                        {(!isMobile && isDesktop) &&
                                            <>
                                                <Link to={matchUrl.url.substring(0, matchUrl.url.length - 8) + `Classes`} className="btn radius10 pl-3 pr-3 font-Paytone" style={{ backgroundColor: '#fff', color: "#000" }}>
                                                    {/* <i className="fas fa-edit mr-3"></i>Manage Class */}
                                                    Back
                                                </Link>
                                                <Link to="#" className="btn pl-3 pr-3 radius10 ml-2 bg-light d-none">
                                                <i className="fas fa-plus mr-3"></i>Create a new Class
                                                </Link>
                                            </>
                                        }
                                    </div>
                                </div>
                            </section>
                            <section className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div className="row justify-content-center">
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    className="form-control mb-2"
                                                    placeholder="Class Name*"
                                                    name="name"
                                                    value={name}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setName(e.target.value);
                                                    }}
                                                />
                                                <ErrorMessage name="name">
                                                    {(msg) => (
                                                        <div className="validate-input mb-1">* {msg}</div>
                                                    )}
                                                </ErrorMessage>
                                                <div className="mb-2">
                                                {/* <form className="mb-2" onSubmit={handleSubmit}> */}
                                                    <div className="input-group">
                                                        <Select
                                                            className="form-control"
                                                            value={primaryTeacher}
                                                            options={teacherOptions}
                                                            onInputChange={getListTeacher}
                                                            onChange={setPrimaryTeacher}
                                                            name="searchTeacher"
                                                            placeholder="Primary Teacher ID*"
                                                            styles={customSelectStyles}
                                                            components={{ DropdownIndicator }}
                                                        />
                                                    </div>
                                                {/* </form> */}
                                                </div>
                                                {/*<h4 class="text-uppercase text-center font-Paytone">ID: MATH09THANHQT1197</h4>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="p-4">
                                            <h4 className="font-Paytone tle23">Add students and sub-teachers to this class</h4>
                                            <div className="box-nd tran-60 posbox1-desktop height-for-desktop">
                                                <div className="search-table z-5 position-absolute">

                                                    <div className="input-group mb-2 bg-ghi radius-10">
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sista2 bol-10"
                                                            placeholder="Search for Students or Sub-Teachers..."
                                                            value={userKeyword}
                                                            onChange={(e) => setUserKeyword(e.target.value)}
                                                        />
                                                        <div className="input-group-append ml-0 border-0">
                                                            <button
                                                                type="button"
                                                                onClick={() => setUserKeyword("")}
                                                                className="input-group-text input-group-text2 bor-10"
                                                            >
                                                                <span className="text-decoration text-white">
                                                                    Clear
                                                            </span>
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="position-absolute tle-table tranTB-70 t-30">
                                                    <div className="row">
                                                        <div className="col-5 text-muted2">User Name</div>
                                                        <div className="col-1 text-muted2 text-center" style={{paddingLeft: 0}}>Grade</div>
                                                        <div className="col-4 text-muted2 text-right">User ID</div>
                                                        <div className="col-2 text-muted2 text-center">Actions</div>
                                                    </div>
                                                </div>
                                                <div className="hidden-scrollbar-scoll">
                                                    <div className="inner-scoll-add-class-desktop">
                                                        {listUsers &&
                                                            listUsers.sort().map((item, index) => {

                                                                return (
                                                                    <div className="row mb-2" key={index.toString()}>
                                                                        <div className="col-5">
                                                                            <div className="align-middle ml-2">
                                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        id={`radio${index}`}
                                                                                        checked={item.checked}
                                                                                        onChange={(e) => {
                                                                                            onCheckStudents(
                                                                                                item.Code,
                                                                                                e.target.checked
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label"
                                                                                        htmlFor={`radio${index}`}
                                                                                    >
                                                                                        {item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-1 text-center">{item.Type==="Student" ? (item.Grade ? item.Grade : 'N/A') : ''}</div>
                                                                        <div className="col-4 text-right">
                                                                            <label htmlFor={`radio${index}`}>
                                                                                {item.Code}
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-2 text-center">
                                                                            {
                                                                                (Fc.getRole() === "VENDOR" || Fc.getRole() === "OPERATOR") &&
                                                                                    <a 
                                                                                        className="text-underline pointer"
                                                                                        style={{marginRight: 10}}
                                                                                        onClick={() => handleEdit(item)}
                                                                                    >Edit</a>
                                                                            }
                                                                        
                                                                            <a href="true" 
                                                                                onClick={() => {
                                                                                    setShowInfoUser(true)
                                                                                    setIdStudent(item._id)
                                                                                    setTypeUser(item.Type)
                                                                                }} 
                                                                                className="text-underline" data-toggle="modal" data-target="#myModal1"
                                                                            >View</a>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-4">
                                            <div className="row">
                                                <div className="col-4 mr-auto"><button
                                                    type="button"
                                                    onClick={clearForm}
                                                    className="btn btn-light font-Paytone btn-block"
                                                >
                                                    Clear Form
                                            </button>

                                            </div>
                                                <div className="col-4">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-light font-Paytone float-right btn-block"
                                                    >
                                                        Create
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* The Modal */}
                            <Modal
                                show={isShowModal}
                                onHide={onHideModal}
                                className="modal fade"
                                id="myModal"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content bg-epen pte-auto">
                                        <div className="modal-header border-bottom-0 pt-5 pl-5 pr-5 text-center m-auto">
                                            <span>Class Name already exists.
                                                <br />
                                                Please change you class name into <br /> <b>{name}A</b> or <b>{name}B</b> instead
                                            </span>
                                            {/* <h4 className="modal-title font-Paytone">
                                                [ClassName]A
              			                        </h4> */}
                                        </div>
                                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                                            <div className="row">
                                                <div className="col-5 m-auto">
                                                    <button
                                                        type="button"
                                                        onClick={onHideModal}
                                                        className="btn btn-light font-Paytone btn-block"
                                                    >
                                                        Ok
                  			</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            {isShowInfoUser && <ModalShowInfoUser
                                isShowInfoUser={isShowInfoUser}
                                setShowInfoUser={setShowInfoUser}
                                onHideInfoUser={onHideInfoUser}
                                idUser={idUser}
                                typeUser={typeUser}
                            />}

                            {<ModalAlertOperator
                                showModal={isShowAddClassAlertModal}
                                alertMessage={alertMessage}
                                role={role}
                                keyStorage={'PopupAddClass'}
                                AccountMethod={AccountMethod}
                                functionClose={onHideAddAlert}
                                functionUpgrade={null}
                            />}

                            <ModalAlertOperator
                                showModal={isShowAddClassErrorModal}
                                alertMessage={errorMessage}
                                role={role}
                                AccountMethod={AccountMethod}
                                functionClose={onHideAddError}
                                functionUpgrade={null}
                            />
                        </div>
                        </form>
                    </>
                )
            }}
        </Formik>
    )

}

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="fa fa-search text-white"></i>
        </components.DropdownIndicator>
    );
};

export default AddClassFormDesktop;