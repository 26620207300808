import React from 'react'
import { Modal } from 'react-bootstrap'
import { useRouteMatch, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as type from "../../../constants/actionType"

import {
    isDesktop, isMobile
} from "react-device-detect"

import useCheckdevice from '../../../hooks/useCheckdevice'
import classNames from 'classnames'

export default function NoteView(props) {
    const dispatch = useDispatch();
    const device = useCheckdevice()
    const checkBrowser = (isDesktop && !isMobile && (device !== 'iPad'))
    let matchUrl = useRouteMatch()

    let selectFolderRx = useSelector(state => state.ws.selectedFolder)

    // console.log(matchUrl);
    const { display,
        folderName, setFolderName,
        createFolder,
        isShowFolderModal,
        isShowArrowBtnFolder,
        showFolderModal, hideFolderModal, showRenameFolderModel,
        keyword, setKeyword,
        listFolders,
        noteName, setNoteName,
        selectedFolder, setSelectedFolder,
        isShowNoteModal,
        showCreateNoteModel, hideNoteModel, showRenameNoteModel,
        createNote,
        listNotes,
        search,
        getFolders,
        clickNote,
        selectAllNotes,
        unSelectAllNotes,
        selectedNote,
        renameNote,
        renameFolder,
        deleteFolder,
        deleteNote,
        isShowDeleteFolderModal, setIsShowDeleteFolderModal,
        isShowDeleteNotesModal, setIsShowDeleteNotesModal,
        isShowMoveNoteModal, setIsShowMoveNoteModal, hideMoveNoteModal,
        allFolders,
        targetFolder, setTargetFolder,
        moveNote,
        selectAllFolder,
        unSelectAllFolder,
        clickFolder,
        isShowDeleteAllFolderModal, setIsShowDeleteAllFolderModal,
        deleteAllFolder,
        onClickFolderRight,
        onClickFolderLeft
    } = { ...props }

    const isIpadLandscape = !checkBrowser && (window.innerWidth == 1024 || window.outerWidth == 1024) 
        && (window.innerHight == 768 || window.outerHeight == 768)

    return (
        <>
            <section className={classNames("container-fluid", "mb-3", [{"mt-desktop-5": checkBrowser}])} 
                style={{ display: display ? 'block' : 'none' }}
            >
                <div className="p-4">
                    <div className="form-row">
                        <div className="col-lg-4 col-md-6 mb-2">
                            <div className="input-group">
                                <input type="text" className="form-control form-control-sista2" placeholder="Enter Note or Folder name" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                                <div className="input-group-append ml-0 border-0" onClick={search}>
                                    <div><span className="input-group-text input-group-text1"><i className="fa fa-search text-white"></i></span></div>
                                </div>
                            </div>
                        </div>
                        {listNotes.filter(x => x.Checked).length > 0 &&
                            <>
                                <div className="col-3" onClick={() => setIsShowDeleteNotesModal(true)}>
                                    <div className="btn radius10 pl-3 pr-3 bg-white-10 btn-block text-danger pointer">Delete Note</div>
                                </div>
                                <div className="col-3">
                                    <div className="btn radius10 pl-3 pr-3 bg-white-10 btn-block text-white pointer" onClick={() => setIsShowMoveNoteModal(true)} >Move to folder</div>
                                </div>
                            </>}
                        {listFolders.filter(x => x.Checked).length > 0 &&
                            <>  
                                {   
                                    !selectedFolder._id && 
                                    <div className="col-5" onClick={() => { setIsShowDeleteAllFolderModal(true) }}>
                                        <div className="btn radius10 pl-3 pr-3 bg-white-10 btn-block text-danger pointer">Delete Folders</div>
                                    </div>
                                }
                            </>}
                    </div>

                </div>
            </section>
            <section className="container-fluid" style={{ display: display ? 'block' : 'none' }}>
                <div className="p-4">
                    <div className="folder_mb">
                        <span className="font-Paytone tle23 font-18 mr-4">Folders</span>
                        {
                            !selectedFolder._id && listFolders.filter(x => x.Checked).length < listFolders.length&&
                            <span className="text-underline pointer" onClick={() => { selectAllFolder() }}>
                                Select All Folder
                            </span>
                        }
                        {
                            listFolders.filter(x => x.Checked).length > 0 && !selectedFolder._id && 
                            <span className={classNames("text-underline", "pointer",  [{"ml-3": (listFolders.filter(x => x.Checked).length < listFolders.length)}])} 
                                onClick={unSelectAllFolder}
                            >
                                UnCheck All Folder
                            </span>
                        }
                        {/* {selectedFolder && <span className="text-underline">{selectedFolder.Name}</span>} */}
                    </div>
                    <div className={classNames("row", "mb-5", [{"mb-1rem-ipad-landscape": isIpadLandscape}])}
                        style={{marginLeft: -30, marginRight: -30}}
                    >
                        <div className="col-lg-12 no-pad scroller mb-3" style={{paddingRight: 30, paddingLeft: 30}}>
                            {
                                isShowArrowBtnFolder && checkBrowser &&  Object.keys(selectFolderRx).length === 0 &&
                                <div className="pointer"
                                    style={{top: "20%",left: 0, position: "absolute", paddingLeft: 10, paddingRight: 10}} 
                                    onClick={onClickFolderLeft}
                                >
                                    <i className="fa fa-angle-left"></i>
                                </div>
                            }
                            <div className="col-lg-12 no-pad btn-header-links  pl-0"
                                id="scroll-folder"
                            >
                                {
                                    !selectedFolder._id ?
                                        <>
                                            <button className="btn radius10 pl-3 pr-3 bg-white-10 text-white mr-3 el-folder" 
                                                type="button" onClick={showFolderModal}
                                            >
                                                <i className="fas fa-plus mr-2"></i>Add folder
                                            </button>
                                            {
                                                listFolders.map((item, index) =>
                                                    <div key={index.toString()} 
                                                        className='btn radius10 pl-3 pr-5 bg-white-40 text-white mr-2 pointer el-folder'
                                                    >
                                                        <div className="custom-control custom-radio float-left">
                                                            <input type="checkbox" 
                                                                className="custom-control-input pointer" 
                                                                id={item._id} 
                                                                checked={item.Checked} 
                                                                onChange={(e) => clickFolder(item._id, e.target.checked)} 
                                                            />
                                                            <label className="custom-control-label" htmlFor={item._id}></label>
                                                        </div>
                                                        <div className="" style={{ marginLeft: 5 }}
                                                            key={index.toString()} onClick={() => {
                                                                dispatch({ type: type.SET_SELECTED_FOLDER, selectedFolder: item })
                                                                setSelectedFolder(item)
                                                            }} >
                                                            <i className="fas fa-folder mr-2"></i>{item.Name}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </> :
                                        <>
                                            <button type="button" className="btn radius10 pl-3 pr-3 bg-white-10 text-white pl-5 pr-5 mr-2" onClick={() => {
                                                getFolders('')
                                                dispatch({ type: type.SET_SELECTED_FOLDER, selectedFolder: {} })
                                                setSelectedFolder({})
                                            }}>Back</button>
                                            <button type="button" className="btn radius10 pl-3 pr-3 bg-white-10 text-danger pl-5 pr-5 mr-2" onClick={() => {
                                                setIsShowDeleteFolderModal(true)
                                            }}>Delete Folder</button>
                                            <button type="button" className="btn radius10 pl-3 pr-3 bg-white-10 text-white pl-5 pr-5 mr-2" onClick={showRenameFolderModel}>Rename Folder</button>
                                        </>
                                }

                            </div>
                            {   
                                isShowArrowBtnFolder && checkBrowser && Object.keys(selectFolderRx).length === 0 &&
                                <div className="pointer"
                                    style={{top: "20%",right: 0, position: "absolute", paddingLeft: 10, paddingRight: 10}}
                                    onClick={onClickFolderRight}
                                >
                                    <i className="fa fa-angle-right"></i>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="mb-3">
                        {
                            (Object.keys(selectedFolder).length === 0 && selectedFolder.constructor === Object) 
                            ?   null 
                            :   <span className="font-Paytone tle23 font-18 mr-4">{selectedFolder.Name}</span>
                        }

                        {
                            listNotes.filter(x => x.Checked).length < listNotes.length &&
                            <span className="text-underline pointer" onClick={selectAllNotes}>Select All Note</span>

                        }

                        {
                            listNotes.filter(x => x.Checked).length > 0 &&
                            <span className={classNames("text-underline", "pointer", [{"ml-3":  listNotes.filter(x => x.Checked).length < listNotes.length}])}
                                onClick={unSelectAllNotes}
                            >UnCheck All Note</span>
                        }
                    </div>
                    <div className="" style={{height: checkBrowser ? "calc(100vh - 24rem)" : "calc(100vh - 32rem)", overflow: 'auto'}}>
                        <div className="tag_list_wrapper row mb-4">
                            <div className="col-lg-2 col-md-3 mb-4" onClick={showCreateNoteModel}>
                                <div className="tran-60 box-upload text-center h-100 pointer">
                                    <p className="mb-0"><i className="fas fa-plus fa-3x"></i><br /><span className="font-Paytone font18">Add Note</span></p>
                                </div>
                            </div>
                            {
                                listNotes && listNotes.map((item, index) =>
                                    <div className="tag_list col-lg-2 col-md-3 mb-4" key={index.toString()}>
                                        <div className="position-relative box-img">
                                            {item.Checked === true && <div style={{ width: '100%', height: '100%', opacity: 0.6, background: '#000', position: 'absolute' }}></div>}

                                            <Link to={`${matchUrl.url}/Edit/Notes/${item._id}/0`} 
                                                onClick={() => dispatch({ type: type.CHANGE_NOT_RELOAD, payload: true })}
                                            >
                                                <img className="img-fluid" src={window.location.origin + "/images/bg_white.jpeg"} alt="" />
                                            </Link>
                                            <span style={{ marginBottom: -1, }} className="box-sp position-absolute" onClick={() => showRenameNoteModel(item)}>
                                                <strong className="align-middle-center">{item.Name}</strong>
                                            </span>
                                            <div className="custom-control custom-radio position-absolute pos-check">
                                                <input type="checkbox" className="custom-control-input" id={item._id} checked={item.Checked} onChange={(e) => clickNote(item._id, e.target.checked)} />
                                                <label className="custom-control-label" htmlFor={item._id}></label>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                show={isShowFolderModal}
                onHide={hideFolderModal}
                className="modal fade"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-epen pte-auto">
                        <div className="border-bottom-0 text-center pt-4 pb-2 pl-4 pr-4" style={{ width: 400 }}>
                            <h4 className="font-Paytone ">{selectedFolder._id ? "Rename folder" : "Create a folder"}</h4>
                        </div>
                        <div className="modal-body pl-5 pr-5">
                            <input type="text" className="form-control mb-3" placeholder="Folder name" value={folderName} onChange={(e) => setFolderName(e.target.value)} />
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 mr-auto">
                                    <button type="button" className="btn btn-light font-Paytone btn-block" onClick={hideFolderModal}>Cancel</button>
                                </div>
                                <div className="col-5">
                                    <button type="button" className="btn btn-light font-Paytone float-right btn-block" onClick={() => {
                                        if (selectedFolder._id)
                                            renameFolder()
                                        else
                                            createFolder()
                                    }}>{selectedFolder._id ? 'Rename' : 'Create'}</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Modal>

            <Modal
                show={isShowNoteModal}
                onHide={hideNoteModel}
                className="modal fade"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-epen pte-auto">
                        <div className="border-bottom-0 text-center pt-4 pb-2 pl-4 pr-4" style={{ width: 400 }}>
                            <h4 className="font-Paytone ">{selectedNote ? "Rename note" : "Create a New Notes"}</h4>
                        </div>
                        <div className="modal-body pl-5 pr-5">
                            <input type="text" className="form-control mb-3" placeholder="Note name" value={noteName} onChange={(e) => setNoteName(e.target.value)} />
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 mr-auto">
                                    <button type="button" className="btn btn-light font-Paytone btn-block" onClick={hideNoteModel}>Cancel</button>
                                </div>
                                <div className="col-5">
                                    <button type="button" className="btn btn-light font-Paytone float-right btn-block" onClick={() => {
                                        if (selectedNote)
                                            renameNote()
                                        else
                                            createNote()
                                    }}>{selectedNote ? 'Rename' : 'Create'}</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Modal>

            <Modal
                show={isShowDeleteFolderModal}
                onHide={() => setIsShowDeleteFolderModal(false)}
                className="modal fade"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-epen pte-auto">
                        <div className="border-bottom-0 text-center pt-4 pb-2 pl-4 pr-4" style={{ width: 400 }}>
                            <h4 className="font-Paytone ">Are you sure want to delete this folder?</h4>
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 mr-auto">
                                    <button type="button" className="btn btn-light font-Paytone btn-block" onClick={() => setIsShowDeleteFolderModal(false)}>Cancel</button>
                                </div>
                                <div className="col-5">
                                    <button type="button" className="btn btn-light font-Paytone float-right btn-block" onClick={deleteFolder}>Delete</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Modal>

            <Modal
                show={isShowDeleteNotesModal}
                onHide={() => setIsShowDeleteNotesModal(false)}
                className="modal fade"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-epen pte-auto">
                        <div className="border-bottom-0 text-center pt-4 pb-2 pl-4 pr-4" style={{ width: 400 }}>
                            <h4 className="font-Paytone ">Are you sure want to delete these selected notes?</h4>
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 mr-auto">
                                    <button type="button" className="btn btn-light font-Paytone btn-block" onClick={() => setIsShowDeleteNotesModal(false)}>Cancel</button>
                                </div>
                                <div className="col-5">
                                    <button type="button" className="btn btn-light font-Paytone float-right btn-block" onClick={deleteNote}>Delete</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Modal>

            <Modal
                show={isShowDeleteAllFolderModal}
                onHide={() => setIsShowDeleteAllFolderModal(false)}
                className="modal fade"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-epen pte-auto">
                        <div className="border-bottom-0 text-center pt-4 pb-2 pl-4 pr-4" style={{ width: 400 }}>
                            <h4 className="font-Paytone ">Are you sure want to delete selected folders?</h4>
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 mr-auto">
                                    <button type="button" className="btn btn-light font-Paytone btn-block" onClick={() => setIsShowDeleteAllFolderModal(false)}>Cancel</button>
                                </div>
                                <div className="col-5">
                                    <button type="button" className="btn btn-light font-Paytone float-right btn-block" onClick={deleteAllFolder}>Delete</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Modal>

            <Modal
                show={isShowMoveNoteModal}
                onHide={() => hideMoveNoteModal(false)}
                className="modal fade"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-epen pte-auto">
                        <div className="border-bottom-0 text-center pt-4 pb-2 pl-4 pr-4" style={{ width: 400 }}>
                            <h4 className="font-Paytone ">Move selected notes to folder</h4>
                        </div>
                        <div className="modal-body pl-5 pr-5">
                            <select name="class" className="custom-select mb-2 mr-sm-3 pr-5" value={targetFolder} onChange={(e) => setTargetFolder(e.target.value)} >
                                {
                                    selectFolderRx && Object.keys(selectFolderRx).length > 0 &&
                                    <option defaultValue=''>None</option>
                                }
                                {allFolders.filter(x => x._id !== selectedFolder._id).map((item, index) =>
                                    <option value={item._id} key={index.toString()} >{item.Name}</option>
                                )}
                            </select>
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className="col-5 mr-auto">
                                    <button type="button" className="btn btn-light font-Paytone btn-block" onClick={() => hideMoveNoteModal(false)}>Cancel</button>
                                </div>
                                <div className="col-5">
                                    <button type="button" className="btn btn-light font-Paytone float-right btn-block" onClick={moveNote}>Move</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Modal>
        </>
    )
}
