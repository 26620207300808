import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom'
import { Modal } from "react-bootstrap"
import API from "../../utils/Api"
import * as Fc from "../../utils/Functions"

import { useDispatch } from 'react-redux'
import * as type from "./../../constants/actionType"

function ModalShowInfoUser(props) {
    const { isShowInfoUser, onHideInfoUser, idUser, typeUser } = props
    const [userInfo, setUserInfo] = React.useState({})
    const dispatch = useDispatch();
    const matchUrl = useRouteMatch()
    let operatorId = Fc.getRole() === "VENDOR" ? matchUrl.params.id : Fc.getUserId()

    React.useEffect(() => {
        if (typeUser === "Student") {
            API.getStudentDetail(idUser)
                .then((res) => {
                    if (res.status === 200 && res.data.Success) {
                        setUserInfo(res.data.Data);
                    }
                })
                .catch((err) => {
                    Fc.showError(err)
                })
        } else {
            API.getTeacherDetail(idUser)
                .then((res) => {
                    if (res.status === 200 && res.data.Success) {
                        setUserInfo(res.data.Data);
                    }
                })
                .catch((err) => {
                    Fc.showError(err)
                })
        }
    }, [idUser])

    function saveUserToRedux(user) {
        dispatch({ type: type.SAVE_USER_INFO, payload: user })
    }
    if (userInfo.FirstName) {
         console.log(userInfo.FirstName.charAt(0).toUpperCase() + userInfo.FirstName.slice(1)+' '+userInfo.LastName.charAt(0).toUpperCase() + userInfo.LastName.slice(1));
    }
    return (
        <>
            {/* Modal show info student */}
            <Modal
                show={isShowInfoUser}
                onHide={onHideInfoUser}
                className="modal fade"
                id="myModal"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-epen" style={{ pointerEvents: 'auto' }}>
                        <div className="modal-body pl-5 pr-5">
                            {typeUser === "Student" && <h4 className="modal-title font-Paytone">{userInfo.FullName && userInfo.FirstName.charAt(0).toUpperCase() + userInfo.FirstName.slice(1)+' '+userInfo.LastName.charAt(0).toUpperCase() + userInfo.LastName.slice(1)}
                             {/* - {userInfo.Code} */}
                             </h4>}
                            {typeUser === "Teacher" && <h4 className="modal-title font-Paytone">{userInfo.FullName && userInfo.FirstName.charAt(0).toUpperCase() + userInfo.FirstName.slice(1)+' '+userInfo.LastName.charAt(0).toUpperCase() + userInfo.LastName.slice(1)}
                             {/* - {userInfo.Code} */}
                            </h4>}
                            <p>
                                Gender: {userInfo.Gender} <br />
                                {typeUser === "Student" && <>Grade: {userInfo.Grade} <br /></>}
                                Comment: {userInfo.Comment}<br />
                            </p>
                            {typeUser === "Student" && (
                                <>
                                    <h4 className="modal-title font-Paytone">Parent/Guardian Info:</h4>
                                    <p>
                                        Name: {userInfo.ParentInfo && userInfo.ParentInfo.Name} <br />
                                        Email: {userInfo.ParentInfo && userInfo.ParentInfo.Email} <br />
                                        Phone: {userInfo.ParentInfo && userInfo.ParentInfo.PhoneNumber}
                                    </p>
                                </>
                            )}
                            {typeUser === "Teacher" && (
                                <>
                                    <h4 className="modal-title font-Paytone">Additional Information:</h4>
                                    <p>
                                        Email: {userInfo && userInfo.Email} <br />
                                        Phone: {userInfo && userInfo.PhoneNumber}
                                    </p>
                                </>
                            )}
                        </div>
                        <div className="modal-footer block border-top-0 pb-5 pl-5 pr-5">
                            <div className="row">
                                <div className={(Fc.getRole() === "VENDOR" || Fc.getRole() === "OPERATOR" ? 'col-5 mr-auto' : "col-5 mr-auto  ml-auto")}>
                                    <button
                                        onClick={() => { onHideInfoUser() }}
                                        className="btn btn-light font-Paytone btn-block"
                                    >Close</button>
                                </div>
                                {(Fc.getRole() === "VENDOR" || Fc.getRole() === "OPERATOR") &&
                                    <div className="col-5">
                                        <Link
                                            to={Fc.getRole() === "VENDOR" ? `/Vendor/ManageClient/${operatorId}/Accounts` : '/operator/Accounts'}
                                            className="btn btn-light font-Paytone float-right btn-block"
                                            onClick={() =>
                                                saveUserToRedux(userInfo)
                                            }
                                        >Edit</Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default React.memo(ModalShowInfoUser);