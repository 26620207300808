import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import { cloneDeep, filter, sortBy } from "lodash"
import * as Const from "../../../constants/Const";
import * as UrlConst from "../../../constants/UrlConst";
import API from "../../../utils/Api";
import * as Fc from "../../../utils/Functions";
import io from "socket.io-client";
import LayoutComponent from "./LayoutComponent";
import { useDispatch, useSelector } from 'react-redux';
import useDidMount from '../../../hooks/useDidMount'
import * as type from './../../../constants/actionType'

let socket = {};

function _Layout(props) {
  const dispatch = useDispatch();
  let didMount = useDidMount()

  const [operatorInfo, setOperatorInfo] = useState({})
  const [openPopup, setOpenPopup] = useState(false)

  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  // const [allRoom, setAllRoom] = useState([])

  const [listClass, setListClass] = useState([])
  const [ClassId, setClassId] = useState("")
  const [ListStudent, setListStudent] = useState([])
  const [ListTeacher, setListTeacher] = useState([])
  const [selectedId, setSelectedId] = useState("")
  const [room, setRoom] = useState("")
  const [tabChat, setTabChat] = useState('ChatClass')
  const [userType, setUserType] = useState("")
  const [userName, setUserName] = useState("")
  
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  let messageTemp = useRef([])
  let currentChatTmp = useRef(null)
  let pageIndexRef = useRef(1)
  let lstNotifTmp = useRef([])
  let timerLstMsgGr = null
  let timerLstMsgPsn = null
  let isLoadTmp = useRef(false)

  let role = Fc.getRole()
  let matchUrl = useRouteMatch();

  let messageEndRef = useRef(null)
  let userLoginIdRef = useRef("")

  const scrollToBottom = () => {
    messageEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const [primaryTeacherId, setPrimaryTeacherId] = useState("")

  // GET LIST CHAT ROOM
  let allRoom = useSelector(state => state.ws.listRoom)
  let countNotify = useSelector(state => state.ws.countNotif)
  let listNotifRedux = useSelector(state => state.ws.notify)
  let currentChatRx = useSelector(state => state.ws.currentChat)
  let userLogin = useSelector(state => state.ws.userLogin)
  let token = useSelector(state => state.ws.token)
  const isLoading = useSelector(state => state.ws.isLoading)

  //orientation
  let isLandscape = useSelector(state => state.ws.isLandscape)

  useEffect(() => {
    if(!currentChatRx) {
      setMessageList([])
      setListStudent([])
      setListTeacher([])
      setClassId("")
      setSelectedId("")
      setTabChat("ChatClass")
      currentChatTmp.current = {tabChat: null, classId: null, fromId: null}
      return
    }
    currentChatTmp.current = currentChatRx
  }, [currentChatRx])

  useEffect(() => {
    if(userLogin)
    userLoginIdRef.current = userLogin._id
    return () => { userLoginIdRef.current = "" }
  }, [userLogin])

  useEffect(() => {
    window.onorientationchange = function (event) {
      switch (window.orientation) {
        case 0:
          dispatch({ type: type.CHANGE_ORIENTATION, payload: false });
          break;

        case -90:
          dispatch({ type: type.CHANGE_ORIENTATION, payload: true });
          break;

        case 90:
          dispatch({ type: type.CHANGE_ORIENTATION, payload: true });
          break;

        case 180:
          dispatch({ type: type.CHANGE_ORIENTATION, payload: false });

        case 270:
          dispatch({ type: type.CHANGE_ORIENTATION, payload: true });

        case 360:
          dispatch({ type: type.CHANGE_ORIENTATION, payload: false });
          break;
      }
    };
  })

  function onScrollTop() {
    if(isLoadTmp.current) return

    pageIndexRef.current = pageIndexRef.current + 1
    if(currentChatTmp.current && currentChatTmp.current.tabChat === "class" && pageIndexRef.current > 1) {
      getListMessageInGroup(currentChatTmp.current.classId, pageIndexRef.current)
      dispatch({ type: type.SAVE_IS_LOADING, payload: true})
    }

    if(currentChatTmp.current && (currentChatTmp.current.tabChat === "person" || currentChatTmp.current.tabChat === "admin") && pageIndexRef.current > 1) {
      GetChatMessages(Fc.getUserId(), currentChatRx.fromId, pageIndexRef.current)
      dispatch({ type: type.SAVE_IS_LOADING, payload: true})
    }
  }

  useEffect(() => {
    isLoadTmp.current = isLoading
  }, [isLoading])

  useEffect(() => {
    dispatch({ type: type.SAVE_CURRENT_CHAT, payload: null });

    /**
    |-------------------------------------------------------------------------------------------
    | kiem tra socket 5s 1 lan 
    |-------------------------------------------------------------------------------------------
    */
    let timeInterval = setInterval(() => {
      if(!socket || Object.keys(socket).length == 0 || !socket.connected) {
        socket = io(UrlConst.CONNECTION_PORT, {transports: [ "websocket" ]}).connect();

        if (Fc.getRole() === Const.Role.TEACHER) {
          socket.emit('join_all_room', allRoom)
          socket.emit('join_room', `${Fc.getOperator()}|${Fc.getUserId()}`)
        }
      }
    }, 4999)
    //==========================================================================================

    if(!socket || Object.keys(socket).length == 0 || !socket.connected)
    socket = io(UrlConst.CONNECTION_PORT, {transports: [ "websocket" ]}).connect();

    //JOIN ALL ROOM
    if (Fc.getRole() === Const.Role.TEACHER) {
      socket.emit('join_all_room', allRoom)
      socket.emit('join_room', `${Fc.getOperator()}|${Fc.getUserId()}`)
    }

    socket.on('show_notification', (data) => {
      if (data.From !== Fc.getUserId() && data.ToRole === Const.Role.TEACHER || data.ToRole === "CLASS") {

        // seen message when operator send message, and teacher is on current contact Operaton tab chat
        if(currentChatTmp.current && currentChatTmp.current.tabChat === "admin" && data.Type !== "groupchat" && data.ClassId === currentChatTmp.current.classId && data.From === currentChatTmp.current.fromId ){
          return
        }
        //khong hien thi notification cua lop chat khi dang o lop do
        if(currentChatTmp.current && currentChatTmp.current.tabChat === "class" && data.Type === "groupchat" && data.ClassId === currentChatTmp.current.classId ) {
          return
        }

        if(currentChatTmp.current && currentChatTmp.current.tabChat === "person" && data.ClassId === currentChatTmp.current.classId && data.From === currentChatTmp.current.fromId ) {
          return
        }

        let a = lstNotifTmp.current.some(x => (x.From === data.From  && x.To === data.To))
        if (a === true) {
          let index = lstNotifTmp.current.findIndex(item => item.From === data.From && item.To === data.To)
          lstNotifTmp.current.splice(index, 1)
        }
        lstNotifTmp.current.push(data)
        dispatch({ type: type.SAVE_NOTIFY, payload: cloneDeep(lstNotifTmp.current) })
      }

      //dong bo tin nhan khi 1 user dang nhap tren 2 thiet bi
      if (data.From == Fc.getUserId()) {
        if(currentChatTmp.current && currentChatTmp.current.tabChat === "person" && data.ClassId === currentChatTmp.current.classId && data.To === currentChatTmp.current.fromId ) {
          messageTemp.current.push(data)
          setMessageList([...messageTemp.current]);
          return
        } 
        
        if(currentChatTmp.current && currentChatTmp.current.tabChat === "admin" && data.Type !== "groupchat" && data.To === currentChatTmp.current.fromId ){
          messageTemp.current.push(data)
          setMessageList([...messageTemp.current]);
          return
        }

        //khong hien thi notification cua lop chat khi dang o lop do
        if(currentChatTmp.current && currentChatTmp.current.tabChat === "class" && data.Type === "groupchat" && data.ClassId === currentChatTmp.current.classId ) {
          messageTemp.current.push(data)
          setMessageList([...messageTemp.current]);
          return
        } 
      }
    });

    // Get Notifi in DB
    Fc.getRole() === Const.Role.TEACHER && getListNotif()

    socket.on('notif_message', (data) => {

    });

    window.toogleMenuEvent()
    API.getAccountInfo().then(res => {
      if (res.status === 200 && res.data.Success) {
        setOperatorInfo(res.data.Data.DetailUserInfo.Operator)
        setListClass(res.data.Data.DetailUserInfo.Classes);
      } else {
        if (res.data.Message)
          Fc.showError(res.data.Message)
        else
          Fc.showError(res.statusText)
      }
    }).catch(err => {
      Fc.showError("Can not connect to server")
    })

    window.addEventListener('focus', () => {
      socket.emit('join_all_room', allRoom)

      if (!socket || Object.keys(socket).length == 0 || !socket.connected)
        socket = io(UrlConst.CONNECTION_PORT, {transports: [ "websocket" ]}).connect();

        // JOIN ALL ROOM
        if (Fc.getRole() === Const.Role.TEACHER) {
          socket.emit('join_all_room', allRoom)
          socket.emit('join_all_room', allRoom)
          socket.emit('join_room', `${Fc.getOperator()}|${Fc.getUserId()}`)
        }
    
      Fc.getRole() === Const.Role.TEACHER && getListNotif()
    })
    return () => {
      clearInterval(timeInterval)
      window.removeEventListener('focus', () => console.log('remove listener focus'))
      if(timerLstMsgGr) clearTimeout(timerLstMsgGr)
      if(timerLstMsgPsn) clearTimeout(timerLstMsgPsn)
    }
  }, [])

  useEffect(() => {
    //JOIN ALL ROOM
    if (Fc.getRole() === Const.Role.TEACHER) {
      socket.emit('join_all_room', allRoom)
      socket.emit('join_room', `${Fc.getOperator()}|${Fc.getUserId()}`)
    }
  })

  useEffect(() => {
    lstNotifTmp.current = listNotifRedux
    if (listNotifRedux && listNotifRedux.length >= 0) {
      dispatch({ type: type.COUNT_NOTIFY, payload: listNotifRedux.length })
    }
  }, [listNotifRedux])

  // GET LIST STUDENT TEACHER IN CLASS
  useEffect(() => {
    setPageIndex(1)
    dispatch({ type: type.SAVE_IS_LOADING, payload: false})
    pageIndexRef.current = 1

    //lan dau render ma curenChatRx ton tai thi gan ve gia tri mac dinh
    if(!didMount && currentChatRx) {
      dispatch({ type: type.SAVE_CURRENT_CHAT, payload: null });
      return
    }

    if(!currentChatRx || Object.keys(currentChatRx).length  === 0 ) {
      setMessageList([])
      setTabChat("ChatClass")
      return 
    }

    setMessageList([])

    if (currentChatRx.tabChat === 'class' && Fc.getRole() === Const.Role.TEACHER) {
      currentChatRx.classId &&  getDetailClass(currentChatRx.classId)

      // JOIN CLASS ROOM CHAT
      socket.emit("join_room", `${currentChatRx.classId}`);
      setRoom(`${currentChatRx.classId}`)

      // Get Message in group with role Teacher
      getListMessageInGroup(currentChatRx.classId, pageIndexRef.current, 
        () =>  { 
          setTabChat("ChatClass")
          getDetailClass(currentChatRx.classId)
        }
      )

    }

    if((currentChatRx.tabChat === 'person') && Fc.getRole() === Const.Role.TEACHER){
      const selectedId = currentChatRx.fromId || null
      if(!selectedId) return 

      socket.emit("join_room", `${Fc.getUserId()}|${selectedId}`);
      socket.emit("join_room", `${selectedId}|${Fc.getUserId()}`);
      setRoom(`${Fc.getUserId()}|${selectedId}`)
 
      GetChatMessages(selectedId, Fc.getUserId(), pageIndexRef.current, 
        () => { 
          setTabChat("ChatPersion");
          getDetailClass(currentChatRx.classId, () => setSelectedId(selectedId)); 
        }
      )
    }

    if((currentChatRx.tabChat === 'admin') && Fc.getRole() === Const.Role.TEACHER){
      const selectedId = currentChatRx.fromId || null

      if(!selectedId) return 

      socket.emit("join_room", `${Fc.getUserId()}|${selectedId}`);
      socket.emit("join_room", `${selectedId}|${Fc.getUserId()}`);
      socket.emit("join_room", `${selectedId}`);
      // setRoom(`${Fc.getUserId()}|${selectedId}`) 
      setRoom(`${selectedId}|${Fc.getUserId()}`) 

      GetChatMessages(Fc.getUserId(), Fc.getOperator(), pageIndexRef.current, 
        () => { 
          setTabChat("contactAdmin")
          getDetailClass(currentChatRx.classId); 
        }
      )
    }

  }, [currentChatRx])
  // END

  function getDetailClass(ClassId, callback = null) {
    API.detailClass(ClassId).then(res => {
      if (res.status === 200 && res.data.Success) {
        let primaryTeacher = res.data.Data.PrimaryTeacher
        setPrimaryTeacherId(primaryTeacher._id);
        let AssistantTeachers = res.data.Data.AssistantTeachers
        AssistantTeachers.push(primaryTeacher)
        let teachers = AssistantTeachers.filter(x => x._id !== Fc.getUserId())
        setListTeacher(teachers);
        setListStudent(res.data.Data.Students)
        if(callback) callback()
      } else {
        if (res.data.Message)
          Fc.showError(res.data.Message)
        else
          Fc.showError(res.statusText)
      }
    }).catch(err => {
      Fc.showError("Cant not connect to server")
    })
  }

  function delNotification(from, to, userId, callback = null) {
    API.DeleteNotification(from, to, userId)
    .then((res) => {
        if (res.data.Success) {
          if(callback) callback()
        } 
    }).catch((err) => {
        // Fc.showError("Can not connect to server")
    })
  }

  useEffect(() => {
    (messageList && messageEndRef.current && pageIndexRef.current === 1) && scrollToBottom()
    messageTemp.current = messageList
  }, [messageList])

  useEffect(() => {
    let timerDelNotif = null

    // if(!socket || Object.keys(socket).length == 0) return

    socket.on("receive_message_1", (data) => {
      if(!currentChatTmp.current || !currentChatTmp.current.tabChat ) return 

      if (data.Type === "groupchat") {
        if (currentChatTmp.current && data.To === currentChatTmp.current.classId && !currentChatTmp.current.fromId) {
          pageIndexRef.current = 1
          messageTemp.current.push(data)
          setMessageList([...messageTemp.current]);

          timerDelNotif = setTimeout(() => {
            delNotification(data.From, data.To, userLoginIdRef.current)
          }, 1000)
        }

      } else {
        //chan hien thi tin nhan ko dung tab, ko dung ng dung
        if(!currentChatTmp.current || (currentChatTmp.current.tabChat === 'person' && currentChatTmp.current.fromId !== data.From)) return

        if(!currentChatTmp.current || (currentChatTmp.current.tabChat === 'class')) return

        if (data.From === currentChatTmp.current.fromId || data.From === Fc.getOperator()) {
          pageIndexRef.current = 1
          messageTemp.current.push(data)
          setMessageList([...messageTemp.current]);

          timerDelNotif = setTimeout(() => {
            delNotification(data.From, data.To, userLoginIdRef.current)
          }, 1000)
        }
      }
    });

    return () => { if(timerDelNotif) clearTimeout(timerDelNotif)}
  }, [])

  // GetList Notif
  function getListNotif(from = null, fromType = null) {
    if(!token) return 

    API.GetListNotification(Fc.getUserId(), Fc.getRole(), 1, 0, "").then((res) => {
      if (res.status === 200 && res.data.Success) {
        dispatch({ type: type.SAVE_NOTIFY, payload: res.data.Data })
        dispatch({ type: type.COUNT_NOTIFY, payload: (listNotifRedux && listNotifRedux.length > 0) ? listNotifRedux.length : 0 })
      } else {
        if (res.data.Message) {
          // Fc.showError(res.data.Message)
        }
        else {
          // Fc.showError(res.statusText)
        }
      }
    }).catch((err) => {
      // Fc.showError("Can not connect to server")
    })
  }
  // Get List Message Group
  function getListMessageInGroup(classID, pageIndex1 = 1, callback = null) {
    dispatch({ type: type.SAVE_IS_LOADING, payload: true})

    API.GetChatMessagesInGroup(classID, '', pageSize, pageIndex1)
      .then((res) => {
        if (res.status === 200 && res.data.Success) {
          const sortData = sortBy(res.data.Data, "CreatedDate")
          if(sortData.length  === 0) dispatch({ type: type.SAVE_IS_LOADING, payload: true})

          if(sortData.length > 0) {
            sortData.forEach(item => messageTemp.current.push(item))
            timerLstMsgGr = setTimeout(() => {
              dispatch({ type: type.SAVE_IS_LOADING, payload: false})
            }, 1000)
          }

          setMessageList([...messageTemp.current]);
          if (callback !== null)
            callback()
        } else {
          timerLstMsgGr = setTimeout(() => {
            dispatch({ type: type.SAVE_IS_LOADING, payload: false})
          }, 1000)

          if (res.data.Message)
            Fc.showError(res.data.Message)
          else
            Fc.showError(res.statusText)
        }
      }).catch(err => {
        timerLstMsgGr = setTimeout(() => {
          dispatch({ type: type.SAVE_IS_LOADING, payload: false})
        }, 1000)

        Fc.showError("Cant not connect to server")
      })
  }

  // Get List Message Personal
  function GetChatMessages(from, to, pageIndex1 = 1, callback = null) {
    dispatch({ type: type.SAVE_IS_LOADING, payload: true})

    API.GetChatMessages(from, to, '', pageSize, pageIndex1)
      .then((res) => {
        if (res.status === 200 && res.data.Success) {
          const sortData = sortBy(res.data.Data, "CreatedDate")
          if(sortData.length  === 0) dispatch({ type: type.SAVE_IS_LOADING, payload: true})

          if(sortData.length > 0) {
            sortData.forEach(item => messageTemp.current.push(item))
            timerLstMsgPsn = setTimeout(() => {
              dispatch({ type: type.SAVE_IS_LOADING, payload: false})
            }, 1000)
          } 

          setMessageList([...messageTemp.current]);
          if (callback !== null)
            callback()
        } else {
          timerLstMsgPsn = setTimeout(() => {
            dispatch({ type: type.SAVE_IS_LOADING, payload: false})
          }, 1000)

          if (res.data.Message)
            Fc.showError(res.data.Message)
          else
            Fc.showError(res.statusText)
        }
      }).catch(err => {
        timerLstMsgPsn = setTimeout(() => {
          dispatch({ type: type.SAVE_IS_LOADING, payload: false})
        }, 1000)

        Fc.showError("Cant not connect to server")
      })
  }
  function hideMenu() {
    dispatch({ type: type.SAVE_PARAMS_SEARCH, payload: null })
    dispatch({ type: type.SET_ACTIVE_TAB_MYWORK, activeTab: "Workbook" })
    dispatch({ type: type.CHANGE_NOT_RELOAD, payload: false})
    dispatch({ type: type.SAVE_PAGEINDEX_WS, payload: null })
    dispatch({ type: type.SAVE_PAGEINDEX_NOTE, payload: 0 })

    document.getElementById("my_offcanvas1").classList.remove("show");
    document
      .getElementsByTagName("body")[0]
      .classList.remove("offcanvas-active");
    document
      .getElementsByClassName("screen-overlay")[0]
      .classList.remove("show");
  }

  function showMenu(){
    document.getElementById("my_offcanvas1").classList.add("show");
    document
      .getElementsByTagName("body")[0]
      .classList.add("offcanvas-active");
    document
      .getElementsByClassName("screen-overlay")[0]
      .classList.add("show");
  }

  function logOut(e) {
    if (socket) socket.disconnect()
    // console.log('soket teacher: ', socket)
    e.preventDefault();
    Fc.setToken("");
    Fc.setRole("");
    Fc.setAccountMethod("")
    Fc.setOperator("");
    clearDataRedux();
    props.history.push("/");
  }

  function seenMessage(userSendId, UserSeenId) {
    API.SeenMessage(userSendId, UserSeenId)
      .then((res) => {
      }).catch(err => {
        Fc.showError("Cant not connect to server")
      })
  }

  function clearDataRedux() {
    dispatch({ type: type.SAVE_USER_INFO, payload: {} })
    dispatch({ type: type.SAVE_CLASS_ID, classId: '' })
    dispatch({ type: type.SELECTWS, payload: [] })
    dispatch({ type: type.SET_STUDENT_ID, studentId: "" })
    dispatch({ type: type.SAVE_TOKEN, payload: "" })
    dispatch({ type: type.SAVE_ROLE, payload: [] })
    dispatch({ type: type.SAVE_PARAMS_SEARCH, payload: null })
    dispatch({ type: type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Exercise' })
    dispatch({ type: type.SET_ACTIVE_TAB_MYWORK, activeTab: "Workbook" })
    dispatch({ type: type.SAVE_ASSIGNMENT_DATE, payload: ""})
    dispatch({ type: type.CHANGE_NOT_RELOAD, payload: false})
    dispatch({ type: type.PERMISSION_WRITENOTE, payload: false })
    dispatch({ type: type.PERMISSION_SOLUTION, payload: false })
    dispatch({ type: type.SAVE_STUDENT_CLASS_LIST, payload: []})
    dispatch({ type: type.SAVE_CURRENT_CHAT, payload: null });
    dispatch({ type: type.SAVE_LIST_ROOM, payload: [] });
    dispatch({ type: type.SAVE_USER_LOGIN, payload: {} });
    dispatch({ type: type.SAVE_PAGEINDEX_WS, payload: null })
    dispatch({ type: type.SAVE_PAGEINDEX_NOTE, payload: 0 })
  }

  return (
    <LayoutComponent
      matchUrl={matchUrl}
      hideMenu={hideMenu}
      showMenu={showMenu}
      logOut={logOut}
      operatorInfo={operatorInfo}
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      socket={socket}
      message={message}
      role={role}
      setMessage={setMessage}
      listClass={listClass}
      messageList={messageList}
      setMessageList={setMessageList}
      ClassId={ClassId}
      setClassId={setClassId}
      ListStudent={ListStudent}
      ListTeacher={ListTeacher}
      setSelectedId={setSelectedId}
      selectedId={selectedId}
      room={room}
      tabChat={tabChat} setTabChat={setTabChat}
      userType={userType} setUserType={setUserType}
      userName={userName} setUserName={setUserName}
      primaryTeacherId={primaryTeacherId}
      getListNotif={getListNotif}
      scrollToBottom={scrollToBottom}
      messageEndRef={messageEndRef}
      getListMessageInGroup={getListMessageInGroup}
      GetChatMessages={GetChatMessages}
      getDetailClass={getDetailClass}
      setPageIndex={setPageIndex}
      onScrollTop={onScrollTop}
      pageIndex={pageIndex}
      {...props} />
  );
}

export default _Layout;
