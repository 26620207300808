import React from 'react';

function LayoutSearchDesktop(props) {
    let {
        username,
        listCategories, listLevel, ListSubject,
        resetForm, handleSubmit,
        ChangeLevel,
        keyword, setKeyword,
        ChangeCategories, ChangeSubject, ChangeBookName,
        bookNameRef, categoryRef, subjectRef, levelRef
    } = props
    return (
        <>
            <section className="box-xam py-2 mb-3 tran-60">
                <div className="row align-items-center">
                    <div className="col-auto mr-auto">
                        {username ? username : 'UserID/Email'}
                    </div>
                    <div className="col-auto">
                        <form className="form-inline" onSubmit={handleSubmit}>
                            <select name="Category" className="custom-select mr-sm-2 pr-5a" ref={categoryRef} onChange={ChangeCategories}>
                                <option value="">All Categories</option>
                                {listCategories && listCategories.map((item, index) => {
                                    return ( <option value={item.value} key={index.toString()}>{item.label}</option> )
                                })}
                            </select>
                            <select name="Subject" className="custom-select mr-sm-2 pr-5a" ref={subjectRef} onChange={ChangeSubject}>
                                <option value="">All Subject</option>
                                {ListSubject && ListSubject.map((item, index) => {
                                    return ( <option value={item.value} key={index.toString()}>{item.label}</option> )
                                })}
                            </select>
                            <select name="Subject" className="custom-select mr-sm-2 pr-5a" ref={levelRef} onChange={ChangeLevel}>
                                <option value="">All Level</option>
                                {listLevel && listLevel.map((item, index) => {
                                    return ( <option value={item.value} key={index.toString()}>{item.label}</option> )
                                })}
                            </select>
                            <input type="text" name="bookName" id="bookName" className="form-control mr-sm-2" placeholder="Enter book's name..."
                                ref={bookNameRef}
                                onChange={ChangeBookName}
                            />
                            <input type="text" name="keyword" id="keyword" className="form-control mr-sm-2" placeholder="Enter keywords..."
                                value={keyword}
                                onChange={(e) => { setKeyword(e.target.value) }}
                            />

                            <button type="submit" className="btn btn-light font-Paytone mr-sm-2">Search</button>
                            <button onClick={resetForm} type="button" className="btn font-Paytone text-white">Reset</button>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}

export default LayoutSearchDesktop;