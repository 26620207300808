/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import API from "../../../utils/Api";
import * as Fc from "../../../utils/Functions";
import * as Const from "../../../constants/Const";
import ClassView from './ClassView';
import ClassViewDesktop from './ClassViewDesktop';
import { isDesktop, isMobile } from "react-device-detect";

import { useDispatch, useSelector } from 'react-redux'
import * as type from "../../../constants/actionType"

function Class(props) {
  const matchUrl = useRouteMatch()
  const dispatch = useDispatch();
  const accountMethod = Fc.getAccountMethod()
  const operatorId = Fc.getRole().includes(Const.Role.VENDOR) ? props.match.params.id : Fc.getOperator()
  let history = useHistory();

  const [userInfo, setUserInfo] = useState({})
  const [classId, setClassId] = useState(props.match.params.classId)
  const [listClass, setListClass] = useState([])
  const [classInfo, setClassInfo] = useState({})
  const [listStudents, setListStudents] = useState([])
  const [firstClassId, setFirstClassId] = useState("")
  const [isShowRestrictedModal, setShowRestrictedModal] = useState(false)
  const [firstStudentId, setFirstStudentId] = useState("")
  const [message, setMessage] = useState("")

  const [enableAll, setEnableAll] = useState(false)
  const [selectAll, setSelectAll] = useState(false)

  const [isShowInfoUser, setShowInfoUser] = useState(false)
  const [idUser, setIdStudent] = useState("")
  const [typeUser, setTypeUser] = useState("")

  const [exceedIds, setExceedIds] = useState([])
  const [listTeachers, setListTeachers] = useState([])
  const [primaryTeacher, setPrimaryTeacher] = useState('')
  const [userOptions, setUserOptions] = useState([])
  const [selectedUser, setSelectedUser] = useState('')
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false)

  function onHideInfoUser() {
    setShowInfoUser(false)
  }


  useEffect(() => {
    getUserInfo()

    API.GetFirstClassOfOperator(operatorId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.Success) {
            setFirstClassId(res.data.Data._id);
          }
          else
            Fc.showError(res.data.Message)
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        Fc.showError(err)
      })

    API.GetFirstStudentAccount(operatorId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.Success) {
            setFirstStudentId(res.data.Data._id);
          }
          else
            Fc.showError(res.data.Message)
        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        Fc.showError(err)
      })

    API.getClassByPrimaryTeacher().then(res => {
      if (res.status === 200 && res.data.Success) {
        if (res.data.Data) {
          if (res.data.Data && res.data.Data.length > 0) {
            let classID = res.data.Data.sort(function (a, b) { return a.Code > b.Code ? 1 : -1 })
            setListClass(classID)
            !classId && setClassId(classID[0]._id)
            !classId && dispatch({ type: type.SAVE_CLASS_ID, classId: classID[0]._id})
          }
        }
      } else {
        console.log(res);
        Fc.showError(res.data.Message || res.statusText)
      }
    })

  }, [])

  function onHideRestrictedModal() {
    setShowRestrictedModal(false)
  }

  useEffect(() => {
    if (classId) {
      getClassInfo()
      history.push(`/Teacher/Classes/${classId}`)
    }
    setClassInfo({})
    setListStudents([])
    setEnableAll(false)
    setSelectAll(false)
  }, [classId])

  function setClass(e) {
    if (accountMethod === Const.AccountMethod.RESTRICTED && e.target.value !== firstClassId) {
      setMessage('This features is locked. Please contact your administrator for more infomation.')
      setShowRestrictedModal(true)
    }
    console.log('SV CL ID: ', e.target.value)
    setClassId(e.target.value)
    dispatch({ type: type.SAVE_CLASS_ID, classId: e.target.value})
  }

  function getUserInfo() {
    API.getAccountInfo().then(res => {
      if (res.status === 200 && res.data.Success) {
        let detail = res.data.Data.DetailUserInfo
        // if (detail.Classes && detail.Classes.length > 0) {
        //   detail.Classes = detail.Classes.sort(function (a, b) { return a.Code > b.Code ? 1 : -1 })
        //   !classId && setClassId(detail.Classes[0]._id)
        // }
        setUserInfo(detail)
      } else {
        console.log(res);
        Fc.showError(res.data.Message || res.statusText)
      }
    })
  }

  function getClassInfo() {
    API.detailClass(classId).then(res => {
      if (res.status === 200 && res.data.Success) {
        let data = res.data.Data
        data.AssistantTeachers = data.AssistantTeachers.sort(function (a, b) { return a.Code > b.Code ? 1 : -1 })
        // console.log('class info: ', operatorId,  data)
        // setClassInfo(data)
        setClassInfo({
          value: data._id,
          label: `${data.Name+' - '+data.Code}`,
          _id: data._id,
          Name: data.Name,
          PrimaryTeacherId: data.PrimaryTeacher._id,
          PrimaryTeacher: data.PrimaryTeacher,
          OperatorId: operatorId,
          Operator: data.Operator,
          AssistantTeacherIds: data.AssistantTeachers ? data.AssistantTeachers.map(item => item._id) : [],
          Students: data.Students,
          IsActive: data.IsActive,
          AssistantTeachers: data.AssistantTeachers
        })
        setPrimaryTeacher(data.PrimaryTeacher)
        setListStudents(data.Students.sort((a, b) => {
          if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
          if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
          return 0;
        }))
        setListTeachers(data.AssistantTeachers.sort((a, b) => {
          if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
          if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
          return 0;
        }))
      } else {
        console.log(res);
        Fc.showError(res.data.Message || res.statusText)
      }
    })
  }

  //#region solution
  function setEnableSolution(id, enable) {
    if (accountMethod === Const.AccountMethod.RESTRICTED && classId !== firstClassId) {
      setMessage('This feature is locked. Please contact your administrator for more infomation')
      setShowRestrictedModal(true)
      return
    }
    if (accountMethod === Const.AccountMethod.RESTRICTED && id !== firstStudentId) {
      setMessage('This feature is locked. Please contact your administrator for more infomation')
      setShowRestrictedModal(true)
      return
    }
    setEnableAll(false)
    let students = Fc.deepCopy(listStudents)
    let student = students.filter(x => x._id === id)[0]
    let submitStudents = []
    if (student.Checked) {
      let checkedStudents = students.filter(x => x.Checked === true)
      checkedStudents.map(item => {
        item.EnableSolution = enable
      })
      submitStudents = checkedStudents
    } else {
      student.EnableSolution = enable
      submitStudents.push(student)
    }
    onSetEnableSolution(submitStudents, students)
  }

  function setEnableAllSolution(enable) {
    if (accountMethod === Const.AccountMethod.RESTRICTED) {
      setMessage('This feature is locked. Please contact your administrator for more infomation')
      setShowRestrictedModal(true)
      return
    }
    if (!listStudents || listStudents.length === 0) {
      setEnableAll(false)
      return
    }
    setEnableAll(enable)
    let students = Fc.deepCopy(listStudents)
    students.map(item => {
      item.EnableSolution = enable
    })
    onSetEnableSolution(students)
  }

  //change view before submit, then revert if error
  function onSetEnableSolution(submitStudents, afterSubmitStudents) {
    let originStudents = Fc.deepCopy(listStudents)
    if (!afterSubmitStudents)
      afterSubmitStudents = submitStudents
    setListStudents(afterSubmitStudents)
    let body = {
      ClassId: classId,
      Students: submitStudents
    }
    // body.Students = students
    API.updateStudentSolution(body).then(res => {
      if (res.status === 200 && res.data.Success) {
        let data = res.data.Data
        setListStudents(data.Students.sort(function (a, b) { return a.Code > b.Code ? 1 : -1 }))
        setSelectAll(false)
      } else {
        setListStudents(originStudents)
        setEnableAll(false)
        if (res.data.Message)
          Fc.showError(res.data.Message)
        else
          Fc.showError(res.statusText)
      }
    }).catch(err => {
      setEnableAll(false)
      console.log(err);
      setListStudents(originStudents)
      Fc.showError("Can not connect to server")
    })
  }
  //#endregion

  function selectUser(id, selected) {
    setSelectAll(false)
    let students = Fc.deepCopy(listStudents)
    let student = students.filter(x => x._id === id)[0]
    student.Checked = selected
    setListStudents(students)
  }

  function selectAllUser(selected) {

    if (!listStudents || listStudents.length === 0) {
      setSelectAll(false)
      return
    }
    setSelectAll(selected)
    let students = Fc.deepCopy(listStudents)
    students.map(item => {
      item.Checked = selected
    })
    setListStudents(students)
  }

  // ==== Phase 2 ==== 

  useEffect(() => {
    let classID = { _id: '' }
    classID._id = props.match.params.classId
    props.match.params.classId && detailClass(classID)
  }, [props.match.params.classId])

  function detailClass(cl) {
    API.detailClass(cl._id).then(res => {
      if (res.status === 200) {
        if (res.data.Success) {
          let data = res.data.Data
          setQuickUpdateStates(data)
        } else
          Fc.showError(res.data.Message)
      } else {
        Fc.showError(res.statusText)
      }
    }).catch(err => {
      console.log(err)
      Fc.showError("Can not connect to server")
    })
  }

  useEffect(() => {
    let exceedStudents = listStudents.map(item => item._id)
    let exceedTeachers = listTeachers.map(item => item._id)
    let exceedUsers = exceedStudents.concat(exceedTeachers)
    if (primaryTeacher !== '')
      exceedUsers.push(primaryTeacher._id)
    setExceedIds(exceedUsers)
  }, [primaryTeacher, listTeachers, listStudents])

  function getListUser(val) {
    //chan ky tu dac biet
    if(val && val.length > 0 && typeof val == 'string' && (val.includes('/') || val.includes('\\'))) return 

    API.listUsers(operatorId, 1, 20, val, exceedIds).then(res => {
      if (res.status === 200) {
        if (res.data.Success) {
          let listOptions = []
          res.data.Data.Students && res.data.Data.Students.map(item => {
            item.Type = "Student"
            listOptions.push({ value: item._id, label: `${item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)} - ${item.Code}`, ...item })
          })
          res.data.Data.Teachers && res.data.Data.Teachers.map(item => {
            item.Type = "Teacher"
            listOptions.push({ value: item._id, label: `${item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)} - ${item.Code}`, ...item })
          })
          setUserOptions(listOptions)
        }
        else
          Fc.showError(res.data.Message)
      } else {
        Fc.showError(res.statusText)
      }
    }).catch(err => console.log(err))
  }

  function selectedUserForAddClass(user) {
    // if (accountMethod === Const.AccountMethod.RESTRICTED && user.Type === "Student" && firstStudentId !== user._id) {
    //     showRestrictionMessage("This student account has been locked.")
    // }
    // if (accountMethod === Const.AccountMethod.RESTRICTED && user.Type === "Teacher" && firstTeacherId !== user._id) {
    //     showRestrictionMessage("This teacher account has been locked.")
    // } else {
    //     setSelectedUser(user)
    // }
    setSelectedUser(user)
  }

  //set some state always reload after update
  function setQuickUpdateStates(data) {
    setClassInfo({
      value: data._id,
      label: `${data.Name+' - '+data.Code}`,
      _id: data._id,
      Name: data.Name,
      PrimaryTeacherId: data.PrimaryTeacher._id,
      OperatorId: operatorId,
      AssistantTeacherIds: data.AssistantTeachers ? data.AssistantTeachers.map(item => item._id) : [],
      Students: data.Students,
      IsActive: data.IsActive,
      AssistantTeachers: data.AssistantTeachers
    })
    setListStudents(data.Students.sort(function (a, b) { return a.Code > b.Code ? 1 : -1 }))
    setListTeachers(data.AssistantTeachers.sort(function (a, b) { return a.Code > b.Code ? 1 : -1 }))
  }

  //#region add-remove user
  function onAddUser() {
    if (!classInfo || classInfo === '' || classInfo === {})
      return
    // if (accountMethod === Const.AccountMethod.RESTRICTED && selectedUser) {
    //   showRestrictionMessage("You need to upgrade to use this features")
    //   return
    // }

    // if (accountMethod === Const.AccountMethod.TRIALS && selectedUser !== '') {
    //   if (selectedUser.Type === 'Teacher') {
    //     setMessageDayleftTeacherOrStudent("This teacher account will be blocked after " + dayLeft + " days")
    //     setShowTrialModal(true)
    //   }
    //   // if (selectedUser.Type === 'Student') {
    //   //     setMessageDayleftTeacherOrStudent("This student account will be blocked after " + dayLeft + " days")
    //   //     setShowTrialModal(true)
    //   // }
    // }
    setSelectedUser('')

    let body = Fc.deepCopy(classInfo)
    // console.log('body before: ', body)
    //change view before submit, then revert if error
    if (selectedUser.Type === 'Student') {
      if (listStudents.filter(x => x._id === selectedUser._id).length > 0)
        return
      let students = [...listStudents]
      students.push(selectedUser)
      setListStudents(students.sort(function (a, b) { return a.Code > b.Code ? 1 : -1 }))
      body.Students.push(selectedUser)
    }
    if (selectedUser.Type === 'Teacher') {
      if (listTeachers.filter(x => x._id === selectedUser._id).length > 0 || primaryTeacher._id === selectedUser._id)
        return
      let teachers = [...listTeachers]
      teachers.push(selectedUser)
      setListTeachers(teachers.sort(function (a, b) { return a.Code > b.Code ? 1 : -1 }))
      body.AssistantTeacherIds.push(selectedUser._id)
    }

    API.UpdateClass(body).then(res => {
      // console.log('Body: ', body)
      if (res.status === 200 && res.data.Success) {
        setQuickUpdateStates(res.data.Data)
        Fc.showSuccess('Update class success')
      } else {
        //revert data
        if (selectedUser.Type === 'Student') {
          setListStudents(listStudents.filter(x => x._id !== selectedUser._id))
        }
        if (selectedUser.Type === 'Teacher') {
          setListTeachers(listTeachers.filter(x => x._id !== selectedUser._id))
        }
        if (res.data.Message)
          Fc.showError(res.data.Message)
        else
          Fc.showError(res.statusText)
      }
    })
  }

  function onShowRemoveUserModal() {
    let checkedStudents = listStudents ? [...listStudents].filter(x => x.Checked === true) : []
    let checkedTeachers = listTeachers ? [...listTeachers].filter(x => x.Checked === true) : []
    if (checkedStudents.length === 0 && checkedTeachers.length === 0)
      return
    setShowRemoveUserModal(true)
  }

  function hideRemoveUserModal() {
    setShowRemoveUserModal(false)
  }

  function removeUser() {
    // if (accountMethod === 'Restricted') {
    //   showRestrictionMessage("You need to upgrade to use this features")
    //   hideRemoveUserModal()
    //   return
    // }
    let checkedStudents = listStudents ? [...listStudents].filter(x => x.Checked === true) : []
    let checkedTeachers = listTeachers ? [...listTeachers].filter(x => x.Checked === true) : []
    if (checkedStudents.length === 0 && checkedTeachers.length === 0)
      return

    let body = Fc.deepCopy(classInfo)
    checkedStudents.length > 0 && checkedStudents.map(item => {
      body.Students = body.Students.filter(x => x._id !== item._id)
    })
    checkedTeachers.length > 0 && checkedTeachers.map(item => {
      body.AssistantTeacherIds = body.AssistantTeacherIds.filter(x => x !== item._id)
    })
    API.UpdateClass(body).then(res => {
      if (res.status === 200) {
        if (res.data.Success) {
          setQuickUpdateStates(res.data.Data)
          setSelectAll(false)
        } else
          Fc.showError(res.data.Message)
      } else {
        Fc.showError(res.statusText)
      }
    })
    setShowRemoveUserModal(false)
  }

  if (isDesktop && !isMobile) {
    return (
      <ClassViewDesktop
        userInfo={userInfo}
        classId={classId} setClassId={setClassId}
        classInfo={classInfo}
        listStudents={listStudents}
        enableAll={enableAll} setEnableAllSolution={setEnableAllSolution} setEnableSolution={setEnableSolution}
        selectAll={selectAll} selectAllUser={selectAllUser} selectUser={selectUser}
        setClass={setClass}
        onHideRestrictedModal={onHideRestrictedModal}
        isShowRestrictedModal={isShowRestrictedModal}
        firstStudentId={firstStudentId}
        message={message} setMessage={setMessage}
        setShowRestrictedModal={setShowRestrictedModal}
        listClass={listClass}
        isShowInfoUser={isShowInfoUser} setShowInfoUser={setShowInfoUser}
        onHideInfoUser={onHideInfoUser}
        setIdStudent={setIdStudent} idUser={idUser}
        setTypeUser={setTypeUser} typeUser={typeUser}

        getListUser={getListUser} userOptions={userOptions}
        selectedUserForAddClass={selectedUserForAddClass} selectedUser={selectedUser}
        onAddUser={onAddUser} onShowRemoveUserModal={onShowRemoveUserModal}
        hideRemoveUserModal={hideRemoveUserModal} removeUser={removeUser}
        showRemoveUserModal={showRemoveUserModal}
        {...props}
      />
    )
  }

  return (
    <ClassView
      userInfo={userInfo}
      classId={classId} setClassId={setClassId}
      classInfo={classInfo}
      listStudents={listStudents}
      enableAll={enableAll} setEnableAllSolution={setEnableAllSolution} setEnableSolution={setEnableSolution}
      selectAll={selectAll} selectAllUser={selectAllUser} selectUser={selectUser}
      setClass={setClass}
      onHideRestrictedModal={onHideRestrictedModal}
      isShowRestrictedModal={isShowRestrictedModal}
      firstStudentId={firstStudentId}
      message={message} setMessage={setMessage}
      setShowRestrictedModal={setShowRestrictedModal}
      listClass={listClass}
      isShowInfoUser={isShowInfoUser} setShowInfoUser={setShowInfoUser}
      onHideInfoUser={onHideInfoUser}
      setIdStudent={setIdStudent} idUser={idUser}
      setTypeUser={setTypeUser} typeUser={typeUser}

      getListUser={getListUser} userOptions={userOptions}
      selectedUserForAddClass={selectedUserForAddClass} selectedUser={selectedUser}
      onAddUser={onAddUser} onShowRemoveUserModal={onShowRemoveUserModal}
      hideRemoveUserModal={hideRemoveUserModal} removeUser={removeUser}
      showRemoveUserModal={showRemoveUserModal}
      {...props} />
  );
}

export default Class;
