import { Formik } from "formik"
import React from 'react'
import { Modal } from "react-bootstrap"
import * as Yup from "yup"
import * as Const from '../../../constants/Const'

import ImageWithBanner from '../../../components/ImageWithBanner'
import useCheckdevice from '../../../hooks/useCheckdevice'
import classNames from 'classnames'
import { isDesktop, isMobile } from "react-device-detect"

function BannerLayout(props) {
    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))
    
    const {
        hideTabToZoom,
        file, handleChangeBanner, upload, fileTitle,
        showZoomModal, hideZoomModal, setShowZoomModal, imageRatio
    } = props

    const staticBanner = window.bannerImage
    let fileName = ""
    if (fileTitle.length > 25) {
        fileName = fileTitle.substring(0, 25) + "..."
    } else {
        fileName = fileTitle
    }

    const validateForm = Yup.object().shape({
        file: Yup.mixed().required('Required')
            .test('fileFormat', 'Image file only', (value) => {
                return value && ['png', 'jpg', 'jpeg', 'jfif'].includes(value.split('.').pop());
            }),
    })

    return (
        <>
            <div className="bg-xanh"></div>
            <main style={{ paddingTop: "80px" }}>
                <section className="box-xam">
                    <h4 className="text-center">Banner upload</h4>
                </section>
                <section className={classNames("container-fluid", [{"container-banner-desktop": isBrowserDesktop}])}>
                    <div className="p-4">
                        <div className="row">
                            <div className="col-6">
                                <Formik
                                    initialValues={{ file: undefined }}
                                    onSubmit={upload}
                                    validationSchema={validateForm}
                                >
                                    {(properties) => {
                                        const {
                                            touched,
                                            errors,
                                            handleSubmit,
                                            handleChange,
                                        } = properties;
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <div className="custom-file mb-3">
                                                    <input type="file" className="custom-file-input" name="file"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            handleChangeBanner(e)
                                                        }}
                                                    />
                                                    <label className="custom-file-label pl-3 text-white-50 custom-file-label-epen1" htmlFor="customFile">{fileName !== "" ? fileName : "Upload your banner here..."}</label>
                                                </div>
                                                {errors.file && touched.file && (
                                                    <div className="validate-input">* {errors.file}</div>
                                                )}
                                                <p className="font-Paytone mb-0">File  requirement:</p>
                                                <p>
                                                    Banner can be .jpg, .png.
                                                    File dimension should be 445px x 132px at 75 dpi for the best resolution outcome.
                                                </p>
                                                <p className="mb-5">
                                                    After Upload, file will be review. Please allow up to 24 hours before your banner to be proceed.
                                                </p>
                                                <button type="submit" className="btn btn-light font-Paytone btn-block mb-4">Apply</button>
                                            </form>
                                        )
                                    }}

                                </Formik>
                            </div>
                            <div className="col-6">
                                <span className="position-relative pos-a" onClick={() => { setShowZoomModal(true) }}>
                                    {
                                        <span className={`${hideTabToZoom ? 'chon-sach position-absolute' : 'fadeOut chon-sach position-absolute'}`} style={{ zIndex: 1000 }}>
                                            <div style={{}}>
                                                <i className="fas fa-search fa-6x"></i> <br />
                                                <span className="font-Paytone font-20">Tap to zoom</span>
                                            </div>

                                        </span>
                                    }
                                    <img style={{
                                        position: 'absolute', zIndex: 1,
                                        width: (Const.StaticBanner.WIDTH) + '%',
                                        height: parseFloat(Const.StaticBanner.HEIGHT * imageRatio) + '%',
                                        top: parseFloat(Const.StaticBanner.TOP * imageRatio) + '%',
                                        left: (Const.StaticBanner.LEFT) + '%'
                                    }}
                                        src={staticBanner.src} alt=""
                                    />

                                    <img style={{
                                        position: 'absolute',
                                        zIndex: 10,
                                        width: (Const.DynamicBanner.WIDTH) + '%',
                                        height: (Const.DynamicBanner.HEIGHT * imageRatio) + '%',
                                        top: (Const.DynamicBanner.TOP * imageRatio) + '%',
                                        left: (Const.DynamicBanner.LEFT) + '%'
                                    }} className="img-fluid" src={file} alt="" />
                                    <img id="Banner" className="img-fluid" src={window.location.origin + '/page-big.jpg'} alt="" />
                                </span>
                                <h5 className="font-Paytone text-center mt-2">Sample Preview</h5>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Modal
                show={showZoomModal}
                onHide={hideZoomModal}
                className="modal pl-0 pr-0 fade"
                size="xl"
                id="zoomModal"
            >
                <div className="modal-dialog modal-xl modal-dialog-centered mt-0 mb-0">
                    <div className="modal-content pte-auto">
                        <div className="modal-body" style={{ padding: 0, margin: 16 }}>
                            {/* <button type="button" className="close" onClick={hideZoomModal}>&times;</button> */}
                            <ImageWithBanner mainImage={'/page-big.jpg'} dynamicBanner={file} onClick={hideZoomModal} />
                            {/* <img id="zoomBanner" className="img-fluid" src={'/page-big.jpg'} alt="" onClick={hideZoomModal} />
                            <img style={{
                                position: 'absolute', zIndex: 1,
                                width: (Const.StaticBanner.WIDTH) + '%',
                                height: (Const.StaticBanner.HEIGHT) * imageRatio + '%',
                                top: (Const.StaticBanner.TOP) * imageRatio + '%',
                                left: (Const.StaticBanner.LEFT) + '%'
                            }} src={staticBanner.src} alt="" />
                            <img style={{
                                position: 'absolute',
                                zIndex: 10,
                                width: (Const.DynamicBanner.WIDTH) + '%',
                                height: (Const.DynamicBanner.HEIGHT) * imageRatio + '%',
                                top: (Const.DynamicBanner.TOP) * imageRatio + '%',
                                left: (Const.DynamicBanner.LEFT) + '%'
                            }} src={file} alt="" /> */}

                        </div>

                    </div>
                </div>
            </Modal>
        </>
    );
}

export default BannerLayout;