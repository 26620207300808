import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import {
  isDesktop, isMobile
} from "react-device-detect"

import useCheckdevice from '../../../../hooks/useCheckdevice'

ClientItem.propTypes = {
  item: PropTypes.object,
}

ClientItem.defaultProps = {
  item: {},
}

function ClientItem(props) {
  const { item } = props
  const device = useCheckdevice()
  const checkBrowser = (isDesktop && !isMobile && (device !== 'iPad'))
  const manageUrl = checkBrowser ? `/Vendor/ManageClient/${item._id}` : `/Vendor/Client/${item._id}`
  return (
    <div className="row mb-2 align-items-center" style={{marginRight: 0}}>
      <div className="col-6">
        <Link to={`/Vendor/ManageClient/${item._id}`} >{item.BusinessName ? item.BusinessName : item.FirstName + " " + item.LastName}</Link>
      </div>
      <div className="col-3 text-right">{item.Type}</div>
      <div className="col-3 text-center">
        <p className="lin1 ml-4 nav-link ">
          {
            checkBrowser &&
            <Link style={{ display: 'inline-block' }} className="mr-3" to={`/Vendor/Client/${item._id}`} >
              <i className="fas fa-user-edit"></i>
              <br />
              <small>Edit</small>
            </Link>
          }
          <Link style={{ marginLeft: 25, display: 'inline-block' }} className="mr-3" to={manageUrl}>
            <i className="fas fa-eye"></i>
            <br />
            <small>Manage</small>
          </Link>
        </p>
      </div>
    </div>
  )
}

export default ClientItem
