import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import {
	isDesktop,
	isTablet, isIPad13,
	isMobile
} from "react-device-detect";

import LayoutSearchDesktop from './../../../components/LayoutSearchDesktop'
import useCheckdevice from './../../../hooks/useCheckdevice'
import classNames from 'classnames'
import './style.css'
import { useSelector } from "react-redux"
import { Role } from '../../../constants/Const'
import * as Fc from '../../../utils/Functions'

function Browse(props) {
	let device = useCheckdevice()
	let userLoginRdx = useSelector(state => state.ws.userLogin)
	const isBrowserDesktop = (isDesktop && !isMobile && (device !== 'iPad'))
	const { listWorkBook, matchUrl, username } = props

	return (
		<>
			<div className="bg-xam77 overflow-hidden h-100"></div>
			{
				isMobile 
				?	<Link style={{ right: 100, bottom: 25 }} className="upload drop-shadow" to={`${props.matchUrl.url}/Upload`}>
						<i className="fas fa-upload fa-2x"></i>
					</Link> 
				:
					<Link 
						style={{ right: 100, bottom: 30, color:"#08222d", backgroundColor:"white",fontWeight: 900, fontSize: 23, zIndex: 120 }} 
						className="btn pl-40 pr-40 radius-20 position-fixed drop-shadow" 
						to={`${props.matchUrl.url}/Upload`}
					>Upload</Link>
			}
			{/* <Link style={{ right: 100 }} className="upload shadow" to={`${props.matchUrl.url}/Upload`}><i className="fas fa-upload fa-2x"></i></Link> */}
			{/* <Link className="upload shadow" to={`${props.matchUrl.url}/Upload`}><i className="fas fa-upload fa-2x"></i></Link> */}

			<main className={classNames({ "pt-73px ": !isBrowserDesktop, "pt-50px": isBrowserDesktop })}>
				{	(isDesktop && !isMobile) && <LayoutSearchDesktop username={username} /> }

				{	
					(isMobile || isTablet) 
					&& 	<section className="box-black mb-2 position-fixed bg-77a ">
							<p className="text-center font18 mb-0">{username}</p>
						</section>
				}

				<section className="container-fluid ">
					<div className="hidden-scrollbar-pos position-absolute t-110 desktop-interface-operator ">
						<div className="inner-pos " id="iScroll">
							<div className={classNames({ "row ": true, "rowIpad": !isBrowserDesktop, "rowDesktop": isBrowserDesktop })}>
								{listWorkBook.map((item, index) => {
									if(userLoginRdx 
										&& (	userLoginRdx.Role.includes(Role.VENDOR)
												|| (item.Category === "Concept" && userLoginRdx.AccessResourceConcept)
												|| (item.Category === "Foundation" && userLoginRdx.AccessResourceFoundation) 
											) 
									)	
									return (
										<div style={{}} 
											className={isBrowserDesktop ? "BrowseDesktop-Width mb-3" : "col-md-3 col-6 mb-3 "} 
											// className={isBrowserDesktop ? "line-7-items mb-3" : "col-3 mb-3"} 
											key={index.toString()}
										>
											<Link 	
												className="position-relative box-img mh-150" 
												to={`${matchUrl.url}/Workbook/${item._id}`}
											>
												<img className="img-fluid w-100" src={item.Cover} alt={item.Subject} />
												<span 
													style={{ background: 'rgba(0, 0, 0, 0.5)' }} 
													className={classNames("box-sp", "position-absolute", [{"bt-nagative-1": !isBrowserDesktop}])}
												>
													<em className="float-left">{item.Level}</em>
													<strong style={{ textAlign: 'left' }} className="align-middle">
														{
															item.Title.length > 27 
															? 	(item.Title.substring(0, 27) + "...") 
															: item.Title
														}
													</strong>
												</span>
											</Link>
										</div>
									)

									return (
										<div style={{}} className={isBrowserDesktop ? "BrowseDesktop-Width mb-3" : "col-3 mb-3"} key={index.toString()}>
											<div 	
												className="position-relative box-img mh-150 pointer" 
												onClick={() => Fc.showError("This account hasn't enable to access this yet")}
											>
												<img className="img-fluid w-100" src={item.Cover} alt={item.Subject} />
												<span 
													style={{ background: 'rgba(0, 0, 0, 0.5)' }} 
													className={classNames("box-sp", "position-absolute", [{"bt-nagative-1": !isBrowserDesktop}])}
												>
													<em className="float-left">{item.Level}</em>
													<strong style={{ textAlign: 'left' }} className="align-middle">
														{
															item.Title.length > 27 
															? 	(item.Title.substring(0, 27) + "...") 
															: item.Title
														}
													</strong>
												</span>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>

				</section>

			</main>
		</>
	);
}

export default Browse;