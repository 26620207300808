
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react"
import API from "../../../../utils/Api"
import * as Fc from "../../../../utils/Functions"
import * as Const from "../../../../constants/Const"
import ListClient from "./ListClient"

function ClientListing(props) {
  const [listClients, setListClients] = useState([])
  const [keyword, setKeyword] = useState(props.match.params.keyword || "")
  const [pageIndex, setPageIndex] = useState(1)
  const pageSize = 20
  const [total, setTotal] = useState(0)
  const [isCheckLoadMore, setIsCheckLoadMore] = useState(true)

  useEffect(() => {
    const el = document.getElementById("list")
    el.addEventListener("scroll", loadMore)
    return function cleanupListener() {
      el.removeEventListener("scroll", loadMore)
    }
  }, [pageIndex, total, isCheckLoadMore])

  function loadMore() {
    const el = document.getElementById("list")
    if (el.scrollTop + el.clientHeight > el.scrollHeight - 100) {
      if (pageSize * pageIndex >= total) {
        return null
      } else {
        if(isCheckLoadMore)
        setPageIndex(pageIndex + 1)
      }
    }
  }

  /**
  |--------------------------------------------------
  | refresh du lieu ve trang thai ban dau khi doi type,
  | hoac thay doi keyword
  |--------------------------------------------------
  */
  useEffect(() => {
    const list = document.getElementById("list")
    list.scrollTop = 0

    setIsCheckLoadMore(true)
    setKeyword(props.match.params.keyword || "")
    setPageIndex(1)

    if(listClients.length > 0)
    setListClients([])

    return () => {}
  }, [props.match.params.type, props.match.params.keyword])

  const getClients = () => {
    let operatorType = props.match.params.type 
      ? Const.clientType.filter(x => x.Type === props.match.params.type)[0].Value 
      : ""
      
    setIsCheckLoadMore(false)
    API.getClients(
      pageIndex,
      pageSize,
      props.match.params.keyword,
      operatorType
    )
      .then((res) => {
        setIsCheckLoadMore(true)
        if (res.status === 200) {
          if (res.data.Success) {
            if (res.data.Data) {
              let data = [...res.data.Data]

              data &&
                data.map((item) => {

                  let accountType = Const.clientType.filter(
                    (x) => x.Value === item.Type
                  )

                  item.AccountType =
                    accountType.length > 0 ? accountType[0].Type : null
                  if (!["Parents", "Individual"].includes(item.AccountType))
                    item.businessName = item.FirstName + " " + item.LastName
                })
              setListClients(pageIndex === 1 ? data : listClients.concat(data))
              setTotal(res.data.Total)
            } else {
              setListClients(pageIndex === 1 ? null : listClients)
              setTotal(res.data.Total)
            }
          } else {
            Fc.showError(res.data.Message)
          }

        } else {
          Fc.showError(res.statusText)
        }
      })
      .catch((err) => {
        setIsCheckLoadMore(true)
        Fc.showError("Can not connect to server")
      })
  }
  /**
  |--------------------------------------------------
  | lay client khi pageIndex thay doi, khong thuc hien doi voi lan dau
  |--------------------------------------------------
  */
  useEffect(() => {
    if(pageIndex > 1)
    getClients()
  }, [pageIndex])

  /**
  |--------------------------------------------------
  | chi thuc hien lay du lieu khi lan dau chay ung dung,
  | hoac chuyen tab: ALL, School, Bussiness ...
  |--------------------------------------------------
  */
  useEffect(() => {
    if(listClients.length <= 0)
    getClients()
  }, [listClients])

  function onSearch(e) {
    e.preventDefault()
    props.history.push(`/vendor/${props.match.params.type || "All"}/${keyword}`)
  }

  return (
    <ListClient
      onSearch={onSearch}
      setKeyword={setKeyword}
      type={props.match.params.type || "All"}
      keyword={keyword}
      listClients={listClients}
      {...props}
    />
  )
}

export default ClientListing
