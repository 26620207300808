import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as type from "../../../constants/actionType"
import * as Fc from '../../../utils/Functions'
import Note from './Note'
import "./style.css"
// import ModalAlertForTeacherStudent from './../../../components/components/ModalStudentTeacher'
import UploadDocuments from './UploadDocuments'
import Worksheet from './Worksheet'
import {
    isDesktop, isMobile
} from "react-device-detect"

import useCheckdevice from '../../../hooks/useCheckdevice'
import classNames from 'classnames'

function MyWorkLayout(props) {
    const role = Fc.getRole()
    const dispatch = useDispatch();
    const device = useCheckdevice()
    const checkBrowser = (isDesktop && !isMobile && (device !== 'iPad'))
    const wrapClassname = checkBrowser ? "desktop-interface-student" : ""
    const sessionClassname = checkBrowser ? "box-xam pt-3 pb-3 desktop-interface-student-item" : "box-xam pt-3 pb-3 mobile-fixed-top mobile-mt-58px z-4"
    const sessionStyle = checkBrowser ? { height: (window.innerHeight - 51 + 'px') } : {}
    const headerClassname = checkBrowser ? "desktop-interface-student-item" :  "mobile-mt-120px"
    let permissionWriteNote = useSelector(state => state.ws.permissionWriteNote)

    const { userInfo,
        listClass, classId,
        activeTab, setActiveTab,
        studentId, onSelectStudent,
        studentList,
        operatorId
    } = props

    studentList.sort((a, b) => {
        if(a.Code.toLowerCase() < b.Code.toLowerCase()) return -1;
        if(a.Code.toLowerCase() > b.Code.toLowerCase()) return 1;
        return 0;
    })


    function setWsActiveTab(activeTab) {
        dispatch({ type: type.SET_ACTIVE_TAB_MYWORK, activeTab: activeTab })
        // setActiveTab(activeTab)
    }

    return (
        <>
            <div className="bg-xanh"></div>
            <main
                className={classNames({ "pt-73px": !checkBrowser, "pt-50px": checkBrowser}, wrapClassname)}
            >
                <section
                    id={'scrollTop'}
                    className={sessionClassname}
                >
                    <div className={classNames("row", [{"mt-desktop-4": checkBrowser}])}>
                        {   role !== "STUDENT" 
                            ?
                                <div className="w-res mx-auto mb-4">
                                    <select className="custom-select custom-select100 text-white pointer"
                                        value={studentId}
                                        onChange={e => {
                                            onSelectStudent(studentId, e.target.value)
                                            dispatch({ type: type.CHANGE_NOT_RELOAD, payload: false })
                                        }}
                                    >
                                        {
                                            studentList && studentList.sort().map((item, index) => (
                                                <option key={index.toString()} value={item._id}>{item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1)+' '+item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)} - {item.Code}</option>
                                            ))
                                        }

                                    </select>
                                </div> 
                            :
                                <div className="col-6 offset-3 text-center">
                                    <p>{userInfo.Code}</p>
                                </div>
                        }
                        <div className="col-12 text-center mobile-d-none">
                            {
                                permissionWriteNote 
                                ?   <button type="button" className={activeTab === "Note" ? 'mr-1 btn font-Paytone pl-3 pr-3 text-white-50 btn_white' : "mr-1 btn font-Paytone pl-3 pr-3 text-white-50 btn_black"} onClick={() => setWsActiveTab('Note')}>Notes</button> 
                                :   <button type="button" className={"mr-1 btn font-Paytone pl-3 pr-3 text-white-50 btn_white"} disabled>Notes</button>
                            }
                            <button type="button" className={activeTab === "Workbook" ? 'mr-1 btn font-Paytone pl-3 pr-3 text-white-50 btn_white' : "mr-1 btn font-Paytone pl-3 pr-3 text-white-50 btn_black"} onClick={() => setWsActiveTab('Workbook')}>Worksheet</button>
                            {
                                checkBrowser
                                ?   <button type="button" className={activeTab === "Upload" ? "mr-1 btn font-Paytone radius10 pl-3 pr-3 text-white-50 btn_white" : "mr-1 btn font-Paytone radius10 text-white-50 pl-3 pr-3 btn_black"} onClick={() => setWsActiveTab('Upload')}>
                                        Upload
                                    </button>
                                :   <button type="button" className={activeTab === "Upload" ? "mr-1 btn font-Paytone radius10 pl-3 pr-3 btn_white" : "mr-1 btn font-Paytone radius10 pl-3 pr-3 btn_black text-white-50"} onClick={() => setWsActiveTab('Upload')}>
                                        <i className="fas fa-upload"></i>
                                    </button>
                            }
                        </div>
                    </div>
                </section>
                <div className={headerClassname}>
                    <Note {...props} display={activeTab === "Note"} studentId={studentId} />

                    <Worksheet studentId={studentId} classId={classId} listClass={listClass} operatorId={operatorId}
                        display={activeTab === "Workbook"} {...props} />

                    <UploadDocuments studentId={studentId}
                        display={activeTab === "Upload"} props />
                </div>

            </main>
        </>
    );
}

export default MyWorkLayout;