import React from 'react';
import { Link } from 'react-router-dom'
import Select, { components } from 'react-select'
import * as Const from "../../../../constants/Const"

import UploadDocuments from '../../../../components/UploadDocuments'
import { isDesktop, isMobile } from 'react-device-detect';
import useCheckdevice from '../../../../hooks/useCheckdevice'
import classNames from 'classnames'

function UploadView(props) {
    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))

    const {
        role, userId,
        keyword, setKeyword,
        submitKeyword,
        onSearch,
        teacherOptions,
        getListTeachers,
        teacher, setTeacher,
        matchUrl } = props

    return (
        <>
            <div className="bg-xam77"></div>
            {
                !isBrowserDesktop 
                ?   <Link 
                        style={{ right: 100, bottom: 25 }} 
                        className="upload drop-shadow" to={matchUrl.url.substring(0, matchUrl.url.length - 7)}>
                        <i className="fas fa-book fa-2x"></i>
                    </Link>
                :   <Link 
                        style={{ right: 100, bottom: 30, color: "#08222d" , backgroundColor:"white", fontSize: 23, fontWeight: 900, zIndex: 120 }} 
                        className="btn pl-40 pr-40 radius-20 position-fixed drop-shadow" 
                        to={matchUrl.url.substring(0, matchUrl.url.length - 7)}
                    >Books</Link>
            }
            <div className={classNames({ "pt-73px": !isBrowserDesktop, "pt-50px": isBrowserDesktop })} >
                <section className="box-black6 mb-4">
                    <div className="row">
                        <div className={isMobile ? "col-md-6 mx-auto" : "col-3 offset-1"}>
                            <div className="input-group">
                                <Select
                                    className="form-control"
                                    value={teacher}
                                    options={teacherOptions}
                                    onInputChange={getListTeachers}
                                    onChange={setTeacher}
                                    placeholder="Enter teacher ID"
                                    styles={customSelectTeacherStyles}
                                    components={{ DropdownIndicator }}
                                    isClearable
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container-fluid" id="list">
                    <div className="row mb-4">
                        <div className={classNames({"col-3": isBrowserDesktop, "col-md-6": !isBrowserDesktop})}>
                            <form className="input-group h-100" onSubmit={onSearch}>
                                <input type="text" className="form-control h-100" placeholder="Enter Note name" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                                <div className="input-group-append ml-0 border-0" style={{ zIndex: 0 }}>
                                    <button className="btn bg-input h-100" type="submit"><i className="fas fa-search text-white"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <UploadDocuments keyword={submitKeyword} role={role} userId={userId} props />
                </section>
            </div>
        </>
    );
}

const customSelectTeacherStyles = {
    ...Const.customSelectStyles,
    singleValue: (provided, state) => ({
        ...provided,
        color: '#858585',
    })
}

export default UploadView;

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="fa fa-search text-white"></i>
        </components.DropdownIndicator>
    );
};