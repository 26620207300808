import React from "react"
import { Modal } from 'react-bootstrap'
import { Swipeable } from 'react-swipeable'
import * as Const from "../../../constants/Const"
import 'bootstrap'
import useCheckdevice from '../../../hooks/useCheckdevice'
import { isDesktop, isMobile } from "react-device-detect"

//component
import Stopwatch from "../../../components/Stopwatch"
import ModalAlertOperator from "../../../components/ModalAlertOperator"
import ModalStudentTeacher from "../../../components/ModalStudentTeacher"
import ModalYesOrNo from "../../../components/ModalYesOrNo"
import ImageWithBanner from '../../../components/ImageWithBanner'
import EditToolbarView from './EditToolbarView'
import ChangePageView from './ChangePageView'
import ZoomPageView from './ZoomPageView'

// const formulas = require('../../../static/formulas.pdf')
let formulas = []
const formulaPages = 11
for (let index = 0; index < formulaPages; index++) {
    formulas[index] = require(`../../../static/images/formulas/formulas-${(index + 1).toString().padStart(2, '0')}.jpg`)
}

const listFontSize = [8, 9, 10, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96]
const listFontFamily = [
    { text: 'Arial', font: 'Arial, Helvetica, sans-serif' },
    { text: 'Arial Black', font: '"Arial Black", Gadget, sans-serif' },
    { text: 'Comic Sans MS', font: '"Comic Sans MS", cursive, sans-serif' },
    { text: 'Courier New', font: '"Courier New", Courier, monospace' },
    { text: 'Georgia', font: 'Georgia, serif' },
    { text: 'Impact', font: 'Impact, Charcoal, sans-serif' },
    { text: 'Lucida Console', font: '"Lucida Console", Monaco, monospace' },
    { text: 'Lucida Sans Unicode', font: '"Lucida Sans Unicode", "Lucida Grande", sans-serif' },
    { text: 'Palatino Linotype', font: '"Palatino Linotype", "Book Antiqua", Palatino, serif' },
    { text: 'Paytone', font: '"Paytone", sans-serif' },
    { text: 'Raleway', font: '"Raleway", sans-serif' },
    { text: 'Tahoma', font: 'Tahoma, Geneva, sans-serif' },
    { text: 'Times New Roman', font: '"Times New Roman", Times, serif' },
    { text: 'Trebuchet MS', font: '"Trebuchet MS", Helvetica, sans-serif' },
    { text: 'Verdana', font: 'Verdana, Geneva, sans-serif' },
]

export default function EditView(props) {
    const {
        sketchpad,
        pageIndexTemp, setPageIndexTemp,
        totalPage,
        worksheet,
        banner,

        menuPosition,
        toolId,

        showFormulaModal,
        hideFormulaModal,

        cropGraph,
        onClickShape,
        changePage,
        onIncreasePage,
        onDecreasePage,
        onZoomInPage,
        onZoomOutPage,
        onFitPage,
        zoomValue,
        onChangeBackgroundSketchpad,

        showNote,
        pageOfNote,
        pageIndexNoteTmp,
        textPageindexNote,
        changePageNote,
        onChangePageNote,
        setPageIndexNoteTmp,
        onIncreasePageNote,
        onDecreasePageNote,

        showSolution,
        solutionPageIndex,
        onSwipeLeft,
        onSwipeRight,
        onSwipeUp,
        onSwipeDown,

        isShowShap,
        hideShap,
        showQuestionClearAll,
        isShowRefreshBrowser,
        denyRefreshBr,
        acceptRefreshBr,
        acceptClearAll,
        denyClearAll,

        //Account restrict
        role,
        accountMethod,
        trialDayLeft,
        showDayLeftModal, onHideDayLeftModal,
        showContactAdminModal, onHideContactAdminModal,
        showFeatureLockedModal, onHideFeatureLockedModal,

        hideEquationModal,
        showEquationModal,
        insertEquation,
    } = props

    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))
    const isRestricted = role !== Const.Role.VENDOR && accountMethod === Const.AccountMethod.RESTRICTED

    const ratioImgAssign = 0.77275886031966
    const H = isBrowserDesktop ? window.innerHeight - 51 : window.innerHeight - 80
    const W = window.innerWidth - 65

    const realW = isBrowserDesktop ? H*ratioImgAssign : W
    const realH = isBrowserDesktop ? realW/ratioImgAssign : realW/ratioImgAssign

    const desktopStyle = isBrowserDesktop ? {width: realW*parseFloat(zoomValue), height: realH*parseFloat(zoomValue)} : {}
    const ipadStyle = !isBrowserDesktop ? {width: realW*parseFloat(zoomValue), height: realH*parseFloat(zoomValue)} : {}
    const imgFluidDesktopStyle = isBrowserDesktop ? {width: window.innerWidth*0.6, marginLeft: window.innerWidth*0.2} : {}
    
    
    return (
        <>
            <div className="bg-xam77"></div>
            <main style={{ paddingTop: isBrowserDesktop ? 51 : 80 }} className=''>
                {/* ================ Exercise ================= */}
                {
                    !showSolution && !showNote &&
                    <section className={`container-fluid ${!showSolution ? "fadeIn" : "fadeOut"}`} >
                        {   
                            toolId === "null" && !isBrowserDesktop
                            &&  <Swipeable
                                    className=''
                                    onSwipedRight={onSwipeRight}
                                    onSwipedLeft={onSwipeLeft}
                                    onSwipedUp={onSwipeUp}
                                    onSwipedDown={onSwipeDown}
                                    style={{ position: "fixed", zIndex: 3, left: 0, right: 0 }}
                                >
                                    <div style={{ width: window.screen.width, height: window.screen.height - 80 }}></div>
                                </Swipeable>
                        }
                        <div className="outersec pt-0 pb-0">
                            <EditToolbarView
                                {...props}
                                listFontFamily={listFontFamily}
                                listFontSize={listFontSize}
                                isRestricted={isRestricted}
                                isNote={false}
                                onClickShape={onClickShape}
                                isBrowserDesktop={isBrowserDesktop}
                            
                            />
                            <div className={`${menuPosition === "left" ? "left-col1" : "left-col2"} ${sketchpad.canvas ? "fadeIn" : "fadeOut"}`} id="huynh">
                                <div style={{ position: "relative", ...desktopStyle}}>
                                    <div 
                                        className={`inner`} 
                                        id="sketchpad"
                                    >
                                        {/* <img className="img-fluid mb-2" src={pagebigpath} alt="" /> */}
                                    </div>
                                    <div id="scaleWrapper" style={{ position: 'absolute', top: 0 }}>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <Stopwatch />

                        {
                            isBrowserDesktop &&
                            <ZoomPageView 
                                onZoomIn={onZoomInPage}
                                onZoomOut={onZoomOutPage}
                                onFitPage={onFitPage}
                            />
                        }
                        
                        {
                            <ChangePageView 
                                onKeyPress={changePage}
                                value={pageIndexTemp}
                                onChange={e => setPageIndexTemp(e.target.value)}
                                totalPage={totalPage}
                                onRightClick={onIncreasePage}
                                onLeftClick={onDecreasePage}
                            />
                        }
                        
                    </section>
                }
                {/* ================ Exercise ================= */}


                {/* ================= Add note ================= */}
                {
                    !showSolution && showNote &&
                    <section className={`container-fluid ${!showSolution && showNote ? "fadeIn" : "fadeOut"}`} >
                        {
                            toolId === "null" && !isBrowserDesktop
                            &&  <Swipeable
                                    onSwipedRight={onSwipeRight}
                                    onSwipedLeft={onSwipeLeft}
                                    onSwipedUp={onSwipeUp}
                                    onSwipedDown={onSwipeDown}
                                    style={{ position: "absolute", zIndex: 3, left: 0, right: 0 }}
                                >
                                    <div style={{ width: window.screen.width, height: window.screen.height - 80 }}></div>
                                </Swipeable>
                        }

                        <div className="outersec pt-0 pb-0">
                            <EditToolbarView
                                {...props}
                                listFontFamily={listFontFamily}
                                listFontSize={listFontSize}
                                isRestricted={isRestricted}
                                onChangeBackgroundSketchpad={onChangeBackgroundSketchpad}
                                isNote={true}
                                onClickShape={onClickShape}
                                isBrowserDesktop={isBrowserDesktop}
                           
                           />
                            <div className={`${menuPosition === "left" ? "left-col1" : "left-col2"} ${sketchpad.canvas ? "fadeIn" : "fadeOut"}`}>
                                <div style={{ position: "relative", ...desktopStyle, ...ipadStyle}}>
                                    <div className={`inner`} id="sketchpadNote">
                                        <canvas id='canvasImg'  style={{display: 'none'}}/>
                                    </div>

                                    <div id="scaleWrapperNote" style={{ position: 'absolute', top:  0 }}>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Stopwatch />

                        {
                            isBrowserDesktop &&
                            <ZoomPageView 
                                onZoomIn={onZoomInPage}
                                onZoomOut={onZoomOutPage}
                                onFitPage={onFitPage}
                            />
                        }

                            <ChangePageView 
                                onKeyPress={changePageNote}
                                value={textPageindexNote}
                                onChange={onChangePageNote}
                                totalPage={pageOfNote}
                                onRightClick={onIncreasePageNote}
                                onLeftClick={onDecreasePageNote}
                            />

                    </section>
                }
                {/* ================= End add note ============= */}

                {/* ================= Solution =================  */}
                {
                    showSolution && worksheet.Exercise && worksheet.Exercise.Solutions && worksheet.Exercise.Solutions.length > 0 &&
                    <section className={`container-fluid ${showSolution ? "fadeIn" : "fadeOut"}`} style={{ position: 'absolute', top: isBrowserDesktop ? 51 : 80 }}>
                        <Swipeable
                            onSwipedRight={onSwipeRight}
                            onSwipedLeft={onSwipeLeft}
                        >
                            <div style={{ position: "relative", ...desktopStyle}}>
                                {
                                    (worksheet.Exercise.Workbook.Category === "Concept") ?
                                        <img className="img-fluid" src={worksheet.Exercise.Solutions[solutionPageIndex].Url} alt="" /> :
                                        <ImageWithBanner mainImage={worksheet.Exercise.Solutions[solutionPageIndex].Url} dynamicBanner={banner} />
                                }
                            </div>
                        </Swipeable>
                    </section>
                }
                {/* ================= End  Solution =================  */}


                {/*================  Equation  ====================  */}
                <Modal
                    show={showEquationModal}
                    onHide={hideEquationModal}
                    className="modal pl-0 pr-0 fade"
                    size="xl"
                    // bsClass="my-modal"
                >
                    <div className="modal-dialog modal-xl modal-dialog-centered mt-0 mb-0">
                        <div className="modal-content pte-auto">
                            <div className="modal-body">
                                <div className='math-wrap'>
                                    <div style={{width:500, height:300}} id="editorContainer"></div>
                                    <div className="math-wrap-btn">
                                        <div className="math-insert-btn" onClick={insertEquation}>Insert</div>
                                        <div className="math-cancel-btn" onClick={hideEquationModal}>Cancel</div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </Modal>
                {/*================ End Equation  ====================  */}

                {/*================  Formulas  ====================  */}
                <Modal
                    show={showFormulaModal}
                    onHide={hideFormulaModal}
                    className="modal pl-0 pr-0 fade"
                    size="xl"
                    bsClass="my-modal"
                >
                    <div className="modal-dialog modal-xl modal-dialog-centered mt-0 mb-0">
                        <div className="modal-content pte-auto">
                            <div className="modal-body" onClick={hideFormulaModal}>
                                <button style={{ width: 33, height: 33, marginRight: 20 }} type="button" className="close" onClick={hideFormulaModal}>&times;</button>
                                <div className="huynh">
                                {formulas.map((item, index) => {
                                    return (
                                        <img className="img-fluid" style={imgFluidDesktopStyle} src={item} alt="" key={index.toString()} />
                                    )
                                })}
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                {/*================ End Formulas  ====================  */}

                    
                {/* =======show modal manual shap tap and drag===== */}
                <Modal
                    show={false}
                    onHide={hideShap}
                    className="modal pl-0 pr-0 fade"
                    size="xl"
                >
                    <div className="modal-dialog modal-xl modal-dialog-centered mt-0 mb-0" style={{zIndex: 9999999999}}>
                        <div className="modal-content pte-auto">
                            <div className="modal-body position-relative" onClick={hideShap}>
                                <div className="box-tb font-Paytone tran-80 font-23">
                                    Tap and Drag to draw your shape
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/*================  End show modal manual shap tag and drag ====================  */}

                
                {/*================  Graphing calculator modal ====================  */}
                <div id="graphingCalculatorModal" className="modal pl-0 pr-0 fade">
                    <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                        <div className="modal-content pte-auto">
                            {/* <button type="button" className="close" onClick={cropGraph} >&times;</button> */}
                            <div className="modal-body" style={{ alignSelf: "center" }}>
                                <button type="button" style={{ width: 33, height: 33 }} data-dismiss="modal" className="close close1a">×</button>
                                <button type="button" style={{ paddingLeft: 9 }} className="chup_mh" onClick={cropGraph}><i className="fas fa-expand"></i></button>
                                <div id="graphingCalculator" 
                                    style={{ width: isBrowserDesktop ? window.screen.width * 0.5 : window.screen.width * 0.9, height: isBrowserDesktop ? window.screen.width * 0.5*0.5 : window.screen.width * 0.9 * 0.7 }} 
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*================ End Graphing calculator modal ====================  */}
                

                {/*================  Calculator modal ====================  */}
                <div id="calculatorModal" className="modal pl-0 pr-0 fade">
                    <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                        <div className="modal-content pte-auto">
                            {/* <button type="button" className="close" onClick={cropGraph} >&times;</button> */}
                            <div className="modal-body" style={{ alignSelf: "center" }}>
                                <button type="button" style={{ width: 33, height: 33 }} data-dismiss="modal" className="close close1a">×</button>
                                <div id="Scientific" className="dcg-scientific-container" style={{ width: isBrowserDesktop ? window.screen.width * 0.5 : window.screen.width * 0.9, height: isBrowserDesktop ? window.screen.width * 0.5*0.5 : window.screen.width * 0.9 * 0.7 }} ></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*================ End Calculator modal ====================  */}


                {/* Account restrict */}
                <ModalAlertOperator
                    showModal={showDayLeftModal}
                    alertMessage={`This tool will be locked after ${trialDayLeft} ${trialDayLeft > 1 ? "days" : "day"}`}
                    functionClose={onHideDayLeftModal}
                    role={role}
                    AccountMethod={accountMethod}
                    keyStorage="PopUpToolDayLeft"
                />

                <ModalStudentTeacher
                    showModal={showContactAdminModal}
                    message="Please contact your administrator for more infomation"
                    functionClose={onHideContactAdminModal}
                    role={role}
                    AccountMethod={accountMethod}
                />
                <ModalAlertOperator
                    showModal={showFeatureLockedModal}
                    functionClose={onHideFeatureLockedModal}
                    alertMessage={`This feature is locked. Upgrade now to use all Epen App features`}
                    role={role}
                    AccountMethod={accountMethod}
                    keyStorage="PopUpToolDayLeft"
                />

                {/* =============== Clear All question =============== */}
                <ModalYesOrNo 
                    title={"Clear all cannot be undo"}
                    showModal={showQuestionClearAll}
                    functionYes={acceptClearAll}
                    functionNo={denyClearAll}
                />

                {/* =============== Refresh browser question =============== */}
                <ModalYesOrNo 
                    title={"There is a problem with your network connection, please refresh your browser to save your work!"}
                    showModal={isShowRefreshBrowser}
                    functionYes={acceptRefreshBr}
                    functionNo={denyRefreshBr}
                />
            </main>
        </>
    )
}