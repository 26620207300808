import React from 'react';
import { Link } from 'react-router-dom'
import Select, { components } from 'react-select'
import * as Const from "../../../constants/Const"

import UploadDocuments from '../../../components/UploadDocuments'
import { isDesktop, isMobile } from 'react-device-detect';
import useCheckdevice from '../../../hooks/useCheckdevice'
import classNames from 'classnames'

function UploadView(props) {
    const checkDevice = useCheckdevice()
    const isBrowserDesktop = (isDesktop && !isMobile && (checkDevice !== 'iPad'))   

    const {
        userInfo,
        keyword, setKeyword,
        submitKeyword,
        onSearch,
        matchUrl 
    } = props

    return (
        <>
            <div className="bg-xam77"></div>
            {
                !isBrowserDesktop 
                ?   <Link 
                        style={{ right: 100, bottom: 25 }} 
                        className="upload drop-shadow" 
                        to={matchUrl.url.substring(0, matchUrl.url.length - 7)}
                    >
                        <i className="fas fa-book fa-2x"></i>
                    </Link>
                :   <Link 
                        style={{ right: 100, bottom: 30, color: "#08222d", backgroundColor: "white", fontSize: 23, fontWeight: 900, zIndex: 120 }} 
                        className="btn pl-40 pr-40 radius-20 position-fixed drop-shadow" 
                        to={matchUrl.url.substring(0, matchUrl.url.length - 7)}
                    >Books</Link>
            }
            <div className={classNames({ "pt-73px": !isBrowserDesktop, "pt-50px": isBrowserDesktop })} >
                <div className="position-fixed bg-77a">
                    <section className="box-black6 mb-4">
                        <p className={classNames("font18", "mb-0", [{"text-center": !isBrowserDesktop, "text-left": isBrowserDesktop}])}>{userInfo && userInfo.Code || ''}</p>
                    </section>
                    <section className="container-fluid">
                        <div className="row mb-4">
                            <div className={classNames({"col-3": isBrowserDesktop, "col-md-6": !isBrowserDesktop})}>
                                <form className="input-group h-100" onSubmit={onSearch}>
                                    <input type="text" className="form-control h-100" placeholder="Enter Note name" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                                    <div className="input-group-append ml-0 border-0 h-100" style={{ zIndex: 0 }}>
                                        <button className="btn bg-input h-100" type="submit"><i className="fas fa-search text-white"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
                <section className="container-fluid">
                    <div className="hidden-scrollbar-pos position-absolute up-tl" style={{ top: isBrowserDesktop? 160 : 180 }}>
                        <div className="inner-pos" id="list" style={{paddingTop: 22}}>
                            <UploadDocuments keyword={submitKeyword} role={'Teacher'} userId={userInfo._id} props />
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default UploadView;
